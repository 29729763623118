import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../styles/BasicStyles';
import { GetUserProcessFullList, DeleteLemania, GetLemaniaList } from '../../infra/requests/ProcessRequests';
import { GetAdminList } from '../../infra/requests/UsersRequests';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Icon } from 'antd';
import React, { Component } from 'react';
import BaseButton from '../../components/generic/buttons/BaseButton';
import LemaniaFilters from './LemaniaFilters';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import NewLemaniaModal from './NewLemaniaModal';
import EditLemaniaModal from './EditLemaniaModal';
import LemaniaDetail from '../process/edit/Lemania/LemaniaDetail';

class LemaniaPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '20%',
      },
      {
        title: 'Processo',
        dataIndex: 'process',
        render: (value) => value?.ref,
        width: '18%'
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        render: (value) => value,
        width: '32%',
      },
      {
        title: 'Valor total',
        key: 'total_value',
        render: (data) => this.renderTotalValue(data),
        width: '18%',
      },
      {
        title: 'Ações',
        key: 'actions',
        render: (data) => this.renderActions(data)
      },
      {
        title: '',
        key: 'expand',
        render: (data) => this.renderExpandIcon(data)
      }
    ],
    rows: [],
    total: 0,
    process: [],
    newModal: false,
    editModal: false,
    expanded: [],
    responsibles: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { user } = this.props;

    return (
      <>
        {
          // Partners can only see the information
          !data.process.archived && user?.type !== 3 &&
          <ButtonActions 
            icon={'dots'} 
            iconType={'fill'} 
            options={[
              {
                onClick: () => this.openEditModal(data),
                label: 'Editar' 
              },
              {
                popConfirmtitle: 'Tem a certeza que quer remover esta conta Lemania?',
                onClick: () => this.removeLemania(data),
                label: 'Remover' 
              }
            ]} 
          />
        }
      </>
    );
  };

  renderTotalValue = (data) => {
    const total = data.funds?.length > 0 ? data.funds.reduce((accum, elem) => accum + elem.amount, 0) : 0;

    return `${total}CHF`;
  }

  removeLemania = async (data) => {
    try {
      await DeleteLemania(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters } = this.state;
      const { user } = this.props;

      const result = await GetLemaniaList(currentPage, pageSize, filters);
      const process = await GetUserProcessFullList();
      const responsibles = user?.type === 1 ? await GetAdminList() : [];

      const processList = [];
      if(process?.data?.length > 0) {
        process.data.forEach(elem => {
          processList.push({
            _id: elem._id,
            name: `${elem.ref} - ${elem.full_name}`
          })
        });
      }
      
      this.setState({
        rows: result.data?.items || [],
        total: result.data?.total || 0,
        process: processList,
        responsibles: responsibles?.data || [],
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  openEditModal = (data) => {
    const { dispatch } = this.props;

    dispatch(initialize('edit_lemania_general_form', { ...data, process: data.process._id }));

    this.setState({ editModal: true });
  }

  onExpandRow = (_, record) => {
    const { expanded } = this.state;

    const index = expanded.findIndex(elem => elem._id === record._id);

    const aux = [...expanded];

    // If the current record already exists in the array, we have to invert the "expanded" status of that element
    if(index >= 0) {
      aux[index].expanded = !aux[index].expanded;
    }
    // If not, add to the array and mark it as "expanded"
    else {
      aux.push({ _id: record._id, expanded: true });
    }

    this.setState({ expanded: aux });
  }

  renderExpandIcon = (data) => {
    const { expanded } = this.state;

    const elem = expanded.find(elem => elem._id === data._id);

    return <Icon type={elem?.expanded ? 'up' : 'down'} />;
  }

  render() {
    const { user } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize, process, newModal, editModal, filters, responsibles } = this.state;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lemania</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            {
              user?.type !== 3 &&
              <BaseButton
                customIcon={'plus'}
                text={'Adicionar lemania'}
                onClick={() => this.setState({ newModal: true })}
              />
            }
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <LemaniaFilters 
            queryChange={this.handleFilterChange} 
            processList={process}
            responsibles={responsibles}
            user={user}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem contas Lemania inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={(data) => <LemaniaDetail lemania={data} filtersString={filters} />}
            expandRowByClick={true}
            expandIconAsCell={false}
            onExpand={(expanded, record) => this.onExpandRow(expanded, record)}
          />
        </PageContainer>
        <NewLemaniaModal
          openModal={newModal}
          closeModal={() => this.setState({ newModal: false })}
          getInfo={this.updateDataTable}
          processList={process}
        />
        <EditLemaniaModal
          openModal={editModal}
          closeModal={() => this.setState({ editModal: false })}
          getInfo={this.updateDataTable}
          processList={process}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(LemaniaPage);
