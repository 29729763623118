import { ToggleWrapper, ToggleButton } from '../styles';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Updates from './Updates';
import Logs from './Logs';
import EmailLogs from './EmailLogs';

const Activity = ({ processInfo, currentResponsible, addressList, reloadTab, showLogsPage, users, user }) => {
  const [activeTab, setActiveTab] = useState((!!processInfo.agent || showLogsPage) ? 2 : 1);

  return (
    <>
      {
        !processInfo.agent ?
        <ToggleWrapper>
          <ToggleButton active={activeTab === 1} onClick={() => setActiveTab(1)}>Atualizações</ToggleButton>
          <ToggleButton active={activeTab === 2} onClick={() => setActiveTab(2)}>Logs</ToggleButton>
          { user.type === 1 && <ToggleButton active={activeTab === 3} onClick={() => setActiveTab(3)}>Email Logs</ToggleButton> }
        </ToggleWrapper>
        :
        processInfo.agent && user.type === 1 ?
        <ToggleWrapper>
          <ToggleButton active={activeTab === 2} onClick={() => setActiveTab(2)}>Logs</ToggleButton>
          <ToggleButton active={activeTab === 3} onClick={() => setActiveTab(3)}>Email Logs</ToggleButton>
        </ToggleWrapper>
        :
        null
      }
      {
        activeTab === 1 ?
        <Updates 
          processInfo={processInfo}
          currentResponsible={currentResponsible}
          addressList={addressList}
          reloadTab={reloadTab}
        />
        :
        activeTab === 2 ?
        <Logs 
          processInfo={processInfo}
          reloadTab={reloadTab}
          users={users}
        />
        :
        activeTab === 3 ?
        <EmailLogs 
          processInfo={processInfo}
        />
        :
        null
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Activity);
