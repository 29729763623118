import styled from 'styled-components';

export const LemaniaWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 20px;
`;

export const HeaderLine = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ type }) => type === 2 ? '#F1F3F5' : '#DEE2E6'};
`;

export const HeaderColumn = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
  padding: 12px 10px;
  width: ${({ width }) => width};

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowColumn = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #343A40;
  padding: 25px 10px;
  width: ${({ width }) => width};

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const NoLemaniaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

export const NoLemaniaText = styled.div`
  text-align: center;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ADB5BD;
`;

export const NoFunds = styled.div`
  width: 100%;
  color: #868E96;
  padding: 12px 24px;
  text-align: center;
`;

export const LemaniaStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ error }) => error ? '#C92A2A' : '#087F5B' };
  margin-top: 10px;
`;
