/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../../styles/BasicStyles';
import { GetClientsList } from '../../../infra/requests/ClientsRequests';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { GetAdminList, GetAgentsList, GetPartnersList } from '../../../infra/requests/UsersRequests';
import { GetProcessList, ToggleProcessArchive } from '../../../infra/requests/ProcessRequests';
import { renderProcessPaymentState, renderProcessState } from '../../../infra/utils/ProcessStates';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import ButtonActions from '../../../components/generic/buttons/ButtonActions';
import Filters from './Filters';
import Table from '../../../components/generic/table/Table';

const ListProcessPage = ({ user, history }) => {
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState('{"archived":false}');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [agents, setAgents] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        const services = (user?.type === 1 || user?.type === 3) ? await GetServicesList() : [];
        const users = user?.type === 1 ? await GetAdminList() : [];
        const agents = user?.type === 1 ? await GetAgentsList() : [];
        const partners = user?.type === 1 ? await GetPartnersList() : [];
        const clients = user?.type === 1 ? await GetClientsList() : [];

        const responsibles = users?.data || [];
        responsibles.unshift({
          _id: 'no_user',
          name: 'Sem responsável'
        });

        let columns = [];

        if(user?.type === 2) {
          columns = [
            {
              title: 'Ref. Processo',
              dataIndex: 'ref',
              render: (value) => value,
              width: '14%'
            },
            {
              title: 'Data de Compra',
              dataIndex: 'purchase_date',
              render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
              width: '14%'
            },
            {
              title: 'Nome',
              dataIndex: 'full_name',
              render: (value) => value,
              width: '20%'
            },
            {
              title: 'Estado do Processo',
              dataIndex: 'state',
              render: (value) => renderProcessState(value),
              width: '18%'
            },
            {
              title: 'Estado de Pagamento',
              key: 'process_state_payment',
              render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
              width: '18%'
            },
            {
              title: 'Valor pendente',
              dataIndex: 'pending_value',
              width: '10%'
            },
            {
              title: 'Ações',
              render: (data) => 
                <ButtonActions 
                  icon={'dots'} 
                  iconType={'fill'} 
                  options={[
                    {
                      onClick: () => history.push(`/process/${data._id}`),
                      label: 'Editar' 
                    },
                    {
                      popConfirmtitle: 'Tem a certeza que quer arquivar o processo?',
                      onClick: () => archiveProcess(data),
                      label: 'Arquivar' 
                    }
                  ]} 
                />
            }
          ];
        }
        else if(user?.type === 3) {
          columns = [
            {
              title: 'Ref. Processo',
              dataIndex: 'ref',
              render: (value) => value,
              width: '10%'
            },
            {
              title: 'Data de Compra',
              dataIndex: 'purchase_date',
              render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
              width: '10%'
            },
            {
              title: 'Nome',
              dataIndex: 'full_name',
              render: (value) => value,
              width: '20%'
            },
            {
              title: 'Serviço',
              dataIndex: 'service',
              render: (value) => value?.title?.pt,
              width: '20%'
            },
            {
              title: 'Estado do Processo',
              dataIndex: 'state',
              render: (value) => renderProcessState(value),
              width: '20%'
            },
            {
              title: 'Estado de Pagamento',
              key: 'process_state_payment',
              render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
              width: '20%'
            }
          ];
        }
        else {
          columns = [
            {
              title: 'Ref. Processo',
              dataIndex: 'ref',
              render: (value) => value,
              width: '8%'
            },
            {
              title: 'Data de Compra',
              dataIndex: 'purchase_date',
              render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
              width: '10%'
            },
            {
              title: 'Nome',
              dataIndex: 'full_name',
              render: (value) => value,
              width: '18%'
            },
            {
              title: 'Serviço',
              dataIndex: 'service',
              render: (value) => value?.title?.pt,
              width: '17%'
            },
            {
              title: 'Estado do Processo',
              dataIndex: 'state',
              render: (value) => renderProcessState(value),
              width: '17%'
            },
            {
              title: 'Estado de Pagamento',
              key: 'process_state_payment',
              render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
              width: '17%'
            },
            {
              title: 'Valor pendente',
              dataIndex: 'pending_value',
              width: '8%'
            },
            {
              title: 'Ações',
              render: (data) => 
                <ButtonActions 
                  icon={'dots'} 
                  iconType={'fill'} 
                  options={[
                    {
                      onClick: () => history.push(`/process/${data._id}`),
                      label: 'Editar' 
                    },
                    {
                      popConfirmtitle: 'Tem a certeza que quer arquivar o processo?',
                      onClick: () => archiveProcess(data),
                      label: 'Arquivar' 
                    }
                  ]} 
                />
            }
          ];
        }

        setServices(services?.data || []);
        setUsers(responsibles);
        setClients(clients?.data || []);
        setAgents(agents?.data || []);
        setPartners(partners?.data || []);
        setColumns(columns);
        setIsReady(true);
      } 
      catch (error) {
        console.error(error);
        setIsReady(true);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const init = async () => await getInfo();

    if(isReady) init();
  }, [isReady, filters, currentPage, pageSize]);

  const getInfo = async () => {
    setLoading(true);

    try {
      let filtersReq = filters, currentPageReq = currentPage, pageSizeReq = pageSize;

      const cookieJson = Cookies.get('processFilters');
      if(cookieJson) {
        const cookie = JSON.parse(cookieJson);

        if(cookie?.user === user._id) {
          filtersReq = cookie.filters ? JSON.stringify(cookie.filters) : '';
          currentPageReq = cookie.currentPage || 1;
          pageSizeReq = cookie.pageSize || 30;
        }
      }

      const result = await GetProcessList(currentPageReq, pageSizeReq, filtersReq);
      
      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setCurrentPage(currentPageReq);
      setPageSize(pageSizeReq);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    // When changing pages, maintain the current pageSize
    // Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage,
      pageSize: pageSize || 30
    });
    Cookies.set('processFilters', cookie, { expires: timeout });

    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    // Changing the number of rows per page, always reset the page to 1
    // Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage: 1,
      pageSize
    });
    Cookies.set('processFilters', cookie, { expires: timeout });

    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const archiveProcess = async (data) => {
    try {
      await ToggleProcessArchive(data._id, { archived: true });
      await getInfo();
    } 
    catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle buttons={1}>
          <PageTitle noMarginBottom={true}>Lista de Processos</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={1}>
          <BaseButton
            customIcon={'plus'}
            text={'Criar processo'}
            onClick={() => history.push('/process/add')}
          />
        </HeaderButtonsContainer>
      </HeaderContainer>
      <PageContainer>
        <Filters
          queryChange={handleFilterChange}
          services={services}
          users={users}
          clients={clients}
          agents={agents}
          partners={partners}
        />
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Processos inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          onPressRow={(record) => history.push(`/process/${record._id}`)}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PageContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ListProcessPage);
