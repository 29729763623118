import { connect } from 'react-redux';
import { Form, Modal, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { SendProcessPdf } from '../../../../infra/requests/ProcessRequests';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React, { useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  email: 'required|email'
});

const PdfEmail = ({ openModal, closeModal, processInfo, pdfType, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const filters = { ...values, type: pdfType };
      const { success } = await SendProcessPdf(processInfo._id, JSON.stringify(filters));

      if(success) {
        notification.success({
          message: 'Email enviado!',
          description: 'O email com o PDF anexado foi enviado com sucesso.'
        });
      } 
      else {
        notification.error({
          message: 'Ocorreu um erro!',
          description: 'Por favor tente mais tarde.'
        });
      }

      dispatch(initialize('pdf_email_form'));
      setLoading(false);
      closeModal();
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(initialize('pdf_email_form'));
    closeModal();
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Enviar Email</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name='email'
            component={TextInput}
            type='email'
            label='Email do destinatário'
            placeholder='Email'
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Enviar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const PdfEmailForm = reduxForm({
  form: 'pdf_email_form',
  validate: validations
})(PdfEmail);

export default connect()(PdfEmailForm);
