import React, { Component } from 'react';
import { reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetDocument,
  UpdateDocument
} from '../../infra/requests/DocumentsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import Details from './components/Details';
import TextList from './components/TextsList';

const validations = FormValidator.make({
  identifier: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  image: 'required'
});

class ManageDocumentPage extends Component {
  state = { 
    loading: true,
    initialValues: null
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const result = await GetDocument(params.id);
    dispatch(initialize('manage_document_form', { 
      ...result.data
    }));

    this.setState({ 
      loading: false,
      initialValues: result.data
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;

      if(values.image && !values.image.blob) delete values.image;

      const payload = FlattenToFormData(values);
      const { success } = await UpdateDocument(params.id, payload);

      if(success) return history.push('/digital-documents/list');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/digital-documents/list');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading, initialValues } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Documentos Digitais'
          breadcrumbs={['Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <TabsContainer defaultActiveKey='details'>
              <Tab
                tab='Detalhes'
                key='details'
              >
                <Details />
              </Tab>
              <Tab
                tab='Conteúdo'
                key='texts'
              >
                <TextList document={initialValues} />
              </Tab>
            </TabsContainer>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDocumentPage = reduxForm({
  form: 'manage_document_form',
  validate: validations,
})(ManageDocumentPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageDocumentPage));