/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { withLocalize } from 'react-localize-redux';
import { FormContainer, SpinLoading, Separator, TabsContainer, Tab } from '../../../styles/BasicStyles';
import { GetProcess, GetLastProcessLog } from '../../../infra/requests/ProcessRequests';
import { GetAdminList, GetProcessUsersList } from '../../../infra/requests/UsersRequests';
import { GetClient } from '../../../infra/requests/ClientsRequests';
import { GetAddressFullList } from '../../../infra/requests/AddressRequests';
import { HeaderWrapper, HeaderContainer, HeaderRow, HeaderTitle } from './styles';
import React, { useEffect, useState } from 'react';
import ProcessHeader from './ProcessHeader';
import PersonalData from './PersonalData';
import Activity from './Activity';
import Emails from './Emails';
import Documents from './Documents';
import Notes from './Notes';
import Tasks from './Tasks';
import Lemania from './Lemania';
import Payments from './Payments';
import Commissions from './Commissions';
import lodash from 'lodash';

const EditProcessPage = ({ match: { path, params }, CloseMenu, user, history }) => {
  const [loading, setLoading] = useState(true);
  const [processInfo, setProcessInfo] = useState(null);
  const [responsibles, setResponsibles] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentResponsible, setCurrentResponsible] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [lastLog, setLastLog] = useState(null);
  const [reloadTab, setReloadTab] = useState([]);
  const [activeTab, setActiveTab] = useState('details');

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, []);

  useEffect(() => {
    if(!loading) {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loading]);

  const getInfo = async (redirectToPayments = false) => {
    try {  
      setLoading(true);

      CloseMenu();
  
      const result = await GetProcess(params.id);
      const responsiblesList = user?.type === 1 ? await GetAdminList() : [];
      const usersList = await GetProcessUsersList(result?.data?._id);
      const addresses = await GetAddressFullList();
      const log = await GetLastProcessLog(params.id);

      //If the User is an Agent, but this process does not belong to him, return to process list
      if(user?.type === 2 && result?.data?.agent?._id !== user?._id) {
        if(path.includes('/archive/')) return history.push('/archive');
        return history.push('/process');
      }
      //If the User is an Partner, but this process does not belong to him, return to process list
      else if(user?.type === 3 && result?.data?.partner?._id !== user?._id) {
        if(path.includes('/archive/')) return history.push('/archive');
        return history.push('/process');
      }

      //Check if the Process is archived or not and redirect to the correct area if needed
      if(path.includes('/archive/') && !result?.data?.archived) {
        return history.push(`/process/${result?.data?._id}`);
      }
      if(path.includes('/process/') && result?.data?.archived) {
        return history.push(`/archive/${result?.data?._id}`);
      }

      if(result?.data?.client) {
        const client = await GetClient(result.data.client);

        setClientInfo(client?.data);
      }

      // If we edited the Main payment detail, we need to redirect to that tab after 
      if(redirectToPayments) {
        setActiveTab('payments');
      }

      // When we click the last log, show the Logs page
      if(history.location.search?.includes('showLogs')) {
        setActiveTab('activity');
      }

      // After creating in Lemania API, redirect to the Lemania tab
      if(history.location.search?.includes('showLemania')) {
        setActiveTab('lemania');
      }

      setLastLog(log?.data);
      setProcessInfo(result?.data);
      setResponsibles(responsiblesList?.data || []);
      setUsers(usersList?.data || []);
      setCurrentResponsible(responsiblesList?.data?.length > 0 && responsiblesList.data.find(elem => elem._id === result?.data?.responsible));
      setAddressList(addresses?.data || []);
      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleScroll = lodash.throttle(() => {
    const scrollPosition = Math.round(window.scrollY);

    if(document.getElementById('processHeaderFixed')) {
      if(scrollPosition > 100) {
        document.getElementById('processHeaderFixed').classList.add('visible');
      }
      else {
        document.getElementById('processHeaderFixed').classList.remove('visible');
      }
    }
  }, 200);

  /* 
  * For each Tab we have a flag to determine if we reload that Tab info or not. 
  * When clicking a Tab it's better to reload all the info 
  * (otherwise after the component it's mounted the first time, it will not fech the updated results until the page is refreshed)
  */
  const reloadTabInfo = (tab) => {
    const aux = [...reloadTab];

    const index = aux.findIndex(elem => elem.tab === tab);
    if(index >= 0) {
      aux[index] = { tab, value: new Date() };
    }
    else {
      aux.push({ tab, value: new Date() });
    }

    setReloadTab(aux);
  }

  if(loading) return <SpinLoading />;

  return (
    <>
      <HeaderWrapper style={{ display: 'flex' }}>
        <HeaderContainer onClick={() => history.push(path.includes('/archive/') ? '/archive' : '/process')}>
          <HeaderRow>
            <Icon type='arrow-left' style={{ fontSize: '15px', color: '#000000' }} />
            <HeaderTitle>Processo ref. {processInfo?.ref}</HeaderTitle>
          </HeaderRow>
        </HeaderContainer>
      </HeaderWrapper>
      <Separator style={{ margin: '15px 0px' }} />
      <ProcessHeader 
        processInfo={processInfo} 
        responsibles={responsibles}
        currentResponsible={currentResponsible}
        getInfo={getInfo} 
        lastLog={lastLog}
      />
      <FormContainer style={{ paddingTop: '0px', marginTop: '20px' }}>
        <TabsContainer defaultActiveKey={activeTab} onTabClick={reloadTabInfo}>
          <Tab tab='Dados pessoais' key='details'>
            <PersonalData 
              processInfo={processInfo}
              clientInfo={clientInfo}
              getInfo={getInfo}
            />
          </Tab>
          <Tab tab={(!!processInfo.agent) ? 'Logs' : 'Atividade'} key='activity'>
            <Activity 
              processInfo={processInfo}
              currentResponsible={currentResponsible}
              addressList={addressList}
              reloadTab={reloadTab.find(elem => elem.tab === 'activity')?.value || ''}
              showLogsPage={activeTab === 'activity'}
              users={users}
            />
          </Tab>
          <Tab tab='Emails' key='emails'>
            <Emails 
              processInfo={processInfo}
              currentResponsible={currentResponsible}
              reloadTab={reloadTab.find(elem => elem.tab === 'emails')?.value || ''}
            />
          </Tab>
          <Tab tab='Pagamentos' key='payments'>
            <Payments
              processInfo={processInfo}
              currentResponsible={currentResponsible}
              responsibles={responsibles}
              users={users}
              getInfo={getInfo}
              reloadTab={reloadTab.find(elem => elem.tab === 'payments')?.value || ''}
            />
          </Tab>
          <Tab tab='Documentos' key='documents'>
            <Documents 
              processInfo={processInfo}
              addressList={addressList}
              currentResponsible={currentResponsible}
              reloadTab={reloadTab.find(elem => elem.tab === 'documents')?.value || ''}
            />
          </Tab>
          <Tab tab='Lemania' key='lemania'>
            <Lemania
              processInfo={processInfo}
              clientInfo={clientInfo}
              reloadTab={reloadTab.find(elem => elem.tab === 'lemania')?.value || ''}
              getInfo={getInfo}
            />
          </Tab>
          <Tab tab='Notas Internas' key='notes'>
            <Notes
              processInfo={processInfo}
              reloadTab={reloadTab.find(elem => elem.tab === 'notes')?.value || ''}
              users={users}
            />
          </Tab>
          <Tab tab='Tarefas' key='tasks'>
            <Tasks
              processInfo={processInfo}
              reloadTab={reloadTab.find(elem => elem.tab === 'tasks')?.value || ''}
              users={users}
            />
          </Tab>
          {
            user.super_admin && user?.type === 1 &&
            <Tab tab='Comissões' key='commissions'>
              <Commissions
                processInfo={processInfo}
                reloadTab={reloadTab.find(elem => elem.tab === 'commissions')?.value || ''}
                getInfo={getInfo}
                users={users}
              />
            </Tab>
          }
        </TabsContainer>
      </FormContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(EditProcessPage));
