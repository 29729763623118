import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, RadioGroup } from './InputStyles';

const RadioButtons = ({ input, meta, label, first, options, disabled, isVertical, execAfterChange }) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const changeRadio = async (e) => {
    input.onChange(e.target.value || "");
    if (typeof execAfterChange === "function") {
      await execAfterChange(e.target.value);
    }
  };

  return (
    <InputDiv first={first}>
      { label && <InputLabelDiv>{label}</InputLabelDiv> }
      <RadioGroup
        disabled={disabled}
        options={options}
        onChange={changeRadio}
        value={input.value}
        error={showError}
        isVertical={isVertical || false}
      />
    </InputDiv>
  );
};

RadioButtons.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  execAfterChange: PropTypes.func
};

export default RadioButtons;