import client from '../config/AxiosConfig';

export const GetAboutUs = async (id) => await client.get(`/company/about_us/${id}`);

export const UpdateAboutUs = async (id, data) => await client.put(`/company/about_us/${id}`, data);

export const GetBanner = async (id) => await client.get(`/company/banner/${id}`);

export const UpdateBanner = async (id, data) => await client.put(`/company/banner/${id}`, data);

export const GetMission = async (id) => await client.get(`/company/mission/${id}`);

export const UpdateMission = async (id, data) => await client.put(`/company/mission/${id}`, data);

export const GetTeamIntro = async (id) => await client.get(`/company/team_intro/${id}`);

export const UpdateTeamIntro = async (id, data) => await client.put(`/company/team_intro/${id}`, data);

export const GetTeam = async (currentPage, limit, filters) => await client.get(`/company/team/${currentPage}/${limit}?filter=${filters}`);

export const GetMember = async (id) => await client.get(`/company/team/${id}`);

export const CreateMember = async (data) => await client.post(`/company/team`, data);

export const UpdateMember = async (id, data) => await client.put(`/company/team/${id}`, data);

export const DeleteMember = async (id) => await client.delete(`/company/team/${id}`);

export const GetTeamList = async () => await client.get(`/company/team`);

export const UpdateTeamOrder = async (data) => await client.post(`/company/team/order`, data);

export const GetNumbers = async (id) => await client.get(`/company/numbers/${id}`);

export const UpdateNumbers = async (id, data) => await client.put(`/company/numbers/${id}`, data);