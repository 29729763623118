import { InputDiv, InputLabelDiv, DateInputField } from './InputStyles';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const DateInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  hasDefaultValue
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <DateInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={dateFormat || 'DD-MM-YYYY'}
        value={input.value ? moment.utc(input.value) : hasDefaultValue ? moment.utc() : null}
        onChange={(date) => {
          if(date) {
            // When we select a date from the picker, it uses the current hour and minute and that's correct
            // But if we enter a date manually it would use the hour "00:00" and in UTC it caused to select the day before
            const newDate = moment(date).set({ hour: moment().get('hour'), minute: moment().get('minute'), second: moment().get('second') });
            input.onChange(moment.utc(newDate));
          }
          else {
            input.onChange('');
          }
        }
        }
      />
    </InputDiv>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default DateInput;
