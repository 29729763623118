import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, MonthInputField } from './InputStyles';
import moment from 'moment';

const MonthInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  hasDefaultValue
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <MonthInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={dateFormat || 'MMMM, YYYY'}
        value={input.value ? moment.utc(input.value) : hasDefaultValue ? moment().utc(true) : null}
        onChange={(date) =>
          date ? input.onChange(moment(date).utc(true)) : input.onChange('')
        }
      />
    </InputDiv>
  );
};

MonthInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default MonthInput;
