/* eslint-disable react-hooks/exhaustive-deps */
import { LemaniaWrapper, HeaderLine, HeaderColumn, Row, RowColumn, NoLemaniaWrapper, NoLemaniaText, LemaniaStatus } from './styles';
import { CardWrapper, Card, CardContainer, CardItem, CardTitle, CardValue } from '../../styles';
import { ActionsWrapper, ActionsCol } from '../styles';
import { SpinLoading } from '../../../../styles/BasicStyles';
import { GetProcessLemania, DeleteLemania, CreateLog } from '../../../../infra/requests/ProcessRequests';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { getCivilState } from '../../../../infra/utils/CivilStates';
import { getLanguage } from '../../../../infra/utils/Languages';
import { getProfessionalState } from '../../../../infra/utils/ProfessionalStates';
import { getGender } from '../../../../infra/utils/Genders';
import { getCountry } from '../../../../infra/utils/Countries';
import { SaveLemania } from '../../../../infra/requests/LemaniaRequests';
import { notification, Popconfirm, Row as AntdRow, Col as AntdCol } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import NewLemaniaModal from '../../modals/NewLemania';
import EditLemaniaModal from '../../modals/EditLemania';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';
import LemaniaDetail from './LemaniaDetail';

const lemaniaApiKey = Buffer.from(`${process.env.REACT_APP_LEMANIA_KEY}:${process.env.REACT_APP_LEMANIA_SECRET}`).toString('base64');

const Lemania = ({ processInfo, clientInfo, reloadTab, dispatch, user, history, getInfo }) => {
  const [loading, setLoading] = useState(true);
  const [lemaniaLoading, setLemaniaLoading] = useState(false);
  const [lemania, setLemania] = useState(null);
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    const init = async () => await getLemania();

    init();
  }, [reloadTab]);

  // Remove "?showLemania" from the URL, when loading the page
  useEffect(() => {
    if(history.location.search?.includes('showLemania')) {
      history.replace(`/process/${processInfo._id}`, `/process/${processInfo._id}`);
    }
  }, [history]);

  const getLemania = async () => {
    setLoading(true);

    try {
      const result = await GetProcessLemania(processInfo._id);

      setLemania(result?.data);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const openEditModal = () => {
    dispatch(initialize('edit_lemania_form', { ...lemania }));

    setEditModal(true);
  }

  const removeLemania = async () => {
    try {
      setLoading(true);

      await DeleteLemania(lemania._id);
      await getLemania();

      notification.success({
        message: 'Lemania removida com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const createLemania = async () => {
    try {
      setLemaniaLoading(true);

      // Step 1: create onboarding

      // If the client already exists in Lemania, create onboarding with that ID
      let url = `${process.env.REACT_APP_LEMANIA_URL}dcob/onboarding`;
      if(clientInfo?.lemania_client_id) url = `${process.env.REACT_APP_LEMANIA_URL}dcob/onboarding/client/${clientInfo.lemania_client_id}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `API ${lemaniaApiKey}`
        }
      });
      const result = await response.json();

      if(result?.clientId && result?.taskId) {
        // Step 2: save ID's in our database
        await SaveLemania(processInfo._id, {
          lemania_onboarding_id: result.taskId,
          lemania_client_id: result.clientId
        });

        // Step 3: send client info
        await sendLemaniaInfo(result.clientId, false);

        notification.success({
          message: 'Conta criada com sucesso na Lemania!'
        });

        setLemaniaLoading(false);

        history.push(`/process/${processInfo._id}?showLemania`);
        await getInfo();
      }
      else {
        notification.error({
          message: 'Ocorreu um erro ao enviar ao informação para a Lemania!'
        });

        setLemaniaLoading(false);
      }
    }
    catch(error) {
      console.log(error);

      notification.error({
        message: 'Ocorreu um erro ao enviar ao informação para a Lemania!'
      });

      setLemaniaLoading(false);
    }
  };

  const sendLemaniaInfo = async (clientId, showAlert = true) => {
    try {
      setLemaniaLoading(true);

      let fullAddress = '';
      if(processInfo?.address) fullAddress = processInfo?.address;

      if(processInfo?.postal_code && fullAddress) fullAddress = `${fullAddress}, ${processInfo?.postal_code}`;
      else fullAddress = processInfo?.postal_code;

      if(processInfo?.city && fullAddress) fullAddress = `${fullAddress} ${processInfo?.city}`;
      else fullAddress = processInfo?.city;

      if(processInfo?.country && fullAddress) fullAddress = `${fullAddress}, ${getCountry(processInfo?.country)}`;
      else fullAddress = getCountry(processInfo?.country);

      const civilState = getCivilState(processInfo?.civil_state);
      const language = getLanguage(processInfo?.language);
      const professionalState = getProfessionalState(processInfo?.professional_state);
      const gender = getGender(processInfo?.gender);
      const birthday = `${moment(processInfo?.birthday).format('YYYY-MM-DD')}T00:00:00+0000`;
      const civilStateDate = `${moment(processInfo?.civil_state_date).format('YYYY-MM-DD')}T00:00:00+0000`;

      const data = [
        { reference: '@avs', value: processInfo?.avs_number },
        { reference: '@civilStatus', value: civilState?.lemaniaID },
        { reference: '@correspondenceLanguage', value: language?.lemaniaID },
        { reference: '@country', value: processInfo?.country },
        { reference: '@dateOfBirth', value: birthday },
        { reference: '@dateOfWedding', value: civilStateDate },
        { reference: '@email', value: processInfo?.email },
        { reference: '@firstName', value: processInfo?.first_name },
        { reference: '@fullAddress', value: fullAddress },
        { reference: '@lastName', value: processInfo?.last_name },
        { reference: '@location', value: processInfo?.city },
        { reference: '@mobilePhone', value: processInfo?.contact },
        { reference: '@professionalSituation', value: professionalState?.lemaniaID },
        { reference: '@street', value: processInfo?.address },
        { reference: '@title', value: gender?.lemaniaID },
        { reference: '@zip', value: processInfo?.postal_code },
      ];

      const response = await fetch(`${process.env.REACT_APP_LEMANIA_URL}dcob/onboarding/${clientId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `API ${lemaniaApiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if(response.ok) {
        if(showAlert) {
          notification.success({
            message: 'Dados atualizados com sucesso na Lemania!'
          });
        }

        await CreateLog({
          user: user?._id,
          process: processInfo._id,
          section: 6,
          description: `Enviou os seguintes dados de cliente para a Lemania: 
            <span style="font-weight: 700">Título</span>: ${gender?.name},
            <span style="font-weight: 700">Primeiro Nome</span>: ${processInfo?.first_name},
            <span style="font-weight: 700">Último Nome</span>: ${processInfo?.last_name},
            <span style="font-weight: 700">Email</span>: ${processInfo?.email},
            <span style="font-weight: 700">Contacto</span>: ${processInfo?.contact},
            <span style="font-weight: 700">Nº AVS</span>: ${processInfo?.avs_number},
            <span style="font-weight: 700">Data de Nascimento</span>: ${birthday},
            <span style="font-weight: 700">Estado Civil</span>: ${civilState?.name},
            <span style="font-weight: 700">Data Estado Civil</span>: ${civilStateDate},
            <span style="font-weight: 700">Situação Profissional</span>: ${professionalState?.name},
            <span style="font-weight: 700">Morada</span>: ${processInfo?.address},
            <span style="font-weight: 700">Código Postal</span>: ${processInfo?.postal_code},
            <span style="font-weight: 700">Cidade</span>: ${processInfo?.city},
            <span style="font-weight: 700">País</span>: ${processInfo?.country},
            <span style="font-weight: 700">Morada Completa</span>: ${fullAddress},
            <span style="font-weight: 700">Idioma</span>: ${language?.name}
          `
        });
      }
      else {
        notification.error({
          message: 'Ocorreu um erro ao enviar ao informação para a Lemania!'
        });
      }

      setLemaniaLoading(false);
    }
    catch(error) {
      console.log(error);

      notification.error({
        message: 'Ocorreu um erro ao enviar ao informação para a Lemania!'
      });
      
      setLemaniaLoading(false);
    }
  };

  const checkLemaniaFields = () => {
    let missingFields = '';

    if(!processInfo?.avs_number) missingFields = `${missingFields}Nº AVS, `;
    if(!processInfo?.gender) missingFields = `${missingFields}Género, `;
    if(!processInfo?.first_name) missingFields = `${missingFields}Primeiro Nome, `;
    if(!processInfo?.last_name) missingFields = `${missingFields}Último Nome, `;
    if(!processInfo?.email) missingFields = `${missingFields}Email, `;
    if(!processInfo?.contact) missingFields = `${missingFields}Contacto, `;
    if(!processInfo?.birthday) missingFields = `${missingFields}Data de Nascimento, `;
    if(!processInfo?.civil_state) missingFields = `${missingFields}Estado Civil, `;
    if(processInfo?.civil_state !== 1 && !processInfo?.civil_state_date) missingFields = `${missingFields}Data Estado Civil, `;
    if(!processInfo?.professional_state) missingFields = `${missingFields}Situação Profissional, `;
    if(!processInfo?.address) missingFields = `${missingFields}Morada, `;
    if(!processInfo?.postal_code) missingFields = `${missingFields}Código Postal, `;
    if(!processInfo?.city) missingFields = `${missingFields}Cidade, `;
    if(!processInfo?.country) missingFields = `${missingFields}País, `;
    if(!processInfo?.language) missingFields = `${missingFields}Idioma, `;

    if(missingFields) {
      missingFields = missingFields.slice(0, -2);

      return {
        error: true,
        message: `Campos em falta: ${missingFields}.`
      };
    }
    else {
      return {
        error: false,
        message: 'Os campos necessários estão preenchidos.'
      };
    }
  };

  // const listLemania = async () => {
  //   console.log("hello");

  //   const response = await fetch(`${process.env.REACT_APP_LEMANIA_URL}dcob/client`, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `API ${lemaniaApiKey}`
  //     }
  //   });

  //   console.log(response);
  //   const result = await response.json();

  //   console.log(result);
  // };

  const lemaniaStatus = checkLemaniaFields();

  if(loading) return <SpinLoading />;

  return (
    <>
      {/* <BaseButton
        htmlType='button'
        type='grey'
        text='list'
        onClick={listLemania}
      /> */}
      {
        user?.type !== 3 &&
        <CardWrapper gutter={24}>
          <Card xs={24}>
            <CardContainer>
              {
                !processInfo.archived &&
                <ActionsWrapper>
                  <ActionsCol></ActionsCol>
                  <ActionsCol>
                    {
                      processInfo.lemania_onboarding_id && processInfo.lemania_client_id &&
                      <Popconfirm
                        placement='topRight'
                        title={'Tem a certeza que pretende atualizar os dados do cliente na Lemania?'}
                        onConfirm={() => sendLemaniaInfo(processInfo.lemania_client_id)}
                      >
                        <BaseButton
                          htmlType='button'
                          type='grey'
                          icon='sync'
                          text='Atualizar cliente na Lemania'
                          style={{ float: 'right' }}
                          loading={lemaniaLoading}
                          disabled={lemaniaLoading}
                        />
                      </Popconfirm> 
                    }
                    {
                      !processInfo.lemania_onboarding_id && !processInfo.lemania_client_id && !lemaniaStatus.error &&
                      <Popconfirm
                        placement='topRight'
                        title={'Tem a certeza que pretende criar a conta na Lemania?'}
                        onConfirm={createLemania}
                      >
                        <BaseButton
                          htmlType='button'
                          type='secondary'
                          icon='upload'
                          text='Enviar para Lemania'
                          style={{ float: 'right' }}
                          loading={lemaniaLoading}
                          disabled={lemaniaLoading}
                        />
                      </Popconfirm>
                    }
                  </ActionsCol>
                </ActionsWrapper>
              }
              <AntdRow gutter={24} style={{ marginTop: '5px' }}>
                {
                  processInfo.lemania_onboarding_id && processInfo.lemania_client_id ?
                  <>
                    <AntdCol xs={24}>
                      <CardItem center>
                        <CardTitle style={{ width: '230px' }}>ID Onboarding:</CardTitle>
                        <CardValue style={{ maxWidth: '600px' }}>{processInfo.lemania_onboarding_id}</CardValue>
                      </CardItem>
                    </AntdCol>
                    <AntdCol xs={24}>
                      <CardItem center>
                        <CardTitle style={{ width: '230px' }}>ID Client:</CardTitle>
                        <CardValue style={{ maxWidth: '600px' }}>{processInfo.lemania_client_id}</CardValue>
                      </CardItem>
                    </AntdCol>
                  </>
                  :
                  <>
                    <AntdCol xs={24}>
                      <CardItem center>
                        <CardTitle style={{ fontSize: 15 }}>Ainda não enviou os dados do cliente para a Lemania!</CardTitle>
                      </CardItem>
                    </AntdCol>
                    <AntdCol xs={24}>
                    <LemaniaStatus error={lemaniaStatus.error}>
                      {lemaniaStatus.message}
                    </LemaniaStatus>
                    </AntdCol>
                  </>
                }
              </AntdRow>
            </CardContainer>
          </Card>
        </CardWrapper>
      }
      {
        lemania ?
        <LemaniaWrapper>
          <HeaderLine>
            <HeaderColumn width="20%">Data</HeaderColumn>
            <HeaderColumn width="36%">Descrição</HeaderColumn>
            <HeaderColumn width="14%">Valor total</HeaderColumn>
            <HeaderColumn width="15%"></HeaderColumn>
            <HeaderColumn width="15%"></HeaderColumn>
            <HeaderColumn width="10%">Ações</HeaderColumn>
          </HeaderLine>
          <Row>
            <RowColumn width="20%">{moment(lemania.createdAt).format('DD/MM/YYYY, HH:mm')}h</RowColumn>
            <RowColumn width="36%">{lemania.description}</RowColumn>
            <RowColumn width="14%">{lemania.funds?.length > 0 && lemania.funds.reduce((accum, elem) => accum + elem.amount, 0)}CHF</RowColumn>
            <RowColumn width="15%"></RowColumn>
            <RowColumn width="15%"></RowColumn>
            <RowColumn width="10%">
              {
                !processInfo.archived && user?.type !== 3 &&
                <ButtonActions 
                  icon={'dots'} 
                  iconType={'fill'} 
                  options={[
                    {
                      onClick: () => openEditModal(),
                      label: 'Editar' 
                    },
                    {
                      popConfirmtitle: 'Tem a certeza que quer apagar esta conta?',
                      onClick: () => removeLemania(),
                      label: 'Remover' 
                    }
                  ]} 
                />
              }
            </RowColumn>
          </Row>
          <LemaniaDetail lemania={lemania} />
        </LemaniaWrapper>
        :
        <NoLemaniaWrapper>
          <NoLemaniaText>Ainda não há conta Lemania</NoLemaniaText>
          {
            !processInfo.archived && user?.type !== 3 &&
            <BaseButton
              customIcon='plus'
              text={'Adicionar conta Lemania'}
              onClick={() => setNewModal(true)}
            />
          }
        </NoLemaniaWrapper>
      }
      {
        !processInfo.archived && user?.type !== 3 &&
        <>
          <NewLemaniaModal
            openModal={newModal}
            closeModal={() => setNewModal(false)}
            getInfo={getInfo}
            processID={processInfo._id}
          />
          <EditLemaniaModal
            openModal={editModal}
            closeModal={() => setEditModal(false)}
            getInfo={getInfo}
          />
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Lemania));
