/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabTitle, StateLabel } from '../../styles';
import { GetLogsList } from '../../../../infra/requests/EmailLogsRequests';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Table from '../../../../components/generic/table/Table';
import EmailLogsFilters from './EmailLogsFilters';

const EmailLogs = ({ processInfo }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(`{"process":"${processInfo._id}"}`);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [columns] = useState([
    {
      title: 'Assunto',
      dataIndex: 'subject',
      width: '20%'
    },
    {
      title: 'Destinatário',
      key: 'destination',
      render: (data) => {
        return (
          <>
            <div>{data.destination_name}</div>
            <div>{data.destination_email}</div>
          </>
        );
      },
      width: '20%'
    },
    {
      title: 'Nº de Anexos',
      dataIndex: 'attachments_number',
      width: '12%'
    },
    {
      title: 'Data de Envio',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '18%'
    },
    {
      title: 'Aberto',
      dataIndex: 'opened',
      render: (value) => renderState(value),
      width: '12%'
    },
    {
      title: 'Data de Abertura',
      dataIndex: 'date_opened',
      render: (value) => value ? `${moment(value).format('DD-MM-YYYY, HH:mm')}h` : '',
      width: '18%'
    }
  ]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const result = await GetLogsList(currentPage, pageSize, filters);

      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#D1FAE5'
          fontColor='#065F46'
        >
          Sim
        </StateLabel>
      );
    }

    return (
      <StateLabel
        bgColor='#ffcdd2'
        fontColor='#e53935'
      >
        Não
      </StateLabel>
    );
  };

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>Email Logs</TabTitle>
      </TabTitleWrapper>
      <EmailLogsFilters
        queryChange={handleFilterChange}
        processID={processInfo._id}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Logs inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default EmailLogs;
