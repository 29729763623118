import { Modal, Col } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  & .ant-modal-content {
    background-color: #F8F9FA;
  }
`;

export const Card = styled(Col)` 
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  margin-bottom: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const CardTitle = styled.div` 
  font-weight: 500;
  font-size: 14px;
  color: #343A40;
  line-height: 20px;
  padding-bottom: 6px;

  & span {
    font-weight: 400;
    font-size: 13px;
  }
`;