import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { UpdateNote } from '../../../../infra/requests/ProcessRequests';
import React, { useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextAreaInput from '../../../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  description: 'required'
});

const EditNote = ({ openModal, closeModal, getInfo, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await UpdateNote(values._id, values);

      if(result?.success) {
        notification.success({
          message: 'Nota atualizada com sucesso!'
        });

        dispatch(initialize('edit_note_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Editar Nota Interna</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={TextAreaInput}
            name={'description'}
            placeholder={'Insira uma nota'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditNoteForm = reduxForm({
  form: 'edit_note_form',
  validate: validations
})(EditNote);

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(EditNoteForm);
