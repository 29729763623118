import { Row, Col } from 'antd';
import { Field } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { RejectedFiles, RejectedAlert, TotalFilesSelected, FilesSelected, File, FilenameColumn, Filename } from '../../process/components/FileUpload/styles';
import { ReactSVG } from 'react-svg';
import { SectionTitle, CardWrapper, Card, CardContainer } from '../styles';
import React from 'react';
import TextInput from '../../../components/generic/inputs/TextInput';
import DraftInput from '../../../components/generic/inputs/DraftInput';
import Dropzone from '../../process/components/FileUpload/MultipleDropzone';

const EmailContent = ({ acceptedFiles, rejectedFiles, setAcceptedFiles, setRejectedFiles }) => {
  const removeFile = (index) => {
    const aux = [...acceptedFiles];
    aux.splice(index, 1);
    setAcceptedFiles([...aux]);
  }

  return (
    <>
      <SectionTitle>Conteúdo do Email</SectionTitle>
      <CardWrapper gutter={24}>
        <Card>
          <CardContainer>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={'subject'}
                  type={'text'}
                  label={'Assunto *'}
                  placeholder={'Nome'}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px'}}>
              <Col xs={24}>
                <Field
                  component={DraftInput}
                  name={'content'}
                  label={'Descrição *'}
                  placeholder={'Descrição'}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px'}}>
              <Col md={12} sm={24}>
                <Field 
                  name='file' 
                  component={Dropzone}
                  handleAcceptedFiles={(files) => setAcceptedFiles([ ...acceptedFiles, ...files ])}
                  handleRejectedFiles={(errors) => setRejectedFiles([ ...rejectedFiles, ...errors ])}
                />
              </Col>
              <Col md={12} sm={24}>
                {
                  rejectedFiles?.length > 0 &&
                  <RejectedFiles>
                    {
                      rejectedFiles.map((error, index) =>
                        <RejectedAlert
                          key={index}
                          description={error}
                          type='error'
                          closable
                        />
                      )
                    }
                  </RejectedFiles>
                }
                <TotalFilesSelected>{acceptedFiles.length} ficheiros anexados</TotalFilesSelected>
                {
                  acceptedFiles.length > 0 &&
                  <FilesSelected>
                    {
                      acceptedFiles.map((file, index) => 
                        <File key={index}>
                          <FilenameColumn>
                            <Filename>
                              { file.blob.name }
                            </Filename>
                          </FilenameColumn>
                          <ReactSVG 
                            src={`${process.env.REACT_APP_BO_URL}close_circle.svg`} 
                            className='icon' 
                            onClick={() => removeFile(index)} 
                          />
                        </File>
                      )
                    }
                  </FilesSelected>
                }
              </Col>
            </Row>
          </CardContainer>
        </Card>
      </CardWrapper>
    </>
  );
}

export default withLocalize(EmailContent);
