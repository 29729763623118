import React from "react";
import PropTypes from "prop-types";
import { Popconfirm } from 'antd';
import {
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer,
} from "../../styles/BasicStyles";
import BaseButton from "../generic/buttons/BaseButton";

const ManageFormHeader = ({ buttons, title, breadcrumbs }) => {
  return (
    <HeaderContainer>
      <HeaderTitle buttons={buttons.length}>
        <PageTitle>{title}</PageTitle>
        {breadcrumbs?.length > 0 && breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumbs.length === index + 1) {
            return breadcrumb;
          } else {
            return `${breadcrumb} > `;
          }
        })}
      </HeaderTitle>
      <HeaderButtonsContainer buttons={buttons.length}>
        {
          buttons.map((button, index) => 
          button.popConfirmtitle ?
            <Popconfirm
              placement='topRight'
              title={button.popConfirmtitle}
              onConfirm={button.onClick}
              key={index}
            >
              <BaseButton
                customIcon={button.customIcon}
                disabled={button.disabled}
                type={button.type ? button.type : "primary"}
                icon={button.icon ? button.icon : "save"}
                text={button.text ? button.text : "Gravar"}
              />
            </Popconfirm>
            :
            <BaseButton
              key={index}
              customIcon={button.customIcon}
              onClick={button.onClick}
              disabled={button.disabled}
              type={button.type ? button.type : "primary"}
              icon={button.icon ? button.icon : "save"}
              text={button.text ? button.text : "Gravar"}
            />
          )
        }
      </HeaderButtonsContainer>
    </HeaderContainer>
  );
};

ManageFormHeader.propTypes = {
  buttons: PropTypes.array,
  titles: PropTypes.array,
};

export default ManageFormHeader;
