import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import {
  GetPosts,
  DeletePost,
  TogglePosts,
  ToggleFeaturedPosts,
  GetCategoriesList
} from '../../infra/requests/BlogRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import BlogFilters from './BlogFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { connect } from 'react-redux';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import moment from 'moment';
import { StateLabel } from '../process/styles';


class BlogPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'date',
        render: (value) => moment(value).format('DD/MM/YYYY'),
        width: '12%',
      },
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '25%',
      },
      {
        title: 'Categorias',
        dataIndex: 'categories',
        render: (value) => this.renderCategories(value),
        width: '30%',
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        render: (value) => this.renderState(value),
        width: '10%',
      },
      {
        title: 'Destacado',
        dataIndex: 'featured',
        render: (value) => this.renderState(value),
        width: '10%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    categories: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/blog/list/${record.identifier}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    const options = [
      {
        onClick: () => history.push(`/blog/list/${data.identifier}`),
        label: 'Editar'
      },
      {
        popConfirmtitle: data.featured ? 'Quer remover o destaque neste post?' : 'Quer destacar este post?',
        onClick: () => this.toggleFeatured(data),
        label: data.featured ? ' Não destacar' : 'Destacar'
      },
      {
        popConfirmtitle: data.active ? 'Quer desativar este post?' : 'Quer publicar este post?',
        onClick: () => this.toggleActive(data),
        label: data.active ? ' Desativar' : 'Publicar'
      },
      {
        popConfirmtitle: 'Tem a certeza que quer remover o post?',
        onClick: () => this.removePost(data),
        label: 'Remover'
      }
    ];

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={options} 
      />
    );
  };

  renderCategories = (categories) => {
    if(categories.length === 0) return '';

    let result = '';
    categories.forEach(elem => result = `${result}, ${elem.title?.pt}`);
    if(result) result = result.slice(2);

    return result;
  }

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Sim
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Não
      </StateLabel>
    );
  };

  removePost = async (data) => {
    try {
      await DeletePost(data.identifier);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetPosts(currentPage, pageSize, filters);
      const categories = await GetCategoriesList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        categories: categories?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await TogglePosts(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  toggleFeatured = async (record) => {
    this.setState({ loading: true });
    await ToggleFeaturedPosts(record._id, { featured: !record.featured });
    await this.updateDataTable();
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      categories
    } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Blog Posts</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/blog/list/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <BlogFilters queryChange={this.handleFilterChange} categories={categories} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Posts inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(BlogPage);