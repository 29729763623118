import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Icon } from 'antd';
import { DeleteUser } from '../../../redux/User/user.actions';
import { MenuToggle } from "../../../redux/Menu/menu.actions";
import { withRouter } from 'react-router-dom';
import { StyledMenuItem, StyledNavLink, StyledSider, StyledContent, TransitionLayout, MenuSVG, StyledSubMenu, StyledMenu, BackToTop } from './LayoutStyles';
import { FieldNumberOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';
import MenuItems from './MenuItems';
import lodash from 'lodash';

const { REACT_APP_BO_URL } = process.env;

class ResponsiveSider extends Component {
  state = {
    index: []
  };

  componentDidMount = () => {
    const { user, history } = this.props;

    this.mounted = true;
    
    if(!user) {
      history.push('/login');
    }

    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll = lodash.throttle(() => {
    const scrollPosition = Math.round(window.scrollY);

    if(scrollPosition > 100) {
      document.getElementById('backToTop').classList.add('visible');
    }
    else {
      document.getElementById('backToTop').classList.remove('visible');
    }
  }, 200);

  logOut = () => {
    const { DeleteUser, history } = this.props;
    localStorage.clear();
    DeleteUser();
    history.push('/login');
  };

  checkUserPermission = (itemPermission) => {
    const { user } = this.props;

    /*
    * Permission types
    * 1 - Admins
    * 2 - Agents
    * 3 - Partners
    * 4 - Admins, Agents or Partners
    * 5 - Agents, Partners or Real Estate Agents
    * 6 - Super Admins
    * 7 - Real Estate Agents
    */
    if(
      !user ||
      (itemPermission === 1 && user.type !== 1) ||
      (itemPermission === 2 && user.type !== 2) ||
      (itemPermission === 3 && user.type !== 3) || 
      (itemPermission === 4 && user.type !== 1 && user.type !== 2 && user.type !== 3) ||
      (itemPermission === 5 && user.type !== 2 && user.type !== 3 && user.type !== 4) || 
      (itemPermission === 6 && !user.super_admin) ||
      (itemPermission === 7 && user.type !== 4)
    ) return false;

    return true;
  }

  renderMenuList = (item, index) => {
    const { history } = this.props;
    
    const hasPermission = this.checkUserPermission(item.permission);
    if(!hasPermission) return null;

    switch (item.type) {
      case 'menu':
        return (
          <StyledMenuItem key={index} onClick={() => this.closeSider()}>
            <StyledNavLink
              to={item.to}
              exact={item.exact}
              activeClassName='activated'
            >
              {
                item.external_icon ?
                <MenuSVG className='anticon' size={item.size}>
                  <ReactSVG src={`${REACT_APP_BO_URL}${item.icon}`} />
                </MenuSVG>
                :
                item.field_number ?
                <FieldNumberOutlined style={{ fontSize: item.size ? item.size : '20px' }} />
                : 
                <Icon
                  style={{ fontSize: item.size ? item.size : '20px' }}
                  type={item.icon}
                />
              }
              <span>{item.name}</span>
            </StyledNavLink>
          </StyledMenuItem>
        );
      case 'submenu':
        return (
          <StyledSubMenu
            key={index}
            className={history?.location?.pathname?.includes(item.baseUrl) ? 'ant-menu-submenu-active' : ''}
            title={
              <>
                {item.external_icon ? (
                  <MenuSVG className='anticon' size={item.size}>
                    <ReactSVG src={`${REACT_APP_BO_URL}${item.icon}`} />
                  </MenuSVG>
                ) : (
                  <Icon
                    style={{ fontSize: item.size ? item.size : '20px' }}
                    type={item.icon}
                  />
                )}
                <span className={item.small ? 'subMenuGroup' : ''}>{item.name}</span>
              </>
            }
          >
            {item.to.map(this.renderMenuList)}
          </StyledSubMenu>
        );
      default:
        return [];
    }
  };

  openSider = () => {
    const { MenuToggle } = this.props;

    MenuToggle(false);
  }

  closeSider = () => {
    const { MenuToggle } = this.props;

    MenuToggle(true);
  }

  handleSider = () => {
    const { menuOpen, MenuToggle } = this.props;

    MenuToggle(!menuOpen);
  }

  render() {
    const { menuOpen } = this.props;

    return (
      <Layout>
        <HeaderComponent logOut={this.logOut}/>
        <div style={{ width: '100%' }}>
          <StyledSider
            className='no-print'
            collapsible
            collapsed={menuOpen}
            onCollapse={this.handleSider}
          > 
            <StyledMenu 
              mode='inline'
              forceSubMenuRender={true}
            >
              {MenuItems.map(this.renderMenuList)}
              <StyledMenuItem key={9999} onClick={this.logOut}>
                <Icon style={{ fontSize: '20px' }} type={'logout'} />
                <span>{'Logout'}</span>
              </StyledMenuItem>
            </StyledMenu>
          </StyledSider>
          <TransitionLayout collapsed={menuOpen}>
            <StyledContent>{this.props.children}</StyledContent>
          </TransitionLayout>
        </div>
        <BackToTop id='backToTop' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <ReactSVG src={`${REACT_APP_BO_URL}arrowUp.svg`} />
        </BackToTop>
      </Layout>
    );
  }
}

ResponsiveSider.propTypes = {};

const mapStateToProps = (state) => ({ 
  user: state.user,
  menuOpen: state.menu
});

const mapActionToProps = (dispatch) => bindActionCreators({ DeleteUser, MenuToggle }, dispatch);

ResponsiveSider = connect(mapStateToProps, mapActionToProps)(ResponsiveSider);

export default withRouter(ResponsiveSider);
