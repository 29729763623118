import { Form, Modal, notification } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ProcessStates } from '../../../../infra/utils/ProcessStates';
import { UpdateProcessState } from '../../../../infra/requests/ProcessRequests';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React, { useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  state: 'required'
});

const EditProcessStateModal = ({ processID, user, openModal, closeModal, handleSubmit, getInfo }) => {
  const [loading, setLoading] = useState(false);

  let states = ProcessStates;
  if(user?.type === 2) {
    states = ProcessStates.filter((elem) => elem.showAgent);
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { data, success } = await UpdateProcessState(processID, values);

      if(success) {
        notification.success({
          message: data?.state === 4 ? 'Estado alterado com sucesso! O cliente foi notificado por email.' : 'Estado alterado com sucesso!',
        });
      }

      setLoading(false);
      closeModal();

      await getInfo();
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Estado do Processo</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'state'}
            placeholder={'Escolha o estado'}
            type='select'
            data={states}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditProcessStateForm = reduxForm({
  form: 'edit_process_state_form',
  validate: validations
})(EditProcessStateModal);

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(EditProcessStateForm);