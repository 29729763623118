import client from '../config/AxiosConfig';

export const GetCommissions = async (currentPage, limit, filters) => await client.get(`/process_commissions/${currentPage}/${limit}?filter=${filters}`);

export const GetCommission = async (identifier) => await client.get(`/process_commissions/${identifier}`);

export const UpdateCommission = async (identifier, data) => await client.put(`/process_commissions/${identifier}`, data);

export const DeleteCommission = async (identifier) => await client.delete(`/process_commissions/${identifier}`);

export const UpdateCommissionState = async (identifier, data) => await client.put(`/process_commissions/state/${identifier}`, data);

export const GetCommissionsBalance = async (identifier) => await client.get(`/process_commissions/user/${identifier}/balance`);

export const GetProfileCommissionsBalance = async () => await client.get('/process_commissions/user/profile/balance');

export const SendCommissionsEmail = async (identifier) => await client.get(`/process_commissions/user/${identifier}/send-email`);
