/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../styles/BasicStyles';
import { GetProcessLogs, DeleteLog, ExportLogs, GetUserProcessFullList } from '../../infra/requests/ProcessRequests';
import { GetAllUsersList, GetUserAdminsList } from '../../infra/requests/UsersRequests';
import { getLogSection } from '../../infra/utils/LogSections';
import { notification, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../components/generic/buttons/BaseButton';
import Table from '../../components/generic/table/Table';
import LogsFilters from './LogsFilters';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import NewLogModal from './NewLogModal';

const Logs = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [process, setProcess] = useState([]);
  const [columns] = useState([
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '14%'
    },
    {
      title: 'Processo',
      dataIndex: 'process',
      render: (value) => value?.ref,
      width: '12%'
    },
    {
      title: 'Utilizador',
      key: 'user',
      render: (data) => data.userInfo?.length > 0 && data.userInfo[0]._id ? data.userInfo[0].name : data.callback ? 'Stripe MB Callback' : data.cronjob ? 'CronJob' : 'Cliente',
      width: '15%'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value) => value,
      width: '30%'
    },
    {
      title: 'Secção',
      dataIndex: 'section',
      render: (value) => getLogSection(value)?.name,
      width: '18%'
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (data) => renderActions(data)
    }
  ]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const result = await GetProcessLogs(currentPage, pageSize, filters);
      const users = (user?.type === 2 || user?.type === 3) ? await GetUserAdminsList() : await GetAllUsersList();
      const process = await GetUserProcessFullList();

      const processList = [];
      if(process?.data?.length > 0) {
        process.data.forEach(elem => {
          processList.push({
            _id: elem._id,
            name: `${elem.ref} - ${elem.full_name}`
          })
        });
      }

      setProcess(processList);
      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setUsers(users?.data || []);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderActions = (data) => {
    if(data.automatic) return null;

    return (
      <>
        {
          // Partners can only see the information
          !data.process.archived && user?.type !== 3 &&
          <ButtonActions 
            icon={'dots'} 
            iconType={'fill'} 
            options={[
              {
                popConfirmtitle: 'Tem a certeza que quer remover este log?',
                onClick: () => removeLog(data),
                label: 'Remover' 
              }
            ]} 
          />
        }
      </>
    );
  }

  const removeLog = async (data) => {
    try {
      setLoading(true);

      await DeleteLog(data._id);
      await getInfo();

      notification.success({
        message: 'Log removido com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const exportLogs = async () => {
    try {
      setLoadingExport(true);

      const result = await ExportLogs({ filters });

      if(result) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'logs.xlsx');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }

      setLoadingExport(false);
    }
    catch(error) {
      console.log(error);
      setLoadingExport(false);
    }
  }

  return (
    <>
      <HeaderContainer>
        <HeaderTitle buttons={2}>
          <PageTitle noMarginBottom={true}>Logs</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={2}>
          {
            user?.type !== 3 &&
            <BaseButton
              customIcon={'plus'}
              text={'Criar log'}
              onClick={() => setNewModal(true)}
            />
          }
          <Popconfirm
            placement='topRight'
            title={'Tem a certeza que pretende exportar os resultados atuais?'}
            onConfirm={() => exportLogs()}
          >
            <BaseButton
              type={'secondary'}
              text={'Exportar'}
              loading={loadingExport}
              disabled={loadingExport}
            />
          </Popconfirm>
        </HeaderButtonsContainer>
      </HeaderContainer>
      <PageContainer>
        <LogsFilters
          queryChange={handleFilterChange}
          users={users}
          processList={process}
        />
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Logs inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PageContainer>
      <NewLogModal
        openModal={newModal}
        closeModal={() => setNewModal(false)}
        getInfo={getInfo}
        processList={process}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Logs);
