import { reduxForm, initialize, reset } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { GetUser, UpdateUser, CreateUser } from '../../infra/requests/UsersRequests';
import { FormContainer, SpinLoading, TabsContainer, Tab } from '../../styles/BasicStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import React, { Component } from 'react';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import UserForm from './components/UserForm';
import CommissionsList from './components/CommissionsList';

const validations = FormValidator.make({
  name: 'required',
  email: 'required',
  type: 'required'
});

class ManageUsersPage extends Component {
  state = {
    isNew: false,
    loading: true,
    userInfo: null
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    //Current user must edit his profile in the "My Profile" page
    if(user._id === params.id) {
      return history.push('/my-profile');
    }

    CloseMenu();

    if(params.id) {
      await this.reloadUser();
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  reloadUser = async () => {
    const { match: { params }, dispatch } = this.props;

    this.setState({ 
      loading: true
    });

    const result = await GetUser(params.id);
    dispatch(initialize('manage_users_form', { ...result.data }));

    this.setState({ 
      loading: false,
      userInfo: result?.data
    });
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch,
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData({ ...values, admin: parseInt(values?.type) === 1 ? true : false });
      const request = isNew ? await CreateUser(payload) : await UpdateUser(params.id, payload);

      this.setState({ loading: false });

      if(request.success) {
        if(createNewAfter) {
          dispatch(reset('manage_users_form'));
          return history.push('/users/add');
        } 
        else {
          return history.push('/users');
        }
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users');
  };

  render() {
    const { handleSubmit, pristine, user } = this.props;
    const { isNew, loading, userInfo } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <>
        <ManageFormHeader
          title='Utilizadores'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        {
          isNew ?
          <FormContainer singleColumn>
            <UserForm 
              handleSubmit={handleSubmit}
              onSubmit={this.onSubmit}
              userInfo={userInfo}
            />
          </FormContainer>
          :
          <FormContainer>
            <TabsContainer defaultActiveKey='details'>
              <Tab tab='Dados pessoais' key='details'>
                <div style={{ maxWidth: 600, margin: 'auto'}}>
                  <UserForm 
                    handleSubmit={handleSubmit}
                    onSubmit={this.onSubmit}
                    userInfo={userInfo}
                  />
                </div>
              </Tab>
              {
                user.super_admin && (userInfo?.type === 1 || userInfo?.type === 3) &&
                <Tab tab='Comissões' key='commissions'>
                  <CommissionsList userInfo={userInfo} reloadUser={this.reloadUser} />
                </Tab>
              }
            </TabsContainer>
          </FormContainer>
        }
      </>
    );
  }
}

ManageUsersPage = reduxForm({
  form: 'manage_users_form',
  validate: validations,
})(ManageUsersPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageUsersPage));