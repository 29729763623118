import client from '../config/AxiosConfig';

export const GetServices = async (currentPage, limit, filters) => await client.get(`/services/${currentPage}/${limit}?filter=${filters}`);

export const GetService = async (identifier) => await client.get(`/services/${identifier}`);

export const GetServiceByID = async (id) => await client.get(`/services/id/${id}`);

export const ToggleServices = async (identifier, data) => await client.put(`/services/${identifier}/toggle`, data);

export const ToggleFeaturedServices = async (identifier, data) => await client.put(`/services/${identifier}/toggle-featured`, data);

export const CreateService = async (data) => await client.post(`/services`, data);

export const UpdateService = async (identifier, data) => await client.put(`/services/${identifier}`, data);

export const DeleteService = async (identifier) => await client.delete(`/services/${identifier}`);

export const GetServicesList = async () => await client.get(`/services`);

export const UpdateServicesOrder = async (data) => await client.post(`/services/order`, data);

export const GetServicesBanner = async (id) => await client.get(`/services/banner/${id}`);

export const UpdateServicesBanner = async (id, data) => await client.put(`/services/banner/${id}`, data);