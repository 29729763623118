/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle, StateLabel } from '../../styles';
import { GetTasks, DeleteTask, UpdateTaskState } from '../../../../infra/requests/TasksRequests';
import { notification } from 'antd';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { getTask, TasksStates } from '../../../../infra/utils/TasksStates';
import { StateWrapper } from './styles';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import Filters from './Filters';
import NewTaskModal from '../../modals/NewTask';
import EditTaskModal from '../../modals/EditTask';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';

const Tasks = ({ processInfo, reloadTab, users, dispatch, user, history }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(`{"process":"${processInfo._id}","validated":true}`);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [columns] = useState([
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '12%'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value) => <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>,
      width: '20%'
    },
    {
      title: 'Responsável',
      dataIndex: 'responsible',
      render: (value) => value.name,
      width: '15%'
    },
    {
      title: 'Destinatário',
      dataIndex: 'recipient',
      render: (value) => value.name,
      width: '15%'
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      render: (value) => value ? `${moment(value).format('DD-MM-YYYY')}` : '',
      width: '12%',
    },
    {
      title: 'Nº Anexos',
      key: 'files',
      render: (data) => data.files?.length || 0,
      width: '7%',
    },
    {
      title: 'Estado',
      key: 'state',
      render: (data) => renderState(data),
      width: '12%',
    },
    {
      title: 'Ações',
      render: (data) => (
        <>
          {
            !processInfo.archived && user?.type !== 3 &&
            <ButtonActions 
              icon={'dots'} 
              iconType={'fill'} 
              options={[
                {
                  onClick: () => openEditModal(data),
                  label: 'Editar' 
                },
                {
                  popConfirmtitle: 'Tem a certeza que quer remover esta tarefa?',
                  onClick: () => removeTask(data),
                  label: 'Remover' 
                }
              ]} 
            />
          }
        </>
      )
    }
  ]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize, reloadTab]);

  const getInfo = async () => {
    setLoading(true);

    try {
      // Validated tasks don't appear by default. We have to filter by that state to show them
      const result = await GetTasks(currentPage, pageSize, filters);

      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleOnPressRow = (record) => {
    if(user?.type === 3) history.push(`/tasks/${record._id}`);
  };

  const openEditModal = (data) => {
    dispatch(initialize('edit_task_form', { ...data, responsible: data.responsible._id, recipient: data.recipient._id }));

    setInitialValues(data);
    setEditModal(true);
  }

  const removeTask = async (data) => {
    try {
      setLoading(true);

      await DeleteTask(data._id);
      await getInfo();

      notification.success({
        message: 'Tarefa removida com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderState = (data) => {
    const currentState = getTask(data.state);

    if(!currentState) return null;

    const options = [];
    if(TasksStates.length > 0) {
      TasksStates.forEach(elem => {
        options.push({
          popConfirmtitle: `Tem a certeza que quer alterar o estado desta tarefa para: ${elem.name}?`,
          onClick: () => changeState(data._id, elem._id),
          label: elem.name
        });
      });
    }

    return (
      <StateWrapper>
        <StateLabel
          bgColor={currentState.bgColor}
          fontColor={currentState.fontColor}
        >
          {currentState.name}
        </StateLabel>
        {
          !processInfo.archived && user?.type !== 3 &&
          <ButtonActions 
            icon={'chevronDown'} 
            iconType={'stroke'} 
            options={options} 
          />
        }
      </StateWrapper>
    );
  };

  const changeState = async (task, state) => {
    try {
      setLoading(true);

      await UpdateTaskState(task, { state });
      await getInfo();

      notification.success({
        message: 'Estado atualizado com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <>
      <TabTitleWrapper>
        <TabTitle></TabTitle>
        <TabButtonsWrapper>
          {
            !processInfo.archived && user?.type !== 3 &&
            <BaseButton
              customIcon='plus'
              text={'Adicionar tarefa'}
              onClick={() => setNewModal(true)}
            />
          }
        </TabButtonsWrapper>
      </TabTitleWrapper>
      <Filters
        queryChange={handleFilterChange}
        users={users}
        processID={processInfo._id}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Tarefas inseridas!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        onPressRow={handleOnPressRow}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {
        !processInfo.archived && user?.type !== 3 &&
        <>
          <NewTaskModal
            openModal={newModal}
            closeModal={() => setNewModal(false)}
            getInfo={getInfo}
            processID={processInfo._id}
            users={users}
          />
          <EditTaskModal
            openModal={editModal}
            closeModal={() => setEditModal(false)}
            getInfo={getInfo}
            users={users}
            processID={processInfo._id}
            initialValues={initialValues}
          />
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Tasks));
