import client from '../config/AxiosConfig';

export const GetEstateBanner = async (id) => await client.get(`/estate/banner/${id}`);

export const UpdateEstateBanner = async (id, data) => await client.put(`/estate/banner/${id}`, data);

export const GetTypes = async (currentPage, limit, filters) => await client.get(`/estate/types/${currentPage}/${limit}?filter=${filters}`);

export const GetType = async (id) => await client.get(`/estate/types/${id}`);

export const CreateType = async (data) => await client.post(`/estate/types`, data);

export const UpdateType = async (id, data) => await client.put(`/estate/types/${id}`, data);

export const DeleteType = async (id) => await client.delete(`/estate/types/${id}`);

export const GetTypesDropdown = async () => await client.get(`/estate/types`);

export const GetFeatures = async (currentPage, limit, filters) => await client.get(`/estate/features/${currentPage}/${limit}?filter=${filters}`);

export const GetFeature = async (id) => await client.get(`/estate/features/${id}`);

export const CreateFeature = async (data) => await client.post(`/estate/features`, data);

export const UpdateFeature = async (id, data) => await client.put(`/estate/features/${id}`, data);

export const DeleteFeature = async (id) => await client.delete(`/estate/features/${id}`);

export const GetFeaturesList = async () => await client.get(`/estate/features`);

export const ToggleEstate = async (identifier, data) => await client.put(`/estate/${identifier}/toggle`, data);

export const ToggleFeaturedEstate = async (identifier, data) => await client.put(`/estate/${identifier}/toggle-featured`, data);

export const CreateEstate = async (data) => await client.post(`/estate`, data);

export const UpdateEstate = async (identifier, data) => await client.put(`/estate/${identifier}`, data);

export const DeleteEstate = async (identifier) => await client.delete(`/estate/${identifier}`);

export const GetEstate = async (identifier) => await client.get(`/estate/${identifier}`);

export const GetEstates = async (currentPage, limit, filters) => await client.get(`/estate/${currentPage}/${limit}?filter=${filters}`);

export const GetCountriesList = async () => await client.get(`/countries/estate`);

export const GetCitiesList = async () => await client.get(`/cities`);

export const GetCitiesByCountry = async (country) => await client.get(`/cities/by_country/${country}`);

export const ValidateRealEstate = async (id) => await client.get(`/estate/validate/${id}`);

export const RejectRealEstate = async (id, data) => await client.post(`/estate/reject/${id}`, data);