/* eslint-disable react-hooks/exhaustive-deps */
import { TableFilterSection } from '../../../styles/BasicStyles';
import { ProcessStates, ProcessPaymentStates } from '../../../infra/utils/ProcessStates';
import { ServiceModes } from '../../../infra/utils/ServiceModes';
import { PaymentMethods } from '../../../infra/utils/PaymentMethods';
import { connect } from 'react-redux';
import { ClearFiltersWrapper, Row, Col } from './styles';
import { sortServices } from '../../../infra/utils/Sort';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import TextInput from '../../../components/generic/inputs/TextInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import DateInput from '../../../components/generic/inputs/DateInput';
import MonthInput from '../../../components/generic/inputs/MonthInput';
import CheckboxInput from '../../../components/generic/inputs/CheckboxInput';

const commissions = [
  {
    _id: 1,
    name: 'Sem comissão'
  },
  {
    _id: 2,
    name: 'Serviço'
  },
  {
    _id: 4,
    name: 'Pagamentos'
  },
  {
    _id: 3,
    name: 'Lemania'
  }
];

const Filters = ({ services, users, clients, user, agents, partners, queryChange }) => {
  const [filters, setFilters] = useState({
    search: '',
    purchase_date: '',
    state: '',
    process_state_payment: '',
    service: '',
    service_mode: '',
    payment_method: '',
    client: '',
    responsible: '',
    agent: '',
    partner: '',
    dateComeback: '',
    dateRetirement: '',
    birthday: '',
    commission: '',
    no_activity: false,
    archived: false
  });

  const states = user?.type === 2 ? ProcessStates.filter(elem => elem.showAgent) : ProcessStates;
  let timeout = null;

  useEffect(() => {
    const cookieJson = Cookies.get('processFilters');
    if(cookieJson) {
      const cookie = JSON.parse(cookieJson);

      if(cookie?.user === user._id) {
        setFilters({ ...cookie.filters });
      }
    }
  }, []);

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if(filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });

    // When selecting a new filter, reset the currentPage and pageSize values
    // Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: query,
      currentPage: 1,
      pageSize: 30
    });
    Cookies.set('processFilters', cookie, { expires: timeout })

    queryChange(JSON.stringify(query));
  };

  const clearFilters = () => {
    Cookies.remove('processFilters');

    setFilters({
      search: '',
      purchase_date: '',
      state: '',
      process_state_payment: '',
      service: '',
      service_mode: '',
      payment_method: '',
      client: '',
      responsible: '',
      agent: '',
      partner: '',
      dateComeback: '',
      dateRetirement: '',
      birthday: '',
      commission: '',
      no_activity: false,
      archived: false
    });

    queryChange(JSON.stringify({no_activity:false,archived:false}));
  };

  return (
    <TableFilterSection>
      <Row>
        <Col>
          <TextInput
            input={{
              value: filters.search,
              onChange: (event) => onInputChange('search', event.target.value, 1000),
            }}
            meta={{ valid: true }}
            type='text'
            label='Pesquisar'
            placeholder='Procurar por ref, cliente, nº AVS/AHV, contacto, email, cidade ou código postal'
          />
        </Col>
        <Col>
          <DateInput
            label='Data de Compra'
            placeholder='Escolher data de compra'
            input={{
              value: filters.purchase_date,
              onChange: value => onInputChange('purchase_date', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <SelectInput
            label='Estado do Processo'
            allowClear
            placeholder='Escolher estado'
            data={states}
            dataKey='_id'
            dataLabel='name'
            input={{
              value: filters.state,
              onChange: value => onInputChange('state', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <SelectInput
            label='Estado do Pagamento'
            allowClear
            placeholder='Escolher estado'
            data={ProcessPaymentStates}
            dataKey='_id'
            dataLabel='name'
            input={{
              value: filters.process_state_payment,
              onChange: value => onInputChange('process_state_payment', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        {
          (user?.type === 1 || user?.type === 3) &&
          <>
            <Col>
              <SelectInput
                label='Serviço'
                allowClear
                placeholder='Escolher serviço'
                data={sortServices(services)}
                dataKey='_id'
                dataLabel='title'
                translatable={true}
                input={{
                  value: filters.service,
                  onChange: value => onInputChange('service', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Modalidade do Serviço'
                allowClear
                placeholder='Escolher modalidade'
                data={ServiceModes}
                dataKey='_id'
                dataLabel='name'
                input={{
                  value: filters.service_mode,
                  onChange: value => onInputChange('service_mode', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Método de Pagamento'
                allowClear
                placeholder='Escolher método'
                data={PaymentMethods}
                dataKey='_id'
                dataLabel='name'
                input={{
                  value: filters.payment_method,
                  onChange: value => onInputChange('payment_method', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          </>
        }
        {
          user?.type === 1 &&
          <>
           <Col>
              <SelectInput
                label='Cliente'
                allowClear
                placeholder='Escolher cliente'
                data={clients}
                dataKey='_id'
                dataLabel='name'
                input={{
                  value: filters.client,
                  onChange: value => onInputChange('client', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Responsável'
                allowClear
                placeholder='Escolher responsável'
                data={users}
                dataKey='_id'
                dataLabel='name'
                translatable={false}
                input={{
                  value: filters.responsible,
                  onChange: value => onInputChange('responsible', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Agente'
                allowClear
                placeholder='Escolher agente'
                data={agents}
                dataKey='_id'
                dataLabel='name'
                translatable={false}
                input={{
                  value: filters.agent,
                  onChange: value => onInputChange('agent', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Parceiro'
                allowClear
                placeholder='Escolher parceiro'
                data={partners}
                dataKey='_id'
                dataLabel='name'
                translatable={false}
                input={{
                  value: filters.partner,
                  onChange: value => onInputChange('partner', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          </>
        }
        <Col>
          <MonthInput
            label='Mês de Regresso a Portugal'
            placeholder='Escolher mês'
            input={{
              value: filters.dateComeback,
              onChange: value => onInputChange('dateComeback', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <MonthInput
            label='Mês de Reforma'
            placeholder='Escolher mês'
            input={{
              value: filters.dateRetirement,
              onChange: value => onInputChange('dateRetirement', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <DateInput
            label="Data de Nascimento"
            placeholder="Escolher data de nascimento"
            input={{
              value: filters.birthday,
              onChange: value => onInputChange('birthday', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <SelectInput
            label='Comissão'
            allowClear
            placeholder='Escolher opção'
            data={commissions}
            dataKey='_id'
            dataLabel='name'
            translatable={false}
            input={{
              value: filters.commission,
              onChange: value => onInputChange('commission', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <div style={{ marginTop: '36px' }}>
            <CheckboxInput
              label={'Sem atividade recente'}
              importantLabel={true}
              inlineCheckbox={true}
              input={{
                value: filters.no_activity,
                onChange: () => onInputChange('no_activity', !filters.no_activity)
              }}
              meta={{valid: true}}
            />
          </div>
        </Col>
        <Col>
          <ClearFiltersWrapper>
            <BaseButton
              type={'clear'}
              icon={'close'}
              text={'Limpar filtros'}
              size={'small'}
              onClick={() => clearFilters()}
            />
          </ClearFiltersWrapper>
        </Col>
      </Row>
    </TableFilterSection>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Filters);
