/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { ActionsWrapper, ActionsCol } from '../styles';
import { CardWrapper, Card, CardContainer, CardItem, CardTitle, CardValue } from '../../styles';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { StateLabel } from '../../styles';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Form from './Form';
import List from './List';

const Commissions = ({ processInfo, getInfo, reloadTab, dispatch, users }) => {
  const [edit, setEdit] = useState(false);

  const openEditForm = () => {
    dispatch(initialize('edit_commissions_form', { ...processInfo }));
    setEdit(true);
  }

  const renderState = (value) => {
    if(value) return <StateLabel bgColor='#389e0d' fontColor='#ffffff'>Sim</StateLabel>;
    return <StateLabel bgColor='#cf1322' fontColor='#ffffff'>Não</StateLabel>;
  };

  return (
    <>
      <CardWrapper gutter={24}>
        <Card xs={24}>
          {
            edit ?
            <Form 
              setEdit={setEdit}
              processInfo={processInfo}
              getInfo={getInfo}
            />
            :
            <>
              <CardContainer>
                <ActionsWrapper>
                  <ActionsCol></ActionsCol>
                  <ActionsCol>
                    {
                      !processInfo.archived &&
                      <BaseButton
                        htmlType='button'
                        type='secondary'
                        icon='edit'
                        text='Editar'
                        style={{ float: 'right' }}
                        onClick={() => openEditForm()}
                      />
                    }
                  </ActionsCol>
                </ActionsWrapper>
                <Row gutter={24} style={{ marginTop: '5px' }}>
                  <Col xs={24}>
                    <CardItem center>
                      <CardTitle style={{ width: '230px' }}>Comissão Serviço?</CardTitle>
                      <CardValue style={{ maxWidth: '600px' }}>{renderState(processInfo.commission_service)}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: '5px' }}>
                  <Col xs={24}>
                    <CardItem center>
                      <CardTitle style={{ width: '230px' }}>Comissão Pagamentos?</CardTitle>
                      <CardValue style={{ maxWidth: '600px' }}>{renderState(processInfo.commission_payments)}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: '5px' }}>
                  <Col xs={24}>
                    <CardItem center>
                      <CardTitle style={{ width: '230px' }}>Comissão Lemania?</CardTitle>
                      <CardValue style={{ maxWidth: '600px' }}>{renderState(processInfo.commission_lemania)}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
              </CardContainer>
            </>
          }
        </Card>
      </CardWrapper>
      <List 
        processInfo={processInfo}
        reloadTab={reloadTab}
        users={users}
      />
    </>
  );
}

export default connect()(Commissions);
