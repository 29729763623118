/* eslint-disable react-hooks/exhaustive-deps */
import { StateLabel } from '../../styles';
import { GetCommissions, DeleteCommission, UpdateCommissionState } from '../../../../infra/requests/CommissionsRequests';
import { notification } from 'antd';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { getCommissionState, CommissionsStates } from '../../../../infra/utils/CommissionsStates';
import { StateWrapper } from '../Tasks/styles';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Table from '../../../../components/generic/table/Table';
import Filters from './Filters';
import EditCommissionModal from '../../modals/EditCommission';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';
import CurrencyComponent from '../../../../components/generic/currency/CurrencyComponent';

const Commissions = ({ processInfo, reloadTab, users, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(`{"process":"${processInfo._id}"}`);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [columns] = useState([
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '13%',
    },
    {
      title: 'Serviço/Pagamento/Lemania',
      key: 'type',
      render: (data) => !!data.lemania ? `Lemania: ${data.lemania}` : !!data.payment?.description ? `Pagamento: ${data.payment?.description}` : data.process?.service_name,
      width: '18%'
    },
    {
      title: 'Total',
      key: 'total',
      render: (data) => 
        <>
          <div>
            <CurrencyComponent
              value={data.total}
              suffix={data.original_currency === 1 ? 'CHF' : data.original_currency === 2 ? '€' : ''}
            />
          </div>
          {
            !!data.lemania && 
            <div style={{ marginTop: 4 }}>
              <CurrencyComponent
                value={data.total_reformas}
                suffix={data.original_currency === 1 ? 'CHF (Reformas)' : data.original_currency === 2 ? '€ (Reformas)' : ''}
              />
            </div>
          }
        </>,
      width: '11%'
    },
    {
      title: 'Utilizador',
      dataIndex: 'user',
      render: (value) => value.name,
      width: '11%'
    },
    {
      title: '% / Valor Fixo',
      key: 'value_type',
      render: (data) => 
        data.value_type === 1 ? 
        <CurrencyComponent
          value={data.value}
          suffix={data.commission_currency === 1 ? 'CHF' : data.commission_currency === 2 ? '€' : ''}
        />
        : 
        `${data.value}%`,
      width: '11%'
    },
    {
      title: 'Valor Comissão',
      key: 'commission_value',
      render: (data) => 
        <CurrencyComponent
          value={data.commission_value}
          suffix={data.commission_currency === 1 ? 'CHF' : data.commission_currency === 2 ? '€' : ''}
        />,
      width: '11%'
    },
    {
      title: 'Estado',
      key: 'state',
      render: (data) => renderState(data),
      width: '14%'
    },
    {
      title: 'Ações',
      render: (data) => (
        <>
          {
            !processInfo.archived &&
            <ButtonActions 
              icon={'dots'} 
              iconType={'fill'} 
              options={[
                {
                  onClick: () => openEditModal(data),
                  label: 'Editar' 
                },
                {
                  popConfirmtitle: 'Tem a certeza que quer remover esta comissão?',
                  onClick: () => removeCommission(data),
                  label: 'Remover' 
                }
              ]} 
            />
          }
        </>
      )
    }
  ]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize, reloadTab]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const result = await GetCommissions(currentPage, pageSize, filters);

      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const openEditModal = (data) => {
    dispatch(initialize('edit_process_commission_form', { 
      ...data, 
      user: data.user._id,
      type: !!data?.lemania ? data.lemania : !!data.payment?.description ? data.payment?.description : data.process?.service_name
    }));

    setModalInfo({
      ...data,
      typeLabel: !!data?.lemania ? 'Lemania' : !!data.payment?.description ? 'Pagamento' : 'Serviço'
    });
    setEditModal(true);
  }

  const removeCommission = async (data) => {
    try {
      setLoading(true);

      await DeleteCommission(data._id);
      await getInfo();

      notification.success({
        message: 'Comissão removida com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderState = (data) => {
    const currentState = getCommissionState(data.state);

    if(!currentState) return null;

    const options = [];
    if(CommissionsStates.length > 0) {
      CommissionsStates.forEach(elem => {
        options.push({
          popConfirmtitle: `Tem a certeza que quer alterar o estado desta comissão para: ${elem.name}?`,
          onClick: () => changeState(data._id, elem._id),
          label: elem.name
        });
      });
    }

    return (
      <StateWrapper>
        <StateLabel bgColor={currentState.bgColor} fontColor={currentState.fontColor}>{currentState.name}</StateLabel>
        {
          !processInfo.archived &&
          <ButtonActions 
            icon={'chevronDown'} 
            iconType={'stroke'} 
            options={options} 
          />
        }
      </StateWrapper>
    );
  };

  const changeState = async (task, state) => {
    try {
      setLoading(true);

      await UpdateCommissionState(task, { state });
      await getInfo();

      notification.success({
        message: 'Estado atualizado com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <>
      <Filters
        queryChange={handleFilterChange}
        users={users}
        processID={processInfo._id}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Comissões inseridas!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {
        !processInfo.archived &&
        <EditCommissionModal
          openModal={editModal}
          closeModal={() => setEditModal(false)}
          getInfo={getInfo}
          users={users}
          modalInfo={modalInfo}
        />
      }
    </>
  );
}

export default connect()(Commissions);
