import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import { CommissionsStates } from '../../infra/utils/CommissionsStates';
import React, { Component } from 'react';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';

class CommissionsFilters extends Component {
  state = { 
    search: '',
    date: '',
    user: '',
    state: '',
    process: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { users, processList } = this.props;
    const { search, date, user, state, process } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) => this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por fundos lemania'
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Processo'
              allowClear
              placeholder='Escolher processo'
              data={processList}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: process,
                onChange: value => this.onInputChange('process', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Utilizador'
              allowClear
              placeholder='Escolher utilizador'
              data={users}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: user,
                onChange: value => this.onInputChange('user', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Estado'
              allowClear
              placeholder='Escolher estado'
              data={CommissionsStates}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: state,
                onChange: value => this.onInputChange('state', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <DateInput
              label='Data'
              placeholder='Escolher data'
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default CommissionsFilters;
