export const LogSections = [
  { _id: 1, name: 'Dados pessoais', showAgent: true, allowAgentInsert: true },
  { _id: 2, name: 'Atualizações', showAgent: false, allowAgentInsert: false },
  { _id: 3, name: 'Emails', showAgent: true, allowAgentInsert: true },
  { _id: 4, name: 'Pagamentos', showAgent: true, allowAgentInsert: true },
  { _id: 5, name: 'Documentos', showAgent: true, allowAgentInsert: true },
  { _id: 6, name: 'Lemania', showAgent: true, allowAgentInsert: true },
  { _id: 7, name: 'Notas internas', showAgent: true, allowAgentInsert: true },
  { _id: 8, name: 'Tarefas', showAgent: true, allowAgentInsert: true },
  { _id: 9, name: 'Responsável', showAgent: false, allowAgentInsert: false },
  { _id: 10, name: 'Estado do Processo', showAgent: true, allowAgentInsert: true },
  { _id: 11, name: 'Estado do Pagamento', showAgent: true, allowAgentInsert: true },
  { _id: 12, name: 'Outros', showAgent: true, allowAgentInsert: true },
  { _id: 13, name: 'Comunicações', showAgent: true, allowAgentInsert: false },
  { _id: 14, name: 'Comissões', showAgent: false, allowAgentInsert: false },
  { _id: 15, name: 'Relatórios', showAgent: false, allowAgentInsert: false }
];

export const getLogSection = (value) => {
  if(Number.isInteger(value) && value > 0 && LogSections[value - 1]) return LogSections[value - 1];
  return null;
};
