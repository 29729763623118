/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../styles/BasicStyles';
import { connect } from 'react-redux';
import { userTypes, newsletterTypes } from '../../../infra/utils/Communications';
import { ProcessStates, ProcessPaymentStates } from '../../../infra/utils/ProcessStates';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../components/generic/inputs/TextInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import DateInput from '../../../components/generic/inputs/DateInput';

const Filters = ({ users, queryChange, agents, responsibles, services, user }) => {
  const [filters, setFilters] = useState({
    search: '',
    user: '',
    createdAt: '',
    agent: '',
    responsible: '',
    service: '',
    process_state: '',
    payment_state: '',
    newsletter: ''
  });

  let timeout = null;

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if (filters[key] && filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  return (
    <TableFilterSection>
    <Row gutter={24}>
      <Col xs={24} md={6}>
        <TextInput
          input={{
            value: filters.search,
            onChange: (event) =>
              onInputChange('search', event.target.value, 1000),
          }}
          meta={{ valid: true }}
          type='text'
          label='Pesquisar'
          placeholder='Procurar por assunto'
        />
      </Col>
      {
        user?.type === 1 &&
        <Col xs={24} md={6}>
          <SelectInput
            label="Admin"
            allowClear
            placeholder="Procurar por admin"
            data={users}
            dataKey="_id"
            dataLabel="name"
            input={{
              value: filters.user,
              onChange: value => onInputChange('user', value)
            }}
            meta={{valid: true}}
          />
        </Col>
      }
      <Col xs={24} md={6}>
        <DateInput
          label='Data'
          placeholder='Escolher data'
          input={{
            value: filters.createdAt,
            onChange: value => onInputChange('createdAt', value)
          }}
          meta={{valid: true}}
        />
      </Col>
      <Col xs={24} md={6}>
        <SelectInput
          label="Utilizadores"
          allowClear
          placeholder="Procurar por utilizadores"
          data={userTypes}
          dataKey="_id"
          dataLabel="name"
          input={{
            value: filters.user,
            onChange: value => onInputChange('user', value)
          }}
          meta={{valid: true}}
        />
      </Col>
      {
        user?.type === 1 &&
        <>
          <Col xs={24} md={6}>
            <SelectInput
              label="Inscrito na Newsletter?"
              allowClear
              placeholder="Procurar por estado"
              data={newsletterTypes}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: filters.newsletter,
                onChange: value => onInputChange('newsletter', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Agente"
              allowClear
              placeholder="Procurar por agente"
              data={agents}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: filters.agent,
                onChange: value => onInputChange('agent', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Responsável"
              allowClear
              placeholder="Procurar por responsável"
              data={responsibles}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: filters.responsible,
                onChange: value => onInputChange('responsible', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Serviço"
              allowClear
              placeholder="Procurar por serviço"
              data={services}
              dataKey="_id"
              dataLabel="title"
              translatable={true}
              input={{
                value: filters.service,
                onChange: value => onInputChange('service', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </>
      }
      <Col xs={24} md={6}>
        <SelectInput
          label="Estado do Processo"
          allowClear
          placeholder="Procurar por estado"
          data={ProcessStates}
          dataKey="_id"
          dataLabel="name"
          input={{
            value: filters.process_state,
            onChange: value => onInputChange('process_state', value)
          }}
          meta={{valid: true}}
        />
      </Col>
      <Col xs={24} md={6}>
        <SelectInput
          label="Estado do Pagamento"
          allowClear
          placeholder="Procurar por estado"
          data={ProcessPaymentStates}
          dataKey="_id"
          dataLabel="name"
          input={{
            value: filters.payment_state,
            onChange: value => onInputChange('payment_state', value)
          }}
          meta={{valid: true}}
        />
      </Col>
    </Row>
  </TableFilterSection>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Filters);
