/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../process/styles';
import { CommissionsStates, commissionOptions } from '../../../infra/utils/CommissionsStates';
import { FlattenToFormData } from '../../../infra/services/formdata/TransformToFormData';
import { UpdateCommission } from '../../../infra/requests/CommissionsRequests';
import { Separator } from '../../../styles/BasicStyles';
import { change, formValueSelector } from 'redux-form';
import { currencyOptions } from '../../../infra/utils/Currencies';
import React, { useState, useEffect } from 'react';
import FormValidator from '../../../infra/services/validations/FormValidator';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import DateInput from '../../../components/generic/inputs/DateInput';
import TextInput from '../../../components/generic/inputs/TextInput';
import CurrencyInput from '../../../components/generic/inputs/CurrencyInput';
import RadioButtons from '../../../components/generic/inputs/RadioButtons';
import NumberInput from '../../../components/generic/inputs/NumberInput';

const validations = FormValidator.make({
  state: 'required',
  value_type: 'required',
  value: 'required',
  commission_currency: 'required'
});

const EditCommission = ({ openModal, closeModal, getInfo, processList, handleSubmit, dispatch, currentValue, modalInfo }) => {
  const [loading, setLoading] = useState(false);
  const [commissionValueType, setCommissionValueType] = useState(0);
  const [commissionCurrency, setCommissionCurrency] = useState(0);

  useEffect(() => {
    if(openModal) {
      setCommissionValueType(modalInfo?.value_type);
      setCommissionCurrency(modalInfo?.commission_currency);
    }
    else {
      setCommissionValueType(0);
      setCommissionCurrency(0);
    }
  }, [openModal, modalInfo]);

  useEffect(() => {
    calculateCommission(currentValue);
  }, [commissionValueType]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const payload = FlattenToFormData({
        state: values.state,
        value_type: values.value_type,
        value: values.value,
        commission_value: values.commission_value,
        commission_currency: values.commission_currency
      });
      const result = await UpdateCommission(values._id, payload);

      if(result?.success) {
        notification.success({
          message: 'Comissão atualizada com sucesso!'
        });

        dispatch(initialize('edit_user_commission_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const calculateCommission = (value) => {
    dispatch(change('edit_user_commission_form', 'commission_value', commissionValueType === 2 ? (modalInfo?.total_reformas * (value / 100)) : value));
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Editar Comissão</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col sm={24}>
              <Field
                component={TextInput}
                name={'type'}
                type={'text'}
                label={modalInfo?.typeLabel}
                placeholder={modalInfo?.typeLabel}
                disabled={true}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Field
                component={SelectInput}
                name={'process'}
                label={'Processo'}
                placeholder={'Escolha o processo'}
                type='select'
                data={processList}
                dataKey={'_id'}
                dataLabel={'name'}
                disabled={true}
              />
            </Col>
            <Col md={12} sm={24}>
              <Field
                component={DateInput}
                name='createdAt'
                label={'Data'}
                disabled={true}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Field
                component={SelectInput}
                name={'state'}
                label={'Estado *'}
                placeholder={'Escolha o estado'}
                type='select'
                data={CommissionsStates}
                dataKey={'_id'}
                dataLabel={'name'}
              />
            </Col>
          </Row>
          <Separator />
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Field
                component={CurrencyInput}
                name={'total'}
                label={'Total'}
                suffix={modalInfo?.original_currency === 1 ? 'CHF' : modalInfo?.original_currency === 2 ? '€' : ''}
                disabled={true}
              />
            </Col>
            {
              !!modalInfo?.lemania &&
              <Col md={12} sm={24}>
                <Field
                  component={CurrencyInput}
                  name={'total_reformas'}
                  label={'Total (Reformas na Suíça)'}
                  suffix={modalInfo?.original_currency === 1 ? 'CHF' : modalInfo?.original_currency === 2 ? '€' : ''}
                  disabled={true}
                />
              </Col>
            }
          </Row>
          <Row gutter={24} style={{ marginTop: 10, marginBottom: 10 }}>
            <Col md={12} sm={24}>
              <Field
                component={RadioButtons}
                name={'value_type'}
                options={commissionOptions}
                label={'Tipo de Comissão *'}
                execAfterChange={(value) => setCommissionValueType(value)}
              />
            </Col>
            <Col md={12} sm={24}>
              <Field
                component={RadioButtons}
                name={'commission_currency'}
                options={currencyOptions}
                label={'Moeda *'}
                execAfterChange={(value) => setCommissionCurrency(value)}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={12} sm={24}>
              {
                commissionValueType === 1 ?
                <Field
                  component={CurrencyInput}
                  name={'value'}
                  label={`Valor (${commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}) *`}
                  suffix={commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}
                  execAfterChange={calculateCommission}
                />
                :
                commissionValueType === 2 ?
                <Field
                  component={NumberInput}
                  name={'value'}
                  label={'Valor (%) *'}
                  placeholder={'0.00%'}
                  min={0}
                  max={100}
                  execAfterChange={calculateCommission}
                />
                :
                null
              }
            </Col>
            <Col md={12} sm={24}>
              <Field
                component={CurrencyInput}
                name={'commission_value'}
                label={'Valor Comissão'}
                suffix={commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}
                disabled={true}
              />
            </Col>
          </Row>
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditCommissionForm = reduxForm({
  form: 'edit_user_commission_form',
  validate: validations
})(EditCommission);

const selector = formValueSelector('edit_user_commission_form');

const mapStateToProps = (state) => ({
  currentValue: selector(state, 'value')
});

export default connect(mapStateToProps)(EditCommissionForm);
