import { Form, Modal, notification } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { UpdateProcessResponsible } from '../../../../infra/requests/ProcessRequests';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React, { useState } from 'react';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const EditProcessResponsibleModal = ({ processID, openModal, closeModal, handleSubmit, responsibles, getInfo }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { success } = await UpdateProcessResponsible(processID, values);

      if(success) {
        notification.success({
          message: 'Responsável alterado com sucesso!',
        });
      }

      setLoading(false);
      closeModal();

      await getInfo();
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Responsável do Processo</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'responsible'}
            placeholder={'Selecionar responsável'}
            type='select'
            data={responsibles}
            dataKey={'_id'}
            dataLabel={'name'}
            allowClear={true}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditProcessResponsibleForm = reduxForm({
  form: 'edit_process_responsible_form'
})(EditProcessResponsibleModal);

export default connect()(EditProcessResponsibleForm);