import { ToggleWrapper, ToggleButton } from '../styles';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Pdf from './Pdf';
import Documents from './Documents';
import Reports from './Reports';

const DocumentsTab = ({ processInfo, addressList, currentResponsible, reloadTab, user }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <ToggleWrapper>
        <ToggleButton active={activeTab === 1} onClick={() => setActiveTab(1)}>Documentos</ToggleButton>
        <ToggleButton active={activeTab === 2} onClick={() => setActiveTab(2)}>PDF's</ToggleButton>
        {user?.type === 1 && <ToggleButton active={activeTab === 3} onClick={() => setActiveTab(3)}>Relatórios</ToggleButton>}
      </ToggleWrapper>
      {
        activeTab === 1 ?
        <Documents 
          processInfo={processInfo}
          addressList={addressList}
          reloadTab={reloadTab}
        />
        :
        activeTab === 2 ?
        <Pdf 
          processInfo={processInfo}
          addressList={addressList}
          currentResponsible={currentResponsible}
        />
        :
        activeTab === 3 && user?.type === 1 ?
        <Reports 
          processInfo={processInfo}
          addressList={addressList}
          reloadTab={reloadTab}
        />
        :
        null
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DocumentsTab);
