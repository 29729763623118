import { Popconfirm, notification } from 'antd';
import { SendEmailBankTransfer, SaveProcessMBReference, SendEmailMbReference } from '../../../../infra/requests/ProcessRequests';
import { getCurrency } from '../../../../infra/utils/Currencies';
import { Row, InfoColumn, FieldWrapper, FieldTitle, FieldValue, ButtonsRow } from './styles';
import { TabTitleWrapper, TabTitle } from '../../styles';
import { CardWrapper, Card, CardContainer, MissingLabel, SuccessLabel } from '../../styles';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import moment from 'moment-timezone';

const PaymentMethod = ({ processInfo, getInfo, user }) => {
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);

  // TRANSFERÊNCIA BANCÁRIA
  const sendBankTransferEmail = async () => {
    try {
      setLoadingEmail(true);
      
      const { success } = await SendEmailBankTransfer({ process: processInfo._id });

      if(success) {
        notification.success({
          message: 'Email enviado com sucesso!'
        });

        await getInfo();
      }

      setLoadingEmail(false);
    }
    catch (e) {
      console.error(e);
      setLoadingEmail(false);
    }
  }

  // MULTIBANCO 
  const newMbReference = async () => {
    try {
      setLoadingNew(true);

      const { success } = await SaveProcessMBReference({ process: processInfo._id });

      if(success) {
        notification.success({
          message: 'Referência MB gerada com sucesso!'
        });

        await getInfo();
      }
      
      setLoadingNew(false);
    }
    catch (e) {
      console.error(e);
      setLoadingNew(false);
    }
  }

  const sendMbEmail = async () => {
    try {
      setLoadingEmail(true);
      
      const { success } = await SendEmailMbReference({ process: processInfo._id });

      if(success) {
        notification.success({
          message: 'Email enviado com sucesso!'
        });

        await getInfo();
      }

      setLoadingEmail(false);
    }
    catch (e) {
      console.error(e);
      setLoadingEmail(false);
    }
  }

  const dateCreated = processInfo.mbDateCreated ? processInfo.mbDateCreated : processInfo.createdAt;
  const dateCreatedTxt = processInfo.mbEntity ? `${moment(dateCreated).format('DD-MM-YYYY, HH:mm')}h` : '-';

  const dateExpired = processInfo.mbDateExpires ? moment(processInfo.mbDateExpires) : null;
  const dateExpiredTxt = dateExpired ? `${moment(dateExpired).format('DD-MM-YYYY, HH:mm')}h` : '-';

  const currentDate = moment();
  const showRefStatus = processInfo.mbEntity ? true : false;
  const refValid = currentDate.isAfter(dateExpired) ? false : true;
  const refValidTxt = refValid ? 'A referência é válida para pagamento!' : 'A referência já expirou!';

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>
          {
            processInfo.payment_method === 2 ?
            'Transferência Bancária'
            :
            'Referência de MB'
          }
        </TabTitle>
      </TabTitleWrapper>
      <CardWrapper gutter={24} style={{ marginTop: '15px' }}>
        <Card xs={24}>
          <CardContainer>
            {
              !processInfo.archived && user?.type !== 3 &&
              <ButtonsRow>
                {
                  processInfo.payment_method === 2 ?
                  <Popconfirm
                    placement='topRight'
                    title='Pretende enviar um email com os dados para pagamento?'
                    onConfirm={() => sendBankTransferEmail()}
                  >
                    <BaseButton
                      type={'secondary'}
                      text={'Enviar email'}
                      loading={loadingEmail}
                    /> 
                  </Popconfirm>
                  :
                  <>
                    {
                      processInfo.currency === 2 &&
                      <>
                        {
                          processInfo.mbEntity &&
                          <Popconfirm
                            placement='topRight'
                            title='Pretende enviar um email com a referência para pagamento?'
                            onConfirm={() => sendMbEmail()}
                          >
                            <BaseButton
                              type={'secondary'}
                              text={'Enviar email'}
                              loading={loadingEmail}
                              disabled={loadingNew}
                              onClick={() => null}
                            />
                          </Popconfirm>
                        }
                        <Popconfirm
                          placement='topRight'
                          title='Pretende gerar uma nova referência MB?'
                          onConfirm={() => newMbReference()}
                        >
                          <BaseButton
                            text={'Criar nova'}
                            loading={loadingNew}
                            disabled={loadingEmail}
                            onClick={() => null}
                          />
                        </Popconfirm>
                      </>
                    }
                  </>
                }
              </ButtonsRow>
            }
            <Row>
              {
                processInfo.payment_method === 2 ?
                <InfoColumn>
                  <FieldWrapper>
                    <FieldTitle>Último envio:</FieldTitle>
                    <FieldValue>{processInfo.bankTransferDateResent ? `${moment(processInfo.bankTransferDateResent).format('DD-MM-YYYY, HH:mm')}h` : 'n/a'}</FieldValue>
                  </FieldWrapper>
                </InfoColumn>
                :
                <>
                  {
                    processInfo.currency === 2 ?
                    <>
                      <InfoColumn>
                        <FieldWrapper>
                          <FieldTitle>Entidade:</FieldTitle>
                          <FieldValue>{processInfo.mbEntity || '-'}</FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                          <FieldTitle>Referência:</FieldTitle>
                          <FieldValue>{processInfo.mbReference || '-'}</FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                          <FieldTitle>Valor a pagar:</FieldTitle>
                          <FieldValue>{processInfo.price}{getCurrency(processInfo.currency)}</FieldValue>
                        </FieldWrapper>
                      </InfoColumn>
                      <InfoColumn>
                        <FieldWrapper>
                          <FieldTitle>Data de criação:</FieldTitle>
                          <FieldValue>{dateCreatedTxt}</FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                          <FieldTitle>Data de expiração:</FieldTitle>
                          <FieldValue>{dateExpiredTxt}</FieldValue>
                        </FieldWrapper>
                        <FieldWrapper>
                          {
                            showRefStatus && (
                              refValid ?
                              <SuccessLabel>
                                <ReactSVG src={`${process.env.REACT_APP_BO_URL}checkCircle.svg`} />
                                <span>{refValidTxt}</span>
                              </SuccessLabel>
                              :
                              <MissingLabel>
                                <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                                <span>{refValidTxt}</span>
                              </MissingLabel>
                            )
                          }
                        </FieldWrapper>
                      </InfoColumn>
                    </>
                    :
                    <MissingLabel>
                      <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                      <span>Pagamento por Multibanco apenas disponível para a moeda EURO</span>
                    </MissingLabel>
                  }
                </>
              }
            </Row>
          </CardContainer>
        </Card>
      </CardWrapper> 
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(PaymentMethod);
