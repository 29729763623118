import { Form, Modal, notification } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { UpdateProcessLanguage } from '../../../../infra/requests/ProcessRequests';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { Languages } from '../../../../infra/utils/Languages';
import React, { useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  language: 'required'
});

const EditProcessLanguageModal = ({ processID, openModal, closeModal, handleSubmit, getInfo }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { success } = await UpdateProcessLanguage(processID, values);

      if(success) {
        notification.success({
          message: 'Idioma alterado com sucesso!',
        });
      }

      setLoading(false);
      closeModal();

      await getInfo();
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Idioma do Processo</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'language'}
            placeholder={'Escolha o idioma'}
            type='select'
            data={Languages}
            dataKey={'value'}
            dataLabel={'name'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditProcessLanguageForm = reduxForm({
  form: 'edit_process_language_form',
  validate: validations
})(EditProcessLanguageModal);

export default connect()(EditProcessLanguageForm);