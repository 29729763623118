/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../process/styles';
import { RejectRealEstate } from '../../infra/requests/EstateRequests';
import React, { useEffect, useState } from 'react';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  message: 'required'
});

const RejectEstateModal = ({ openModal, closeModal, getInfo, initialValues, dispatch, handleSubmit }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(initialize('reject_estate_form'));
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await RejectRealEstate(initialValues._id, values);

      if(result?.success) {
        notification.success({
          message: 'O Imóvel foi rejeitado com sucesso, e o agente foi notificado por email.'
        });

        dispatch(initialize('reject_estate_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Rejeitar Imóvel</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ fontSize: 15 }}>Imóvel: <span style={{ fontWeight: 'bold' }}>{initialValues?.title?.pt}</span></div>
          <Field
            component={TextAreaInput}
            name='message'
            label='Mensagem *'
            placeholder='Insira uma mesagem'
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const RejectEstateModalForm = reduxForm({
  form: 'reject_estate_form',
  validate: validations
})(RejectEstateModal);

export default connect()(RejectEstateModalForm);
