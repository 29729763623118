import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import {
  GetClients,
  DeleteClient,
  ToggleClientState
} from '../../infra/requests/ClientsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ClientsFilters from './ClientsFilters';
import { connect } from 'react-redux';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import { StateLabel } from '../process/styles';

class ClientsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Ref.',
        dataIndex: 'ref',
        render: (value) => value,
        width: '15%',
      },
      {
        title: 'Nome',
        key: 'name',
        render: (data) => `${data.first_name} ${data.last_name}`,
        width: '30%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (value) => value,
        width: '20%',
      },
      {
        title: 'Contacto',
        dataIndex: 'contact',
        render: (value) => value,
        width: '15%',
      },
      {
        title: 'Estado',
        dataIndex: 'accepted',
        render: (value) => this.renderState(value),
        width: '10%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/clients/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/clients/${data._id}`),
            label: 'Editar' 
          },
          {
            popConfirmtitle: data.accepted ? 'Quer desativar este Cliente?' : ' Quer ativar este Cliente?',
            onClick: () => this.toggleClient(data),
            label: data.accepted ? 'Desativar' : 'Ativar'
          },
          {
            popConfirmtitle: 'Tem a certeza que quer remover este Cliente?',
            onClick: () => this.removeClient(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  };

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Ativo
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Inativo
      </StateLabel>
    );
  };

  removeClient = async (data) => {
    try {
      await DeleteClient(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetClients(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  toggleClient = async (record) => {
    this.setState({ loading: true });
    await ToggleClientState(record._id, { accepted: !record.accepted });
    await this.updateDataTable();
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Clientes</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/clients/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ClientsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Clientes inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ClientsPage);