/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../../styles/BasicStyles';
import { PaymentMethods } from '../../../../infra/utils/PaymentMethods';
import { PaymentStates } from '../../../../infra/utils/ProcessStates';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';

const Filters = ({ users, queryChange }) => {
  const [filters, setFilters] = useState({
    search: '',
    date: '',
    user: '',
    payment_method: '',
    payment_state: ''
  });

  let timeout = null;

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if(filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });

    queryChange(JSON.stringify(query));
  };

  return (
    <TableFilterSection>
      <Row gutter={24}>
        <Col xs={24} md={8}>
          <TextInput
            input={{
              value: filters.search,
              onChange: (event) => onInputChange('search', event.target.value, 1000),
            }}
            meta={{ valid: true }}
            type='text'
            label='Pesquisar'
            placeholder='Procurar por descrição'
          />
        </Col>
        <Col xs={24} md={8}>
          <DateInput
            label='Data'
            placeholder='Escolher data'
            input={{
              value: filters.date,
              onChange: value => onInputChange('date', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectInput
            label='Utilizador'
            allowClear
            placeholder='Escolher utilizador'
            data={users}
            dataKey='_id'
            dataLabel='name'
            translatable={false}
            input={{
              value: filters.user,
              onChange: value => onInputChange('user', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectInput
            label='Método de Pagamento'
            allowClear
            placeholder='Escolher método'
            data={PaymentMethods.filter(elem => elem.allowInPayments)}
            dataKey='_id'
            dataLabel='name'
            input={{
              value: filters.payment_method,
              onChange: value => onInputChange('payment_method', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectInput
            label='Estado'
            allowClear
            placeholder='Escolher estado'
            data={PaymentStates}
            dataKey='_id'
            dataLabel='name'
            input={{
              value: filters.payment_state,
              onChange: value => onInputChange('payment_state', value)
            }}
            meta={{valid: true}}
          />
        </Col>
      </Row>
    </TableFilterSection>
  );
}

export default Filters;
