/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from '../styles';
import { TableFilterSection } from '../../../../styles/BasicStyles';
import { TasksStates } from '../../../../infra/utils/TasksStates';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';

const Filters = ({ processID, users, queryChange }) => {
  const [filters, setFilters] = useState({
    search: '',
    date: '',
    responsible: '',
    recipient: '',
    state: '',
    process: processID,
    validated: true
  });

  let timeout = null;

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if(filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });

    queryChange(JSON.stringify(query));
  };

  return (
    <TableFilterSection>
      <Row>
        <Col>
          <TextInput
            input={{
              value: filters.search,
              onChange: (event) => onInputChange('search', event.target.value, 1000),
            }}
            meta={{ valid: true }}
            type='text'
            label='Pesquisar'
            placeholder='Procurar por descrição'
          />
        </Col>
        <Col>
          <SelectInput
            label='Responsável'
            allowClear
            placeholder='Escolher utilizador'
            data={users}
            dataKey='_id'
            dataLabel='name'
            translatable={false}
            input={{
              value: filters.responsible,
              onChange: value => onInputChange('responsible', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <SelectInput
            label='Destinatário'
            allowClear
            placeholder='Escolher utilizador'
            data={users}
            dataKey='_id'
            dataLabel='name'
            translatable={false}
            input={{
              value: filters.recipient,
              onChange: value => onInputChange('recipient', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <SelectInput
            label='Estado'
            allowClear
            placeholder='Escolher estado'
            data={TasksStates}
            dataKey='_id'
            dataLabel='name'
            translatable={false}
            input={{
              value: filters.state,
              onChange: value => onInputChange('state', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col>
          <DateInput
            label='Due date'
            placeholder='Escolher data'
            input={{
              value: filters.date,
              onChange: value => onInputChange('date', value)
            }}
            meta={{valid: true}}
          />
        </Col>
      </Row>
    </TableFilterSection>
  );
}

export default Filters;
