import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetBenefit,
  UpdateBenefit
} from '../../infra/requests/HowItWorksRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../styles/BasicStyles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import ImageInput from '../../components/generic/inputs/ImageInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages',
  image: 'required'
});

class ManageBenefitsPage extends Component {
  state = { loading: true };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetBenefit(params.id);
      dispatch(
        initialize('manage_benefits_form', {
          ...result.data,
        })
      );
      this.setState({ loading: false });
    } 
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      
      const payload = FlattenToFormData(values);
      const { success } = await UpdateBenefit(params.id, payload);

      if(success) return history.push('/how-it-works/benefits');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/how-it-works/benefits');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Como funciona'
          breadcrumbs={['Vantagens', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={ImageInput}
                    name='image'
                    label='Imagem *'
                    ratio={0.95}
                  />
                </Col>
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    type='text'
                    name={`title.${activeLanguage?.code}`}
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição *'}
                    placeholder={'Descrição'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            type='text'
                            name={`title.${language.code}`}
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição *'}
                            placeholder={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageBenefitsPage = reduxForm({
  form: 'manage_benefits_form',
  validate: validations,
})(ManageBenefitsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageBenefitsPage));