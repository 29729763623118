import { NewFundsWrapper, FundsListWrapper, HeaderLine, HeaderColumn, Rows, Row, RowColumn } from './styles';
import { Field } from 'redux-form';
import { ReactSVG } from 'react-svg';
import React, { useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import CurrencyInput from '../../../../components/generic/inputs/CurrencyInput';
import CheckboxInput from '../../../../components/generic/inputs/CheckboxInput';

const FundsList = ({ fields }) => {
  const [addFundsError, setAddFundsError] = useState(false);
  const [currentFund, setCurrentFund] = useState(null);

  const addFunds = () => {
    if(currentFund) {
      fields.push({ 
        fund: currentFund,
        amount: 0, 
        notes: '',
        lemania: false,
        commission: false
      });

      setAddFundsError(false);
      setCurrentFund(null);
    }
    else {
      setAddFundsError(true);
    }
  }

  return (
    <>
      <NewFundsWrapper>
        <TextInput
          label={'Tem fundos onde?'}
          type={'text'}
          hasError={addFundsError}
          meta={{}}
          input={{value: currentFund, onChange: (e) => setCurrentFund(e.target.value)}}
        />
        <BaseButton
          type='black'
          customIcon='plus'
          htmlType='button'
          onClick={addFunds}
          text='Adicionar'
        />
      </NewFundsWrapper>
      {
        fields.length > 0 &&
        <FundsListWrapper>
          <HeaderLine>
            <HeaderColumn width="17%">Fundo:</HeaderColumn>
            <HeaderColumn width="17%">Montante:</HeaderColumn>
            <HeaderColumn width="28%">Notas:</HeaderColumn>
            <HeaderColumn width="15%">Já passou para Lemania</HeaderColumn>
            <HeaderColumn width="15%">Comissão</HeaderColumn>
            <HeaderColumn width="8%"></HeaderColumn>
          </HeaderLine>
          <Rows>
            {
              fields.map((field, index) => 
                <Row key={index}>
                  <RowColumn width="17%">
                    <Field
                      component={TextInput}
                      name={`${field}.fund`}
                      type='text'
                      placeholder='Fundo'
                      first={true}
                    />
                  </RowColumn>
                  <RowColumn width="17%">
                    <Field
                      component={CurrencyInput}
                      name={`${field}.amount`}
                      suffix={'CHF'}
                      first={true}
                    />
                  </RowColumn>
                  <RowColumn width="28%">
                    <Field
                      component={TextInput}
                      name={`${field}.notes`}
                      type='text'
                      placeholder='Notas'
                      first={true}
                    />
                  </RowColumn>
                  <RowColumn width="15%">
                    <Field
                      component={CheckboxInput}
                      name={`${field}.lemania`}
                      first={true}
                    />
                  </RowColumn>
                  <RowColumn width="15%">
                    <Field
                      component={CheckboxInput}
                      name={`${field}.commission`}
                      first={true}
                    />
                  </RowColumn>
                  <RowColumn width="8%">
                    <ReactSVG src={`${process.env.REACT_APP_BO_URL}trash.svg`} className='icon' onClick={() => fields.remove(index)} />
                  </RowColumn>
                </Row>
              )
            }
          </Rows>
        </FundsListWrapper>
      }
    </>
  );
};

export default FundsList;
