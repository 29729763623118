/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import { reduxForm, change } from 'redux-form';
import { GetAdminList, GetAgentsList } from '../../../infra/requests/UsersRequests';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { GetClientsList } from '../../../infra/requests/CommunicationsRequests';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { withLocalize } from 'react-localize-redux';
import { FormContainer, BaseForm, SpinLoading } from '../../../styles/BasicStyles';
import { SaveCommunication } from '../../../infra/requests/CommunicationsRequests';
import { FlattenToFormData } from '../../../infra/services/formdata/TransformToFormData';
import React, { useEffect, useState } from 'react';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import FormValidator from '../../../infra/services/validations/FormValidator';
import EmailsList from './EmailsList';
import EmailContent from './EmailContent';

const validations = FormValidator.make({
  subject: 'required',
  content: 'required',
  emailsList: 'array|required'
});

const ComunicationsSendEmailPage= ({ dispatch, user, handleSubmit, pristine, history }) => {
  const [loading, setLoading] = useState(true);
  const [loadingClients, setLoadingClients] = useState(false);
  const [responsibles, setResponsibles] = useState([]);
  const [services, setServices] = useState([]);
  const [agents, setAgents] = useState([]);
  const [clients, setClients] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  useEffect(() => {
    // NOTE: This area was made for both agents and admins, but only admins have access for now. 
    // If the agents should have access in the future just remove this verification
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 
    
    const init = async () => await getInfo();

    init();
  }, []);

  const getInfo = async () => {
    try {
      const servicesList = user?.type === 1 ? await GetServicesList() : [];
      const agentsList = user?.type === 1 ? await GetAgentsList() : [];
      const responsiblesList = user?.type === 1 ? await GetAdminList() : [];

      if(responsiblesList?.data?.length > 0) {
        responsiblesList.data.unshift({
          _id: 'all',
          name: 'Todos os responsáveis'
        })
      }

      if(agentsList?.data?.length > 0) {
        agentsList.data.unshift({
          _id: 'all',
          name: 'Todos os agentes'
        })
      }
     
      setServices(servicesList?.data || []);
      setResponsibles(responsiblesList?.data || []);
      setAgents(agentsList?.data || []);
      setLoading(false);
    }
    catch(error) {
      console.log(error);
    }
  }

  const getClients = async (formValues) => {
    try {
      if(loadingClients) return false;

      setLoadingClients(true);

      if(!formValues?.user && !formValues?.newsletter && !formValues?.agent && !formValues?.responsible && !formValues?.service && !formValues?.process_state && !formValues?.payment_state) {
        dispatch(change('create_communication_form', 'emailsList', []));

        setClients([]);
      }
      else {
        const payload = {
          all_users: formValues?.user, 
          newsletter: formValues?.newsletter,
          agent: formValues?.agent, 
          responsible: formValues?.responsible, 
          service: formValues?.service, 
          process_state: formValues?.process_state, 
          payment_state: formValues?.payment_state
        };

        const clients = await GetClientsList(payload);

        dispatch(change('create_communication_form', 'emailsList', clients?.data?.map(e => e.email)));

        setClients(clients?.data || []);
      }

      setLoadingClients(false);
    }
    catch(error) {
      console.log(error);
      setLoadingClients(false);
    }
  }

  const handleEmails = (emails) => {
    const newArray = emails.map((str) => ({ email: str, state: 1 }));
    return newArray
  } 

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const payload = {
        all_users: values.user === 'all' ? true : false,
        all_agents: values.agent === 'all' ? true : false,
        newsletter: values.newsletter === 'yes' ? true : false,
        agent: values.agent === 'all' ? null : values.agent,
        all_responsibles: values.responsible === 'all' ? true : false,
        responsible: values.responsible === 'all' ? null : values.responsible,
        service: values.service,
        process_state: values.process_state,
        payment_state: values.payment_state,
        subject: values.subject,
        content: values.content,
        emails: handleEmails(values.emailsList),
        user: user._id,
        selectedFiles: acceptedFiles
      };

      const saveEmail = await SaveCommunication(FlattenToFormData(payload));

      if(saveEmail?.success) {
        notification.success({ message: 'Comunicação criada com sucesso!' });

        return history.push(`/communications/${saveEmail.data._id}?showStatus&showAlert`);
      }
  
      setLoading(false);
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  if(loading) return <SpinLoading />;

  return (
    <>
     <ManageFormHeader
        title='Nova Comunicação'
        buttons={[
          {
            text: 'Guardar',
            onClick: handleSubmit(onSubmit),
            disabled: pristine
          },
          {
            icon: 'close',
            type: 'secondary',
            text: 'Cancelar',
            onClick: () => history.push('/communications')
          }
        ]}
      />
      <FormContainer>
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <EmailsList
            services={services}
            responsibles={responsibles}
            agents={agents}
            clients={clients}
            loadingClients={loadingClients}
            getClients={getClients}
          />
          <EmailContent
            acceptedFiles={acceptedFiles}
            rejectedFiles={rejectedFiles}
            setAcceptedFiles={setAcceptedFiles}
            setRejectedFiles={setRejectedFiles}
          />
        </BaseForm>
      </FormContainer>
    </>
  );
}

const CreateComunicationForm = reduxForm({
  form: 'create_communication_form',
  validate: validations
})(ComunicationsSendEmailPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(CreateComunicationForm));
