import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../styles/BasicStyles';
import React, { Component } from 'react';
import TextInput from '../../../components/generic/inputs/TextInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../components/generic/buttons/BaseButton';

const states = [
  {
    _id: 1,
    name: "Não enviado"
  },
  {
    _id: 2,
    name: "Enviado"
  },
  {
    _id: 3,
    name: "Erro"
  },
];

class StatusFilters extends Component {
  state = { 
    search: '',
    state: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { updateDataTable } = this.props;
    const { search, state } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por email'
            />
          </Col>
          <Col xs={24} md={8}>
            <SelectInput
              label="Estado"
              allowClear
              placeholder="Escolher estado"
              data={states}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: state,
                onChange: value => this.onInputChange('state', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={8}>
            <BaseButton
              style={{ marginTop: '38px', float: 'right' }}
              icon={'reload'}
              text={'Atualizar'}
              onClick={() => updateDataTable()}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default StatusFilters;