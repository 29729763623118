export const CivilStates = [
  { _id: 1, name: 'Solteiro(a)', hasDate: false, lemaniaID: 'aae365c3-23c4-4c0c-bfd9-57475371fe05' },
  { _id: 2, name: 'Casado(a)', hasDate: true, lemaniaID: 'aa14d6b1-d3f1-4b20-b36b-b2c6c3380d59' },
  { _id: 3, name: 'Divorciado(a)', hasDate: true, lemaniaID: '2aae8e62-af34-48b8-9ec8-14d7372613f6' },
  { _id: 4, name: 'Viúvo(a)', hasDate: true, lemaniaID: '4f3929d4-36a1-464e-9865-a04eb85391e8' },
  { _id: 5, name: 'Parceria dissolvida', hasDate: true, lemaniaID: '7e95f7bf-ac4e-4bbc-98f3-e90d8366e778' },
  { _id: 6, name: 'Parceria registrada', hasDate: true, lemaniaID: 'c16ef39d-daad-4964-a5bc-7ed3e0b1a0e7' },
  { _id: 7, name: 'Separado(a)', hasDate: true, lemaniaID: '8dda8037-b6da-4940-a090-2face6e9af78' }
];

export const getCivilState = (value) => {
  if(Number.isInteger(value) && value > 0 && CivilStates[value - 1]) return CivilStates[value - 1];
  return null;
};