import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetAdminsPartnersList } from '../../infra/requests/UsersRequests';
import { GetCommission, UpdateCommission } from '../../infra/requests/CommissionsRequests';
import { GetUserProcessFullList, GetProcess } from '../../infra/requests/ProcessRequests';
import { FormContainer, BaseForm, SpinLoading, Separator } from '../../styles/BasicStyles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { CommissionsStates, commissionOptions } from '../../infra/utils/CommissionsStates';
import { change, formValueSelector } from 'redux-form';
import { currencyOptions } from '../../infra/utils/Currencies';
import React, { Component } from 'react';
import SelectInput from '../../components/generic/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import DateInput from "../../components/generic/inputs/DateInput";
import TextInput from '../../components/generic/inputs/TextInput';
import CurrencyInput from '../../components/generic/inputs/CurrencyInput';
import RadioButtons from '../../components/generic/inputs/RadioButtons';
import NumberInput from '../../components/generic/inputs/NumberInput';

const validations = FormValidator.make({
  state: 'required',
  value_type: 'required',
  value: 'required',
  commission_currency: 'required'
});

class ManageCommissionsPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    users: [],
    processList: [],
    processInfo: null,
    commissionInfo: null,
    commissionValueType: null,
    commissionCurrency: null
  };

  componentDidMount = async () => {
    const { user, history, match: { params }, dispatch, CloseMenu } = this.props;

    if((user.type === 1 && !user.super_admin) || user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const process = await GetUserProcessFullList();

    const processList = [];
    if(process?.data?.length > 0) {
      process.data.forEach(elem => {
        processList.push({
          _id: elem._id,
          name: `${elem.ref} - ${elem.full_name}`
        })
      });
    }

    const result = await GetCommission(params.id);
    const processInfo = await GetProcess(result?.data?.process);
    const users = await GetAdminsPartnersList();
    
    dispatch(initialize('manage_commissions_form', { 
      ...result.data, 
      type: !!result?.data?.lemania ? result.data.lemania : !!result?.data?.payment?.description ? result.data.payment?.description : processInfo?.data?.service_name
    }));

    this.setState({ 
      loading: false,
      processList,
      processInfo: processInfo?.data,
      users: users?.data || [],
      commissionInfo: result?.data,
      commissionValueType: result?.data?.value_type,
      commissionCurrency: result?.data?.commission_currency
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;

      const payload = FlattenToFormData({
        state: values.state,
        value_type: values.value_type,
        value: values.value,
        commission_value: values.commission_value,
        commission_currency: values.commission_currency
      });
      const { success } = await UpdateCommission(params.id, payload);

      if(success) {
        return history.push('/commissions');
      }

      this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/commissions');
  };

  calculateCommission = (value) => {
    const { dispatch } = this.props;
    const { commissionInfo, commissionValueType } = this.state;

    dispatch(change('manage_commissions_form', 'commission_value', commissionValueType === 2 ? (commissionInfo?.total_reformas * (value / 100)) : value));
  }

  render() {
    const { handleSubmit, pristine, currentValue } = this.props;
    const { isNew, loading, users, processList, processInfo, commissionValueType, commissionInfo, commissionCurrency } = this.state;

    if(loading) return <SpinLoading />;

    const title = isNew ? 'Adicionar' : 'Editar';

    const buttons = [{
      type: 'secondary',
      icon: 'close',
      text: 'Cancelar',
      onClick: this.handleOnCancel
    }];

    if(processInfo && !processInfo.archived) {
      buttons.unshift({
        type: 'primary',
        icon: 'save',
        text: 'Gravar',
        onClick: handleSubmit((values) => this.onSubmit(values)),
        disabled: pristine
      });
    }

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Comissões'
          breadcrumbs={[title]}
          buttons={buttons}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col md={12} xs={24}>
                <Field
                  component={SelectInput}
                  name={'process'}
                  label={'Processo'}
                  placeholder={'Escolha o processo'}
                  type='select'
                  data={processList}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={true}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'type'}
                  type={'text'}
                  label={!!commissionInfo?.lemania ? 'Lemania' : !!commissionInfo.payment?.description ? 'Pagamento' : 'Serviço'}
                  placeholder={!!commissionInfo?.lemania ? 'Lemania' : !!commissionInfo.payment?.description ? 'Pagamento' : 'Serviço'}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'user'}
                  label={'Utilizador'}
                  placeholder={'Escolha o utilizador'}
                  type='select'
                  data={users}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={true}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={DateInput}
                  name='createdAt'
                  label={'Data'}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'state'}
                  label={'Estado *'}
                  placeholder={'Escolha o estado'}
                  type='select'
                  data={CommissionsStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                />
              </Col>
            </Row>
            <Separator />
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={CurrencyInput}
                  name={'total'}
                  label={'Total'}
                  suffix={commissionInfo?.original_currency === 1 ? 'CHF' : commissionInfo?.original_currency === 2 ? '€' : ''}
                  disabled={true}
                />
              </Col>
              {
                !!commissionInfo?.lemania &&
                <Col md={12} sm={24}>
                  <Field
                    component={CurrencyInput}
                    name={'total_reformas'}
                    label={'Total (Reformas na Suíça)'}
                    suffix={commissionInfo?.original_currency === 1 ? 'CHF' : commissionInfo?.original_currency === 2 ? '€' : ''}
                    disabled={true}
                  />
                </Col>
              }
            </Row>
            <Row gutter={24} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col md={12} sm={24}>
                <Field
                  component={RadioButtons}
                  name={'value_type'}
                  options={commissionOptions}
                  label={'Tipo de Comissão *'}
                  execAfterChange={(value) => this.setState({ commissionValueType: value }, () => this.calculateCommission(currentValue))}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={RadioButtons}
                  name={'commission_currency'}
                  options={currencyOptions}
                  label={'Moeda *'}
                  execAfterChange={(value) => this.setState({ commissionCurrency: value })}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                {
                  commissionValueType === 1 ?
                  <Field
                    component={CurrencyInput}
                    name={'value'}
                    label={`Valor (${commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}) *`}
                    suffix={commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}
                    execAfterChange={this.calculateCommission}
                  />
                  :
                  commissionValueType === 2 ?
                  <Field
                    component={NumberInput}
                    name={'value'}
                    label={'Valor (%) *'}
                    placeholder={'0.00%'}
                    min={0}
                    max={100}
                    execAfterChange={this.calculateCommission}
                  />
                  :
                  null
                }
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={CurrencyInput}
                  name={'commission_value'}
                  label={'Valor Comissão'}
                  suffix={commissionCurrency === 1 ? 'CHF' : commissionCurrency === 2 ? '€' : ''}
                  disabled={true}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCommissionsPage = reduxForm({
  form: 'manage_commissions_form',
  validate: validations
})(ManageCommissionsPage);

const selector = formValueSelector('manage_commissions_form');

const mapStateToProps = (state) => ({
  user: state.user,
  currentValue: selector(state, 'value')
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageCommissionsPage));
