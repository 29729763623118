import { Row, Col } from 'antd';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { userTypes, newsletterTypes } from '../../../infra/utils/Communications';
import { change } from 'redux-form';
import { SectionTitle, CardWrapper, Card, CardContainer } from '../styles';
import { ProcessStates, ProcessPaymentStates } from '../../../infra/utils/ProcessStates';
import { Separator } from '../../../styles/BasicStyles';
import React, { useState } from 'react';
import SelectInput from '../../../components/generic/inputs/SelectInput';

const EmailsList = ({ clients, responsibles, services, agents, loadingClients, getClients, dispatch, user, formValues }) => {
  const [savedValues, setSavedValues] = useState({});
  const [filtersDisabled, setFiltersDisabled] = useState({ user: false, newsletter: false, agent: false, responsible: false, service: false, process_state: false, payment_state: false });

  const emailsTotal = () => {
    if(loadingClients) return 'A carregar...';
    return `${formValues?.emailsList?.length || 0} emails selecionados`;
  }

  // When selecting "all users", disable and clear every other filter
  const checkUser = async (value) => {
    if(value) {
      dispatch(change('create_communication_form', 'newsletter', null));
      dispatch(change('create_communication_form', 'agent', null));
      dispatch(change('create_communication_form', 'responsible', null));
      dispatch(change('create_communication_form', 'service', null));
      dispatch(change('create_communication_form', 'process_state', null));
      dispatch(change('create_communication_form', 'payment_state', null));

      setFiltersDisabled({ newsletter: true, agent: true, responsible: true, service: true, process_state: true, payment_state: true });
    }
    else {
      setFiltersDisabled({ newsletter: false, agent: false, responsible: false, service: false, process_state: false, payment_state: false });
    }

    setSavedValues({ ...savedValues, user: value });
    await getClients({ ...savedValues, user: value });
  }

  // When selecting "newsletter", disable and clear every other filter
  const checkNewsletter = async (value) => {
    if(value) {
      dispatch(change('create_communication_form', 'user', null));
      dispatch(change('create_communication_form', 'agent', null));
      dispatch(change('create_communication_form', 'responsible', null));
      dispatch(change('create_communication_form', 'service', null));
      dispatch(change('create_communication_form', 'process_state', null));
      dispatch(change('create_communication_form', 'payment_state', null));

      setFiltersDisabled({ user: true, agent: true, responsible: true, service: true, process_state: true, payment_state: true });
    }
    else {
      setFiltersDisabled({ user: false, agent: false, responsible: false, service: false, process_state: false, payment_state: false });
    }

    setSavedValues({ ...savedValues, newsletter: value });
    await getClients({ ...savedValues, newsletter: value });
  }

  // When selecting a Agent, disable and clear User, Responsible and Service filters
  const checkAgent = async (value) => {
    if(value) {
      dispatch(change('create_communication_form', 'user', null));
      dispatch(change('create_communication_form', 'newsletter', null));
      dispatch(change('create_communication_form', 'responsible', null));
      dispatch(change('create_communication_form', 'service', null));

      setFiltersDisabled({ ...filtersDisabled, user: true, newsletter: true, responsible: true, service: true });
    }
    else {
      setFiltersDisabled({ ...filtersDisabled, user: false, newsletter: false, responsible: false, service: false });
    }

    setSavedValues({ ...savedValues, agent: value });
    await getClients({ ...savedValues, agent: value });
  }

  // When selecting a Responsible, disable and clear User and Agent filters
  const checkResponsible = async (value) =>  {
    if(value) {
      dispatch(change('create_communication_form', 'user', null));
      dispatch(change('create_communication_form', 'newsletter', null));
      dispatch(change('create_communication_form', 'agent', null));

      setFiltersDisabled({ ...filtersDisabled, user: true, newsletter: true, agent: true });
    }
    else {
      setFiltersDisabled({ ...filtersDisabled, user: false, newsletter: false, agent: false });
    }

    setSavedValues({ ...savedValues, responsible: value });
    await getClients({ ...savedValues, responsible: value });
  } 

  // When selecting a Service, Process State or Payment State, disable and clear User filter
  const checkType = async (field, value) => {
    if(value) {
      dispatch(change('create_communication_form', 'user', null));
      dispatch(change('create_communication_form', 'newsletter', null));

      setFiltersDisabled({ ...filtersDisabled, user: true, newsletter: true });
    }
    else {
      setFiltersDisabled({ ...filtersDisabled, user: false, newsletter: false  });
    }

    setSavedValues({ ...savedValues, [field]: value });
    await getClients({ ...savedValues, [field]: value });
  }

  return (
    <>
      <SectionTitle>Destinatários</SectionTitle>
      <CardWrapper gutter={24}>
        <Card>
          <CardContainer>
            <Row gutter={24}>
              <Col md={8} sm={12} xs={24}>
                <Field
                  component={SelectInput}
                  name={'user'}
                  allowClear
                  label={'Utilizadores'}
                  placeholder={'Escolher utilizadores'}
                  type='select'
                  data={userTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={filtersDisabled.user}
                  execAfterChange={checkUser}
                />
              </Col>
              {
                user?.type === 1 &&
                <>
                  <Col md={8} sm={12} xs={24}>
                    <Field
                      component={SelectInput}
                      name={'newsletter'}
                      allowClear
                      label={'Inscrito na Newsletter?'}
                      placeholder={'Escolher estado'}
                      type='select'
                      data={newsletterTypes}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      disabled={filtersDisabled.newsletter}
                      execAfterChange={checkNewsletter}
                    />
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Field
                      component={SelectInput}
                      name={'agent'}
                      allowClear
                      label={'Agente'}
                      placeholder={'Escolher agente'}
                      type='select'
                      data={agents}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      disabled={filtersDisabled.agent}
                      execAfterChange={checkAgent}
                    />
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Field
                      component={SelectInput}
                      name={'responsible'}
                      allowClear
                      label={'Responsável'}
                      placeholder={'Escolher responsável'}
                      type='select'
                      data={responsibles}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      disabled={filtersDisabled.responsible}
                      execAfterChange={checkResponsible}
                    />
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Field
                      component={SelectInput}
                      name={'service'}
                      allowClear
                      label={'Serviço'}
                      placeholder={'Escolher serviço'}
                      type='select'
                      data={services}
                      dataKey={'_id'}
                      dataLabel={'title'}
                      translatable={true}
                      disabled={filtersDisabled.service}
                      execAfterChange={(value) => checkType('service', value)}
                    />
                  </Col>
                </>
              }
              <Col md={8} sm={12} xs={24}>
                <Field
                  component={SelectInput}
                  name={'process_state'}
                  allowClear
                  label={'Estado do Processo'}
                  placeholder={'Escolher estado'}
                  type='select'
                  data={ProcessStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={filtersDisabled.process_state}
                  execAfterChange={(value) => checkType('process_state', value)}
                />
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Field
                  component={SelectInput}
                  name={'payment_state'}
                  allowClear
                  label={'Estado do Pagamento'}
                  placeholder={'Escolher estado'}
                  type='select'
                  data={ProcessPaymentStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={filtersDisabled.payment_state}
                  execAfterChange={(value) => checkType('payment_state', value)}
                />
              </Col>
            </Row>
            <Separator />
            <Field
              mode="multiple"
              allowClear
              component={SelectInput}
              name={'emailsList'}
              label={'Emails *'}
              placeholder={'Emails'}
              type='select'
              data={clients}
              dataKey={'email'}
              dataLabel={'email'}
              disabled={(!formValues?.emailsList || formValues?.emailsList?.length === 0) ? true : false}
              noteText={emailsTotal()}
              loading={loadingClients}
            />
          </CardContainer>
        </Card>
      </CardWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  formValues: state?.form?.create_communication_form?.values
});

export default withLocalize(connect(mapStateToProps)(EmailsList));
