import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetNumbers,
  UpdateNumbers
} from '../../infra/requests/CompanyRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import NumberInput from '../../components/generic/inputs/NumberInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const numbersID = '5f3e3ab2cab15556ebb655ae';

const validations = FormValidator.make({
  title1: 'required|languages',
  number1: 'required',
  title2: 'required|languages',
  number2: 'required'
});

class ManageNumbersPage extends Component {
  state = { 
    loading: true 
  };

  componentDidMount = async () => {
    const { dispatch, CloseMenu, user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const result = await GetNumbers(numbersID);
    dispatch(
      initialize('manage_numbers_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false 
    });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;
      this.setState({ loading: true });

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { data, success } = await UpdateNumbers(numbersID, payload);

      if(success) {
        dispatch(
          initialize('manage_numbers_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }

      return this.setState({ 
        loading: false 
      });
    } 
    catch (e) {
      console.error(e);
      this.setState({ 
        loading: false 
      });
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Sobre a empresa'
          breadcrumbs={['Números', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={NumberInput}
                    name={`number1`}
                    label={'1º Número *'}
                    placeholder={'0'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title1.${activeLanguage?.code}`}
                    type='text'
                    label={'1º Título *'}
                    placeholder={'1º Título'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title1.${language.code}`}
                            type='text'
                            label={'1º Título *'}
                            placeholder={'1º Título'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={NumberInput}
                    name={`number2`}
                    label={'2º Número *'}
                    placeholder={'0'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title2.${activeLanguage?.code}`}
                    type='text'
                    label={'2º Título *'}
                    placeholder={'2º Título'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title2.${language.code}`}
                            type='text'
                            label={'2º Título *'}
                            placeholder={'2º Título'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageNumbersPage = reduxForm({
  form: 'manage_numbers_form',
  validate: validations,
})(ManageNumbersPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageNumbersPage));