import ServicesPage from '../../pages/services/ServicesPage';
import ManageServicesPage from '../../pages/services/ManageServicesPage';
import ManageHomepageBannerPage from '../../pages/homepage/ManageHomepageBannerPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import ManageDynamicPages from '../../pages/dynamicPages/ManageDynamicPages';
import Metatags from '../../pages/metatags/Metatags';
import ManageMetatags from '../../pages/metatags/ManageMetatags';
import FaqsPage from '../../pages/faqs/FaqsPage';
import ManageFaqsPage from '../../pages/faqs/ManageFaqsPage';
import ManageCompanyBannerPage from '../../pages/company/Banner';
import ManageAboutUsPage from '../../pages/company/AboutUs';
import ManageCompanyMissionPage from '../../pages/company/Mission';
import ManageTeamIntroPage from '../../pages/company/TeamIntro';
import TeamPage from '../../pages/company_team/TeamPage';
import ManageTeamPage from '../../pages/company_team/ManageTeamPage';
import ManageNumbersPage from '../../pages/company/Numbers';
import ManageHowItWorksBannerPage from '../../pages/how_it_works/Banner';
import ManageHowItWorksVideoPage from '../../pages/how_it_works/Video';
import ManageHowItWorksBenefitsIntro from '../../pages/how_it_works/BenefitsIntro';
import ManageHowItWorksProcessTypesIntroPage from '../../pages/how_it_works/ProcessTypesIntro';
import ProcessTypesPage from '../../pages/how_it_works_process_types/ProcessTypesPage';
import ManageProcessTypesPage from '../../pages/how_it_works_process_types/ManageProcessTypesPage';
import BenefitsPage from '../../pages/how_it_works_benefits/BenefitsPage';
import ManageBenefitsPage from '../../pages/how_it_works_benefits/ManageBenefitsPage';
import ManageServicesBannerPage from '../../pages/services/ManageServicesBannerPage';
import ManageBlogBannerPage from '../../pages/blog/ManageBlogBannerPage';
import BlogCategoriesPage from '../../pages/blog_categories/BlogCategoriesPage';
import ManageBlogCategoriesPage from '../../pages/blog_categories/ManageBlogCategoriesPage';
import BlogPage from '../../pages/blog/BlogPage';
import ManageBlogPage from '../../pages/blog/ManageBlogPage';
import ManageEstateBannerPage from '../../pages/estate/ManageEstateBannerPage';
import EstatePage from '../../pages/estate/EstatePage';
import ManageEstatePage from '../../pages/estate/ManageEstatePage';
import EstateTypesPage from '../../pages/estate_types/EstateTypesPage';
import ManageEstateTypesPage from '../../pages/estate_types/ManageEstateTypesPage';
import EstateFeaturesPage from '../../pages/estate_features/EstateFeaturesPage';
import ManageEstateFeaturesPage from '../../pages/estate_features/ManageEstateFeaturesPage';
import ManageOtherServicesPage from '../../pages/other_services/ManageOtherServicesPage';
import NewsletterPage from '../../pages/newsletter/NewsletterPage';
import ContactRequestsPage from '../../pages/contact_requests/ContactRequestsPage';
import ManageContactRequestPage from '../../pages/contact_requests/ManageContactRequestPage';
import ManageHomepagePage from '../../pages/homepage/ManageHomepagePage';
import ManageFaqsIntroPage from '../../pages/faqs/ManageFaqsIntroPage';
import ManageDocumentsBannerPage from '../../pages/digital_documents/ManageDocumentsBannerPage';
import DocumentsPage from '../../pages/digital_documents/DocumentsPage';
import ManageDocumentPage from '../../pages/digital_documents/ManageDocumentPage';
import ManageDocumentTextPage from '../../pages/digital_documents/components/ManageDocumentTextPage';
import DocumentRequestsPage from '../../pages/document_requests/DocumentRequestsPage';
import ManageDocumentRequestPage from '../../pages/document_requests/ManageDocumentRequestPage';
import ClientsRecommendPage from '../../pages/clients_recommend/ClientsRecommendPage';
import ManageClientRecommendPage from '../../pages/clients_recommend/ManageClientRecommendPage';

export default [
  {
    path: '/contact-requests',
    component: ContactRequestsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/contact-requests/:id',
    component: ManageContactRequestPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/newsletter',
    component: NewsletterPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/document-requests',
    component: DocumentRequestsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/document-requests/:id',
    component: ManageDocumentRequestPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients-recommend',
    component: ClientsRecommendPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients-recommend/add',
    component: ManageClientRecommendPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients-recommend/:id',
    component: ManageClientRecommendPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/services/banner',
    component: ManageServicesBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/services/list',
    component: ServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/services/list/add',
    component: ManageServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/services/list/:id',
    component: ManageServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/other-services/insurance',
    component: ManageOtherServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/other-services/credit',
    component: ManageOtherServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/other-services/2-pilar',
    component: ManageOtherServicesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/banner',
    component: ManageBlogBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/categories',
    component: BlogCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/categories/add',
    component: ManageBlogCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/categories/:id',
    component: ManageBlogCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/list',
    component: BlogPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/list/add',
    component: ManageBlogPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blog/list/:id',
    component: ManageBlogPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/banner',
    component: ManageEstateBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/types',
    component: EstateTypesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/types/add',
    component: ManageEstateTypesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/types/:id',
    component: ManageEstateTypesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/features',
    component: EstateFeaturesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/features/add',
    component: ManageEstateFeaturesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/features/:id',
    component: ManageEstateFeaturesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/list',
    component: EstatePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/list/add',
    component: ManageEstatePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/estate/list/:id',
    component: ManageEstatePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/homepage/banner',
    component: ManageHomepageBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/homepage/estate',
    component: ManageHomepagePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/homepage/documents',
    component: ManageHomepagePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/homepage/services',
    component: ManageHomepagePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/metatags',
    component: Metatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/metatags/:id',
    component: ManageMetatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/faqs/intro',
    component: ManageFaqsIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/faqs/list',
    component: FaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/faqs/list/add',
    component: ManageFaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/faqs/list/:id',
    component: ManageFaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/banner',
    component: ManageCompanyBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/mission',
    component: ManageCompanyMissionPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/about-us',
    component: ManageAboutUsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/team-intro',
    component: ManageTeamIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/team',
    component: TeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/team/add',
    component: ManageTeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/team/:id',
    component: ManageTeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/company/numbers',
    component: ManageNumbersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/banner',
    component: ManageHowItWorksBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/video',
    component: ManageHowItWorksVideoPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/process-types-intro',
    component: ManageHowItWorksProcessTypesIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/process-types',
    component: ProcessTypesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/process-types/:id',
    component: ManageProcessTypesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/benefits-intro',
    component: ManageHowItWorksBenefitsIntro,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/benefits',
    component: BenefitsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/how-it-works/benefits/:id',
    component: ManageBenefitsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/digital-documents/banner',
    component: ManageDocumentsBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/digital-documents/list/:document/:text',
    component: ManageDocumentTextPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/digital-documents/list',
    component: DocumentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/digital-documents/list/add',
    component: ManageDocumentPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/digital-documents/list/:id',
    component: ManageDocumentPage,
    isAuthenticated: true,
    role: 1,
  },
];
