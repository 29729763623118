import { Spin } from 'antd';
import { PageTitle } from "../../../styles/BasicStyles";
import styled from 'styled-components';

export const SectionTitle = styled(PageTitle)` 
  margin: 15px 0px;
`;

export const PriceSpinLoading = styled(Spin)`
  height: 60px;
  display: flex;
  align-items: flex-end;
`;
