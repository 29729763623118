import moment from 'moment';

export const getYears = () => {
  const currentYear = moment().year();
  const years = [];

  for(let i = currentYear; i >= 2020; i--) {
    years.push({
      _id: i,
      name: i
    });
  }

  return years;
};
