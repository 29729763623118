import { renderEmailState } from '../../../infra/utils/EmailState';
import { GetCommunicationEmails } from '../../../infra/requests/CommunicationsRequests';
import { Alert } from 'antd';
import { ServiceStatusWrapper, ServiceStatusLabel, ServiceStatus } from '../styles';
import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import StatusFilters from './StatusFilters';

class Status extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    columns: [
      {
        title: 'Email',
        dataIndex: 'email',
        width: '70%',
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        render: (value) => renderEmailState(value),
        width: '30%',
      }
    ],
    rows: [],
    isRunning: false,
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters } = this.state;
      const { communicationId, checkSendButton } = this.props;

      const result = await GetCommunicationEmails(communicationId, currentPage, pageSize, filters);

      checkSendButton(result?.data);

      this.setState({
        rows: result?.data?.emails || [],
        total: result?.data?.total || 0,
        loading: false,
        isRunning: result?.data?.isRunning
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  }

  render() {
    const { alert } = this.props;
    const { columns, rows, loading, isRunning, currentPage, pageSize, total } = this.state;

    return (
      <>
        {
          alert && !isRunning &&
          <div style={{ margin: '10px 0px' }}>
            <Alert 
              message="Para iniciar o envio do emails deverá clicar no botão acima!" 
              type="warning" 
              showIcon 
              closable
            />
          </div>
        }
        {
          isRunning &&
          <ServiceStatusWrapper>
            <ServiceStatusLabel>Estado:</ServiceStatusLabel>
            <ServiceStatus>A enviar</ServiceStatus>
          </ServiceStatusWrapper>
        }
        <StatusFilters queryChange={this.handleFilterChange} updateDataTable={this.updateDataTable} />
        <Table
          columns={columns}
          loading={loading}
          rows={rows}
          showHeader={true}
          rowKey={'email'}
          hasPagination={true}
          emptyIcon='warning'
          emptyText={'Não existem Emails!'}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          currentPage={currentPage}
          pageSize={pageSize}
          total={total}
        />
      </>
    );
  }
}

export default Status;