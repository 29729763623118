import styled from 'styled-components';

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > div:first-child {
    padding-right: 13px;
  }
  & > div:last-child {
    padding-left: 13px;
  }
`;
