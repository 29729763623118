import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetContactDetail } from '../../infra/requests/ContactsRequests';
import { FormContainer, SpinLoading } from '../../styles/BasicStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { Card, CardWrapper, CardTitle } from '../communications/styles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import moment from 'moment';

class ManageContactRequestPage extends Component {
  state = { 
    loading: true,
    initialValues: null
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3 || !params.id) {
      return history.push('/');
    }

    CloseMenu();

    const result = await GetContactDetail(params.id);

    this.setState({ 
      loading: false,
      initialValues: result.data
    });
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/contact-requests');
  };

  renderOrigin = () => {
    const { initialValues } = this.state;

    let result = 'Formulário de Contacto';
    if(!!initialValues?.other_service) {
      if(initialValues?.other_service.tag === 'INSURANCE') result = 'Seguros';
      else if(initialValues?.other_service.tag === 'CREDIT') result = 'Créditos';
      else if(initialValues?.other_service.tag === '2_PILAR') result = '2º Pilar para Empresas';
    }
    else if(!!initialValues?.estate) result = `Imobiliário (${initialValues?.estate.title?.pt})`;

    return result;
  }

  render() {
    const { loading, initialValues } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Pedidos de Contacto'
          breadcrumbs={['Visualizar']}
          buttons={[
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <CardWrapper style={{ marginTop: '30px' }}>
            <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Data</CardTitle>
                <Card>
                {`${moment(initialValues.createdAt).format('DD-MM-YYYY, HH:mm')}h`}
                </Card>
              </Col>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Origem</CardTitle>
                <Card>
                {this.renderOrigin()}
                </Card>
              </Col>
            </Row>  
            <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Primeiro Nome</CardTitle>
                <Card>
                  {initialValues?.first_name}
                </Card>
              </Col>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Último Nome</CardTitle>
                <Card>
                {initialValues?.last_name}
                </Card>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Email</CardTitle>
                <Card>
                  {initialValues?.email}
                </Card>
              </Col>
              <Col xl={12} md={12} style={{paddingRight: '0px'}}>
                <CardTitle>Contacto</CardTitle>
                <Card>
                {initialValues?.contact || '-'}
                </Card>
              </Col>
            </Row>  
            <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
              <CardTitle>Mensagem</CardTitle>
              <Card>
                <div dangerouslySetInnerHTML={{ __html: initialValues?.message }} />
              </Card>
            </Row>
          </CardWrapper>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageContactRequestPage));