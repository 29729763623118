import React from "react";
import ReactDOM from "react-dom";
import { LocalizeProvider } from "react-localize-redux";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";
import configureStore, { history } from "./redux/ConfigStore";
import startThunk from "./redux/thunks/StartThunk";
import client from "./infra/config/AxiosConfig";
import Theme from "./styles/Theme";
import App from "./App";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Zurich");

const store = configureStore();
client.setupInterceptors(store);
store.dispatch(startThunk());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
