export const CommissionsStates = [
  { _id: 1, name: 'A decorrer', bgColor: '#FFF9DB', fontColor: '#E67700', showProfile: false },
  { _id: 3, name: 'Pago', bgColor: '#D1FAE5', fontColor: '#065F46', showProfile: true },
  { _id: 4, name: 'Cancelado', bgColor: '#ffcdd2', fontColor: '#e53935', showProfile: false },
  { _id: 5, name: 'Não aplicável', bgColor: '#bdbdbd', fontColor: '#212121', showProfile: false }
];

export const getCommissionState = (value) => {
  if(Number.isInteger(value) && value > 0) return CommissionsStates.find(elem => elem._id === value);
  return null;
};

export const commissionOptions = [
  {
    label: 'Valor fixo',
    value: 1
  },
  {
    label: 'Percentagem',
    value: 2
  }
];