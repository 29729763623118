export const Currencies = [
  { _id: 1, name: 'CFH', value: 'CHF' },
  { _id: 2, name: 'Euro', value: '€' }
];

export const currencyOptions = [
  {
    label: 'CFH',
    value: 1
  },
  {
    label: 'Euro',
    value: 2
  }
];

export const getCurrency = (value) => {
  if(Number.isInteger(value) && value > 0 && Currencies[value - 1]) return Currencies[value - 1].value;
  return null;
};