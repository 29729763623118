import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import {
  GetCode,
  UpdateCode,
  CreateCode
} from '../../infra/requests/PromoCodesRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import {
  PromoCodeTotal
} from './PromoCodesStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import { PaymentMethods } from '../../infra/utils/PaymentMethods';
import { ServiceModes } from '../../infra/utils/ServiceModes';
import { GetServicesList } from '../../infra/requests/ServicesRequests';
import { commissionOptions } from '../../infra/utils/CommissionsStates';
import SelectInput from '../../components/generic/inputs/SelectInput';
import RadioButtons from '../../components/generic/inputs/RadioButtons';
import CurrencyInput from '../../components/generic/inputs/CurrencyInput';
import NumberInput from '../../components/generic/inputs/NumberInput';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import BaseButton from '../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  name: 'required',
  code: 'required',
  discount_type: 'required'
});

class ManagePromoCodesPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    discountType: undefined,
    totalUsed: 0,
    newRandomCode: false,
    services: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const services = await GetServicesList();

    if(params.id) {
      const result = await GetCode(params.id);
      dispatch(
        initialize('manage_promo_codes_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        services: services?.data,
        discountType: result?.data?.discount_type,
        totalUsed: result?.data?.totalUsed
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        services: services?.data
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateCode(params.id, payload) : await CreateCode(payload);

      if(success) return history.push('/promo-codes');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/promo-codes');
  };

  checkDiscountType = (value) => {
    this.setState({
      discountType: value
    });
  }

  generateCode = () => {
    const { dispatch, formValues } = this.props;
    let code = '';
    const maxLength = 8, charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    
    for(let i = 0; i < maxLength; i++) {
      code = `${code}${charset.charAt(Math.floor(Math.random() * charset.length))}`;
    }
    
    dispatch(
      initialize('manage_promo_codes_form', { ...formValues, code })
    );

    this.setState({ newRandomCode: true });
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, services, discountType, newRandomCode, totalUsed } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Códigos Promocionais'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: !newRandomCode && pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Row gutter={24}>
                  <Col xs={12}>
                    <Field
                      component={CheckboxInput}
                      name={`active`}
                      label={'Ativo?'}
                      importantLabel={true}
                      inlineCheckbox={true}
                    />
                  </Col>
                  <Col xs={12}>
                    {
                      !isNew &&
                      <PromoCodeTotal>
                        Nº de Utilizações:&nbsp;<span>{totalUsed}</span>
                      </PromoCodeTotal>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={24} style={{marginTop: '10px'}}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={TextInput}
                  name={`name`}
                  type='text'
                  label={'Nome *'}
                  placeholder={'Nome'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 4 }} lg={{ span: 8, offset: 6 }}>
                <Field
                  component={TextInput}
                  name={'code'}
                  type='text'
                  label={'Código *'}
                  placeholder={'Código'}
                />
              </Col>
              <Col xs={24} md={6} lg={4}>
                <BaseButton
                  type='black'
                  icon='reload'
                  text='Gerar código'              
                  style={{marginTop: '34px'}}
                  onClick={() => this.generateCode()}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{marginTop: '10px'}}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={RadioButtons}
                  name={'discount_type'}
                  options={commissionOptions}
                  label={'Tipo de Desconto *'}
                  execAfterChange={this.checkDiscountType}
                />
              </Col>
            </Row>
            {
              discountType === 1 ?
              <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                  <Row gutter={24}>
                    <Col xs={12}>
                      <Field
                        component={CurrencyInput}
                        name={'discount_chf'}
                        label={'Desconto (CHF)'}
                        suffix={'CHF'}
                      />
                    </Col>
                    <Col xs={12}>
                      <Field
                        component={CurrencyInput}
                        name={'discount_euro'}
                        label={'Desconto (Euro)'}
                        suffix={'€'}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              discountType === 2 ?
              <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                  <Row gutter={24}>
                    <Col xs={12}>
                      <Field
                        component={NumberInput}
                        name={'discount_perc'}
                        label={'Desconto (%)'}
                        placeholder={'0.00%'}
                        min={0}
                        max={100}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              null
            }
            <Row gutter={24} style={{marginTop: '20px'}}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={SelectInput}
                  name={'service'}
                  label={'Serviço'}
                  placeholder={'Selecionar serviço'}
                  type='select'
                  data={services}
                  dataKey={'_id'}
                  dataLabel={'title'}
                  translatable={true}
                  allowClear
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={SelectInput}
                  name={'service_mode'}
                  label={'Modalidade do Serviço'}
                  placeholder={'Escolha a modalidade'}
                  type='select'
                  data={ServiceModes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={SelectInput}
                  name={'payment_method'}
                  label={'Mét. Pagamento'}
                  placeholder={'Escolha o método de pagamento'}
                  type='select'
                  data={PaymentMethods}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePromoCodesPage = reduxForm({
  form: 'manage_promo_codes_form',
  validate: validations,
})(ManagePromoCodesPage);

const mapStateToProps = (state) => ({
  user: state.user,
	formValues: state?.form?.manage_promo_codes_form?.values
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ManagePromoCodesPage);