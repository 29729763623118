/* eslint-disable react-hooks/exhaustive-deps */
import { PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer, SpinLoading, TabsContainer, Tab, FormContainer } from '../../../styles/BasicStyles';
import { GetCommunication, SendCommunicationEmails } from '../../../infra/requests/CommunicationsRequests';
import { Popconfirm } from 'antd';
import { notification } from 'antd';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import Details from './Details';
import Status from './Status';

const CommunicationsDetailPage = ({ match: { params }, history, user }) => {
  const [loading, setLoading] = useState(true);
  const [communication, setCommunication] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [alert, setAlert] = useState(false);
  const [sendButton, setSendButton] = useState(true);
  const [disabledSendButton, setDisabledSendButton] = useState(false);

  useEffect(() => {
    // NOTE: This area was made for both agents and admins, but only admins have access for now. 
    // If the agents should have access in the future just remove this verification
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 
    
    const init = async () => await getInfo();

    init();
  }, []);
  
  const getInfo = async () => {
    try {  
      setLoading(true);

      const result = await GetCommunication(params.id);

      // If the user is an Agent, he can only access his Communications
      if(user?.type === 2 && result?.data?.user?._id !== user?._id) {
        return history.push('/communications');
      }

      if(history.location.search?.includes('showStatus')) {
        setActiveTab('status');
      }

      if(history?.location?.search?.includes('showAlert')) {
        history.replace(`/communications/${params.id}?showStatus`);

        setAlert(true);
      }
      else {
        setAlert(false);
      }

      checkSendButton(result?.data);
      setCommunication(result?.data);
      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const sendEmails = async () => {
    try {
      setLoading(true);

      const { success } = await SendCommunicationEmails(communication._id);

      if(success) {
        notification.success({ message: 'O envio dos emails foi iniciado!' });

        history.push(`/communications/${communication._id}?showStatus`);

        await getInfo();
      } 
  
      setLoading(false);
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  const checkSendButton = (data) => {
    if(data.isRunning) setDisabledSendButton(true);

    if(data.emails.filter(elem => elem.state !== 2).length > 0) 
      setSendButton(true);
    else
      setSendButton(false);
  } 

  if(loading) return <SpinLoading />;
  
  return (
    <>
      <HeaderContainer style={{marginBottom: '10px'}}>
        <HeaderTitle buttons={2}>
          <PageTitle>Detalhes</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={2}>
          {
            sendButton && 
            <Popconfirm
              placement='topRight'
              title={'Pretende iniciar o envio dos emails?'}
              onConfirm={sendEmails}
            >
              <BaseButton
                text={'Enviar email(s)'}
                disabled={disabledSendButton}
              />
            </Popconfirm>
          }
          <BaseButton
            type={'secondary'}
            text={'Cancelar'}
            onClick={() => history.push('/communications')}
          />
        </HeaderButtonsContainer>
      </HeaderContainer>
      <FormContainer>
        <TabsContainer defaultActiveKey={activeTab}>
          <Tab tab='Detalhes' key='details'>
            <Details communication={communication} />
          </Tab>
          <Tab tab='Estado' key='status'>
            <Status communicationId={communication?._id} alert={alert} checkSendButton={checkSendButton} />
          </Tab>
        </TabsContainer>
      </FormContainer>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  formValues: state?.form?.create_communication_form?.values
});

export default connect(mapStateToProps)(CommunicationsDetailPage);
