/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle } from '../../styles';
import { GetProcessLogs, DeleteLog, ExportLogs } from '../../../../infra/requests/ProcessRequests';
import { getLogSection } from '../../../../infra/utils/LogSections';
import { notification, Popconfirm } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import LogsFilters from './LogsFilters';
import NewLogModal from '../../modals/NewLog';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';

const Logs = ({ processInfo, reloadTab, users, history, user }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(`{"process":"${processInfo._id}"}`);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [newModal, setNewModal] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [columns] = useState([
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '15%'
    },
    {
      title: 'Utilizador',
      key: 'user',
      render: (data) => data.userInfo?.length > 0 && data.userInfo[0]._id ? data.userInfo[0].name : data.callback ? 'Stripe MB Callback' : data.cronjob ? 'CronJob' : 'Cliente',
      width: '20%'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }}/>,
      width: '35%'
    },
    {
      title: 'Secção',
      dataIndex: 'section',
      render: (value) => getLogSection(value)?.name,
      width: '20%'
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (data) => renderActions(data)
    }
  ]);

  // Remove "?showLogs" from the URL, when loading the page
  useEffect(() => {
    if(history.location.search?.includes('showLogs')) {
      history.replace(`/process/${processInfo._id}`, `/process/${processInfo._id}`);
    }
  }, [history]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize, reloadTab]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const result = await GetProcessLogs(currentPage, pageSize, filters);

      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderActions = (data) => {
    if(data.automatic || processInfo.archived || user?.type === 3) return null;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            popConfirmtitle: 'Tem a certeza que quer remover este log?',
            onClick: () => removeLog(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  }

  const removeLog = async (data) => {
    try {
      setLoading(true);

      await DeleteLog(data._id);
      await getInfo();

      notification.success({
        message: 'Log removido com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const exportLogs = async () => {
    try {
      setLoadingExport(true);

      const result = await ExportLogs({ filters });

      if(result) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'logs.xlsx');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }

      setLoadingExport(false);
    }
    catch(error) {
      console.log(error);
      setLoadingExport(false);
    }
  }

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>Logs</TabTitle>
        <TabButtonsWrapper>
          <Popconfirm
            placement='topRight'
            title={'Tem a certeza que pretende exportar os resultados atuais?'}
            onConfirm={() => exportLogs()}
          >
            <BaseButton
              type={'secondary'}
              text={'Exportar'}
              loading={loadingExport}
              disabled={loadingExport}
            />
          </Popconfirm>
          {
            !processInfo.archived && user?.type !== 3 &&
            <BaseButton
              type={'primary'}
              text={'Criar log'}
              disabled={loadingExport}
              onClick={() => setNewModal(true)}
            />
          }
        </TabButtonsWrapper>
      </TabTitleWrapper>
      <LogsFilters
        queryChange={handleFilterChange}
        users={users}
        processID={processInfo._id}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Logs inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {
        !processInfo.archived &&
        <NewLogModal
          openModal={newModal}
          closeModal={() => setNewModal(false)}
          getInfo={getInfo}
          processID={processInfo._id}
        />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Logs));
