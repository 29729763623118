import PropTypes from "prop-types";
import React from "react";
import { InputDiv, InputLabelDiv, InputNumberField } from "./InputStyles";

const NumberInput = ({
  input,
  meta,
  size,
  label,
  first,
  max,
  min,
  step,
  placeholder,
  disabled,
  execAfterChange
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const changeValue = (value) => {
    input.onChange(value || "");

    setTimeout(function() {
      if (typeof execAfterChange === "function") {
        execAfterChange(value);
      }
    }, 200)
  };

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <InputNumberField
        disabled={disabled}
        size={size}
        max={max}
        type="number"
        min={min}
        step={step}
        placeholder={placeholder}
        value={input.value}
        onChange={changeValue}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default NumberInput;
