import { OverflowWrapper, Table, THead, THeadRow, THeadCol, TBody, TBodyRow, TBodyCol, TFooter, TFooterRow, TFooterCol, Wrapper, NoRecords } from '../clients/styles';
import React from 'react';

const ClientDetail = ({ filterByYear = false, data, minYear, maxYear }) => {
  const renderYearColumns = () => {
    const years = [];

    if(!filterByYear) years.push(<THeadCol key={0}>Desde sempre</THeadCol>);

    for(let i = maxYear; i >= minYear; i--) {
      years.push(<THeadCol key={i}>{i}</THeadCol>);
    }

    return years;
  };

  const renderYearValues = (state) => {
    const years = [];
    let totalAmountChf = 0, totalAmountEur = 0;

    for(let i = maxYear; i >= minYear; i--) {
      const item = state.years.find(elem => elem.year === i);
      let amount = '';

      if(!!item) {
        const amountChf = Math.round(item.amountChf * 100) / 100;
        const amountEur = Math.round(item.amountEur * 100) / 100;

        if(amountChf > 0 && amountEur > 0) {
          amount = `${amountChf}CHF + ${amountEur}€`;

          totalAmountChf += amountChf;
          totalAmountEur += amountEur;
        }
        else if(amountChf > 0) {
          amount = `${amountChf}CHF`;

          totalAmountChf += amountChf;
        }
        else if(amountEur > 0) {
          amount = `${amountEur}€`;

          totalAmountEur += amountEur;
        }
      }

      years.push(<TBodyCol key={`${state.stateId}_${i}`}>{amount}</TBodyCol>);
    }

    // Total (all years) for this Service
    if(!filterByYear) {
      totalAmountChf = Math.round(totalAmountChf * 100) / 100; 
      totalAmountEur = Math.round(totalAmountEur * 100) / 100; 

      let totalServiceAmount = '';
      if(totalAmountChf > 0 && totalAmountEur > 0) totalServiceAmount = `${totalAmountChf}CHF + ${totalAmountEur}€`;
      else if(totalAmountChf > 0) totalServiceAmount = `${totalAmountChf}CHF`;
      else if(totalAmountEur > 0) totalServiceAmount = `${totalAmountEur}€`;

      years.unshift(<TBodyCol key={`${state.stateId}_0`}>{totalServiceAmount}</TBodyCol>);
    }

    return years;
  };

  const renderYearTotals = () => {
    const years = [];
    let totalAmountChf = 0, totalAmountEur = 0;

    for(let i = maxYear; i >= minYear; i--) {
      const item = data.years.find(elem => elem.year === i);
      let amount = '';

      if(!!item) {
        const amountChf = Math.round(item.amountChf * 100) / 100;
        const amountEur = Math.round(item.amountEur * 100) / 100;

        if(amountChf > 0 && amountEur > 0) {
          amount = `${amountChf}CHF + ${amountEur}€`;

          totalAmountChf += amountChf;
          totalAmountEur += amountEur;
        }
        else if(amountChf > 0) {
          amount = `${amountChf}CHF`;

          totalAmountChf += amountChf;
        }
        else if(amountEur > 0) {
          amount = `${amountEur}€`;

          totalAmountEur += amountEur;
        }
      }

      years.push(<TFooterCol key={`${i}_total`}>{amount}</TFooterCol>);
    }

    // Years total
    if(!filterByYear) {
      totalAmountChf = Math.round(totalAmountChf * 100) / 100; 
      totalAmountEur = Math.round(totalAmountEur * 100) / 100; 

      let totalAmount = '';
      if(totalAmountChf > 0 && totalAmountEur > 0) totalAmount = `${totalAmountChf}CHF + ${totalAmountEur}€`;
      else if(totalAmountChf > 0) totalAmount = `${totalAmountChf}CHF`;
      else if(totalAmountEur > 0) totalAmount = `${totalAmountEur}€`;

      years.unshift(<TFooterCol key={'0_total'}>{totalAmount}</TFooterCol>);
    }

    return years;
  };

  return (
    <>
      {
        data?.states?.length > 0 ?
        <Wrapper>
          <OverflowWrapper>
            <Table>
              <THead>
                <THeadRow>
                  <THeadCol>Estado de Pagamento</THeadCol>
                  {renderYearColumns()}
                </THeadRow>
              </THead>
              <TBody>
                {
                  data.states.map((state, index) => 
                    <TBodyRow key={index}>
                      <TBodyCol>{state.stateName}</TBodyCol>
                      {renderYearValues(state)}
                    </TBodyRow>
                  )
                }
              </TBody>
              <TFooter>
                <TFooterRow>
                  <TFooterCol />
                  {renderYearTotals()}
                </TFooterRow>
              </TFooter>
            </Table>
          </OverflowWrapper>
        </Wrapper>
        :
        <NoRecords>
          Não existem estatísticas a apresentar!
        </NoRecords>
      }
    </>
  );
}

export default ClientDetail;
