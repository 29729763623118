import { PageContainer, PageTitle, HeaderContainer, HeaderTitle } from '../../../styles/BasicStyles';
import { GetSCommissionStats } from '../../../infra/requests/StatisticsRequests';
import { GetAdminsPartnersList } from '../../../infra/requests/UsersRequests';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { DescriptionWrapper } from '../clients/styles';
import React, { Component } from 'react';
import CommissionStatisticsFilters from './Filters';
import Table from '../../../components/generic/table/Table';
import ClientDetail from './ClientDetail';
import TotalDetail from './TotalDetail';

class CommissionStatisticsPage extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (value) => value,
        width: '30%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (value) => value,
        width: '30%'
      },
      {
        title: 'Valor total',
        key: 'totalValue',
        render: (data) => this.renderTotalValue(data),
        width: '30%',
      },
      {
        title: '',
        key: 'expand',
        render: (data) => this.renderExpandIcon(data)
      }
    ],
    rows: [],
    total: 0,
    expanded: [],
    users: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  renderTotalValue = (data) => {
    const amountChf = Math.round(data.totalValueChf * 100) / 100;
    const amountEur = Math.round(data.totalValueEur * 100) / 100;

    if(amountChf > 0 && amountEur > 0) return `${amountChf}CHF + ${amountEur}€`;
    else if(amountChf > 0) return `${amountChf}CHF`;
    else if(amountEur > 0) return `${amountEur}€`;
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { filters } = this.state;
      const { user, history } = this.props;

      if(!user.super_admin) return history.push('/');

      const result = await GetSCommissionStats(filters);
      const users = await GetAdminsPartnersList();

      this.setState({
        rows: result.data || [],
        total: result.data?.length || 0,
        users: users?.data || [],
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  onExpandRow = (_, record) => {
    const { expanded } = this.state;

    const index = expanded.findIndex(elem => elem._id === record._id);

    const aux = [...expanded];

    // If the current record already exists in the array, we have to invert the "expanded" status of that element
    if(index >= 0) {
      aux[index].expanded = !aux[index].expanded;
    }
    // If not, add to the array and mark it as "expanded"
    else {
      aux.push({ _id: record._id, expanded: true });
    }

    this.setState({ expanded: aux });
  };

  renderExpandIcon = (data) => {
    const { expanded } = this.state;

    const elem = expanded.find(elem => elem._id === data._id);

    return <Icon type={elem?.expanded ? 'up' : 'down'} />;
  };

  render() {
    const { columns, rows, total, loading, users, filters } = this.state;

    let hasUser = false, hasYear = false;
    if(filters) {
      const parsedJson = JSON.parse(filters);
      if(parsedJson.year) hasYear = true;
      if(parsedJson.user) hasUser = true;
    }

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Estatísticas - Comissões</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DescriptionWrapper>
            <div>Nesta área pode visualizar um conjunto de resultados sobre as comissões.</div>
            <div>- Total de valores pagos por ano (global e por utilizador);</div>
            <div>- Total de valores a aguardar pagamento por ano (global e por utilizador);</div>
            <div>- Total de valores cancelados por ano (global e por utilizador);</div>
            <div>- Total de valores contenciosos por ano (global e por utilizador);</div>
            <div>- Total global;</div>
          </DescriptionWrapper>
          <CommissionStatisticsFilters 
            queryChange={this.handleFilterChange} 
            users={users}
          />
          {
            !hasUser &&
            <TotalDetail 
              data={rows?.all} 
              minYear={rows?.minYear} 
              maxYear={rows?.maxYear} 
              filterByYear={hasYear} 
            />
          }
          <Table
            columns={columns}
            loading={loading}
            rows={rows?.users || []}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem registos a apresentar!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            expandedRowRender={(data) => <ClientDetail data={data} minYear={rows?.minYear} maxYear={rows?.maxYear} filterByYear={hasYear} />}
            expandRowByClick={true}
            expandIconAsCell={false}
            onExpand={(expanded, record) => this.onExpandRow(expanded, record)}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(CommissionStatisticsPage);
