import client from '../config/AxiosConfig';

export const GetClients = async (currentPage, limit, filters) => await client.get(`/clients/${currentPage}/${limit}?filter=${filters}`);

export const GetClient = async (id) => await client.get(`/clients/${id}`);

export const CreateClient = async (data) => await client.post(`/clients`, data);

export const UpdateClient = async (id, data) => await client.put(`/clients/${id}`, data);

export const DeleteClient = async (id) => await client.delete(`/clients/${id}`);

export const GetClientsList = async () => await client.get(`/clients`);

export const ToggleClientState = async (id, data) => await client.put(`/clients/${id}/toggle`, data);