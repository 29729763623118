import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetMetatags } from '../../infra/requests/BaseRequests';
import { connect } from 'react-redux';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class Metatags extends Component {
  state = {
    loading: false,
    columns: [
      {
        title: 'Metatags',
        dataIndex: 'name',
        width: '90%'
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/metatags/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetMetatags();
      this.setState({
        rows: result.data,
        loading: false,
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/metatags/${data._id}`),
            label: 'Editar' 
          }
        ]} 
      />
    );
  };

  render() {
    const { columns, rows, total, loading } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle>
            <PageTitle noMarginBottom={true}>Lista de Metatags</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon='copy'
            emptyText={'No Metatags'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Metatags);