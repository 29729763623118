/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { Field, initialize } from 'redux-form';
import { GetServiceByID } from '../../../infra/requests/ServicesRequests';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { SectionTitle, PriceSpinLoading } from './styles';
import { CardWrapper, Card, CardContainer } from '../styles';
import { Languages } from '../../../infra/utils/Languages';
import { ProcessStates } from '../../../infra/utils/ProcessStates';
import { ServiceModes } from '../../../infra/utils/ServiceModes';
import { PaymentMethods } from '../../../infra/utils/PaymentMethods';
import { Currencies, getCurrency } from '../../../infra/utils/Currencies';
import { Separator } from '../../../styles/BasicStyles';
import { sortServices } from '../../../infra/utils/Sort';
import React, { useEffect, useState } from 'react';
import DateInput from '../../../components/generic/inputs/DateInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import CurrencyInput from '../../../components/generic/inputs/CurrencyInput';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';

const ProcessDetails = ({ dispatch, user, formValues, setConfirmPaymentMethod, services, responsibles, setService }) => {
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [currentServiceMode, setCurrentServiceMode] = useState(1);
  const [currentCurrencyID, setCurrentCurrencyID] = useState(2);
  const [currentCurrency, setCurrentCurrency] = useState('€');
  const [enablePriceField, setEnablePriceField] = useState(true);
  const [showPrice, setShowPrice] = useState(false);

  const states = user?.type === 2 ? ProcessStates.filter((elem) => elem.showAgent) : ProcessStates;

  // Get the Service price for a certain currency and service mode
  useEffect(() => {
    const getServicePrice = async () => {
      try {
        if(currentCurrencyID && currentService && currentServiceMode) {
          setLoadingPrice(true);

          const service = await GetServiceByID(currentService);

          if(service?.data) {
            setService(service?.data);
            
            if(!service?.data?.free) {
              let servicePrice = 0;

              //CFH
              if(parseInt(currentCurrencyID) === 1) {
                if(parseInt(currentServiceMode) === 1)
                  servicePrice = service?.data?.price_online_chf;
                if(parseInt(currentServiceMode) === 2)
                  servicePrice = service?.data?.price_postal_chf;
                if(parseInt(currentServiceMode) === 3)
                  servicePrice = service?.data?.price_presential_chf;
              }
              //Euro
              else if(parseInt(currentCurrencyID) === 2) {
                if(parseInt(currentServiceMode) === 1)
                  servicePrice = service?.data?.price_online_euro;
                if(parseInt(currentServiceMode) === 2)
                  servicePrice = service?.data?.price_postal_euro;
                if(parseInt(currentServiceMode) === 3)
                  servicePrice = service?.data?.price_presential_euro;
              }

              dispatch(initialize('create_process_form', { ...formValues, price: servicePrice }));
              setShowPrice(true);
            }
            else {
              setShowPrice(false);
            }
          }

          setLoadingPrice(false);
        }
      }
      catch(error) {
        console.log(error);
        setLoadingPrice(false);
      }
    }

    getServicePrice();
  }, [currentCurrencyID, currentService, currentServiceMode]);

  const checkCurrency = (value) => {
    setEnablePriceField(parseInt(value) === 1 || parseInt(value) === 2 ? true : false);
    setCurrentCurrency(getCurrency(parseInt(value)));
    setCurrentCurrencyID(parseInt(value));
  };

  return (
    <>
      <SectionTitle>Detalhes do Processo</SectionTitle>
      <CardWrapper gutter={24}>
        <Card xxl={18} xl={20} lg={24}>
          <CardContainer>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'language'}
                  label={'Idioma *'}
                  placeholder={'Escolha o idioma'}
                  type='select'
                  data={Languages}
                  dataKey={'value'}
                  dataLabel={'name'}
                  noteText='Os emails serão enviados com o idioma selecionado.'
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={DateInput}
                  name='purchase_date'
                  label={'Data Aquisição *'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'state'}
                  label={'Estado do Processo *'}
                  placeholder={'Escolha o estado'}
                  type='select'
                  data={states}
                  dataKey={'_id'}
                  dataLabel={'name'}
                />
              </Col>
              {
                user?.type === 1 &&
                <Col md={12} sm={24}>
                  <Field
                    component={SelectInput}
                    name={'responsible'}
                    label={'Responsável'}
                    placeholder={'Selecionar responsável'}
                    type='select'
                    data={responsibles}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    allowClear={true}
                  />
                </Col>
              }
            </Row>
            {
              (user?.type === 1 || user?.type === 3) &&
              <>
                <Row gutter={24}>
                  <Col md={12} sm={24}>
                    <Field
                      component={SelectInput}
                      name={'service'}
                      label={'Serviço Adquirido *'}
                      placeholder={'Selecionar serviço'}
                      type='select'
                      data={sortServices(services)}
                      dataKey={'_id'}
                      dataLabel={'title'}
                      translatable={true}
                      execAfterChange={(value) => setCurrentService(value)}
                    />
                  </Col>
                  <Col md={12} sm={24}>
                    <Field
                      component={SelectInput}
                      name={'service_mode'}
                      label={'Modalidade do Serviço *'}
                      placeholder={'Escolha a modalidade'}
                      type='select'
                      data={ServiceModes}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      execAfterChange={(value) => setCurrentServiceMode(value)}
                    />
                  </Col>
                </Row>
                {
                  showPrice &&
                  <>
                    <Row gutter={24}>
                      <Col md={12} sm={24}>
                        {/* If payment method = 'TRANSFERÊNCIA BANCÁRIA' open a modal to confirm if we should send the email or just save the info. */}
                        <Field
                          component={SelectInput}
                          name={'payment_method'}
                          label={'Método de Pagamento *'}
                          placeholder={'Escolha o método de pagamento'}
                          type='select'
                          data={PaymentMethods}
                          dataKey={'_id'}
                          dataLabel={'name'}
                          execAfterChange={(value) => setConfirmPaymentMethod(parseInt(value) === 2 ? true : false)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col md={12} sm={24}>
                        <Field
                          component={SelectInput}
                          name={'currency'}
                          label={'Moeda *'}
                          placeholder={'Escolha a moeda de pagamento'}
                          type='select'
                          data={Currencies}
                          dataKey={'_id'}
                          dataLabel={'name'}
                          execAfterChange={checkCurrency}
                        />
                      </Col>
                      {
                        user?.type === 1 &&
                        <Col md={12} sm={24}>
                          {
                            loadingPrice ? 
                            <PriceSpinLoading />
                            :
                            <Field
                              component={CurrencyInput}
                              name={'price'}
                              label={'Total *'}
                              suffix={currentCurrency || 'CHF'}
                              disabled={!enablePriceField}
                            />
                          }
                        </Col>
                      }
                    </Row>
                  </>
                }
              </>
            }
          </CardContainer>
          <Separator />
          <CardContainer>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextAreaInput}
                  name={`internal_notes`}
                  label={"Notas Internas"}
                />
              </Col>
            </Row>
          </CardContainer>
        </Card>
      </CardWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  formValues: state?.form?.create_process_form?.values
});

export default withLocalize(connect(mapStateToProps)(ProcessDetails));
