import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import { GetEstateAgentsList } from '../../infra/requests/UsersRequests';
import {
  GetEstates,
  DeleteEstate,
  ToggleEstate,
  ToggleFeaturedEstate,
  GetTypesDropdown,
  GetCountriesList
} from '../../infra/requests/EstateRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import EstateFilters from './EstateFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { connect } from 'react-redux';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import moment from 'moment';
import { StateLabel } from '../process/styles';


class EstatePage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [],
    rows: [],
    total: 0,
    types: [],
    countries: [],
    agents: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    let columns = [];
    if(user?.type === 4) {
      columns = [
        {
          title: 'Data',
          dataIndex: 'date',
          render: (value) => moment(value).format('DD/MM/YYYY'),
          width: '12%',
        },
        {
          title: 'Título',
          dataIndex: 'title',
          render: (value) => TranslateValue(value),
          width: '22%',
        },
        {
          title: 'Tipologia',
          dataIndex: 'type',
          render: (value) => value?.title?.pt,
          width: '18%',
        },
        {
          title: 'Localização',
          key: 'location',
          render: (data) => this.renderLocation(data),
          width: '15%',
        },
        {
          title: 'Validado',
          dataIndex: 'validated',
          render: (value) => this.renderValidatedState(value),
          width: '15%',
        },
        {
          title: 'Ativo',
          dataIndex: 'active',
          render: (value) => this.renderState(value),
          width: '10%',
        },
        {
          title: 'Ações',
          render: (data) => this.renderActions(data)
        }
      ];
    }
    else {
      columns = [
        {
          title: 'Data',
          dataIndex: 'date',
          render: (value) => moment(value).format('DD/MM/YYYY'),
          width: '8%',
        },
        {
          title: 'Agente Imobiliário',
          dataIndex: 'agent',
          render: (value) => value?.name || '-',
          width: '12%',
        },
        {
          title: 'Título',
          dataIndex: 'title',
          render: (value) => TranslateValue(value),
          width: '20%',
        },
        {
          title: 'Tipologia',
          dataIndex: 'type',
          render: (value) => value?.title?.pt,
          width: '12%',
        },
        {
          title: 'Localização',
          key: 'location',
          render: (data) => this.renderLocation(data),
          width: '12%',
        },
        {
          title: 'Estado',
          dataIndex: 'validated',
          render: (value) => this.renderValidatedState(value),
          width: '14%',
        },
        {
          title: 'Ativo',
          dataIndex: 'active',
          render: (value) => this.renderState(value),
          width: '8%',
        },
        {
          title: 'Destacado',
          dataIndex: 'featured',
          render: (value) => this.renderState(value),
          width: '8%',
        },
        {
          title: 'Ações',
          render: (data) => this.renderActions(data)
        }
      ];
    }

    this.setState({ columns });
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/estate/list/${record.identifier}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history, user } = this.props;

    const options = [
      {
        onClick: () => history.push(`/estate/list/${data.identifier}`),
        label: 'Editar'
      },
      {
        popConfirmtitle: 'Tem a certeza que quer remover o registo?',
        onClick: () => this.removeEstate(data),
        label: 'Remover'
      }
    ];

    if(user?.type === 1) {
      options.splice(1, 0, {
        popConfirmtitle: data.featured ? 'Quer remover o destaque neste registo?' : 'Quer destacar este registo?',
        onClick: () => this.toggleFeatured(data),
        label: data.featured ? ' Não destacar' : 'Destacar'
      });

      options.splice(2, 0, {
        popConfirmtitle: data.active ? 'Quer desativar este registo?' : 'Quer publicar este registo?',
        onClick: () => this.toggleActive(data),
        label: data.active ? ' Desativar' : 'Publicar'
      });
    }

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={options} 
      />
    );
  };

  renderLocation = (data) => {
    let location = '';
    if(data.city?.name) location = `${data.city?.name}, `;
    if(data.country?.name?.pt) location = `${location}${data.country?.name?.pt}`;
    return location;
  };

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Sim
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Não
      </StateLabel>
    );
  };

  renderValidatedState = (value) => {
    if(value === 'VALIDATED') {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Validado
        </StateLabel>
      );
    }
    if(value === 'PENDING') {
      return (
        <StateLabel
          bgColor='#FFA000'
          fontColor='#ffffff'
        >
          À espera de validação
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Rejeitado
      </StateLabel>
    );
  };

  removeEstate = async (data) => {
    try {
      await DeleteEstate(data.identifier);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const { user } = this.props;

      const result = await GetEstates(currentPage, pageSize, filters);
      const types = await GetTypesDropdown();
      const countries = await GetCountriesList();
      const agents = user?.type === 1 ? await GetEstateAgentsList() : [];

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        types: types?.data || [],
        countries: countries?.data || [],
        agents: agents?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await ToggleEstate(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  toggleFeatured = async (record) => {
    this.setState({ loading: true });
    await ToggleFeaturedEstate(record._id, { featured: !record.featured });
    await this.updateDataTable();
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      types,
      countries,
      agents
    } = this.state;
    const { history } = this.props;
    
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Imobiliário</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/estate/list/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <EstateFilters 
            queryChange={this.handleFilterChange} 
            types={types} 
            countries={countries} 
            agents={agents}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem registos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(EstatePage);