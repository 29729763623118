import styled from "styled-components";

export const BalanceTitle = styled.div` 
  color: #495057;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding-bottom: 10px;
`;

export const BalanceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
`;

export const BalanceItemTitle = styled.div`
  font-size: ${({ mainTitle }) => mainTitle ? '16px' : '14px'};
  color ${({ color }) => color || 'rgba(0, 0, 0, 0.65)'};
`;

export const BalanceItemValue = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BalanceItemTotal = styled.div`
  font-weight: 700;
  font-size: ${({ mainTitle }) => mainTitle ? '16px' : '14px'};
  color ${({ color }) => color || 'rgba(0, 0, 0, 0.65)'};
`;


export const BalanceItemAmount = styled.div`
  padding-left: 5px;
  font-weight: 700;
  font-size: ${({ mainTitle }) => mainTitle ? '15px' : '13px'};
  color ${({ color }) => color || 'rgba(0, 0, 0, 0.65)'};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const LastSent = styled.div`
  margin-top: 10px;

  & span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
  }
`;