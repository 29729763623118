import styled from 'styled-components';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

export const NewClientText = styled.div`
  font-size: 15px;
  line-height: 25px;

  & span {
    font-weight: bold;
  }
`;

export const UserDeleted = styled.div`
  font-size: 15px;
  line-height: 25px;

  & span {
    font-weight: bold;
  }
`;

export const UserRef = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.primaryColor};
  margin-bottom: 20px;

  & span {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const UserDate = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: ${(p) => p.theme.primaryColor};
  margin-bottom: 20px;

  & span {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const TabsContainer = styled(Tabs)`
  margin-top: 30px;

  & .ant-tabs-nav-scroll {
    padding: 0px 20px;
  }

  & .ant-tabs-nav-container {
    font-size: 16px;
  }
`;

export const Tab = styled(TabPane)`

`;

export const AutomaticEmailDate = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  margin: 15px 0px;

  & span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
  }
`;