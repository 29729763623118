import client from "../config/AxiosConfig";

export const GetUser = async id => await client.get(`/users/${id}`);

export const GetUsers = async (currentPage, limit, filters) => await client.get(`/users/${currentPage}/${limit}?filter=${filters}`);

export const UpdateUser = async (id, data) => await client.put(`/users/${id}`, data);

export const CreateUser = async data => await client.post(`/users`, data);

export const DeleteUser = async id => await client.delete(`/users/${id}`);

export const GetAdminList = async () => await client.get(`/users/admins`);

export const GetAgentsList = async () => await client.get(`/users/agents`);

export const GetPartnersList = async () => await client.get(`/users/partners`);

export const GetEstateAgentsList = async () => await client.get(`/users/estate_agents`);

export const GetProcessUsersList = async (process) => await client.get(`/users/process/${process}`);

export const GetAllUsersList = async () => await client.get(`/users`);

export const GetUserAdminsList = async () => await client.get(`/users/current_user/admins`);

export const GetAdminsPartnersList = async () => await client.get(`/users/admins_partners`);

export const ToggleUserState = async (id, data) => await client.put(`/users/${id}/toggle`, data);

export const UpdateProfile = async (id, data) => await client.put(`/users/profile/${id}`, data);

export const GetProfile = async () => await client.get('/users/my-profile');