import React, { Component } from "react";
import Table from "../../components/generic/table/Table";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from "../../styles/BasicStyles";
import {
  GetUsers,
  DeleteUser,
  ToggleUserState,
} from "../../infra/requests/UsersRequests";
import BaseButton from "../../components/generic/buttons/BaseButton";
import UsersFilters from "./UsersFilters";
import { getUserType } from "../../infra/utils/UserTypes";
import { connect } from "react-redux";
import ButtonActions from "../../components/generic/buttons/ButtonActions";
import { StateLabel } from "../process/styles";

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: "",
    loading: false,
    columns: [],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if (user.type === 2 || user.type === 3) {
      return history.push("/");
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    const options = [
      {
        onClick: () => history.push(`/users/${data._id}`),
        label: "Editar",
      },
      {
        popConfirmtitle: "Tem a certeza que quer remover este Utilizador?",
        onClick: () => this.removeUser(data),
        label: "Remover",
      },
    ];

    if (!data.super_admin) {
      options.splice(1, 0, {
        popConfirmtitle: data.active
          ? "Quer desativar este Utilizador?"
          : "Quer ativar este Utilizador?",
        onClick: () => this.toggleUser(data),
        label: data.active ? " Desativar" : " Ativar",
      });
    }

    return <ButtonActions icon={"dots"} iconType={"fill"} options={options} />;
  };

  renderCommissions = (data) => {
    let serviceAmount = "",
      paymentsAmount = "";
    if (data.commission_service_type === 1) {
      if (data.commission_service_chf > 0 && data.commission_service_eur > 0)
        serviceAmount = ` ${data.commission_service_chf}CHF / ${data.commission_service_eur}€`;
      else if (data.commission_service_chf > 0)
        serviceAmount = ` ${data.commission_service_chf}CHF`;
      else if (data.commission_service_eur > 0)
        serviceAmount = ` ${data.commission_service_eur}€`;
      else serviceAmount = "-";
    }

    if (data.commission_payments_type === 1) {
      if (data.commission_payments_chf > 0 && data.commission_payments_eur > 0)
        paymentsAmount = ` ${data.commission_payments_chf}CHF / ${data.commission_payments_eur}€`;
      else if (data.commission_payments_chf > 0)
        paymentsAmount = ` ${data.commission_payments_chf}CHF`;
      else if (data.commission_payments_eur > 0)
        paymentsAmount = ` ${data.commission_payments_eur}€`;
      else paymentsAmount = "-";
    }

    return (
      <>
        {!!data.commission_service_type && (
          <div>
            Serviço:
            <span style={{ fontWeight: 700 }}>
              {data.commission_service_type === 2
                ? ` ${data.commission_service_perc || 0}%`
                : serviceAmount}
            </span>
          </div>
        )}
        {!!data.commission_payments_type && (
          <div>
            Pagamentos:
            <span style={{ fontWeight: 700 }}>
              {data.commission_payments_type === 2
                ? ` ${data.commission_payments_perc || 0}%`
                : paymentsAmount}
            </span>
          </div>
        )}
        {!!data.commission_lemania_type && (
          <div>
            Lemania:
            <span style={{ fontWeight: 700 }}>
              {` ${data.commission_lemania || 0}${
                data.commission_lemania_type === 1 ? "CHF" : "%"
              }`}
            </span>
          </div>
        )}
      </>
    );
  };

  renderState = (value) => {
    if (value) {
      return (
        <StateLabel bgColor="#389e0d" fontColor="#ffffff">
          Ativo
        </StateLabel>
      );
    }
    return (
      <StateLabel bgColor="#cf1322" fontColor="#ffffff">
        Inativo
      </StateLabel>
    );
  };

  removeUser = async (data) => {
    try {
      this.setState({ loading: true });
      await DeleteUser(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  toggleUser = async (record) => {
    try {
      this.setState({ loading: true });
      await ToggleUserState(record._id, { active: !record.active });
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { user } = this.props;
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetUsers(currentPage, pageSize, filters);

      let columns = [
        {
          title: "Tipo",
          dataIndex: "type",
          render: (value) => getUserType(value),
          width: "18%",
        },
        {
          title: "Nome",
          dataIndex: "name",
          width: "20%",
        },
        {
          title: "Email",
          dataIndex: "email",
          width: "20%",
        },
        {
          title: "Contacto",
          dataIndex: "contact",
          width: "15%",
        },
        {
          title: "Estado",
          dataIndex: "active",
          render: (value) => this.renderState(value),
          width: "15%",
        },
        {
          title: "Ações",
          render: (data) => this.renderActions(data),
        },
      ];

      if (user.type === 1 && user.super_admin) {
        columns = [
          {
            title: "Tipo",
            dataIndex: "type",
            render: (value) => getUserType(value),
            width: "15%",
          },
          {
            title: "Nome",
            dataIndex: "name",
            width: "18%",
          },
          {
            title: "Email",
            dataIndex: "email",
            width: "18%",
          },
          {
            title: "Contacto",
            dataIndex: "contact",
            width: "13%",
          },
          {
            title: "Comissões",
            key: "commissions",
            render: (data) => this.renderCommissions(data),
            width: "17%",
          },
          {
            title: "Estado",
            dataIndex: "active",
            render: (value) => this.renderState(value),
            width: "10%",
          },
          {
            title: "Ações",
            render: (data) => this.renderActions(data),
          },
        ];
      }

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        columns,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle noMarginBottom={true}>Lista de Utilizadores</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              customIcon={"plus"}
              text={"Criar"}
              onClick={() => history.push("/users/add")}
            />
            <BaseButton
              type="secondary"
              icon={"sync"}
              text={"Atualizar"}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="warning"
            emptyText={"Não existem Utilizadores inseridos!"}
            total={total}
            rowKey={"_id"}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UsersPage);
