import styled from 'styled-components';

export const TreeWrapper = styled.div`
  & .ant-tree-node-content-wrapper {
    vertical-align: middle;

    & .ant-tree-title {
      line-height: 24px;
    }
  }

  & .ant-tree-child-tree .ant-tree-title {
    font-size: 13px;
  }

  & .ant-tree-child-tree .ant-tree-title span {
    color: rgba(255, 0, 0, 0.75);
  }
`;

export const NoDocuments = styled.div`
  text-align: center;
  margin: 15px 0;
`;

export const Note = styled.div`
  padding-top: 30px;

  & span {
    color: rgba(255, 0, 0, 0.75);
    font-weight: 700;
  }
`;
