import { Popconfirm } from 'antd';
import { DropdownMenu, DropdownButton, NormalButton, OptionWrapper, OptionButton } from './ButtonsStyles';
import { ReactSVG } from 'react-svg';
import React from 'react';

export const ButtonActions = ({ icon, options, iconType }) => {
  const DropDownOptions = () =>  (
    <DropdownMenu>
      {
        options.map((option, index) =>
          <DropdownMenu.Item key={index} style={{ borderBottom: options.length - 1 === index ? '' : '1px solid #DEE2E6' }}>
            <OptionWrapper onClick={(e) => e.stopPropagation()}>
              {
                option.popConfirmtitle ?
                <Popconfirm
                  placement='topRight'
                  title={option.popConfirmtitle}
                  onConfirm={option.onClick}
                >
                  <OptionButton optionIconType={option.optionIconType}>
                    {
                      option.icon &&
                      <ReactSVG src={`${process.env.REACT_APP_BO_URL}${option.icon}.svg`} className='icon' />
                    }
                    {option.label}
                  </OptionButton>
                </Popconfirm>
                :
                <OptionButton optionIconType={option.optionIconType} onClick={option.onClick}>
                  {
                    option.icon &&
                    <ReactSVG src={`${process.env.REACT_APP_BO_URL}${option.icon}.svg`} className='icon' />
                  }
                  {option.label}
                </OptionButton>
              }
            </OptionWrapper>
          </DropdownMenu.Item>
        )
      }
    </DropdownMenu>
  )

  return(
    <DropdownButton overlay={DropDownOptions} placement="bottomRight" trigger={['click']} onClick={(e) => e.stopPropagation()}>
      <NormalButton icontype={iconType}>
        <ReactSVG src={`${process.env.REACT_APP_BO_URL}${icon}.svg`} />
      </NormalButton>
    </DropdownButton>
  )
};

export default ButtonActions;
