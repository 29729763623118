import { Modal } from 'antd';
import { ModalText, ModalTextTitle, ModalTextLine } from './styles';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const BankTransferAlertModal = ({ openModal, closeModal, onSubmitSave, onSubmitSaveEmail, loading }) => {
  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Criar Processo</ModalTitle>
        <ModalText>
          <ModalTextTitle>Atenção!</ModalTextTitle>
          <ModalTextLine>Está prestes a criar um novo processo com o método de pagamento "Transferência bancária".</ModalTextLine>
          <ModalTextLine>Pretende apenas criar o processo ou também enviar o email para o cliente com os dados para pagamento?</ModalTextLine>
          <ModalTextLine><span>Nota:</span> Deverá preencher o campo "Email" para o mesmo ser enviado.</ModalTextLine>
        </ModalText>
        <ModalButtonWrapper>
          <BaseButton
            type="secondary"
            text="Apenas criar"
            loading={loading}
            onClick={() => onSubmitSave()}
          />
          <BaseButton
            type="primary"
            text="Criar e enviar email"
            loading={loading}
            onClick={() => onSubmitSaveEmail()}
          />
        </ModalButtonWrapper>
      </>
    </Modal>
  );
};

export default BankTransferAlertModal;