import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetHomepageText,
  UpdateHomepageText
} from '../../infra/requests/HomepageRequests';
import DraftInput from '../../components/generic/inputs/DraftInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages'
});

class ManageHomepageBannerPage extends Component {
  state = { 
    loading: true,
    tag: ''
  };

  componentDidMount = async () => {
    const { match: { url }, dispatch, CloseMenu, user, history } = this.props;

    const pageTag = url.split('/')[2].toUpperCase().replace(/-/g, '_') || '';
    if(!pageTag) return history.push('/');

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 

    CloseMenu();

    const result = await GetHomepageText(pageTag);
    if(!result) return history.push('/');

    dispatch(
      initialize('manage_homepage_text_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      tag: pageTag
    });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;
      const { tag } = this.state;

      this.setState({ loading: true });

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { data, success } = await UpdateHomepageText(tag, payload);
      
      if(success) {
        dispatch(
          initialize('manage_homepage_text_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }

      return this.setState({ 
        loading: false 
      });
    } 
    catch (e) {
      console.error(e);
      this.setState({ 
        loading: false 
      });
    }
  };

  renderTitle = () => {
    const { tag } = this.state;

    if(tag === 'ESTATE') return 'Imóveis';
    else if(tag === 'DOCUMENTS') return 'Documents Digitais';
    else if(tag === 'SERVICES') return 'Serviços';
    return '';
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Homepage'
          breadcrumbs={[this.renderTitle(), 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição *'}
                    placeholder={'Descrição'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição *'}
                            placeholder={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageHomepageBannerPage = reduxForm({
  form: 'manage_homepage_text_form',
  validate: validations,
})(ManageHomepageBannerPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageHomepageBannerPage));