export const subjectsList = [
  {
    label: "Aviso falta de pagamento serviço",
    value: 1,
  },
  {
    label: "Segundo Aviso falta de pagamento serviço",
    value: 2,
  },
  {
    label: "Último aviso falta de pagamento antes de poursuites",
    value: 3,
  },
];
