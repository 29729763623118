import { StateLabel } from '../../pages/process/styles';
import React from 'react';

// export const ProcessStates = [
//   { _id: 1, name: 'A aguardar pagamento', bgColor: '#8c8c8c', fontColor: '#ffffff', value: 0, showAgent: false },
//   { _id: 2, name: 'Em processamento', bgColor: '#096dd9', fontColor: '#ffffff', value: 0, showAgent: true },
//   { _id: 3, name: 'A aguardar informação', bgColor: '#d46b08', fontColor: '#ffffff', value: 0, showAgent: true },
//   { _id: 4, name: 'Pendente', bgColor: '#d4b106', fontColor: '#ffffff', value: 0, showAgent: true },
//   { _id: 5, name: 'Concluído', bgColor: '#389e0d', fontColor: '#ffffff', value: 0, showAgent: true },
//   { _id: 6, name: 'Cancelado', bgColor: '#cf1322', fontColor: '#ffffff', value: 0, showAgent: true },
//   { _id: 7, name: 'Contencioso', bgColor: '#000000', fontColor: '#ffffff', value: 0, showAgent: false },
//   { _id: 8, name: 'Stand By', bgColor: '#9C27B0', fontColor: '#ffffff', value: 0, showAgent: true }
// ];

/* PROCESS STATES */

export const ProcessStates = [
  { _id: 15, name: 'Enviar Correio', bgColor: '#b2dfdb', fontColor: '#004d40', value: 0, showAgent: true },
  { _id: 1, name: 'A aguardar procuração', bgColor: '#FEF3C7', fontColor: '#92400E', value: 0, showAgent: true },
  { _id: 2, name: 'Procuração recebida', bgColor: '#DBEAFE', fontColor: '#1E40AF', value: 0, showAgent: true },
  { _id: 3, name: 'A aguardar resposta da instituição', bgColor: '#FFE8CC', fontColor: '#A32E00', value: 0, showAgent: true },
  { _id: 4, name: 'Fundos localizados', bgColor: '#E0E7FF', fontColor: '#3730A3', value: 0, showAgent: true },
  { _id: 5, name: 'Pedido de extratos', bgColor: '#EDE9FE', fontColor: '#5B21B6', value: 0, showAgent: true },
  { _id: 6, name: 'Lemania', bgColor: '#FCE7F3 ', fontColor: '#9D174D', value: 0, showAgent: true },
  { _id: 7, name: 'Pedido de reforma submetido', bgColor: '#FEE2E2', fontColor: '#991B1B', value: 0, showAgent: true },
  { _id: 8, name: 'Mês de regresso a portugal', bgColor: '#F3D9FA', fontColor: '#862E9C', value: 0, showAgent: true },
  { _id: 9, name: 'Resgate de fundos pedido', bgColor: '#C5F6FA', fontColor: '#0B7285', value: 0, showAgent: true },
  { _id: 10, name: 'Concluído', bgColor: '#D1FAE5', fontColor: '#065F46', value: 0, showAgent: true },
  { _id: 11, name: 'Pendente', bgColor: '#f0f4c3', fontColor: '#827717', value: 0, showAgent: true },
  { _id: 14, name: 'A aguardar informação', bgColor: '#fff9c4', fontColor: '#f57f17', value: 0, showAgent: true },
  { _id: 12, name: 'Cancelado', bgColor: '#ffcdd2', fontColor: '#e53935', value: 0, showAgent: true },
  { _id: 13, name: 'A CORRIGIR', bgColor: '#e0e0e0', fontColor: '#212121', value: 0, showAgent: true }
]; 

export const getProcessState = (value) => {
  if(Number.isInteger(value) && value > 0) return ProcessStates.find(elem => elem._id === value);
  return null;
};

export const renderProcessState = (value) => {
  const currentState = getProcessState(value);
  
  if(!currentState) return null;

  return (
    <StateLabel
      bgColor={currentState.bgColor}
      fontColor={currentState.fontColor}
    >
      {currentState.name}
    </StateLabel>
  );
};

/* PAYMENT STATES */

export const PaymentStates = [
  { _id: 1, name: 'A aguardar pagamento - TRF', bgColor: '#bbdefb', fontColor: '#0d47a1', value: 0, allowInPayments: true },
  { _id: 2, name: 'Pago - TRF', bgColor: '#D1FAE5', fontColor: '#065F46', value: 0, allowInPayments: true },
  { _id: 3, name: 'A aguardar pagamento - MB', bgColor: '#bbdefb', fontColor: '#0d47a1', value: 0, allowInPayments: false },
  { _id: 4, name: 'Pago - MB', bgColor: '#D1FAE5', fontColor: '#065F46', value: 0, allowInPayments: false },
  { _id: 5, name: 'A aguardar pagamento - BP', bgColor: '#bbdefb', fontColor: '#0d47a1', value: 0, allowInPayments: true },
  { _id: 6, name: 'Pago - BP', bgColor: '#D1FAE5', fontColor: '#065F46', value: 0, allowInPayments: true },
  { _id: 7, name: 'A aguardar pagamento - CC', bgColor: '#bbdefb', fontColor: '#0d47a1', value: 0, allowInPayments: false },
  { _id: 8, name: 'Pago - CC', bgColor: '#D1FAE5', fontColor: '#065F46', value: 0, allowInPayments: false },
  { _id: 12, name: 'Pago - Serviço Gratuito', bgColor: '#FEF3C7', fontColor: '#CA6901', value: 0, allowInPayments: false },
  { _id: 9, name: 'Contencioso', bgColor: '#bdbdbd', fontColor: '#212121', value: 0, allowInPayments: true },
  { _id: 10, name: 'Cancelado', bgColor: '#ffcdd2', fontColor: '#e53935', value: 0, allowInPayments: true },
  { _id: 11, name: 'A CORRIGIR', bgColor: '#e0e0e0', fontColor: '#212121', value: 0, allowInPayments: false }
];

export const getPaymentState = (value) => {
  if(Number.isInteger(value) && value > 0) return PaymentStates.find(elem => elem._id === value);
  return null;
};

export const renderPaymentState = (value) => {
  const currentState = getPaymentState(value);

  if(!currentState) return null;

  return (
    <StateLabel
      bgColor={currentState.bgColor}
      fontColor={currentState.fontColor}
    >
      {currentState.name}
    </StateLabel>
  );
};

/* PROCESS GLOBAL PAYMENT STATES */

export const ProcessPaymentStates = [
  { _id: 1, name: 'A aguardar pagamento', bgColor: '#bbdefb', fontColor: '#0d47a1', value: 0 },
  { _id: 2, name: 'Pago', bgColor: '#D1FAE5', fontColor: '#065F46', freeBgColor: '#FEF3C7', freeFontColor: '#CA6901', value: 0 },
  { _id: 3, name: 'Contencioso', bgColor: '#bdbdbd', fontColor: '#212121', value: 0 },
  { _id: 4, name: 'Cancelado', bgColor: '#ffcdd2', fontColor: '#e53935', value: 0 },
  { _id: 5, name: 'A CORRIGIR', bgColor: '#e0e0e0', fontColor: '#212121', value: 0 }
];

export const getProcessPaymentState = (value) => {
  if(Number.isInteger(value) && value > 0) return ProcessPaymentStates.find(elem => elem._id === value);
  return null;
};

export const renderProcessPaymentState = (value, isFree = false) => {
  const currentState = getProcessPaymentState(value);

  if(!currentState) return null;

  return (
    <StateLabel
      bgColor={isFree && currentState.freeBgColor ? currentState.freeBgColor : currentState.bgColor}
      fontColor={isFree && currentState.freeFontColor ? currentState.freeFontColor : currentState.fontColor}
    >
      {currentState.name}
    </StateLabel>
  );
};
