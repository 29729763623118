/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { UpdateProcess } from  '../../../../infra/requests/ProcessRequests';
import { Row, InfoColumn, ButtonsRow } from './styles';
import { PaymentStates } from '../../../../infra/utils/ProcessStates';
import { PaymentMethods } from '../../../../infra/utils/PaymentMethods';
import { ServiceModes } from '../../../../infra/utils/ServiceModes';
import { Currencies, getCurrency } from '../../../../infra/utils/Currencies';
import { GetServicesList } from '../../../../infra/requests/ServicesRequests';
import { sortServices } from '../../../../infra/utils/Sort';
import CurrencyInput from '../../../../components/generic/inputs/CurrencyInput';
import React, { useEffect, useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import DateInput from '../../../../components/generic/inputs/DateInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';

const validations = FormValidator.make({
  purchase_date: 'required',
  service: 'required',
  service_mode: 'required',
  state_payment: 'required',
  currency: 'required',
  price: 'required'
});

const EditProcess = ({ processInfo, responsibles, returnDetail, getInfo, handleSubmit, pristine }) => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState(getCurrency(processInfo.currency));

  useEffect(() => {
    const init = async () => {
      try {
        const servicesList = await GetServicesList();

        setServices(servicesList?.data || []);
      }
      catch(error) {
        console.log(error);
      }
    }

    init();
  }, []);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { success } = await UpdateProcess(processInfo._id, values);

      if(success) {
        notification.success({
          message: 'Pagamento atualizado com sucesso!'
        });

        returnDetail();

        // Redirect to Payments page after reloading the info
        await getInfo(true);
      }

      setLoading(false);
    }
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      {
        !processInfo.archived &&
        <ButtonsRow>
          <BaseButton
            type={'secondary'}
            text={'Cancelar'}
            disabled={loading}
            onClick={returnDetail}
          />
          <BaseButton
            text={'Guardar'}
            loading={loading}
            disabled={loading || pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonsRow>
      }
      <Row>
        <InfoColumn>
          <Field
            component={DateInput}
            name='purchase_date'
            label={'Data de Compra *'}
          />
          <Field
            component={SelectInput}
            name={'service'}
            label={'Serviço Adquirido *'}
            placeholder={'Selecionar serviço'}
            type='select'
            data={sortServices(services)}
            dataKey={'_id'}
            dataLabel={'title'}
            translatable={true}
          />
          <Field
            component={SelectInput}
            name={'service_mode'}
            label={'Modalidade do Serviço *'}
            placeholder={'Escolha a modalidade'}
            type='select'
            data={ServiceModes}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          <Field
            component={SelectInput}
            name={'responsible'}
            label={'Responsável'}
            placeholder={'Selecionar responsável'}
            type='select'
            data={responsibles}
            dataKey={'_id'}
            dataLabel={'name'}
            allowClear={true}
          />
          {
            !processInfo.free &&
            <Field
              component={SelectInput}
              name={'payment_method'}
              label={'Método de Pagamento *'}
              placeholder={'Escolha o método de pagamento'}
              type='select'
              data={PaymentMethods}
              dataKey={'_id'}
              dataLabel={'name'}
            />
          }
          <Field
            component={SelectInput}
            name={'state_payment'}
            label={'Estado de Pagamento *'}
            placeholder={'Escolha o estado'}
            type='select'
            data={PaymentStates}
            dataKey={'_id'}
            dataLabel={'name'}
          />
        </InfoColumn>
        {
          !processInfo.free &&
          <InfoColumn>
            <Field
              component={SelectInput}
              name={'currency'}
              label={'Moeda *'}
              placeholder={'Escolha a moeda de pagamento'}
              type='select'
              data={Currencies}
              dataKey={'_id'}
              dataLabel={'name'}
              execAfterChange={(value) => setCurrentCurrency(getCurrency(parseInt(value)))}
            />
            {
              processInfo.front ?
              <>
                <Field
                  component={CurrencyInput}
                  name={'price_base'}
                  label={'Valor base'}
                  suffix={currentCurrency}
                />
                <Field
                  component={CurrencyInput}
                  name={'price_extra'}
                  label={'Valor extra'}
                  suffix={currentCurrency}
                />
                <Field
                  component={CurrencyInput}
                  name={'cod_promo_discount'}
                  label={'Promocode'}
                  suffix={currentCurrency}
                />
                <Field
                  component={CurrencyInput}
                  name={'price'}
                  label={'Total *'}
                  suffix={currentCurrency}
                />
              </>
              :
              <Field
                component={CurrencyInput}
                name={'price'}
                label={'Total *'}
                suffix={currentCurrency}
              />
            }
          </InfoColumn>
        }
      </Row>
    </>
  );
}

const EditProcessForm = reduxForm({
  form: 'edit_process_payment_form',
  validate: validations
})(EditProcess);

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(EditProcessForm);
