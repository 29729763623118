import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';

const favoriteStates = [
  {
    _id: true,
    name: 'Sim'
  },
  {
    _id: false,
    name: 'Não'
  }
];

const addressStates = [
  {
    _id: true,
    name: 'Sim'
  },
  {
    _id: false,
    name: 'Não'
  }
];

class AddressFilters extends Component {
  state = { 
    search: '',
    favorite: '',
    user: '',
    main_address: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, favorite, user, main_address } = this.state;
    const { admins } = this.props;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome, email, contacto, morada, código postal, cidade ou país'
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Favoritos"
              allowClear
              placeholder="Escolher estado"
              data={favoriteStates}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: favorite,
                onChange: value => this.onInputChange('favorite', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Morada Principal Reformas"
              allowClear
              placeholder="Escolher estado"
              data={addressStates}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: main_address,
                onChange: value => this.onInputChange('main_address', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label="Administrador"
              allowClear
              placeholder="Escolher utilizador"
              data={admins}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: user,
                onChange: value => this.onInputChange('user', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default AddressFilters;