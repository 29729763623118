import styled from 'styled-components';

export const StateLabel = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  color: #ffffff;
  display: inline-block;
  width: auto;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

export const Note = styled.div`
  font-size: 15px;
  margin-bottom: 15px;

  & span {
    font-weight: 700;
  }
`;