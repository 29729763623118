import client from '../config/AxiosConfig';

export const GetEmailsList = async (currentPage, limit, filters) => await client.get(`/newsletter/${currentPage}/${limit}?filter=${filters}`);

export const DeleteEmail = async (id) => await client.delete(`/newsletter/${id}`);

export const GetContactRequests = async (currentPage, limit, filters) => await client.get(`/contact_requests/${currentPage}/${limit}?filter=${filters}`);

export const DeleteContact = async (id) => await client.delete(`/contact_requests/${id}`);

export const GetContactDetail = async (id) => await client.get(`/contact_requests/${id}`);

export const GetDocumentRequests = async (currentPage, limit, filters) => await client.get(`/digital_documents_requests/${currentPage}/${limit}?filter=${filters}`);

export const DeleteDocument = async (id) => await client.delete(`/digital_documents_requests/${id}`);

export const GetDocumentDetail = async (id) => await client.get(`/digital_documents_requests/${id}`);
