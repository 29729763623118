import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, SpinLoading } from '../../../styles/BasicStyles';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { GetServiceStats } from '../../../infra/requests/StatisticsRequests';
import { GetAdminList } from '../../../infra/requests/UsersRequests';
import { connect } from 'react-redux';
import { DescriptionWrapper, NoRecords } from '../clients/styles';
import React, { Component } from 'react';
import ServiceStatisticsFilters from './Filters';
import ServiceDetail from './ServiceDetail';

class ServiceStatisticsPage extends Component {
  state = {
    filters: '',
    loading: true,
    isReady: false,
    data: {},
    expanded: [],
    services: [],
    responsibles: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { filters } = this.state;
      const { user, history } = this.props;

      if(!user.super_admin) return history.push('/');

      const result = await GetServiceStats(filters);
      const services = await GetServicesList();
      const responsibles = await GetAdminList();

      this.setState({
        data: result.data || {},
        services: services?.data || [],
        responsibles: responsibles?.data || [],
        isReady: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ isReady: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { data, services, filters, responsibles, isReady, loading } = this.state;

    let hasService = false, hasYear = false;
    if(filters) {
      const parsedJson = JSON.parse(filters);
      if(parsedJson.service) hasService = true;
      if(parsedJson.year) hasYear = true;
    }

    if(!isReady) return <SpinLoading />;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Estatísticas - Serviços</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DescriptionWrapper>
            <div>Nesta área pode visualizar um conjunto de resultados sobre os serviços/outros pagamentos.</div>
            <div>- Total gasto por serviço por ano;</div>
            <div>- Total gasto por ano;</div>
            <div>- Total gasto global;</div>
          </DescriptionWrapper>
          <ServiceStatisticsFilters 
            queryChange={this.handleFilterChange} 
            services={services}
            responsibles={responsibles}
          />
          {
            loading ?
            <SpinLoading />
            :
            data?.all?.items?.length === 0 && data?.services?.items?.length === 0 ?
            <NoRecords>
              Não existem estatísticas a apresentar!
            </NoRecords>
            :
            <>
              {
                !hasService && 
                <ServiceDetail 
                  minYear={data?.minYear} 
                  maxYear={data?.maxYear} 
                  data={data?.all}
                  filterByYear={hasYear}
                  isFirst={true} 
                />
              }
              <ServiceDetail 
                minYear={data?.minYear} 
                maxYear={data?.maxYear} 
                data={data?.services} 
                filterByYear={hasYear}
              />
            </>
          }
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ServiceStatisticsPage);
