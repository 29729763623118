import { OverflowWrapper, Table, THead, THeadRow, THeadCol, TBody, TBodyRow, TBodyCol, TFooter, TFooterRow, TFooterCol } from '../clients/styles';
import React from 'react';

const ServiceDetail = ({ filterByYear = false, isFirst = false, minYear, maxYear, data }) => {
  const renderYearColumns = () => {
    const years = [];

    if(!filterByYear) years.push(<THeadCol key={0}>Desde sempre</THeadCol>);

    for(let i = maxYear; i >= minYear; i--) {
      years.push(<THeadCol key={i}>{i}</THeadCol>);
    }

    return years;
  };

  const renderYearValues = (service) => {
    const years = [];

    // Total (all years) for this Service
    if(!filterByYear) {
      const amountChf = Math.round(service.totalValueChf * 100) / 100;
      const amountEur = Math.round(service.totalValueEur * 100) / 100;

      let totalServiceAmount = '';
      if(amountChf > 0 && amountEur > 0) totalServiceAmount = `${amountChf}CHF + ${amountEur}€`;
      else if(amountChf > 0) totalServiceAmount = `${amountChf}CHF`;
      else if(amountEur > 0) totalServiceAmount = `${amountEur}€`;

      years.push(<TBodyCol key={`${service.serviceId}_0`}>{totalServiceAmount}</TBodyCol>);
    }

    // By year
    for(let i = maxYear; i >= minYear; i--) {
      const item = service.years.find(elem => elem.year === i);
      let amount = '';

      if(!!item) {
        const amountChf = Math.round(item.amountChf * 100) / 100;
        const amountEur = Math.round(item.amountEur * 100) / 100;

        if(amountChf > 0 && amountEur > 0) amount = `${amountChf}CHF + ${amountEur}€`;
        else if(amountChf > 0) amount = `${amountChf}CHF`;
        else if(amountEur > 0) amount = `${amountEur}€`;
      }

      years.push(<TBodyCol key={`${service.serviceId}_${i}`}>{amount}</TBodyCol>);
    }

    return years;
  };

  const renderYearTotals = () => {
    const years = [];
    let totalAmountChf = 0, totalAmountEur = 0;

    // By year
    for(let i = maxYear; i >= minYear; i--) {
      const item = data.years.find(elem => elem.year === i);
      let amount = '';

      if(!!item) {
        const amountChf = Math.round(item.amountChf * 100) / 100;
        const amountEur = Math.round(item.amountEur * 100) / 100;

        if(amountChf > 0 && amountEur > 0) {
          amount = `${amountChf}CHF + ${amountEur}€`;

          totalAmountChf += amountChf;
          totalAmountEur += amountEur;
        }
        else if(amountChf > 0) {
          amount = `${amountChf}CHF`;

          totalAmountChf += amountChf;
        }
        else if(amountEur > 0) {
          amount = `${amountEur}€`;

          totalAmountEur += amountEur;
        }
      }

      years.push(<TFooterCol key={`${i}_total`}>{amount}</TFooterCol>);
    }

    // Years total
    if(!filterByYear) {
      totalAmountChf = Math.round(totalAmountChf * 100) / 100;
      totalAmountEur = Math.round(totalAmountEur * 100) / 100;

      let totalAmount = '';
      if(totalAmountChf > 0 && totalAmountEur > 0) totalAmount = `${totalAmountChf}CHF + ${totalAmountEur}€`;
      else if(totalAmountChf > 0) totalAmount = `${totalAmountChf}CHF`;
      else if(totalAmountEur > 0) totalAmount = `${totalAmountEur}€`;

      years.unshift(<TFooterCol key={'0_total'}>{totalAmount}</TFooterCol>);
    }

    return years;
  };

  return (
    <>
      {
        data?.items?.length > 0 &&
        <OverflowWrapper style={{ marginBottom: isFirst ? '50px' : '0px' }}>
          <Table>
            <THead>
              <THeadRow>
                <THeadCol>{isFirst ? 'Todos os Pagamentos' : 'Serviço'}</THeadCol>
                {renderYearColumns()}
              </THeadRow>
            </THead>
            <TBody>
              {
                data.items.map((service, index) => 
                  !!service &&
                  <TBodyRow key={index}>
                    <TBodyCol>{service.title}</TBodyCol>
                    {renderYearValues(service)}
                  </TBodyRow>
                )
              }
            </TBody>
            <TFooter>
              <TFooterRow>
                <TFooterCol>Total</TFooterCol>
                {renderYearTotals()}
              </TFooterRow>
            </TFooter>
          </Table>
        </OverflowWrapper>
      }
    </>
  );
}

export default ServiceDetail;
