import styled from 'styled-components';

export const PromoCodeTotal = styled.div`
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  & span {
    color: ${p => p.theme.primaryColor};
  }
`;