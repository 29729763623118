import styled from 'styled-components';

export const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const GraphTitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const GraphContent = styled.div`
  position: relative;

  & .recharts-responsive-container {
    margin: auto;
  }
`;

export const CenterLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenterLabelText = styled.h1`
  line-height: 29px;
  color: #757575;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const LabelColor = styled.div`
  background-color: ${p => (p.color ? p.color : p.theme.primaryCTA)};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const LabelName = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
`;

export const TextLabel = styled.text`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.3;
`;

export const CustomTooltipContent = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

export const CustomTooltipLabel = styled.div`
  color: #000000;
`;