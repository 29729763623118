import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';

const options = [
  { _id: 1, title: 'Sim' },
  { _id: 2, title: 'Não' },
];

const states = [
  { _id: 'PENDING', title: 'Á espera de validação' },
  { _id: 'VALIDADO', title: 'Validado' },
  { _id: 'REJECTED', title: 'Rejeitado' }
];

class EstateFilters extends Component {
  state = { 
    search: '',
    type: '',
    date: '',
    active: '',
    featured: '',
    country: '',
    agent: '',
    validated: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, type, date, active, featured, country, agent, validated } = this.state;
    const { types, countries, user, agents } = this.props;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por título'
            />
          </Col>
          <Col xs={24} md={8}>
            <SelectInput
              label="Tipologia"
              allowClear
              placeholder="Escolher tipologia"
              data={types}
              dataKey="_id"
              dataLabel="title"
              translatable
              input={{
                value: type,
                onChange: value => this.onInputChange('type', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={8}>
            <DateInput
              label="Data"
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <SelectInput
              label="País"
              allowClear
              placeholder="Escolher país"
              data={countries}
              dataKey="_id"
              dataLabel="name"
              translatable
              input={{
                value: country,
                onChange: value => this.onInputChange('country', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={8}>
            <SelectInput
              label="Ativo"
              allowClear
              placeholder="Escolher estado"
              data={options}
              dataKey="_id"
              dataLabel="title"
              input={{
                value: active,
                onChange: value => this.onInputChange('active', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          {
            user?.type === 1 ?
            <Col xs={24} md={8}>
              <SelectInput
                label="Destacado"
                allowClear
                placeholder="Escolher estado"
                data={options}
                dataKey="_id"
                dataLabel="title"
                input={{
                  value: featured,
                  onChange: value => this.onInputChange('featured', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            :
            <Col xs={24} md={8}>
              <SelectInput
                label="Estado"
                allowClear
                placeholder="Escolher estado"
                data={states}
                dataKey="_id"
                dataLabel="title"
                input={{
                  value: validated,
                  onChange: value => this.onInputChange('validated', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          }
        </Row>
        {
          user?.type === 1 &&
          <Row gutter={24}>
            <Col xs={24} md={8}>
              <SelectInput
                label="Agente Imobiliário"
                allowClear
                placeholder="Escolher agente"
                data={agents}
                dataKey="_id"
                dataLabel="name"
                input={{
                  value: agent,
                  onChange: value => this.onInputChange('agent', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col xs={24} md={8}>
              <SelectInput
                label="Estado"
                allowClear
                placeholder="Escolher estado"
                data={states}
                dataKey="_id"
                dataLabel="title"
                input={{
                  value: validated,
                  onChange: value => this.onInputChange('validated', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          </Row>
        }
      </TableFilterSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(EstateFilters);
