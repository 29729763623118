import { Alert } from 'antd';
import { transitionsDelay } from '../../../../styles/BasicStyles';
import styled from 'styled-components';

export const TitleModal = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-top: 15px;
`;

export const SubtitleModal = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
  padding-top: 7px;
  padding-bottom: 12px;
`;

export const NewFileContainer = styled.div` 
  background: #F1F3F5;
  border-radius: 8px;
  padding: 15px;
`;

export const OrTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ADB5BD;
  padding-top: 12px;
`;

export const TemplateFileNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${({ hasError, theme }) => hasError ? theme.primaryColor : '#000000'};
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const TotalFilesSelected = styled.div`
  padding-top: 16px;
  padding-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #868E96;
`;

export const FilesSelected = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #C3C8CC;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 10px 16px;

  & .icon {
    cursor: pointer;
    
    & div {
      height: 22px;
    }

    & svg circle {
      transition: fill ${transitionsDelay} linear;
      fill: #A6AEB6;
    }

    &:hover {
      & svg circle {
        fill: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

export const FilenameColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  cursor: ${({ hasLink }) => hasLink ? 'pointer' : 'initial'};

  & .alertIcon {    
    & div {
      height: 18px;
    }

    & svg path {
      stroke: #CA6901;
    }
  }
`;

export const Filename = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

export const Filesize = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 6px;
`;

/* DROPZONE */

export const DropzoneField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border: 2px dashed ${({ error, theme }) => error ? theme.primaryColor : '#D1D5DB'};
  border-radius: 6px;
  margin-top: ${({ isFirst }) => isFirst ? '0px' : '15px'};
  cursor: pointer;

  & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #868E96;
  }
`;

export const DropzoneNote = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #868E96;
  padding-top: 5px;
`;

export const RejectedFiles = styled.div`

`;

export const RejectedAlert = styled(Alert)`
  padding: 7px !important;
  margin-top: 10px;

  & .ant-alert-message {
    display: none;
  }

  & .ant-alert-description {
    padding-right: 15px;
    font-size: 13px;
    line-height: 18px;
  }

  & .ant-alert-close-icon {
    top: 5px;
    right: 5px;
  }
`;

export const Note = styled.div`
  margin-top: 10px;

  & span {
    color: rgba(255, 0, 0, 0.8);
    font-weight: 700;
  }
`;
