import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetHowItWorks,
  UpdateHowItWorks
} from '../../infra/requests/HowItWorksRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import DraftInput from '../../components/generic/inputs/DraftInput';
const TabPane = Tabs.TabPane;

const sectionID = '6613b25b8a2bc8695398220c';

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages'
});

class ManageHowItWorksBenefitsIntro extends Component {
  state = { 
    loading: true 
  };

  componentDidMount = async () => {
    const { dispatch, CloseMenu, user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const result = await GetHowItWorks(sectionID);
    dispatch(
      initialize('manage_how_it_works_benefits_intro_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false 
    });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;
      this.setState({ loading: true });

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { data, success } = await UpdateHowItWorks(sectionID, payload);

      if(success) {
        dispatch(
          initialize('manage_how_it_works_benefits_intro_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }

      return this.setState({ 
        loading: false 
      });
    } 
    catch (e) {
      console.error(e);
      this.setState({ 
        loading: false 
      });
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Como funciona'
          breadcrumbs={['Vantagens', 'Introdução', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col md={12} xs={24} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título'}
                />
                <Field
                  component={DraftInput}
                  name={`description.${activeLanguage?.code}`}
                  label={'Descrição *'}
                  placeholder={'Descrição'}
                />
              </Col>
              <Col md={12} xs={24} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          type='text'
                          label={'Título *'}
                          placeholder={'Título'}
                        />
                        <Field
                          component={DraftInput}
                          name={`description.${language.code}`}
                          label={'Descrição *'}
                          placeholder={'Descrição'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageHowItWorksBenefitsIntro = reduxForm({
  form: 'manage_how_it_works_benefits_intro_form',
  validate: validations,
})(ManageHowItWorksBenefitsIntro);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageHowItWorksBenefitsIntro));