import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../styles/BasicStyles';
import { getYears } from '../../../infra/utils/Date';
import { Currencies } from '../../../infra/utils/Currencies';
import { CommissionsStates } from '../../../infra/utils/CommissionsStates';
import React, { Component } from 'react';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import TextInput from '../../../components/generic/inputs/TextInput';

class CommissionStatisticsFilters extends Component {
  state = { 
    search: '',
    user: '',
    year: '',
    state: '',
    currency: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { users } = this.props;
    const { search, user, year, state, currency } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) => this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome ou email'
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Utilizador'
              allowClear
              placeholder='Escolher utilizador'
              data={users}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: user,
                onChange: value => this.onInputChange('user', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Ano'
              allowClear
              placeholder='Escolher ano'
              data={getYears()}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: year,
                onChange: value => this.onInputChange('year', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Moeda'
              allowClear
              placeholder='Escolher moeda'
              data={Currencies}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: currency,
                onChange: value => this.onInputChange('currency', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Estado de Pagamento'
              allowClear
              placeholder='Escolher estado'
              data={CommissionsStates}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: state,
                onChange: value => this.onInputChange('state', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default CommissionStatisticsFilters;
