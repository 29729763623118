import { StateLabel } from '../../pages/process/styles';
import React from 'react';

export const EmailStates = [
  { _id: 1, name: 'Não enviado', bgColor: '#9C27B0', fontColor: '#ffffff', value: 0, showAgent: true },
  { _id: 2, name: 'Enviado', bgColor: '#389e0d', fontColor: '#ffffff', value: 0, showAgent: true },
  { _id: 3, name: 'Erro', bgColor: '#cf1322', fontColor: '#ffffff', value: 0, showAgent: true }
]; 

export const getEmailState = (value) => {
  if(Number.isInteger(value) && value > 0 && EmailStates[value - 1]) return EmailStates[value - 1];
  return null;
};

export const renderEmailState = (value) => {
  const currentState = getEmailState(value);

  if(!currentState) return null;

  return (
    <StateLabel
      bgColor={currentState.bgColor}
      fontColor={currentState.fontColor}
    >
      {currentState.name}
    </StateLabel>
  );
};