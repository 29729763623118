import { Row, Col } from './styles';
import { TableFilterSection } from '../../styles/BasicStyles';
import React, { Component } from 'react';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';

const filterOptions = [
  { _id: 1, name: 'Sim' },
  { _id: 2, name: 'Não' }
];

class LemaniaFilters extends Component {
  state = { 
    search: '',
    date: '',
    process: '',
    lemania: '',
    commission: '',
    responsible: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { processList, responsibles, user } = this.props;
    const { search, date, lemania, commission, process, responsible } = this.state;

    return (
      <TableFilterSection>
        <Row>
          <Col strechField={user?.type === 2 || user?.type === 3}>
            <TextInput
              input={{
                value: search,
                onChange: (event) => this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por descrição, fundo ou notas'
            />
          </Col>
          <Col>
            <SelectInput
              label='Processo'
              allowClear
              placeholder='Escolher processo'
              data={processList}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: process,
                onChange: value => this.onInputChange('process', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          {
            user?.type === 1 &&
            <Col>
              <SelectInput
                label='Responsável'
                allowClear
                placeholder='Escolher responsável'
                data={responsibles}
                dataKey='_id'
                dataLabel='name'
                translatable={false}
                input={{
                  value: responsible,
                  onChange: value => this.onInputChange('responsible', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          }
          <Col>
            <DateInput
              label='Data'
              placeholder='Escolher data'
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col>
            <SelectInput
              label='Já passou para Lemania'
              allowClear
              placeholder='Escolher estado'
              data={filterOptions}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: lemania,
                onChange: value => this.onInputChange('lemania', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col>
            <SelectInput
              label='Comissão'
              allowClear
              placeholder='Escolher estado'
              data={filterOptions}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: commission,
                onChange: value => this.onInputChange('commission', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default LemaniaFilters;
