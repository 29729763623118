import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { InputDiv, InputLabelDiv, CurrencyInputStyle } from './InputStyles';

const CurrencyInput = ({
  input,
  meta,
  label,
  first,
  disabled,
  defaultValue,
  suffix,
  execAfterChange
}) => {
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed;

  const setValue = (value) => {
    input.onChange(value);

    setTimeout(function() {
      if (typeof execAfterChange === "function") {
        execAfterChange(value);
      }
    }, 200)
  }

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <NumberFormat
        customInput={CurrencyInputStyle}
        value={input.value || ''}
        defaultValue={defaultValue || '0.00'}
        showError={showError}
        displayType={'input'}
        suffix={suffix}
        decimalScale={2}
        placeholder={`0.00${suffix}`}
        fixedDecimalScale
        allowNegative={false}
        onValueChange={values => setValue(values.floatValue || '')}
        disabled={disabled}
      />
    </InputDiv>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default CurrencyInput;
