import { Row, Col, notification } from 'antd';
import { BaseForm } from '../../../../styles/BasicStyles';
import { ActionsWrapper, ActionsCol } from '../styles';
import { CardContainer } from '../../styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { UpdateProcessCommissions } from  '../../../../infra/requests/ProcessRequests';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import CheckboxInput from '../../../../components/generic/inputs/CheckboxInput';

const CommissionsForm = ({ processInfo, handleSubmit, setEdit, getInfo, pristine }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { success } = await UpdateProcessCommissions(processInfo._id, values);

      if(success) {
        notification.success({
          message: 'Dados alterados com sucesso!'
        });

        setEdit(false);
        await getInfo();
      }

      setLoading(false);
    }
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <CardContainer>
        <ActionsWrapper alignTop='true'>
          <ActionsCol></ActionsCol>
          <ActionsCol>
            <BaseButton
              htmlType='button'
              type='secondary'
              text='Cancelar'
              style={{ float: 'right', marginRight: '8px' }}
              disabled={loading}
              onClick={() => setEdit(false)}
            />
            <BaseButton
              htmlType='submit'
              type='primary'
              text='Guardar'
              style={{ float: 'right' }}
              loading={loading}
              disabled={loading || pristine}
              onClick={handleSubmit(onSubmit)}
            />
          </ActionsCol>
        </ActionsWrapper>
        <Row gutter={24}>
          <Col lg={12} xs={24}>
            <Field
              component={CheckboxInput}
              name={`commission_service`}
              label={'Comissão Serviço?'}
              importantLabel={true}
              inlineCheckbox={true}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24}>
            <Field
              component={CheckboxInput}
              name={`commission_payments`}
              label={'Comissão Pagamentos?'}
              importantLabel={true}
              inlineCheckbox={true}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24}>
            <Field
              component={CheckboxInput}
              name={`commission_lemania`}
              label={'Comissão Lemania?'}
              importantLabel={true}
              inlineCheckbox={true}
            />
          </Col>
        </Row>
      </CardContainer>
    </BaseForm>
  );
}

const DataForm = reduxForm({
  form: 'edit_commissions_form'
})(CommissionsForm);

export default connect()(DataForm);
