import { Row, Col } from 'antd';
import { device } from '../../styles/Responsive';
import { transitionsDelay } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

/* CARDS */

export const CardWrapper = styled(Row)` 
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const Card = styled(Col)` 
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 20px;

  @media ${device.laptopL} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media ${device.tablet} {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const CardContainer = styled.div` 
  padding-left: 63px;
  padding-right: 63px;

  @media ${device.laptopL} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${device.tablet} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const CardItem = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: ${({ center }) => center ? 'center' : 'flex-start'};
  flex-wrap: wrap;

  & .icon {
    padding-left: 8px;
    cursor: pointer;
    
    & svg path {
      transition: fill ${transitionsDelay} linear;
    }

    &:hover {
      & svg path {
        fill: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

export const CardTitle = styled.div` 
  font-weight: ${({ small }) => small ? 400 : 600};
  font-size: ${({ small }) => small ? '14px' : '16px'};
  color: ${({ agentLabel, theme }) => agentLabel ? theme.primaryColor : '#343A40'};
  line-height: ${({ small }) => small ? '20px' : '25px'};
  padding-right: 14px;

  @media ${device.tabletS} {
    padding-right: 0px;
    width: 100% !important;
  }
`;

export const CardValue = styled.div` 
  font-weight: 400;
  font-size: ${({ small }) => small ? '14px' : '16px'};
  color: ${({ agentLabel, theme }) => agentLabel ? theme.primaryColor : '#343A40'};
  line-height: ${({ small }) => small ? '20px' : '25px'};

  ${({ noExpandWidth }) => !noExpandWidth &&
    css` 
      @media ${device.tabletS} {
        width: 100%;
      }
    `}
`;

/* STATE LABELS */

export const StateLabel = styled.div`
  padding: 2px 10px;
  border-radius: 10px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  color: ${(p) => (p.fontColor ? p.fontColor : 'rgba(0, 0, 0, 0.65)')};
  display: inline-block;
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const MissingLabel = styled.div`
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #FEF3C7;
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    color: #CA6901;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 8px;
  }

  & div {
    width: 16px;
    height: 16px;
    line-height: 1;

    & svg {
      width: 16px;
      height: 16px;
      line-height: 1;

      & path {
        stroke: #CA6901;
      }
    }
  }
`;

export const SuccessLabel = styled.div`
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #D1FAE5;
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    color: #065F46;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 8px;
  }

  & div {
    width: 16px;
    height: 16px;
    line-height: 1;

    & svg {
      width: 16px;
      height: 16px;
      line-height: 1;

      & path {
        stroke: #065F46;
      }
    }
  }
`;

/* MODALS */

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  line-height: 28px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 43px;

  & button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

/* HEADER: TITLE AND BUTTONS */

export const TabTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    padding-top: 10px;
    
    & p, & > div {
      width: 100%;
    }
  }
`;

export const TabButtonsWrapper = styled.div` 
  display: flex;
  flex-direction: row;

  & button {
    margin-left: 5px;
  }

  & button:first-child {
    margin-left: 0px;
  }

  @media ${device.tablet} {
    justify-content: flex-end;
    order: 1;
  }

  @media ${device.mobileL} {
    flex-direction: column;

    & button {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    
    & button:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const TabTitle = styled.p` 
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0px;

  @media ${device.tablet} {
    display: flex;
    order: 2;
    padding-top: 10px;
  }
`;

/* FILE DOWNLOAD */

export const FileDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;

  &:first-child {
    margin-top: 0px;
  }

  & span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #343A40;
    padding-right: 10px;
    transition: color ${transitionsDelay} linear;
  }

  & div {
    height: 22px;

    & svg {
      height: 22px;

      & path {
        transition: stroke ${transitionsDelay} linear;
      }
    }
  }

  &:hover {
    & span {
      color: ${({ theme }) => theme.primaryColor};
    }

    & div {
      & svg path {
        stroke: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;