/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { CreateTask } from '../../../../infra/requests/TasksRequests';
import { TasksStates } from '../../../../infra/utils/TasksStates';
import { FilesSelected, File, FilenameColumn, Filename, RejectedFiles, RejectedAlert } from '../../components/FileUpload/styles';
import { ReactSVG } from 'react-svg';
import { FilesTitle } from './styles';
import { FlattenToFormData } from '../../../../infra/services/formdata/TransformToFormData';
import React, { useEffect, useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextAreaInput from '../../../../components/generic/inputs/TextAreaInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Dropzone from '../../components/FileUpload/MultipleDropzone';

const validations = FormValidator.make({
  process: 'required',
  recipient: 'required',
  state: 'required',
  description: 'required'
});

const NewTask = ({ openModal, closeModal, processID, getInfo, users, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  useEffect(() => {
    dispatch(initialize('new_task_form', { state: 1 }));
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const payload = FlattenToFormData({ ...values, process: processID, responsible: user._id, selectedFiles: acceptedFiles });
      const result = await CreateTask(payload);

      if(result?.success) {
        notification.success({
          message: 'Tarefa inserida com sucesso!'
        });

        dispatch(initialize('new_task_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  };

  const removeFile = (index) => {
    const aux = [...acceptedFiles];
    aux.splice(index, 1);
    setAcceptedFiles([...aux]);
  };

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Inserir Tarefa</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Field
                component={SelectInput}
                name={'recipient'}
                label={'Destinatário *'}
                placeholder={'Escolha o utilizador'}
                type='select'
                data={users}
                dataKey={'_id'}
                dataLabel={'name'}
              />
            </Col>
            <Col md={12} sm={24}>
              <Field
                component={SelectInput}
                name={'state'}
                label={'Estado *'}
                placeholder={'Escolha o estado'}
                type='select'
                data={TasksStates}
                dataKey={'_id'}
                dataLabel={'name'}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Field
                component={DateInput}
                name='due_date'
                label={'Due date'}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Field
                component={TextAreaInput}
                name={'description'}
                type='text'
                label={'Descrição *'}
                placeholder={'Insira uma descrição'}
                minRows={8}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <FilesTitle>Ficheiros</FilesTitle>
              <Field 
                name='files' 
                component={Dropzone}
                handleAcceptedFiles={(files) => setAcceptedFiles([ ...acceptedFiles, ...files ])}
                handleRejectedFiles={(errors) => setRejectedFiles([ ...rejectedFiles, ...errors ])}
                isFirst
              />
            </Col>
          </Row>     
          {
            rejectedFiles?.length > 0 &&
            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col xs={24}>
                <RejectedFiles>
                  {
                    rejectedFiles.map((error, index) =>
                      <RejectedAlert
                        key={index}
                        description={error}
                        type='error'
                        closable
                      />
                    )
                  }
                </RejectedFiles>
              </Col>
            </Row>
          }
          {
            acceptedFiles.length > 0 &&
            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col xs={24}>
                <FilesSelected>
                  {
                    acceptedFiles.map((file, index) => {
                      return (
                        <File key={index}>
                          <FilenameColumn>
                            <Filename>
                              {file.filename || file.blob?.name || ''}
                            </Filename>
                          </FilenameColumn>
                          <ReactSVG 
                            src={`${process.env.REACT_APP_BO_URL}close_circle.svg`} 
                            className='icon' 
                            onClick={() => removeFile(index)} 
                          />
                        </File>
                      );
                    })
                  }
                </FilesSelected>
              </Col>
            </Row>
          }
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewTaskForm = reduxForm({
  form: 'new_task_form',
  validate: validations
})(NewTask);

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(NewTaskForm);
