import React, { Component } from 'react';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import {
  GetContactRequests,
  DeleteContact,
} from '../../infra/requests/ContactsRequests';
import { connect } from 'react-redux';
import Table from '../../components/generic/table/Table';
import ContactRequestsFilters from './ContactRequestsFilters';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import moment from 'moment';

class ContactRequestsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('DD/MM/YYYY, HH:mm'),
        width: '15%',
      },
      {
        title: 'Origem',
        key: 'origin',
        render: (data) => this.renderOrigin(data),
        width: '25%',
      },
      {
        title: 'Nome',
        key: 'name',
        render: (data) => `${data.first_name} ${data.last_name}`,
        width: '20%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '18%',
      },
      {
        title: 'Contacto',
        dataIndex: 'contact',
        width: '15%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/contact-requests/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    const options = [
      {
        onClick: () => history.push(`/contact-requests/${data._id}`),
        label: 'Ver'
      },
      {
        popConfirmtitle: 'Tem a certeza que quer remover o registo?',
        onClick: () => this.removeContact(data),
        label: 'Remover'
      }
    ];

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={options} 
      />
    );
  };

  renderOrigin = (data) => {
    let result = 'Formulário de Contacto';
    if(!!data.other_service) {
      if(data.other_service.tag === 'INSURANCE') result = 'Seguros';
      else if(data.other_service.tag === 'CREDIT') result = 'Créditos';
      else if(data.other_service.tag === '2_PILAR') result = '2º Pilar para Empresas';
    }
    else if(!!data.estate) result = `Imobiliário (${data.estate.title?.pt})`;

    return result;
  }


  removeContact = async (data) => {
    try {
      await DeleteContact(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetContactRequests(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Pedidos de Contacto</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <ContactRequestsFilters 
            queryChange={this.handleFilterChange} 
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem registos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ContactRequestsPage);