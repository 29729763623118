import client from '../config/AxiosConfig';

export const GetTasks = async (currentPage, limit, filters) => await client.get(`/process_tasks/${currentPage}/${limit}?filter=${filters}`);

export const GetTask = async (identifier) => await client.get(`/process_tasks/${identifier}`);

export const CreateTask = async (data) => await client.post(`/process_tasks`, data);

export const UpdateTask = async (identifier, data) => await client.put(`/process_tasks/${identifier}`, data);

export const DeleteTask = async (identifier) => await client.delete(`/process_tasks/${identifier}`);

export const UpdateTaskState = async (identifier, data) => await client.put(`/process_tasks/state/${identifier}`, data);
