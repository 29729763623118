import { transitionsDelay } from '../../../styles/BasicStyles';
import styled from 'styled-components';

export const FilesSelected = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  cursor: pointer;

  &:hover {
    & div {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const FileName = styled.div` 
  transition: color ${transitionsDelay} linear;
`;

export const FileSize = styled.div` 
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.5);
  transition: color ${transitionsDelay} linear;
`;