import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React from 'react';
import RadioButtons from '../../../../components/generic/inputs/RadioButtons';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  subject: 'required'
});

const PdfGenerateSubject = ({ openModal, closeModal, processID, pdfType, subjectsList, user, handleSubmit, dispatch }) => {
  const onSubmit = values => {
    const filters = { ...values, type: pdfType };
    window.open(`${process.env.REACT_APP_API}/automatic_pdf/process/${user?._id}/${processID}?filter=${JSON.stringify(filters)}`, '_blank');    

    dispatch(initialize('pdf_generate_subject_form'));
    closeModal();
  };

  const onClose = () =>{
    dispatch(initialize('pdf_generate_subject_form'));
    closeModal();
  }

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Gerar PDF</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name='subject'
            component={RadioButtons}
            options={subjectsList}
            isVertical={true}
            label='Selecione um assunto para concluir a exportação'
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Gerar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const PdfGenerateSubjectForm = reduxForm({
  form: 'pdf_generate_subject_form',
  validate: validations
})(PdfGenerateSubject);

export default connect()(PdfGenerateSubjectForm);
