import { AutomaticEmailDate } from './ClientsStyles';
import React from 'react';
import moment from 'moment-timezone';

const AutomaticEmails = ({ client }) => {
  return (
    <>
      <AutomaticEmailDate>
        Email Aniversário (último envio):&nbsp;<span>{client?.last_birthday_date_sent ? `${moment(client.last_birthday_date_sent).format('DD/MM/YYYY, HH:mm')}h` : 'Não enviado'}</span>
      </AutomaticEmailDate>
      <AutomaticEmailDate>
        Registo sem compra efetuada:&nbsp;<span>{client?.no_purchase_date_sent ? `${moment(client.no_purchase_date_sent).format('DD/MM/YYYY, HH:mm')}h` : 'Não enviado'}</span>
      </AutomaticEmailDate>
    </>
  );
}

export default AutomaticEmails;
