/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import { reduxForm, initialize } from 'redux-form';
import { GetAdminList } from '../../../infra/requests/UsersRequests';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { GetClientsList } from '../../../infra/requests/ClientsRequests';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { withLocalize } from 'react-localize-redux';
import { FormContainer, BaseForm, SpinLoading } from '../../../styles/BasicStyles';
import { CreateProcess } from '../../../infra/requests/ProcessRequests';
import React, { useEffect, useState } from 'react';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import FormValidator from '../../../infra/services/validations/FormValidator';
import BankTransferAlertModal from '../modals/BankTransferAlert';
import ProcessDetails from './ProcessDetails';
import ClientDetails from './ClientDetails';
import moment from 'moment';

const validations = FormValidator.make({
  language: 'required',
  state: 'required',
  service: 'required',
  service_mode: 'required',
  first_name: 'required',
  last_name: 'required',
  purchase_date: 'required',
  email: 'email'
});

const CreateProcessPage = ({ dispatch, CloseMenu, user, formValues, handleSubmit, pristine, history }) => {
  const [loading, setLoading] = useState(true);
  const [responsibles, setResponsibles] = useState([]);
  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);
  const [confirmPaymentMethod, setConfirmPaymentMethod] = useState(false);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [service, setService] = useState(null);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, []);

  const getInfo = async () => {
    try {
      CloseMenu();

      const users = user?.type === 1 ? await GetAdminList() : [];
      const services = (user?.type === 1 || user?.type === 3) ? await GetServicesList() : [];
      const clients = user?.type === 1 ? await GetClientsList() : [];

      dispatch(initialize('create_process_form', {
        language: 'pt',
        currency: 2,
        service_mode: 1,
        purchase_date: moment() 
      }));

      setLoading(false);
      setResponsibles(users?.data || []);
      setServices(services?.data || []);
      setClients(clients?.data || []);
    }
    catch(error) {
      console.log(error);
    }
  }

  // Before showing the modal about the payment method "Transferência Bancária", check if all required fields were filled
  const beforeSubmit = (values) => {
    if (
      values?.language &&
      values?.state &&
      values?.service &&
      values?.service_mode &&
      values?.payment_method &&
      values?.price &&
      values?.currency &&
      values?.first_name &&
      values?.last_name &&
      values?.purchase_date
    ) {
      setPaymentMethodModal(true);
    }
  };

  const onSubmit = async (values, saveType) => {
    try {
      setLoading(true);

      // If the current user is an Agent, we have to inject certain values to pass the API validations
      if(user?.type === 2) {
        values.service = '608ab3d0803cef39fd8778b7';
        values.service_mode = 0;
        values.payment_method = 0;
        values.price = 0;
        values.currency = 1;
      }

      const full_name = `${values.first_name} ${values.last_name}`;

      // Assign payment state according to the selected payment method
      let state_payment = 0;
      if(values.payment_method === '1') state_payment = 7;
      else if(values.payment_method === '2') state_payment = 1;
      else if(values.payment_method === '3') state_payment = 5;
      else if(values.payment_method === '4') state_payment = 3;
      else if(service?.free) state_payment = 12;

      // Process payment state (default "A aguardar pagamento", serviços gratuitos "Pago")
      let process_state_payment = 1;
      if(service?.free) process_state_payment = 2;

      // Bug fix, when selecting a client and then unselect it would return an error
      if(!values.client) delete values.client;

      const payload = {
        ...values,
        process_state_payment,
        state_payment,
        full_name,
        saveType,
        agent: user?.type === 2 ? user._id : undefined,
        partner: user?.type === 3 ? user._id : undefined,
        free: service?.free
      };

      const { success } = await CreateProcess(payload);

      setLoading(false);

      if(success) {
        notification.success({ message: 'Processo adicionado com sucesso!' });

        return history.push('/process');
      }
      else {
        notification.error({ message: 'Ocorreu um erro ao criar o processo!' });
      }
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  if(loading) return <SpinLoading />;

  return (
    <>
      <ManageFormHeader
        title='Criar Processo'
        buttons={[
          {
            type: 'primary',
            icon: 'save',
            text: 'Gravar',
            onClick: handleSubmit((values) => confirmPaymentMethod ? beforeSubmit(values) : onSubmit(values, 1)),
            disabled: pristine && !formValues
          },
          {
            type: 'secondary',
            icon: 'close',
            text: 'Cancelar',
            onClick: () => history.push('/process')
          }
        ]}
      />
      <FormContainer>
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <ProcessDetails
            setConfirmPaymentMethod={setConfirmPaymentMethod}
            services={services}
            responsibles={responsibles}
            setService={setService}
          />
          <ClientDetails
            clients={clients}
          />
        </BaseForm>
      </FormContainer>
      <BankTransferAlertModal
        openModal={paymentMethodModal}
        closeModal={() => setPaymentMethodModal(false)}
        onSubmitSave={handleSubmit((values) => onSubmit(values, 1))}
        onSubmitSaveEmail={handleSubmit((values) => onSubmit(values, 3))}
        loading={loading}
      />
    </>
  );
}

const CreateProcessForm = reduxForm({
  form: 'create_process_form',
  validate: validations
})(CreateProcessPage);

const mapStateToProps = (state) => ({
  user: state.user,
  formValues: state?.form?.create_process_form?.values
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(CreateProcessForm));
