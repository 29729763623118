import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse,
  Cascader,
  Switch,
  Calendar,
  Radio,
} from 'antd';
import { transitionsDelay } from '../../../styles/BasicStyles';
import styled, { css } from 'styled-components';
const { TextArea } = Input;
const { Panel } = Collapse;
const { MonthPicker } = DatePicker;

const defineBorderColor = (props) => {
  if (props.error) return props.theme.inputErrorColor;
  if (props.active) return props.theme.primaryColor;
  return props.theme.inputBorderColor;
};

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
`;

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`;

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
  height: 64px;
`;

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: ${({ small }) => (small ? '12px' : '14px')};
  width: ${p => p.inlineCheckbox ? 'auto' : '100%'};
  margin-top: ${({ sublabel }) => (sublabel ? '10px' : 0)};
  font-weight: ${p => p.importantLabel ? 'bold' : '500'};
  margin-bottom: 4px;
  line-height: 22px;
  color: #495057;

  ${({ error }) => error && 'color: red'};
`;

export const InputField = styled(Input)`
  width: 100%;
  
  &&& {
    margin-bottom: ${({ language }) => (language ? 3 : 0)}px;
  }

  &&&.ant-input-affix-wrapper .ant-input-prefix {
    ${({ language, theme }) =>
      language
        ? `
    width: 70px;
    height: 100%;
    padding-right: 5px;
    border-right: 1px solid ${theme.inputBorderColor};
    `
        : ''};
  }
  &&&.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: ${({ language }) => (language ? 90 : 30)}px;
  }

  &:focus-within,
  &:hover {
    box-shadow: unset !important;
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const StyledSelectInput = styled(Select)`
  width: 100%;   

  .ant-select-selection {
    min-height: 40px;
    max-height: 500px;
    overflow-y: auto;
    border-radius: 8px;
    padding-top: 5px;
    box-shadow: unset !important;
  }

  .ant-select-focused {
    box-shadow: unset !important;
    border-color: ${({ theme }) => theme.inputHoverColor};
  }

  .ant-select-arrow, .ant-select-arrow-icon {
    color: ${({ theme }) => theme.tableHeaderTextColor};
  }

  .ant-select-selection:hover, 
  .ant-select-selection:focus, 
  .ant-select-selection:active {
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
  }

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const CalendarInputField = styled(Calendar)`
  width: 100%;
`;

export const TextAreaInputField = styled(TextArea)`
  width: 100%;

  &:focus-within,
  &:hover {
    ${(p) =>
      p.error && `border: 1px solid ${p.theme.inputErrorColor} !important;`};
  }

  ${(p) =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;
  min-height: 40px;

  & .ant-input-number-input-wrap, & input {
    min-height: 40px;
  }

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-input-number-input{
        border: 1px solid ${p.theme.inputErrorColor};
      }
  `}
`;

export const CheckboxField = styled(Checkbox)`
  margin-left: ${p => p.inlineCheckbox ? '15px' : '0px'};
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #495057;
    border-color: #495057;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #495057;
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #495057;
  }

  .ant-checkbox-inner::after {
    left: 25%;
    top: 45%;
    height: 13px;
    width: 6px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
`;

export const SwitchField = styled(Switch)``;

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 1px solid ${(props) => defineBorderColor(props)};
  color: ${({ active }) => (active ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
  border-radius: 3px;
`;

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const DateInputField = styled(DatePicker)`
  display: inline-block;
  width: 100%;

  .ant-input:hover, .ant-input:focus {
    box-shadow: unset !important;
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
  }

  .ant-calendar-picker:hover, .ant-calendar-picker:focus {
    box-shadow: unset !important;
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
  }

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const MonthInputField = styled(MonthPicker)`
  display: inline-block;
  width: 100%;

  .ant-input:hover, .ant-input:focus {
    box-shadow: unset !important;
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
  }

  .ant-calendar-picker:hover, .ant-calendar-picker:focus {
    box-shadow: unset !important;
    border: 1px solid ${({ theme }) => theme.inputHoverColor};
  }

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`;

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: ${({ auto }) => (auto ? 0 : 200)}px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => defineBorderColor(props)};
  cursor: pointer;
  ${({ filled }) => (filled ? 'border: none' : '')}
`;

export const ImageMessage = styled.div`
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
  font-size: 18px;
  margin-top: 30px;
`;

export const ImageIcon = styled(Icon)`
  font-size: 36px;
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
`;

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px 0;
`;

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`;

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`;

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`;

export const FormPanel = styled(Panel)`
  &&& {
    .ant-collapse-header {
      color: ${({ error }) => (error ? 'red !important' : '')};
    }
    .ant-collapse-content-box {
      padding: 5px;
    }
  }
`;

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`;

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
`;

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`;

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`;

export const PreviewVideo = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const FileSection = styled.div`
  margin-top: 3px;
  background-color: ${(p) => p.theme.secondaryColor};
  padding: 5px;
  text-transform: uppercase;
  position: relative;

  & i {
    position: absolute;
    right: 1%;
    top: 25%;
    cursor: pointer;
  }
`;

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.inputBorderColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;

  i {
    font-size: 50px;
  }

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
    outline: none;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const FilesContent = styled.div`
  position: relative;
  width: 100%;
`;

export const FilesLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #323232;
  padding-right: 30px;
  flex-shrink: 0;
`;

export const ImageFilesSection = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  background: ${(p) => p.theme.primaryGray};
  border-radius: 6px;
  padding: 12px 10px;
  padding-left: 40px;
  cursor: pointer;
  border: 1px solid ${(p) => (p.error ? p.theme.errorColor : 'transparent')};

  & button {
    position: absolute;
    top: 0;
    right: 0;
    height: 49px !important;
    padding: 0px 25px !important;
  }
`;

export const FilesErrorLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(p) => p.theme.errorColor};
  position: absolute;
  bottom: -20px;
  left: 0;
`;

export const ImageName = styled.div`
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: calc(100% - 150px);
  height: 24px;
  overflow: hidden;
  opacity: ${(p) => (p.isPlaceholder ? '0.4' : '1')};
`;

export const ImageClearIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const ImageInputContent = styled.div`
  position: relative;
`;

export const CurrencyInputStyle = styled.input`
  display: inline-block;
  width: 100%;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  height: 40px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  transition: all ${transitionsDelay};

  &:focus {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
    outline: 0;
    box-shadow: 0 0 0 2px rgba(188, 146, 36, 0.2);
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
  }

  ${({ disabled }) => disabled &&
    css`
      background: #f5f5f5;
      cursor: not-allowed;
    `}
`;

export const ColourInput = styled(InputField)`
  float: left !important;
  width: calc(100% - 40px) !important;
`;

export const ColourBox = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  float: right;
  background-color: ${({ colour }) => colour};
  border: 1px solid black;
`;

export const StyledCascadeInput = styled(Cascader)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const DynamicImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const FilesGallerySection = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 500px;
`;

export const NotesText = styled.div`
  font-size: 13px;
  padding-top: ${({ noPadding }) => noPadding ? '0px' : '5px'};
`;

export const RadioGroup = styled(Radio.Group)`
  margin-top: 10px;

  &.ant-radio-group {
    & .ant-radio-wrapper {
      display: ${(p) => (p.isVertical ? 'block' : 'inline-block')};
      margin-bottom: ${(p) => (p.isVertical ? '10px' : '0px')};

      & .ant-radio-inner {
        border: 1px solid
          ${(p) => (p.error ? p.theme.inputErrorColor : 'rgba(0, 0, 0, 0.6)')};
      }
    }
  }
`;
