/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle, FileDownload } from '../../styles';
import { GetProcessFilesList, DeleteProcessFile } from '../../../../infra/requests/ProcessRequests';
import { getCategory } from '../../../../infra/utils/FileCategories';
import { notification } from 'antd';
import { ReactSVG } from 'react-svg';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import DocumentsFilters from './DocumentsFilters';
import GetFile from '../../../../infra/utils/GetFile';
import NewDocumentModal from '../../modals/NewDocument';
import EditDocumentNameModal from '../../modals/EditDocumentName';
import EditDocumentCategoryModal from '../../modals/EditDocumentCategory';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';

const Documents = ({ processInfo, reloadTab, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newModal, setNewModal] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, reloadTab]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const list = await GetProcessFilesList(processInfo._id, filters);
      
      let finalColumns = [
        {
          title: 'Data',
          dataIndex: 'date',
          render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
          width: '20%'
        },
        {
          title: 'Categoria',
          dataIndex: 'category',
          render: (value) => getCategory(value)?.name,
          width: '30%'
        },
        {
          title: 'Ficheiro',
          key: 'file',
          render: (data) => 
            <FileDownload onClick={() => GetFile(data.fileID)}>
              <span>{data.file}</span>
              <ReactSVG src={`${process.env.REACT_APP_BO_URL}download.svg`} />
            </FileDownload>,
          width: '40%'
        },
        {
          title: 'Ações',
          render: (data) => renderActions(data)
        }
      ];
  
      if(processInfo.archived) {
        finalColumns = [
          {
            title: 'Data',
            dataIndex: 'date',
            render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
            width: '20%'
          },
          {
            title: 'Categoria',
            dataIndex: 'category',
            render: (value) => getCategory(value)?.name,
            width: '35%'
          },
          {
            title: 'Ficheiro',
            key: 'file',
            render: (data) => 
              <FileDownload onClick={() => GetFile(data.fileID)}>
                <span>{data.file}</span>
                <ReactSVG src={`${process.env.REACT_APP_BO_URL}download.svg`} />
              </FileDownload>,
            width: '45%'
          }
        ];
      }

      setRows(list?.data || []);
      setColumns(finalColumns);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  const openEditNameModal = (data) => {
    // Remove extension from filename
    let filename = data.file;
    const ext = filename.split('.').pop();
    filename = filename.replace(`.${ext}`, '');

    dispatch(initialize('edit_document_name_form', { _id: data.fileID, filename }));

    setEditNameModal(true);
  }

  const openEditCategoryModal = (data) => {
    dispatch(initialize('edit_document_category_form', { _id: data._id, category: data.category }));

    setEditCategoryModal(true);
  }

  const removeFile = async (data) => {
    try {
      setLoading(true);

      await DeleteProcessFile(data._id);
      await getInfo();

      notification.success({
        message: 'Documento removido com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderActions = (data) => {
    if(processInfo.archived) {
      return null;
    }
    
    const options = [
      {
        onClick: () => openEditNameModal(data),
        label: 'Editar Nome' 
      }
    ];

    if(data.category !== 99) {
      options.push(
        {
          onClick: () => openEditCategoryModal(data),
          label: 'Editar Categoria'
        }
      );

      options.push(
        {
          popConfirmtitle: 'Tem a certeza que quer remover o ficheiro?',
          onClick: () => removeFile(data),
          label: 'Remover' 
        }
      );
    }

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={options} 
      />  
    );
  }

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>Documentos</TabTitle>
        <TabButtonsWrapper>
          {
            !processInfo.archived &&
            <BaseButton
              type={'primary'}
              text={'Adicionar documento'}
              onClick={() => setNewModal(true)}
            />
          }
        </TabButtonsWrapper>
      </TabTitleWrapper>
      <DocumentsFilters
        queryChange={handleFilterChange}
      />
      <Table
        columns={columns}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Documentos inseridos!'}
        rowKey={'_id'}
        hasPagination={false}
      />
      {
        !processInfo.archived &&
        <>
          <NewDocumentModal
            openModal={newModal}
            closeModal={() => setNewModal(false)}
            getInfo={getInfo}
            processID={processInfo._id}
          />
          <EditDocumentNameModal
            openModal={editNameModal}
            closeModal={() => setEditNameModal(false)}
            getInfo={getInfo}
            processID={processInfo._id}
          />
          <EditDocumentCategoryModal
            openModal={editCategoryModal}
            closeModal={() => setEditCategoryModal(false)}
            getInfo={getInfo}
          />
        </>
      }
    </>
  );
}

export default connect()(Documents);
