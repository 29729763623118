import styled from 'styled-components';

export const ModalText = styled.div`
  & span {
    font-weight: bold;
  }
`;

export const ModalTextTitle = styled.div`
  color: red;
  font-size: 16px;
  padding-bottom: 15px;
`;

export const ModalTextLine = styled.div`
  font-size: 14px;
  padding-bottom: 7px;
`;