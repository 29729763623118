import client from '../config/AxiosConfig';

export const GetDocumentsBanner = async (id) => await client.get(`/digital_documents/banner/${id}`);

export const UpdateDocumentsBanner = async (id, data) => await client.put(`/digital_documents/banner/${id}`, data);

export const UpdateDocument = async (identifier, data) => await client.put(`/digital_documents/${identifier}`, data);

export const GetDocument = async (identifier) => await client.get(`/digital_documents/${identifier}`);

export const GetDocuments = async (currentPage, limit, filters) => await client.get(`/digital_documents/${currentPage}/${limit}?filter=${filters}`);

export const GetDocumentsList = async () => await client.get(`/digital_documents`);

export const GetDocumentTexts = async (id) => await client.get(`/digital_documents/texts/by-document/${id}`);

export const UpdateDocumentText = async (id, data) => await client.put(`/digital_documents/texts/${id}`, data);

export const GetDocumentText = async (id) => await client.get(`/digital_documents/texts/${id}`);
