import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetProcessUsersList } from '../../infra/requests/UsersRequests';
import { GetTask, UpdateTask, CreateTask } from '../../infra/requests/TasksRequests';
import { GetUserProcessFullList, GetProcess } from '../../infra/requests/ProcessRequests';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { TasksStates } from '../../infra/utils/TasksStates';
import { PriceSpinLoading } from '../process/create/styles';
import { FilesSelected, File, FilenameColumn, Filename, RejectedFiles, RejectedAlert } from '../process/components/FileUpload/styles';
import { ReactSVG } from 'react-svg';
import { FilesTitle, NoFiles } from '../process/modals/NewTask/styles';
import React, { Component } from 'react';
import SelectInput from '../../components/generic/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import DateInput from '../../components/generic/inputs/DateInput';
import Dropzone from '../process/components/FileUpload/MultipleDropzone';
import GetFile from '../../infra/utils/GetFile';

const validations = FormValidator.make({
  process: 'required',
  recipient: 'required',
  description: 'required',
  state: 'required'
});

const apiUrl = process.env.REACT_APP_BO_URL;

class ManageTasksPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    users: [],
    process: [],
    loadingUsers: false,
    processInfo: null,
    acceptedFiles: [],
    rejectedFiles: []
  };

  componentDidMount = async () => {
    const { match: { params }, dispatch, CloseMenu, user, history } = this.props;

    CloseMenu();

    const process = await GetUserProcessFullList();

    const processList = [];
    if(process?.data?.length > 0) {
      process.data.forEach(elem => {
        processList.push({
          _id: elem._id,
          name: `${elem.ref} - ${elem.full_name}`
        })
      });
    }

    if(params.id) {
      const result = await GetTask(params.id);
      const processInfo = await GetProcess(result?.data?.process);

      //If the User is an Agent, but this process does not belong to him, return to tasks list
      if(user?.type === 2 && processInfo?.data?.agent?._id !== user?._id) {
        return history.push('/tasks');
      }
      //If the User is an Partner, but this process does not belong to him, return to tasks list
      else if(user?.type === 3 && processInfo?.data?.partner?._id !== user?._id) {
        return history.push('/tasks');
      }
      
      dispatch(initialize('manage_tasks_form', { ...result.data }));

      await this.loadUsers(processInfo?.data?._id);

      this.setState({ 
        loading: false,
        process: processList,
        processInfo: processInfo?.data,
        acceptedFiles: result?.data?.files || []
      });
    } 
    else {
      dispatch(initialize('manage_tasks_form', { state: 1 }));

      this.setState({
        isNew: true,
        loading: false,
        process: processList
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history, user } = this.props;
      const { acceptedFiles } = this.state;

      const payload = FlattenToFormData({ responsible: user._id, selectedFiles: acceptedFiles, ...values });
      const { success } = params.id ? await UpdateTask(params.id, payload) : await CreateTask(payload);

      if(success) {
        return history.push('/tasks');
      }

      this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/tasks');
  };

  // We can only load the Users after selecting the Process, because we can only show Admin users OR the selected Process Agent
  loadUsers = async (process) => {
    try {
      this.setState({ loadingUsers: true });

      const users = await GetProcessUsersList(process);

      this.setState({
        loadingUsers: false,
        users: users?.data || []
      });
    }
    catch(error) {
      console.log(error);
      this.setState({ loadingUsers: false });
    }
  }

  removeFile = (index) => {
    const { acceptedFiles } = this.state;

    const aux = [...acceptedFiles];
    aux.splice(index, 1);

    this.setState({ acceptedFiles: aux });
  }

  render() {
    const { handleSubmit, user } = this.props;
    const { isNew, loading, users, process, loadingUsers, processInfo, acceptedFiles, rejectedFiles } = this.state;

    if(loading) return <SpinLoading />;

    const title = isNew ? 'Adicionar' : 'Editar';

    const buttons = [{
      type: 'secondary',
      icon: 'close',
      text: 'Cancelar',
      onClick: this.handleOnCancel
    }];

    if((!processInfo || (processInfo && !processInfo.archived)) && user?.type !== 3) {
      buttons.unshift({
        type: 'primary',
        icon: 'save',
        text: 'Gravar',
        onClick: handleSubmit((values) => this.onSubmit(values))
      });
    }

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Tarefas'
          breadcrumbs={[title]}
          buttons={buttons}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={SelectInput}
                  name={'process'}
                  label={'Processo *'}
                  placeholder={'Escolha o processo'}
                  type='select'
                  data={process}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  execAfterChange={this.loadUsers}
                  disabled={user?.type === 3}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                {
                  loadingUsers ?
                  <PriceSpinLoading />
                  :
                  <Field
                    component={SelectInput}
                    name={'recipient'}
                    label={'Destinatário *'}
                    placeholder={'Escolha o utilizador'}
                    type='select'
                    data={users}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    disabled={users?.length === 0 || user?.type === 3}
                  />
                }
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'state'}
                  label={'Estado *'}
                  placeholder={'Escolha o estado'}
                  type='select'
                  data={TasksStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  disabled={user?.type === 3}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={DateInput}
                  name='due_date'
                  label={'Due date'}
                  disabled={user?.type === 3}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextAreaInput}
                  name={'description'}
                  type='text'
                  label={'Descrição *'}
                  placeholder={'Insira uma descrição'}
                  minRows={10}
                  disabled={user?.type === 3}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <FilesTitle>Ficheiros</FilesTitle>
                {
                  user?.type !== 3 &&
                  <Field 
                    name='files' 
                    component={Dropzone}
                    handleAcceptedFiles={(files) => this.setState({ acceptedFiles: [ ...acceptedFiles, ...files ] })}
                    handleRejectedFiles={(errors) => this.setState({ rejectedFiles: [ ...rejectedFiles, ...errors ] })}
                    isFirst
                  />
                }
              </Col>
            </Row>
            {
              rejectedFiles?.length > 0 &&
              <Row gutter={24} style={{ marginTop: '10px' }}>
                <Col xs={24}>
                  <RejectedFiles>
                    {
                      rejectedFiles.map((error, index) =>
                        <RejectedAlert
                          key={index}
                          description={error}
                          type='error'
                          closable
                        />
                      )
                    }
                  </RejectedFiles>
                </Col>
              </Row>
            }
            {
              user?.type === 3 && acceptedFiles.length === 0 &&
              <NoFiles>Não existem ficheiros anexados</NoFiles>
            }
            {
              acceptedFiles.length > 0 &&
              <Row gutter={24} style={{ marginTop: '10px' }}>
                <Col xs={24}>
                  <FilesSelected>
                    {
                      acceptedFiles.map((file, index) => {
                        return (
                          <File key={index}>
                            <FilenameColumn hasLink={!!file._id} onClick={() => file._id ? GetFile(file._id) : null}>
                              <Filename>
                                {file.filename || file.blob?.name || ''}
                              </Filename>
                            </FilenameColumn>
                            {
                              user?.type !== 3 &&
                              <ReactSVG 
                                src={`${apiUrl}close_circle.svg`} 
                                className='icon' 
                                onClick={() => this.removeFile(index)} 
                              />
                            }
                          </File>
                        );
                      })
                    }
                  </FilesSelected>
                </Col>
              </Row>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageTasksPage = reduxForm({
  form: 'manage_tasks_form',
  validate: validations
})(ManageTasksPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageTasksPage));
