import client from '../config/AxiosConfig';

export const GetCodeList = async (currentPage, limit, filters) => await client.get(`/promo_codes/${currentPage}/${limit}?filter=${filters}`);

export const GetCode = async (id) => await client.get(`/promo_codes/${id}`);

export const CreateCode = async (data) => await client.post(`/promo_codes`, data);

export const UpdateCode = async (id, data) => await client.put(`/promo_codes/${id}`, data);

export const DeleteCode = async (id) => await client.delete(`/promo_codes/${id}`);

export const TogglePromoCode = async (id, data) => await client.put(`/promo_codes/${id}/toggle`, data);