import client from '../config/AxiosConfig';

export const GetBlogBanner = async (id) => await client.get(`/blog/banner/${id}`);

export const UpdateBlogBanner = async (id, data) => await client.put(`/blog/banner/${id}`, data);

export const GetCategories = async (currentPage, limit, filters) => await client.get(`/blog/categories/${currentPage}/${limit}?filter=${filters}`);

export const GetCategory = async (id) => await client.get(`/blog/categories/${id}`);

export const CreateCategory = async (data) => await client.post(`/blog/categories`, data);

export const UpdateCategory = async (id, data) => await client.put(`/blog/categories/${id}`, data);

export const DeleteCategory = async (id) => await client.delete(`/blog/categories/${id}`);

export const GetCategoriesList = async () => await client.get(`/blog/categories`);

export const TogglePosts = async (identifier, data) => await client.put(`/blog/${identifier}/toggle`, data);

export const ToggleFeaturedPosts = async (identifier, data) => await client.put(`/blog/${identifier}/toggle-featured`, data);

export const CreatePost = async (data) => await client.post(`/blog`, data);

export const UpdatePost = async (identifier, data) => await client.put(`/blog/${identifier}`, data);

export const DeletePost = async (identifier) => await client.delete(`/blog/${identifier}`);

export const GetPost = async (identifier) => await client.get(`/blog/${identifier}`);

export const GetPosts = async (currentPage, limit, filters) => await client.get(`/blog/${currentPage}/${limit}?filter=${filters}`);