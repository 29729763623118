import styled from 'styled-components';

export const FilesTitle = styled.div`
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 22px;
  color: #495057;
`;

export const NoFiles = styled.div`
  font-size: 13px;
  color: #495057;
`;
