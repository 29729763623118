import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../styles/BasicStyles';
import { sortServices } from '../../../infra/utils/Sort';
import { getYears } from '../../../infra/utils/Date';
import { Currencies } from '../../../infra/utils/Currencies';
import { ProcessPaymentStates } from '../../../infra/utils/ProcessStates';
import React, { Component } from 'react';
import SelectInput from '../../../components/generic/inputs/SelectInput';

class ServiceStatisticsFilters extends Component {
  state = { 
    service: '',
    responsible: '',
    year: '',
    state: '',
    currency: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { services, responsibles } = this.props;
    const { service, responsible, year, state, currency } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <SelectInput
              label='Serviço'
              allowClear
              placeholder='Escolher serviço'
              data={sortServices(services)}
              dataKey='_id'
              dataLabel='title'
              translatable={true}
              input={{
                value: service,
                onChange: value => this.onInputChange('service', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Responsável'
              allowClear
              placeholder='Escolher responsável'
              data={responsibles}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: responsible,
                onChange: value => this.onInputChange('responsible', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Ano'
              allowClear
              placeholder='Escolher ano'
              data={getYears()}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: year,
                onChange: value => this.onInputChange('year', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Moeda'
              allowClear
              placeholder='Escolher moeda'
              data={Currencies}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: currency,
                onChange: value => this.onInputChange('currency', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Estado de Pagamento'
              allowClear
              placeholder='Escolher estado'
              data={ProcessPaymentStates.filter(elem => elem._id !== 5)}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: state,
                onChange: value => this.onInputChange('state', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default ServiceStatisticsFilters;
