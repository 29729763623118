export const PaymentMethods = [
  { _id: 1, name: 'STRIPE (cartão de crédito)', allowInPayments: false },
  { _id: 2, name: 'Transferência Bancária', allowInPayments: true },
  { _id: 3, name: 'Boletim Postal', allowInPayments: true },
  { _id: 4, name: 'Multibanco', allowInPayments: false }
];

export const getPaymentMethod = (value) => {
  if(Number.isInteger(value) && value > 0 && PaymentMethods[value - 1]) return PaymentMethods[value - 1].name;
  return null;
};