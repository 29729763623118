import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName,
  GraphTitle
} from './GraphStyles';

const BaseGraph = ({ title, data, addedLabels }) => {
  return (
    <GraphContainer>
      <GraphContent>
        <ResponsiveContainer width={280} height={280}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data.filter(elem => elem.value > 0)}
              outerRadius={100}>
              {data
                .filter(elem => elem.value > 0)
                .map((entry, index) => (
                  <Cell key={index} fill={entry.bgColor} />
                ))}
            </Pie>
            <Tooltip formatter={(value, name) => value} />
          </PieChart>
        </ResponsiveContainer>
      </GraphContent>
      <LabelsContainer>
        { title && <GraphTitle>{title}</GraphTitle> }
        {[...addedLabels, ...data].map((label, index) => (
          <Label key={index}>
            <LabelColor color={label.bgColor} />
            <LabelName>
              {label.name} - {label.value}
            </LabelName>
          </Label>
        ))}
      </LabelsContainer>
    </GraphContainer>
  );
};

export default BaseGraph;