import { Table } from "antd";
import { device } from "../../../styles/Responsive";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  margin: 0;
  font-weight: 100;

  & .ant-pagination {
    width: 100%;
    text-align: right;

    @media ${device.tablet} {
      text-align: left;
    }

    // Total results text
    & .ant-pagination-total-text {
      float: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #343A40;
      padding-top: 9px;
      margin-right: 30px;

      @media ${device.tablet} {
        float: unset;
        text-align: left;
        display: block;
        margin-right: 0px;
        margin-bottom: 18px;
        padding-top: 0px;
        height: auto;
      }
    }

    // Dropdown (page size)
    & .ant-pagination-options {
      float: left;
      display: inline-block !important;

      @media ${device.tablet} {
        float: unset;
        text-align: left;
        display: block !important;
        margin-top: 22px;
      }
    }

    // Dropdown (page size)
    & .ant-select-selection--single {
      height: 38px;
      padding-top: 7px;
      padding-left: 17px;
      padding-right: 17px;
      color: #343A40;
      border: 1px solid #D1D5DB;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }

    // Dropdown selected value
    & .ant-select-selection-selected-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343A40;
    }

    // Previous arrow
    & .ant-pagination-prev a {
      padding-right: 16px;
      margin-top: 6px;
    } 
    
    // Next arrow
    & .ant-pagination-next a {
      padding-left: 16px;
      margin-top: 6px;
    }

    // Page number
    & .ant-pagination-item a { 
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #6B7280;
      padding: 9px 16px;
    }

    // Active page number
    & .ant-pagination-item-active {
      border: none;
      position: relative;
      background-color: transparent;
      
      &:before {
        content: "";
        position: absolute;
        height: 2px;
        width: 39px;
        background-color: red;
        right: 0;
      }
    }

    // Active page number
    & .ant-pagination-item-active a {
      margin-top: 1px;
    }

    // "..." jump element (previous and next)
    & .ant-pagination-jump-prev .ant-pagination-item-container, & .ant-pagination-jump-next .ant-pagination-item-container {
      margin-top: 7px;
    }
  }

  // Dropdown (page size) options 
  & .ant-pagination ul {
    text-align: center;
  }

  table {
    background-color: #fff;
    tr {
      th {
        color: ${(p) =>
          p.secondary ? "#565656" : p.theme.primaryBackgroundColor};
        background-color: ${(p) =>
          p.secondary
            ? p.theme.secondaryBackgroundColor
            : p.theme.secondaryBackgroundColor} !important;
      }
    }

    td {
      max-width: 400px;
      vertical-align: ${({ alignContentTop }) =>
        alignContentTop ? "top" : "inherit"};
    }
  }

  .ant-table-tbody > tr {
    cursor: ${(p) => (p.hasOnPressRow ? "pointer" : "default")};
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: ${(p) => (p.hasOnPressRow ? "#FFE3E3" : "transparent")} !important;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: 0px;
    display: inline-block;
    width: 100%;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: 0px;
    display: inline-block;
    width: 100%;
  }

  .ant-table-small {
    border-radius: 8px;
  }
`;
