import { auth_token_key } from '../config/LocalStorageKeys';

const GetFile = (id, callback) => {
  const authToken = localStorage.getItem(auth_token_key);
  let filename = id;

  fetch(`${process.env.REACT_APP_API}/files/document/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
  .then((resp) => {
    const headerFile = resp?.headers?.get('Content-Disposition');
    if(headerFile) {
      const parts = headerFile.split('=');
      filename = parts[1];
    }

    return resp.blob();
  })
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    if(typeof callback === 'function') callback(id);
  })
  .catch((e) => console.log(e));
}

export default GetFile;