import styled, { css } from "styled-components";
import { Button, Dropdown, Menu } from "antd";
import { transitionsDelay } from '../../../styles/BasicStyles';

export const StyledButton = styled(Button)`
  &.ant-btn {
    height: auto;
  }

  &.ant-btn {
    & i {
      padding-right: ${({ hastext }) => hastext === 'true' ? '10px' : '0px'};
      height: ${({ size }) => size === 'small' ? '14px' : '16px'};
    }
  }

  ${({ type }) => 
    type === 'secondary' ?
      css`
        font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
        line-height: ${({ size }) => size === 'small' ? '14px' : '16px'};
        padding: ${({ size }) => size === 'small' ? '9px 15px' : '9px 25px'};
        color: #E03131;
        background-color: #F8F9FA;
        border-width: 1px;
        border-style: solid;
        border-color: #E03131;
        border-radius: 8px;
        transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;

        & svg path {
          width: ${({ size }) => size === 'small' ? '14px' : '16px'};
          height: ${({ size }) => size === 'small' ? '14px' : '16px'};
          stroke: #E03131;
          transition: stroke ${transitionsDelay} linear;
        }

        &:hover {
          color: #ffffff;
          background-color: #E03131;
          border-color: #E03131;

          & svg path {
            stroke: #ffffff;  
          }
        }
      `
    :
    type === 'grey' ? 
      css`
        font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
        line-height: ${({ size }) => size === 'small' ? '14px' : '16px'};
        padding: ${({ size }) => size === 'small' ? '9px 15px' : '9px 25px'};
        color: #868E96;
        background-color: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-color: #868E96;
        border-radius: 8px;
        transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;

        & svg path {
          width: ${({ size }) => size === 'small' ? '14px' : '16px'};
          height: ${({ size }) => size === 'small' ? '14px' : '16px'};
          stroke: #868E96;
          transition: stroke ${transitionsDelay} linear;
        }
      
        &:hover {
          color: #ffffff;
          background-color: #868E96;
          border-color: #868E96;

          & svg path {
            stroke: #ffffff;  
          }
        }
      `
    :
    type === 'black' ? 
      css`
        font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
        line-height: ${({ size }) => size === 'small' ? '14px' : '16px'};
        padding: ${({ size }) => size === 'small' ? '9px 15px' : '9px 25px'};
        color: #ffffff;
        background-color: #495057;
        border-width: 1px;
        border-style: solid;
        border-color: #495057;
        border-radius: 8px;
        transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;

        & svg path {
          width: ${({ size }) => size === 'small' ? '14px' : '16px'};
          height: ${({ size }) => size === 'small' ? '14px' : '16px'};
          stroke: #ffffff;
          transition: stroke ${transitionsDelay} linear;
        }

        &:hover {
          color: #495057;
          background-color: #ffffff;
          border-color: #495057;

          & svg path {
            stroke: #495057;
          }
        }
      `
    :
    type === 'clear' ? 
      css`
        font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
        line-height: ${({ size }) => size === 'small' ? '14px' : '16px'};
        padding: ${({ size }) => size === 'small' ? '9px 15px' : '9px 25px'};
        color: #868E96;
        background-color: #F1F3F5;
        border-width: 1px;
        border-style: solid;
        border-color: #F1F3F5;
        border-radius: 30px;
        transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;

        & svg path {
          width: ${({ size }) => size === 'small' ? '14px' : '16px'};
          height: ${({ size }) => size === 'small' ? '14px' : '16px'};
          stroke: #868E96;
        }
        
        &:hover {
          color: #868E96;
          background-color: #ffffff;
          border-color: #F1F3F5;
        }
      `
    :
      css`
        font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
        line-height: ${({ size }) => size === 'small' ? '14px' : '16px'};
        padding: ${({ size }) => size === 'small' ? '9px 15px' : '9px 25px'};
        color: #ffffff;
        background-color: #F03E3E;
        border-width: 1px;
        border-style: solid;
        border-color: #E03131;
        border-radius: 8px;
        transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;

        & svg path {
          width: ${({ size }) => size === 'small' ? '14px' : '16px'};
          height: ${({ size }) => size === 'small' ? '14px' : '16px'};
          stroke: #ffffff;
          transition: stroke ${transitionsDelay} linear;
        }

        &:hover {
          color: #E03131;
          background-color: #ffffff;
          border-color: #E03131;

          & svg path {
            stroke: #E03131;
          }
        }
      `
  }

  ${({ disabled }) => disabled &&
    css`
      pointer-events: none;

      & svg path {
        stroke: rgba(0, 0, 0, 0.25);
      }
    `
  }

  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: ${({ size }) => size === 'small' ? '35px' : '28px'};
  }

  /* &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;
    }
  } */
`;

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({ position }) =>
    position
      ? position === 1
        ? "70px"
        : `${(position - 1) * 55 + 70}px`
      : "15px"};

  &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;
    }
  }
`;

export const NormalButton = styled(Button)`
  width: 100%;

  & svg path {
    ${({ icontype }) => icontype === 'fill' && `fill: #ADB5BD`};
    ${({ icontype }) => icontype === 'stroke' && `stroke: #ADB5BD`};
  }
`;

export const OptionButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  
  & .icon {
    padding-right: 10px;
    height: 16px;

    & div {
      width: 16px;
      height: 16px;
    }

    & svg {
      width: 16px;
      height: 16px;

      & path {
        ${({ optionIconType }) => optionIconType === 'fill' && `fill: #ADB5BD`};
        ${({ optionIconType }) => optionIconType === 'stroke' && `stroke: #ADB5BD`};
      }
    }
  }
`;

export const DropdownButton = styled(Dropdown)`
  width: auto;

  &.ant-btn {
    border: 0px;  
    background-color: transparent;
    box-shadow: none;
  }
`;

export const DropdownMenu = styled(Menu)`
  width: auto;
  height: auto;

  &.ant-dropdown-menu {
    border-radius: 8px;
    padding: 0px;
  }

  & .ant-dropdown-menu-item {
    padding: 8px 16px;
    color: #495057;
  }
`;

export const CustomIconWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    padding-right: ${({ hasText }) => hasText === 'true' ? '10px' : '0px'};
    height: ${({ size }) => size === 'small' ? '14px' : '16px'};

    & div, & svg {
      height: ${({ size }) => size === 'small' ? '14px' : '16px'};
    }
  }
`;

export const OptionWrapper = styled.div`
  width: 100%;
`;
