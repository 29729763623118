/*eslint no-useless-escape: "off"*/
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { InputLabelDiv } from './InputStyles';
import { transitionsDelay } from '../../../styles/BasicStyles';
import React from 'react';
import styled from 'styled-components';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const emptyDraft = /^<p><\/p>/;

const sanitizeHtml = require('sanitize-html');

const sanitizeOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'strong',
    'em',
    'inst',
    'del',
    'ul',
    'li',
    'ol',
    'span',
    'a',
    'u',
    'ins'
  ],
  allowedAttributes: {
    p: ['style'],
    span: ['style'],
    a: ['href', 'target']
  },
  allowedStyles: {
    '*': {
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'color': [/rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/]
    }
  }
};

const urlValidations = /\b(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])(?![^<>]*>|[^"]*?<\/a)/gim;

const DraftWrapper = styled.div`
  .wrapper-class {
    position: relative;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid ${({theme}) => theme.inputBorderColor};
    border-radius: 4px;
    transition: all ${transitionsDelay};

    &:hover,
    &:active {
      border: 1px solid ${({theme}) => theme.inputFocusColor};
      border-color: ${({theme}) => theme.inputFocusColor};
      outline: 0;
      box-shadow: 0 0 0 2px rgba(143, 121, 70, 0.2);
      border-right-width: 1px;
    }
  }

  .rdw-image-left {
    .rdw-image-imagewrapper {
      text-align: left;
    }
  }

  .rdw-image-center {
    .rdw-image-imagewrapper {
      text-align: center;
    }
  }

  .rdw-image-right {
    .rdw-image-imagewrapper {
      text-align: right;
    }
  }

  .rdw-image-alignment-options-popup {
    bottom: 10px;
    font-size: 20px;
    line-height: 20px;
  }

  .rdw-image-alignment-option {
    height: auto;
    width: auto;
  }

  .editor-class {
    padding: 0 15px;
    min-height: 200px;

    &.disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
      opacity: 1;
    }
  }

  .wrapper-class {
    position: relative;
    width: 100%;
    display: inline-block;
    padding-top: ${({focus}) => (focus ? '60px' : '0px')};
  }

  .editor-class {
    width: 100%;
    ${p =>
      p.error
        ? `border: 1px solid ${p.theme.inputErrorColor};`
        : 'border-color: inherit;'}
    min-height: 200px;
  }

  .toolbar-class {
    position: ${({fixed}) => (fixed ? 'fixed' : 'absolute')};
    top: ${({fixed}) => (fixed ? '150px' : 0)};
    left: ${({fixed}) => (fixed ? 'inherit' : 0)};
    width: 'auto';
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0px;
    z-index: 9999;
  }

  .toolbar-class .rdw-option-wrapper {
    padding: 0px;
  }

  .toolbar-class .rdw-dropdown-wrapper {
    height: 20px;
  }

  img {
    max-width: 100%;
  }

  .public-DraftEditorPlaceholder-root {
    color: #bfbfbf;
  }
`;

class DraftInput extends React.Component {
  constructor(props) {
    super(props);

    let editorState = '';
    if (props && props.input && props.input.value) {
      const blocksFromHtml = htmlToDraft(props.input.value);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      editorState = EditorState.createWithContent(contentState);
    }
    this.state = {
      editorState,
      fixed: false,
      focus: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.langKey !== this.props.langKey) {
      let editorState = '';
      if (this.props && this.props.input && this.props.input.value) {
        const blocksFromHtml = htmlToDraft(this.props.input.value);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        editorState = EditorState.createWithContent(contentState);
      }
      this.setState({editorState});
    }
  }

  handleScroll = event => {
    const {fixedPosition = 130} = this.props;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > fixedPosition) {
      this.setState({fixed: true});
    } else {
      this.setState({fixed: false});
    }
  };

  handleBeforeOnChange = editorState => {
    const {input} = this.props;

    if (input) {
      let rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let urlMatches = rawHtml.match(urlValidations);
      if (urlMatches && urlMatches.length > 0) {
        urlMatches.map(
          result =>
            (rawHtml = rawHtml.replace(
              result,
              `<a href="${result}" target="_blank">${result}</a>`
            ))
        );
      }
      const cleanHTML = sanitizeHtml(rawHtml, sanitizeOptions);
      input.onChange(emptyDraft.test(cleanHTML) ? '' : cleanHTML);
    }
    this.setState({editorState});
  };

  render() {
    const {editorState, fixed, focus} = this.state;
    const {label, meta, placeholder, disabled, noLabel} = this.props;

    const {invalid, submitFailed} = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    return (
      <DraftWrapper error={showError} fixed={fixed} focus={focus}>
        {!noLabel && <InputLabelDiv>{label}</InputLabelDiv>}
        <Editor
          readOnly={disabled}
          toolbarOnFocus
          placeholder={placeholder}
          editorState={editorState}
          onEditorStateChange={es => this.handleBeforeOnChange(es)}
          wrapperClassName="wrapper-class"
          editorClassName={!disabled ? 'editor-class' : 'editor-class disabled'}
          toolbarClassName="toolbar-class"
          onFocus={() => this.setState({focus: true})}
          onBlur={() => this.setState({focus: false})}
          toolbar={{
            options: ['inline', 'blockType', 'textAlign', 'link', 'list', 'history', 'fontSize', 'colorPicker'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            },
            list: {
              options: ['unordered', 'ordered']
            },
            blockType: {
              options: ['Normal', 'H1', 'H2']
            },
            fontSize: {
              options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96]
            },
            colorPicker: {
              colors: ['rgb(220,41,27)', 'rgb(60,42,151)', 'rgb(250,224,23)', 'rgb(238,238,238)',
                'rgb(217,217,217)', 'rgb(62,62,62)', 'rgb(255,197,61)', 'rgb(0,0,0)', 'rgb(255,255,255)', 
                'rgb(160,217,17)', 'rgb(82,196,26)', 'rgb(19,194,194)', 'rgb(24,144,255)', 'rgb(47,84,235)', 'rgb(114,46,209)', 'rgb(235,47,150)'],
            }
          }}
        />
      </DraftWrapper>
    );
  }
}

export default DraftInput;
