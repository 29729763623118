import { PageContainer, PageTitle, HeaderContainer, HeaderTitle } from '../../../styles/BasicStyles';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { GetClientsList } from '../../../infra/requests/ClientsRequests';
import { GetClientStats } from '../../../infra/requests/StatisticsRequests';
import { GetAdminList } from '../../../infra/requests/UsersRequests';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { DescriptionWrapper } from './styles';
import React, { Component } from 'react';
import ClientStatisticsFilters from './Filters';
import Table from '../../../components/generic/table/Table';
import ClientDetail from './ClientDetail';

class ClientStatisticsPage extends Component {
  state = {
    pageSize: 15,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (value) => value,
        width: '30%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (value) => value,
        width: '30%'
      },
      {
        title: 'Valor total',
        key: 'totalValue',
        render: (data) => this.renderTotalValue(data),
        width: '30%',
      },
      {
        title: '',
        key: 'expand',
        render: (data) => this.renderExpandIcon(data)
      }
    ],
    rows: [],
    total: 0,
    expanded: [],
    services: [],
    clients: [],
    responsibles: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderTotalValue = (data) => {
    if(data.totalValueChf > 0 && data.totalValueEur > 0) return `${data.totalValueChf}CHF + ${data.totalValueEur}€`;
    else if(data.totalValueChf > 0) return `${data.totalValueChf}CHF`;
    else if(data.totalValueEur > 0) return `${data.totalValueEur}€`;
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters } = this.state;
      const { user, history } = this.props;

      if(!user.super_admin) return history.push('/');

      const result = await GetClientStats(currentPage, pageSize, filters);
      const services = await GetServicesList();
      const clients = await GetClientsList();
      const responsibles = await GetAdminList();

      this.setState({
        rows: result.data?.items || [],
        total: result.data?.total || 0,
        services: services?.data || [],
        clients: clients?.data || [],
        responsibles: responsibles?.data || [],
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  onExpandRow = (_, record) => {
    const { expanded } = this.state;

    const index = expanded.findIndex(elem => elem._id === record._id);

    const aux = [...expanded];

    // If the current record already exists in the array, we have to invert the "expanded" status of that element
    if(index >= 0) {
      aux[index].expanded = !aux[index].expanded;
    }
    // If not, add to the array and mark it as "expanded"
    else {
      aux.push({ _id: record._id, expanded: true });
    }

    this.setState({ expanded: aux });
  };

  renderExpandIcon = (data) => {
    const { expanded } = this.state;

    const elem = expanded.find(elem => elem._id === data._id);

    return <Icon type={elem?.expanded ? 'up' : 'down'} />;
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, services, clients, filters, responsibles } = this.state;

    let hasYear = false;
    if(filters) {
      const parsedJson = JSON.parse(filters);
      if(parsedJson.year) hasYear = true;
    }

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Estatísticas - Clientes</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DescriptionWrapper>
            <div>Nesta área pode visualizar um conjunto de resultados sobre os clientes.</div>
            <div>Por cada cliente podemos ver:</div>
            <div>- Total gasto por serviço/pagamento por ano;</div>
            <div>- Total gasto por ano;</div>
            <div>- Total gasto global;</div>
          </DescriptionWrapper>
          <ClientStatisticsFilters 
            queryChange={this.handleFilterChange} 
            clients={clients}
            services={services}
            responsibles={responsibles}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem registos a apresentar!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={(data) => <ClientDetail data={data} filterByYear={hasYear} />}
            expandRowByClick={true}
            expandIconAsCell={false}
            onExpand={(expanded, record) => this.onExpandRow(expanded, record)}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ClientStatisticsPage);
