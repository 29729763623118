import client from '../config/AxiosConfig';

export const GetHowItWorks = async (id) => await client.get(`/how_it_works/${id}`);

export const UpdateHowItWorks = async (id, data) => await client.put(`/how_it_works/${id}`, data);

export const GetBenefits = async (currentPage, limit, filters) => await client.get(`/how_it_works/benefits/${currentPage}/${limit}?filter=${filters}`);

export const GetBenefit = async (id) => await client.get(`/how_it_works/benefits/${id}`);

export const UpdateBenefit = async (id, data) => await client.put(`/how_it_works/benefits/${id}`, data);

export const GetProcessTypes = async (currentPage, limit, filters) => await client.get(`/how_it_works/process_types/${currentPage}/${limit}?filter=${filters}`);

export const GetProcessType = async (id) => await client.get(`/how_it_works/process_types/${id}`);

export const UpdateProcessType = async (id, data) => await client.put(`/how_it_works/process_types/${id}`, data);