import { Tabs, Spin, Form as AntForm } from 'antd';
import { StyledButton } from '../components/generic/buttons/ButtonsStyles';
import { MenuBorderColor, AlertColour } from './Colours';
import { headerSize } from './Variables';
import { device } from './Responsive';
import styled, { createGlobalStyle } from 'styled-components';
const TabPane = Tabs.TabPane;

export const transitionsDelay = '0.3s';

export const BasicStyles = createGlobalStyle`
  body {
    font-family: 'Roboto',  'sans-serif' !important;
    overflow-x: hidden;
  }

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

  .ant-popover{
    z-index: 9999999 !important
  }

  .ant-menu-item {
    padding: 0px 10px !important;
  }

  .ant-notification {
    z-index: 9999999;
  }

  .ant-modal-mask {
    z-index: 999999;
  }

  .ant-modal-wrap {
    z-index: 9999999;
  }

  .ant-menu-submenu-popup {
    z-index: 9999999;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px;
  }

  .ant-menu-submenu {
    &.ant-menu-submenu-active {
      color: #ED1D24 !important;
    }

    & .ant-menu-sub.ant-menu-inline {
      padding: 0px 10px 0px 15px;
    }

    & .ant-menu-submenu-title {
      padding-left: 10px !important;
    }

    & .ant-menu-item {
      height: 35px !important;
      line-height: 35px !important;

      & span {
        font-size: 13px;
      }
    }
  }

  .anticon.ant-notification-notice-icon-success {
    color: green;
  }

  .ant-calendar-picker-container {
    z-index: 99999999;
  }

  .ant-calendar-header {
    border-bottom: none;
  }
  
  .ant-select-dropdown {
    z-index: 99999999;
  }

  .ant-select-dropdown-menu-item-selected {
    color: #C92A2A;
    font-weight: 500;
    background-color: #FFE3E3;
  }

  .ant-tooltip {
    z-index: 9999999;
  }

  .ant-input {
    border-radius: 8px;
    height: 40px;
  }

  .ant-calendar-footer {
    line-height: 0px;
    visibility: hidden;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background: #E03131;
    color: white;
  }
  
  .ant-tooltip-inner {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 15px 10px;
  }

  [ant-click-animating-without-extra-node='true']::after{display:none;}

  .ant-notification {
    z-index: 9999999999;
  }

  .ant-menu-submenu .ant-menu-item span, .subMenuGroup {
    font-size: 12px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  top: ${headerSize};
  padding: 14px 33px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  z-index: 99999;
  border-bottom: 1px solid ${MenuBorderColor};
  align-items: center;

  @media print {
    top: 0px !important;
    padding: 10px !important;
  }

  @media ${device.tabletS} {
    flex-wrap: wrap;
  }
`;

export const HeaderTitle = styled.div`
  width: calc(100% - ${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')});
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 20px;

  @media print {
    width: 100% !important;
  }

  @media ${device.tabletS} {
    order: 2;
    padding-top: 15px;
    padding-right: 0px;
    width: 100%;
  }
`;

export const HeaderButtonsContainer = styled.div`
  width: calc(${({ buttons }) => (buttons ? buttons + ' * 200px' : '0px')});
  float: right;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }

  @media print {
    &.no-print, &.no-print * {
      display: none !important;
    }
  }

  @media ${device.tabletS} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    order: 1;
    width: 100%;

    & button:first-child {
      margin-left: 0px;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    & button {
      margin-bottom: 6px;
      margin-left: 0px !important;
    }

    & button:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  font-weight: 700;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? '0px' : '5px'};
`;

export const SectionTitle = styled.div`
  display: block;
  width: calc(100% + 30px);
  position: relative;
  font-size: 18px;
  color: white;
  background: ${({ theme }) => theme.primaryColor};
  text-align: left;
  margin: 30px -15px 10px -15px;
  padding: 10px 27px;
`;

export const PageContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 14px 33px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  margin-bottom: 30px;

  @media print {
    padding: 10px !important;
  }
`;

export const TableButton = styled.div`
  display: inline-block;
  z-index: 999;
  padding: 5px;
  margin: 0 10px;

  &:hover {
    color: ${({ theme, primary, error }) =>
      primary
        ? theme.primaryColor
        : error
        ? theme.inputErrorColor
        : theme.thirdColor};
    font-weight: 700;
  }
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const PriceSpinLoading = styled(Spin)`
  height: 60px;
  display: flex;
  align-items: flex-end;
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: ${({ singleColumn }) => (singleColumn ? '600px' : '100%')};
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 14px 33px;
  line-height: 1;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`;

export const DefaultLanguageTab = styled.div`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 12px 16px;
  margin-top: -2px;
  margin-bottom: 16px;
`;

export const DefaultSectionOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.inputFocusColor};
  border-radius: 4px;
  cursor: pointer;
  font-size: 28px;
  color: ${({ theme }) => theme.inputFocusColor};
  font-weight: bold;
  padding: 50px 15px;
  flex-direction: column;

  img {
    height: 50px;
  }

  span {
    display: block;
    margin-top: 10px;
    color: ${({ theme }) => theme.inputFocusColor};
    font-size: 18px;
    height: 36px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    box-shadow: 2px 2px 6px #ccc;
  }
`;

export const StyledPopContainer = styled.div`
  font-size: 10px;
  border: 1px solid ${MenuBorderColor};
  border-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 3px;

  &:hover {
    color: ${AlertColour};
    border: 1px solid ${AlertColour};
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 20px;
`;

export const Separator = styled.div`
  border: 1px solid #E9ECEF;
  margin: 25px 0px;
  width: 100%;
`;

export const InputNote = styled.div`
  padding: 5px 0px;
  font-size: 13px;

  & span {
    font-weight: bold;
    color: #ED1D24;
  }
`;

export const ChapterOrderName = styled.div`
  background-color: #faf9f9;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const OrderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const FieldTitle = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;

  & span {
    font-weight: bold;
  }
`;

export const TabsContainer = styled(Tabs)`
  & .ant-tabs-bar {
    margin-bottom: 20px;
  }
  
  & .ant-tabs-nav-scroll {
    
  }

  & .ant-tabs-nav-container {
    font-size: 16px;
  }

  & .ant-tabs-nav .ant-tabs-tab {
    padding-top: 0px;
  }
`;

export const Tab = styled(TabPane)``;
