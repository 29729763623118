import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetAddressList,
  DeleteAddress,
  ToggleAddressFavorite
} from '../../infra/requests/AddressRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import AddressFilters from './AddressFilters';
import { connect } from 'react-redux';
import { StateLabel } from './AddressStyles';
import { GetAdminList } from '../../infra/requests/UsersRequests';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class AddressPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: (value) => value,
        width: '15%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (value) => value,
        width: '12%',
      },
      {
        title: 'Contacto',
        dataIndex: 'contact',
        render: (value) => value,
        width: '12%',
      },
      {
        title: 'Morada',
        key: 'address',
        render: (data) => <div dangerouslySetInnerHTML={{ __html: this.renderAddress(data) }} />,
        width: '20%',
      },
      {
        title: 'Favorito',
        dataIndex: 'favorite',
        render: (value) => this.renderFavorite(value),
        width: '10%',
      },
      {
        title: 'Morada Principal Reformas',
        dataIndex: 'main_address',
        render: (value) => this.renderFavorite(value),
        width: '12%',
      },
      {
        title: 'Administrador',
        dataIndex: 'user',
        render: (value) => value?.name || '-',
        width: '12%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    admins: []
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/address/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/address/${data._id}`),
            label: 'Editar' 
          },
          {
            popConfirmtitle: data.favorite ? 'Quer remover esta Morada dos favoritos?' : 'Quer adicionar esta Morada aos favoritos?',
            onClick: () => this.toggleFavorite(data),
            label: data.favorite ? ' Remover dos Favoritos' : ' Adicionar aos Favoritos'
          },
          {
            popConfirmtitle: 'Tem a certeza que quer remover esta Morada?',
            onClick: () => this.removeAdress(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  };

  removeAdress = async (data) => {
    try {
      await DeleteAddress(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetAddressList(currentPage, pageSize, filters);
      const admins = await GetAdminList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        admins: admins?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  toggleFavorite = async (record) => {
    this.setState({ loading: true });
    await ToggleAddressFavorite(record._id, { favorite: !record.favorite });
    await this.updateDataTable();
  };

  renderFavorite = (value) => {
    if(value) {
      return (
        <StateLabel bgColor='#389e0d'>Sim</StateLabel>
      );
    }
    return (
      <StateLabel bgColor='#cf1322'>Não</StateLabel>
    );
  };

  renderAddress = (data) => {
    let address = '';

    if(data.address) address = `${data.address}<br/>`;
    
    if(data.cod_postal) address = `${address}${data.cod_postal}`;

    if(data.city && data.cod_postal) address = `${address}, ${data.city}<br/>`;
    else if(data.city) address = `${address}${data.city}<br/>`;

    if(data.country) address = `${address}${data.country}`;

    return address;
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, admins } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Moradas</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/address/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <AddressFilters queryChange={this.handleFilterChange} admins={admins} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Moradas inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(AddressPage);