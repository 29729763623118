import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import {
  GetClient,
  UpdateClient,
  CreateClient
} from '../../infra/requests/ClientsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import {
  NewClientText,
  UserDeleted,
  UserRef,
  UserDate
} from './ClientsStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import DateInput from '../../components/generic/inputs/DateInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import { CivilStates, getCivilState } from '../../infra/utils/CivilStates';
import { Genders } from '../../infra/utils/Genders';
import { ProfessionalStates } from '../../infra/utils/ProfessionalStates';
import { Languages } from '../../infra/utils/Languages';
import { Countries } from '../../infra/utils/Countries';
import moment from 'moment-timezone';
import ProcessList from './ProcessList';
import AutomaticEmails from './AutomaticEmails';

const validations = FormValidator.make({
  language: 'required',
  first_name: 'required',
  last_name: 'required',
  email: 'required|email'
});

class ManageClientsPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    showCivilStateDate: false,
    labelCivilState: '',
    userInfo: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetClient(params.id);
      dispatch(
        initialize('manage_clients_form', {
          ...result.data,
        })
      );

      if(result?.data?.civil_state) {
        this.checkCivilState(result?.data?.civil_state);
      }

      this.setState({ 
        loading: false,
        userInfo: result?.data
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateClient(params.id, payload) : await CreateClient(payload);

      if(success) return history.push('/clients');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/clients');
  };

  checkCivilState = (value) => {
    value = parseInt(value);
    const civilState = getCivilState(value);
    const labelCivilState = civilState ? `${civilState.name} desde` : '';

    this.setState({
      showCivilStateDate: civilState?.hasDate || false,
      labelCivilState,
    });
  };

  renderDetails = () => {
    const { showCivilStateDate, labelCivilState } = this.state;

    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={SelectInput}
              name={'language'}
              label={'Idioma *'}
              placeholder={'Escolha o idioma'}
              type='select'
              data={Languages}
              dataKey={'value'}
              dataLabel={'name'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'first_name'}
              type='text'
              label={'Nome *'}
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'last_name'}
              type='text'
              label={'Apelido *'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'email'}
              type='email'
              label={'Email *'}
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'contact'}
              type='text'
              label={'Contacto'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={DateInput}
              name='birthday'
              label='Data de Nascimento'
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'avs_number'}
              type='text'
              label={'Nº AVS/AHV'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={SelectInput}
              name={'professional_state'}
              label={'Situação Profissional'}
              placeholder={'Escolha o estado'}
              type='select'
              data={ProfessionalStates}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={SelectInput}
              name={'gender'}
              label={'Género'}
              placeholder={'Escolha o género'}
              type='select'
              data={Genders}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={SelectInput}
              name={'civil_state'}
              label={'Estado Civil'}
              placeholder={'Escolha o estado civil'}
              type='select'
              data={CivilStates}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
              execAfterChange={this.checkCivilState}
            />
          </Col>
          {showCivilStateDate && (
            <Col md={12} xs={24}>
              <Field
                component={DateInput}
                name='civil_state_date'
                label={labelCivilState}
              />
            </Col>
          )}
        </Row>
        <Separator />
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'address'}
              type='text'
              label={'Morada'}
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'cod_postal'}
              type='text'
              label={'Cód. Postal'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Field
              component={TextInput}
              name={'city'}
              type='text'
              label={'Cidade'}
            />
          </Col>
          <Col md={12} xs={24}>
            <Field
              component={SelectInput}
              name={'country'}
              label={'País'}
              placeholder={'Escolha um país'}
              type='select'
              data={Countries}
              dataKey={'code'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, userInfo } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    let buttons = [
      {
        type: 'primary',
        icon: 'save',
        text: 'Gravar',
        onClick: handleSubmit((values) => this.onSubmit(values)),
        disabled: pristine,
      },
      {
        type: 'secondary',
        icon: 'close',
        text: 'Cancelar',
        onClick: this.handleOnCancel,
      },
    ];
    
    if(userInfo?.deleted) {
      buttons = [
        {
          type: 'secondary',
          icon: 'close',
          text: 'Cancelar',
          onClick: this.handleOnCancel,
        }
      ];
    }

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Clientes'
          breadcrumbs={[title]}
          buttons={buttons}
        />
        <FormContainer>
          {
            isNew ?
            <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
              {
                isNew &&
                <Row gutter={24} style={{marginBottom: '20px'}}>
                  <Col xs={24}>
                    <NewClientText>
                      <span>Nota:</span> Ao criar um novo cliente, o sistema irá enviar automaticamente um email para o mesmo com os seus dados de acesso.
                    </NewClientText>
                  </Col>
                </Row>
              }
              { this.renderDetails() }
            </BaseForm>
            :
            <React.Fragment>
              <UserRef>
                Ref.:&nbsp;<span>{userInfo?.ref}</span>
              </UserRef>
              {
                userInfo?.createdAt &&
                <UserDate>
                  Data de criação:&nbsp;<span>{`${moment(userInfo?.createdAt).format('DD/MM/YYYY, HH:mm')}h`}</span>
                </UserDate>
              }
              <TabsContainer defaultActiveKey='details'>
                <Tab
                  tab='Detalhes'
                  key='details'
                >
                  {
                    userInfo?.deleted ?
                    <UserDeleted>
                      O cliente pediu a eliminação da sua conta na data <span>{moment(userInfo?.deleted_date).format('DD/MM/YYYY, HH:mm')}h.</span>
                    </UserDeleted>
                    :
                    <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                      {
                        isNew &&
                        <Row gutter={24} style={{marginBottom: '20px'}}>
                          <Col xs={24}>
                            <NewClientText>
                              <span>Nota:</span> Ao criar um novo cliente, o sistema irá enviar automaticamente um email para o mesmo com os seus dados de acesso.
                            </NewClientText>
                          </Col>
                        </Row>
                      }
                      { this.renderDetails() }
                    </BaseForm>
                  }
                </Tab>
                <Tab
                  tab='Processos'
                  key='process'
                >
                  <ProcessList clientID={userInfo?._id} />
                </Tab>
                <Tab
                  tab='Emails Automáticos'
                  key='automatic_emails'
                >
                  <AutomaticEmails client={userInfo} />
                </Tab>
              </TabsContainer>
            </React.Fragment>
          }
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageClientsPage = reduxForm({
  form: 'manage_clients_form',
  validate: validations,
})(ManageClientsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ManageClientsPage);