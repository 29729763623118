import styled from 'styled-components';
import { transitionsDelay } from '../../../../styles/BasicStyles';

export const NewFundsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: flex-end;

  & button {
    margin-left: 18px;
  }
`;

export const FundsListWrapper = styled.div`
  background: #F8F9FA;
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 15px;
`;

export const HeaderLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderColumn = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #495057;
  padding-right: 10px;
  width: ${({ width }) => width};

  &:nth-child(7) {
    padding-right: 0px;
  }
`;

export const Rows = styled.div`
  margin-top: 5px;
`;

export const Row = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RowColumn = styled.div`
  padding-right: 10px;
  width: ${({ width }) => width};

  &:nth-child(7) {
    padding-right: 0px;
  }

  & .icon {
    cursor: pointer;
    height: 18px;

    & div {
      height: 18px; 
    }

    & svg path {
      transition: stroke ${transitionsDelay} linear;
      stroke: #495057;
    }

    &:hover {
      & svg path {
        stroke: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;
