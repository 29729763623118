/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../process/styles';
import React, { useEffect } from 'react';
import FormValidator from '../../../infra/services/validations/FormValidator';
import TextInput from '../../../components/generic/inputs/TextInput';
import BaseButton from '../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  key: 'required'
});

const NewParamModal = ({ open, closeModal, addParam, handleSubmit, dispatch }) => {
  useEffect(() => {
    dispatch(initialize('new_param_form'));
  }, [open]);

  const onSubmit = async (values) => {
    addParam(values.key);
    closeModal();
  }

  return (
    <Modal
      visible={open}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Inserir Parâmetro</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={TextInput}
            name='key'
            type='text'
            label={'Identificador Parâmetro'}
            placeholder={'Identificador'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewParamModalForm = reduxForm({
  form: 'new_param_form',
  validate: validations
})(NewParamModal);

export default connect()(NewParamModalForm);
