export const TasksStates = [
  { _id: 1, name: 'To Do', bgColor: '#FFF9DB', fontColor: '#E67700' },
  { _id: 2, name: 'Pendente', bgColor: '#FFE8CC', fontColor: '#A32E00' },
  { _id: 3, name: 'Em progresso', bgColor: '#E7F5FF', fontColor: '#1864AB' },
  { _id: 4, name: 'Concluída', bgColor: '#E6FCF5', fontColor: '#087F5B' },
  { _id: 5, name: 'Validada', bgColor: '#EDF2FF', fontColor: '#364FC7' },
  { _id: 6, name: 'Cancelada', bgColor: '#FFF5F5', fontColor: '#C92A2A' }
];

export const getTask = (value) => {
  if(Number.isInteger(value) && value > 0 && TasksStates[value - 1]) return TasksStates[value - 1];
  return null;
};
