import { transitionsDelay } from '../../../styles/BasicStyles';
import { device, vertical } from '../../../styles/Responsive';
import styled, { css } from 'styled-components';

/* PROCESS REF HEADER */

export const HeaderWrapper = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;

  & i {
    transition: color ${transitionsDelay} linear;
  }

  & p {
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    & i {
      color: ${({ theme }) => theme.primaryColor} !important;
    }

    & p {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const HeaderContainer = styled.div` 
  display: inline-block;
  width: auto;
  cursor: pointer;
`;

export const HeaderRow = styled.div` 
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const HeaderTitle = styled.p` 
  padding-left: 18px;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0px;
`;

/* PROCESS HEADER */

export const FixedWrapper = styled.div`
  position: fixed;
  top: 64px;
  left: 70px;
  width: calc(100% - 70px);
  z-index: 999;
  display: none;
  
  // Not visible for lower resolutions. Screen must a have a minimum of 992px width and 800px height
  @media ${device.minLaptop} and ${vertical.minMedium} {
    &.visible {
      display: block;
    }
  }

  & .cardTitle, .cardValue {
    font-size: 12px;
    line-height: 16px;
  }

  & .infoRow {
    padding-bottom: 10px !important;
  }

  & .missingLabel {
    & div {
      width: 13px;
      height: 13px;

      & svg {
        width: 13px;
        height: 13px;
      }
    }

    & span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .icon {
    & div {
      width: 13px;
      height: 13px;

      & svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

export const ServiceContainer = styled.div` 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  & span {
    padding-right: 10px;

    & .purchaseDate {
      color: #868E96;
      padding-right: 0px;
      padding-left: 4px;
    }
  }

  & div {
    width: 20px;
    height: 20px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

/* ARCHIVED ALERT */

export const ArchivedWrapper = styled.div`
  padding: 0px 33px;
`;

export const ArchivedContent = styled.div`
  border-radius: 5px;
  background-color: #54a0ff;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  width: 100%;
  padding: 8px 12px;

  @media ${device.desktop} {
    font-size: 16px;
  }

  @media ${device.laptop} {
    font-size: 15px;
  }
`;

/* CARDS */

export const LastUpdate = styled.div` 
  color: #868E96;
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  cursor: pointer;
  transition: color ${transitionsDelay} linear;

  & span:first-child {
    font-weight: 700;
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

/* PERSONAL DATA */

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignTop }) => alignTop ? 'flex-start' : 'center'};
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  & > div:first-child {
    width: 50%;
  }

  & > div:last-child {
    width: 50%;
    justify-content: flex-end;
  }

  @media ${device.laptop} {
    & > div:first-child {
      width: 100%;
      order: 2;
    }

    & > div:last-child {
      width: 100%;
      order: 1;
      margin-bottom: 5px;
    }
  }
`;

export const ActionsCol = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
`;

export const DateResent = styled.div`
  font-size: 13px;
  color: #868E96;
  
  & span {
    font-weight: bold; 
  }
`;

/* ACTIVITY */

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > div:first-child {
    border-radius: 8px 0px 0px 8px;
    border-right-width: 0;
  }

  & > div:last-child {
    border-radius: 0px 8px 8px 0px;
    border-left-width: 0;
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const ToggleButton = styled.div`
  width: 50%;
  max-width: 215px;
  border-width: 1px;
  border-style: solid;
  border-color: #868E96;
  background-color: transparent;
  font-weight: 500;
  line-height: 25px;
  font-size: 16px;
  color: #868E96;
  cursor: pointer;
  padding: 8px 60px;
  text-align: center;
  transition: background-color ${transitionsDelay} linear, color ${transitionsDelay} linear, border-color ${transitionsDelay} linear;

  &:hover {
    background-color: ${({ theme }) => theme.thirdBackgroundColor};
    color: #ffffff;
    border-color: ${({ theme }) => theme.thirdBackgroundColor};
  }

  ${({ active }) => active &&
    css`
      color: #ffffff;
      background-color: #868E96;
    `}

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
    border-radius: 8px !important;

    &:first-child {
      margin-bottom: 8px;
      border-right-width: 1px !important;
    }

    &:last-child {
      margin-top: 8px;
      border-left-width: 1px !important;
    }
  }
`;

export const UpdatesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const UpdateItem = styled.div`
  width: 50%;
  padding-bottom: 18px;

  &:nth-child(odd) {
    padding-right: 9px;
  }

  @media ${device.laptop} {
    width: 100%;

    &:nth-child(odd) {
      padding-right: 0px;
    }
  }
`;

export const UpdateContainer = styled.div`
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const UpdateCol = styled.div`
  &:first-child {
    width: 35%;
    padding-right: 35px;
  }

  &:last-child {
    width: 65%;
  }

  @media ${device.tablet} {
    &:first-child {
      width: 100%;
      padding-right: 0px;
    }

    &:last-child {
      width: 100%;
    }
  }
`;

export const UpdateName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

export const UpdateDate = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ADB5BD;
`;

export const UpdateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  @media ${device.tablet} {
    padding-top: 20px;
  }
`;

export const UpdateActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;

  & .icon {
    cursor: pointer;

    & svg path {
      transition: stroke ${transitionsDelay} linear;
    }

    &:hover {
      & svg path {
        stroke: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

export const UpdateFilesList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UpdateFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #868E96;
    padding-left: 8px;
    transition: color ${transitionsDelay} linear;
  }

  & .icon {
    & div {
      height: 22px;

      & svg path {
        transition: stroke ${transitionsDelay} linear;
      }
    }
  }

  &:hover {
    & span {
      color: ${({ theme }) => theme.primaryColor};
    }

    & .icon {
      & div {
        & svg path {
          stroke: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
`;

export const UpdatesSeeMore = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export const NoUpdates = styled.div`
  text-align: center;
  padding-top: 15px;
  font-size: 15px;
`;

/* FILTERS */

export const Row = styled.div` 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
`;

export const Col = styled.div` 
  width: 25%;
  padding-left: 12px;
  padding-right: 12px;

  & .checkboxWrapper {
    margin-top: 10px;
  }

  @media ${device.laptop} {
    width: 50%;
  }

  @media ${device.tabletS} {
    width: 100%;
  }
`;