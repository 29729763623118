import { Row, Col } from 'antd';
import { Field, initialize } from 'redux-form';
import { GetClient } from '../../../infra/requests/ClientsRequests';
import { SectionTitle } from './styles';
import { Separator } from '../../../styles/BasicStyles';
import { CardWrapper, Card, CardContainer } from '../styles';
import { CivilStates, getCivilState } from '../../../infra/utils/CivilStates';
import { Genders } from '../../../infra/utils/Genders';
import { ProfessionalStates } from '../../../infra/utils/ProfessionalStates';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Countries } from '../../../infra/utils/Countries';
import React, { useState } from 'react';
import DateInput from '../../../components/generic/inputs/DateInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import TextInput from '../../../components/generic/inputs/TextInput';

const ClientDetails = ({ dispatch, user, formValues, clients }) => {
  const [civilStateDate, setCivilStateDate] = useState(false);
  const [labelCivilState, setLabelCivilState] = useState(null);

  const loadClientInfo = async (value) => {
    try {
      if(value) {
        const client = await GetClient(value);

        if(client?.data) {
          dispatch(
            initialize('create_process_form', {
              ...formValues,
              client: value,
              first_name: client.data.first_name,
              last_name: client.data.last_name,
              birthday: client.data.birthday,
              avs_number: client.data.avs_number,
              contact: client.data.contact,
              email: client.data.email,
              gender: client.data.gender,
              civil_state: client.data.civil_state,
              civil_state_date: client.data.civil_state_date,
              address: client.data.address,
              postal_code: client.data.cod_postal,
              city: client.data.city,
              country: client.data.country,
              professional_state: client.data.professional_state
            })
          );

          checkCivilState(client.data.civil_state);
        }
      }
    }
    catch(error) {
      console.log(error);
    }
  };

  const checkCivilState = (value) => {
    const civilState = getCivilState(parseInt(value));
    const labelCivilState = civilState ? `${civilState.name} desde` : '';
    
    setCivilStateDate(civilState?.hasDate || false);
    setLabelCivilState(labelCivilState);
  };

  return (
    <>
      <SectionTitle>Detalhes do Cliente</SectionTitle>
      <CardWrapper gutter={24}>
        <Card xxl={18} xl={20} lg={24}>
          <CardContainer>
            {user?.type === 1 &&
              <Row gutter={24}>
                <Col md={12} sm={24}>
                  <Field
                    component={SelectInput}
                    name={'client'}
                    label={'Cliente'}
                    placeholder={'Escolha um cliente'}
                    type='select'
                    data={clients}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    execAfterChange={loadClientInfo}
                    allowClear={true}
                  />
                </Col>
              </Row>
            }
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'first_name'}
                  type='text'
                  label={'Nome *'}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'last_name'}
                  type='text'
                  label={'Apelido *'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={DateInput}
                  name='birthday'
                  label='Data de Nascimento'
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'avs_number'}
                  type='text'
                  label={'Nº AVS/AHV'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'contact'}
                  type='text'
                  label={'Contacto'}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'email'}
                  type='email'
                  label={'Email'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'professional_state'}
                  label={'Situação Profissional'}
                  placeholder={'Escolha o estado'}
                  type='select'
                  data={ProfessionalStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'gender'}
                  label={'Género'}
                  placeholder={'Escolha o género'}
                  type='select'
                  data={Genders}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'civil_state'}
                  label={'Estado Civil'}
                  placeholder={'Escolha o estado civil'}
                  type='select'
                  data={CivilStates}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                  execAfterChange={checkCivilState}
                />
              </Col>
              {civilStateDate &&
                <Col md={12} sm={24}>
                  <Field
                    component={DateInput}
                    name='civil_state_date'
                    label={labelCivilState}
                  />
                </Col>
              }
            </Row>
          </CardContainer>
          <Separator />
          <CardContainer>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={DateInput}
                  name='dateRetirement'
                  label='Data Prevista da Reforma'
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={DateInput}
                  name='dateComeback'
                  label='Data Prevista de Regresso a Portugal'
                />
              </Col>
            </Row>
          </CardContainer>
          <Separator />
          <CardContainer>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'address'}
                  type='text'
                  label={'Morada'}
                  first={true}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'postal_code'}
                  type='text'
                  label={'Código Postal'}
                  first={true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12} sm={24}>
                <Field
                  component={TextInput}
                  name={'city'}
                  type='text'
                  label={'Cidade'}
                />
              </Col>
              <Col md={12} sm={24}>
                <Field
                  component={SelectInput}
                  name={'country'}
                  label={'País'}
                  placeholder={'Escolha um país'}
                  type='select'
                  data={Countries}
                  dataKey={'code'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
          </CardContainer>
        </Card>
      </CardWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  formValues: state?.form?.create_process_form?.values
});

export default withLocalize(connect(mapStateToProps)(ClientDetails));
