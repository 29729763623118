/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change as ChangeField, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { FlattenToFormData } from '../../../../infra/services/formdata/TransformToFormData';
import { SendPersonalizedEmail } from '../../../../infra/requests/ProcessRequests';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import FileUpload from '../../components/FileUpload';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import DraftInput from '../../../../components/generic/inputs/DraftInput';

const validations = FormValidator.make({
  email: 'required|email',
  email_subject: 'required',
  email_content: 'required'
});

const NewEmail = ({ open, closeModal, processInfo, currentResponsible, institutions, addressList, getInfo, documents, reports, file_template, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);
  const [addressSelect, setAddressSelect] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [subjectSelect, setSubjectSelect] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [institutionInfo, setInstitutionInfo] = useState(null);
  const [institutionsList, setInstitutionsList] = useState([]);

  useEffect(() => {
    dispatch(initialize('new_email_form'));
    
    setAddressSelect(false);
    setAddressError(false);
    setSubjectSelect(false);
    setSubjectError(false);
    setAcceptedFiles([]);
    setRejectedFiles([]);

    if(user?.type === 2) setInstitutionsList([institutions.find(elem => elem._id == 1)]);
    else setInstitutionsList(institutions);
  }, [open]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      /* 
      * In case the user selected a file that needed an address or subject (Procuração for example), don't let submit unless they select something in that field
      * Otherwise, the user thinks he added a file, but he didn't
      */
      if(addressSelect) {
        setAddressError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar uma morada para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }
      if(subjectSelect) {
        setSubjectError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar um assunto para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }

      const payload = FlattenToFormData({ ...values, process: processInfo._id, selectedFiles: acceptedFiles });

      const { success } = await SendPersonalizedEmail(payload);

      if(success) {
        notification.success({
          message: 'Email enviado com sucesso!'
        });

        dispatch(initialize('new_email_form'));

        setInstitutionInfo(null);
        setAddressSelect(false);
        setAddressError(false);
        setSubjectSelect(false);
        setSubjectError(false);
        setAcceptedFiles([]);
        setRejectedFiles([]);
        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const checkSelectedTemplate = (value) => {
    //In these types we must select an address to complete the file
    if(parseInt(value) === 1 || parseInt(value) === 3 || parseInt(value) === 4) {
      setAddressSelect(true);
      setSubjectSelect(false);
    }
    //In this type we must select a subject to complete the file
    else if(parseInt(value) === 10) {
      setAddressSelect(false);
      setSubjectSelect(true);
    }
    //Otherwise we can add the file to the selected array
    else {
      setAddressSelect(false);
      setSubjectSelect(false);
      setAcceptedFiles([ ...acceptedFiles, { file_template: value } ]);

      //Clear Template field
      dispatch(ChangeField('new_email_form', 'file_template', null));
    }
  }

  const checkSelectedAddress = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, address: value } ]);
    setAddressSelect(false);
    setAddressError(false);

    //Clear Template field
    dispatch(ChangeField('new_email_form', 'file_template', null));

    //Clear Address field
    dispatch(ChangeField('new_email_form', 'address', null));
  }

  const checkSelectedSubject = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, subject: value } ]);
    setSubjectSelect(false);
    setSubjectError(false);

    //Clear Template field
    dispatch(ChangeField('new_email_form', 'file_template', null));

    //Clear Subject field
    dispatch(ChangeField('new_email_form', 'subject', null));
  }

  const checkInstitution = async (value) => {
    const institutionInfo = institutions.find(elem => elem._id === value);

    dispatch(ChangeField('new_email_form', 'email', institutionInfo?.email || ''));

    setInstitutionInfo(institutionInfo);
  }

  const checkSelectedDocument = (value) => {
    const file = documents.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { document: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Document field
    dispatch(ChangeField('new_email_form', 'document', null));
  }

  const checkSelectedReport = (value) => {
    const file = reports.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { report: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Report field
    dispatch(ChangeField('new_email_form', 'report', null));
  }

  return (
    <Modal
      visible={open}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <>
        <ModalTitle>Novo Email</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            placeholder={'Selecione uma Instituição'}
            name={'institution'}
            type='select'
            data={institutionsList}
            dataKey={'_id'}
            dataLabel={'name'}
            label={'Instituição'}
            execAfterChange={checkInstitution}
            allowClear
          />
          <Field
            name='email'
            component={TextInput}
            type='email'
            label='Email do destinatário *'
            placeholder='Email'
            notesText={institutionInfo && !institutionInfo.email ? 'A Instituição selecionada ainda não possui um endereço de email!' : ''}
          />
          <Field
            name='email_subject'
            component={TextInput}
            type='text'
            label='Assunto *'
            placeholder='Assunto'
          />
          <div style={{ marginTop: '10px' }}>
            <Field
              component={DraftInput}
              name={'email_content'}
              label={'Conteúdo *'}
              placeholder={'Conteúdo'}
            />
          </div>
          <FileUpload 
            addressList={addressList}
            checkSelectedTemplate={checkSelectedTemplate}
            checkSelectedAddress={checkSelectedAddress}
            checkSelectedSubject={checkSelectedSubject}
            checkSelectedDocument={checkSelectedDocument}
            checkSelectedReport={checkSelectedReport}
            addressSelect={addressSelect}
            subjectSelect={subjectSelect}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            rejectedFiles={rejectedFiles}
            setRejectedFiles={setRejectedFiles}
            documents={documents}
            reports={reports}
            processInfo={processInfo}
            currentResponsible={currentResponsible}
            addressError={addressError}
            subjectError={subjectError}
            user={user}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Enviar email"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const selector = formValueSelector('new_email_form');

const NewEmailForm = reduxForm({
  form: 'new_email_form',
  validate: validations
})(NewEmail);

const mapStateToProps = state => ({
  user: state.user,
  file_template: selector(state, 'file_template')
});

export default connect(mapStateToProps)(NewEmailForm);
