
import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const InputContainer = styled.div`
  margin-bottom: ${({ hasMargin }) => hasMargin ? '40px' : '0px' };

  @media ${device.laptop} {
    margin-bottom: 40px;
  }

  & .ace_editor {
    width: 100% !important;
    height: 600px !important;
  }
`;

export const HtmlPreview = styled.div`
  background-color: #F5F5F5;
  padding: 10px;
`;

export const ExpandWindowContainer = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;

export const NoteContainer = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;

  & span {
    color: red;
  }
`;