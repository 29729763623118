import UsersPage from '../../pages/users/UsersPage';
import ManageUsersPage from '../../pages/users/ManageUsersPage';
import ConfigsPage from '../../pages/configs/ConfigsPage';
import ManageConfigsPage from '../../pages/configs/ManageConfigsPage';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import ManageProfilePage from '../../pages/users/ManageProfilePage';
import EmailTemplatesPage from '../../pages/email_templates/EmailTemplatesPage';
import ManageEmailTemplatesPage from '../../pages/email_templates/ManageEmailTemplatesPage';

export default [
  {
    path: '/my-profile',
    component: ManageProfilePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/add',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:id',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs',
    component: ConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs/edit',
    component: ManageConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates',
    component: EmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates/:id',
    component: ManageEmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  }
];
