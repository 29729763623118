import { Row, Col } from 'antd';
import { PageTitle } from "../../styles/BasicStyles";
import styled from 'styled-components';

export const SectionTitle = styled(PageTitle)` 
  margin: 15px 0px;
`;

export const FiltersText = styled.div`
  & span {
    font-weight: 700;
  }
`;

export const CardWrapper = styled(Row)` 
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

export const Card = styled(Col)` 
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  margin-bottom: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const CardContainer = styled.div` 
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
`;

export const CardTitle = styled.div` 
  font-weight: 500;
  font-size: 14px;
  color: #343A40;
  line-height: 20px;
  padding-bottom: 6px;

  & span {
    font-weight: 400;
    font-size: 13px;
  }
`;

export const CardSubtitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #343A40;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
`;

export const SeeMore = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: red;
  text-decoration: underline;
  padding-top: 3px;
  cursor: pointer;
`;

export const UserEmail = styled.div`
  margin-bottom: 0px !important;
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;


export const ServiceStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const ServiceStatusLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #343A40;
  padding-right: 15px;
`;

export const ServiceStatus = styled.div`
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #20bf6b;
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
`;
