export const FileCategories = [
  { _id: 1, name: 'Procuração', insert: true },
  { _id: 4, name: 'Resposta de Bern', insert: true },
  { _id: 5, name: 'Extratos', insert: true },
  { _id: 7, name: 'Extrato de AVS', insert: true },
  { _id: 2, name: 'Documentos Pessoais', insert: true },
  { _id: 6, name: 'Decisões de Reforma', insert: true },
  { _id: 9, name: 'Simulação Reforma AVS', insert: true },
  { _id: 10, name: 'Pedido Reforma AVS', insert: true },
  { _id: 11, name: 'Faturação', insert: true },
  { _id: 3, name: 'Outros', insert: true },
  { _id: 99, name: 'Actualizações', insert: false },
  { _id: 98, name: 'FICHEIROS_ANTIGOS', insert: false }
];

export const getCategory = (value) => {
  if(Number.isInteger(value) && value > 0) return FileCategories.find(elem => elem._id === value);
  return null;
};