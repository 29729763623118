export const InstitutionTemplates = [
  {
    _id: 1,
    name: 'Fazer pedido fundo garantia'
  },
  {
    _id: 2,
    name: 'Pedido informação estado fundo garantia'
  },
  {
    _id: 3,
    name: 'Enviar pedido reforma fora CH'
  },
  {
    _id: 4,
    name: 'Demande de versement et DOCS'
  },
  {
    _id: 5,
    name: "Demande d'extrait de compte d'avoir LPP"
  },
  {
    _id: 6,
    name: "Demande de transfert d'avoir LPP"
  }
];

export const getInstitutionTemplate = (value) => {
  if(Number.isInteger(value) && value > 0 && InstitutionTemplates[value - 1]) return InstitutionTemplates[value - 1].name;
  return null;
};