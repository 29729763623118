import { device } from '../../../styles/Responsive';
import styled from 'styled-components';

/* FILTERS */

export const ClearFiltersWrapper = styled.div`
  margin-top: ${({ smallMargin }) => smallMargin ? '10px' : '38px'};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -12px;
  margin-right: -12px;
`;

export const Col = styled.div`
  width: 25%;
  padding-left: 12px;
  padding-right: 12px;

  @media ${device.laptop} {
    width: 33.333%;
  }

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.tabletS} {
    width: 100%;
  }
`;
