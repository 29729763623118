import { Tabs, Row, Col } from 'antd';
import { Field } from 'redux-form';
import { GetTranslationDescription } from '../../../infra/services/translations/AvailableTranslations';
import { DefaultLanguageTab, InputNote, Separator } from '../../../styles/BasicStyles';
import { withLocalize } from 'react-localize-redux';
import React from 'react';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';
import ImageInput from '../../../components/generic/inputs/ImageInput';
import TextInput from '../../../components/generic/inputs/TextInput';
const TabPane = Tabs.TabPane;

const Details = ({ languages, activeLanguage }) => {
  return (
    <>
      <Row gutter={24}>
        <Col md={12} xs={24}>
          <Field
            component={ImageInput}
            name='image'
            label='Imagem de capa *'
            ratio={0.8}
          />
        </Col>
      </Row>
      <Separator />
      <Row gutter={24}>
        <Col md={12} xs={24} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          <Field
            component={TextInput}
            name={`title.${activeLanguage?.code}`}
            type='text'
            label={'Título *'}
            placeholder={'Título do documento'}
          />
        </Col>
        <Col md={12} xs={24} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  <Field
                    component={TextInput}
                    name={`title.${language.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título do documento'}
                  />
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
      <Separator />
      <Row gutter={24}>
        <Col xs={24}>
          <Field
            component={TextInput}
            name={`identifier`}
            type='text'
            label={'URL *'}
            placeholder={'URL do Serviço'}
          />
          <InputNote>
            <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
            acentos, letras minúsculas e apenas com letras, números e
            hífens.
          </InputNote>
          <InputNote>
            <span>EXEMPLO:</span> Título do Serviço: "Novo Serviço 2020"
            / URL: "novo-servico-2020"
          </InputNote>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={12} xs={24}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          <Field
            component={TextInput}
            name={`meta_title.${activeLanguage?.code}`}
            label={'Meta Title'}
            type={'text'}
          />
          <Field
            component={TextAreaInput}
            name={`meta_description.${activeLanguage?.code}`}
            label={'Meta Description'}
          />
          <Field
            component={TextAreaInput}
            name={`meta_keywords.${activeLanguage?.code}`}
            label={'Meta Keywords'}
          />
        </Col>
        <Col md={12} xs={24}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  <Field
                    component={TextInput}
                    name={`meta_title.${language.code}`}
                    label={'Meta Title'}
                    type={'text'}
                  />
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default withLocalize(Details);
