import { Row, Col } from 'antd';
import { Card, CardTitle } from '../../communications/styles';
import { Separator } from '../../../styles/BasicStyles';
import React from 'react';
import moment from 'moment';

const InsuranceDetail = ({ info }) => {
  return (
    <>
      <Row gutter={24} style={{ paddingBottom: '20px' }}>
        <Col xs={24}>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Dados Pessoais</span>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Nome Completo</CardTitle>
          <Card>
            {info?.name}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Email</CardTitle>
          <Card>
            {info?.email}
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Morada</CardTitle>
          <Card>
            {info?.address}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Código Postal</CardTitle>
          <Card>
            {info?.zip_code}
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Cidade</CardTitle>
          <Card>
            {info?.city}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>País</CardTitle>
          <Card>
            {info?.country?.name?.pt}
          </Card>
        </Col>
      </Row>
      <Separator />
      <Row gutter={24} style={{ paddingBottom: '20px' }}>
        <Col xs={24}>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Dados da Seguradora</span>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xs={24}  style={{paddingRight: '0px'}}>
          <CardTitle>Nome da Seguradora</CardTitle>
          <Card>
            {info?.insurance_name}
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Morada</CardTitle>
          <Card>
            {info?.insurance_address}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Código Postal</CardTitle>
          <Card>
            {info?.insurance_zip_code}
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Cidade</CardTitle>
          <Card>
            {info?.insurance_city}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>País</CardTitle>
          <Card>
            {info?.insurance_country?.name?.pt}
          </Card>
        </Col>
      </Row>
      <Separator />
      <Row gutter={24} style={{ paddingBottom: '20px' }}>
        <Col xs={24}>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Informação sobre o contrato de rescisão</span>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Número do Contrato</CardTitle>
          <Card>
            {info?.contract_number}
          </Card>
        </Col>
        <Col xl={12} md={12} style={{paddingRight: '0px'}}>
          <CardTitle>Data de Rescisão Desejada</CardTitle>
          <Card>
            {`${moment(info?.contract_end_date).format('DD-MM-YYYY')}`}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InsuranceDetail;
