import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import {
  GetServices,
  DeleteService,
  ToggleServices,
  ToggleFeaturedServices,
  UpdateServicesOrder,
} from '../../infra/requests/ServicesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ServicesFilters from './ServicesFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { getCategory } from '../../infra/utils/Categories';
import OrderServices from './OrderServices';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import { StateLabel } from '../process/styles';

class ServicesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '27%',
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: (value) => getCategory(value),
        width: '12%',
      },
      {
        title: 'Preço Online',
        key: 'price_online',
        render: (data) => this.renderPrice(data, 1),
        width: '12%',
      },
      {
        title: 'Preço Via Postal',
        key: 'price_postal',
        render: (data) => this.renderPrice(data, 2),
        width: '12%',
      },
      {
        title: 'Preço Presencial',
        key: 'price_presential',
        render: (data) => this.renderPrice(data, 3),
        width: '12%',
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        render: (value) => this.renderState(value),
        width: '9%',
      },
      {
        title: 'Destacado',
        dataIndex: 'featured',
        render: (value) => this.renderState(value),
        width: '9%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    orderModal: false,
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/services/list/${record.identifier}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    const options = [
      {
        onClick: () => history.push(`/services/list/${data.identifier}`),
        label: 'Editar'
      }
    ];

    if(data._id !== '608ab3d0803cef39fd8778b7') {
      options.push(
        {
          popConfirmtitle: data.featured ? 'Quer remover o destaque neste serviço?' : 'Quer destacar este serviço?',
          onClick: () => this.toggleFeatured(data),
          label: data.featured ? ' Não destacar' : 'Destacar'
        }
      );
      options.push(
        {
          popConfirmtitle: data.active ? 'Quer desativar este serviço?' : 'Quer publicar este serviço?',
          onClick: () => this.toggleActive(data),
          label: data.active ? ' Desativar' : 'Publicar'
        }
      );
      options.push(
        {
          popConfirmtitle: 'Tem a certeza que quer remover o serviço?',
          onClick: () => this.removeService(data),
          label: 'Remover'
        }
      );
    }

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={options} 
      />
    );
  };

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Sim
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Não
      </StateLabel>
    );
  };

  removeService = async (data) => {
    try {
      await DeleteService(data.identifier);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetServices(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await ToggleServices(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  toggleFeatured = async (record) => {
    this.setState({ loading: true });
    await ToggleFeaturedServices(record._id, { featured: !record.featured });
    await this.updateDataTable();
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderPrice = (data, type) => {
    if(data.free) return 'Gratuito';

    if (type === 2) return `${data.price_postal_euro}€ / ${data.price_postal_chf}CHF`;
    else if (type === 3) return `${data.price_presential_euro}€ / ${data.price_presential_chf}CHF`;
    
    return `${data.price_online_euro}€ / ${data.price_online_chf}CHF`;
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (services) => {
    const { dispatch } = this.props;
    dispatch(
      initialize(
        'manage_order_services_form',
        { services: [...services] },
        false
      )
    );

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });

      if (values.services.length > 0) {
        let orderedList = [];
        Object.keys(values.services).forEach(function (key) {
          let obj = { _id: values.services[key]._id, order: key };
          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateServicesOrder({ order: orderedList });

          if (res.success) {
            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      orderModal,
    } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle noMarginBottom={true}>Lista de Serviços</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/services/list/add')}
            />
            <BaseButton
              type='secondary'
              icon='retweet'
              text='Editar Ordem'
              onClick={() => this.openOrderModal(rows)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ServicesFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Serviços inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <OrderServices
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ServicesPage);