import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import {
  GetDocumentRequests,
  DeleteDocument
} from '../../infra/requests/ContactsRequests';
import { GetDocumentsList } from '../../infra/requests/DocumentsRequests';
import { connect } from 'react-redux';
import DocumentRequestsFilters from './DocumentRequestsFilters';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import moment from 'moment';

class DocumentRequestsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('DD/MM/YYYY, HH:mm'),
        width: '18%',
      },
      {
        title: 'Documento',
        key: 'document',
        render: (data) => data.document?.title?.pt,
        width: '27%',
      },
      {
        title: 'Nome',
        key: 'name',
        render: (data) => this.renderName(data),
        width: '22%',
      },
      {
        title: 'Email',
        key: 'email',
        render: (data) => this.renderEmail(data),
        width: '22%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    documents: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/document-requests/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/document-requests/${data._id}`),
            label: 'Visualizar' 
          },
          {
            popConfirmtitle: 'Tem a certeza que quer remover este pedido?',
            onClick: () => this.removeRequest(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  };

  removeRequest = async (data) => {
    try {
      await DeleteDocument(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  renderName = (data) => {
    if(!!data.house_contract_termination) return data.house_contract_termination.name;
    else if(!!data.insurance_contract_termination) return data.insurance_contract_termination.name;
    else if(!!data.work_contract_termination) return data.work_contract_termination.name;
    return '';
  };

  renderEmail = (data) => {
    if(!!data.house_contract_termination) return data.house_contract_termination.email;
    else if(!!data.insurance_contract_termination) return data.insurance_contract_termination.email;
    else if(!!data.work_contract_termination) return data.work_contract_termination.email;
    return '';
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetDocumentRequests(currentPage, pageSize, filters);
      const documents = await GetDocumentsList(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        documents: documents?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      documents
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Pedidos de Documentos</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DocumentRequestsFilters queryChange={this.handleFilterChange} documents={documents} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem registos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DocumentRequestsPage);