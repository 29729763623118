import { DropzoneField, DropzoneNote } from './styles';
import { ReactSVG } from 'react-svg';
import React from 'react';
import Dropzone from 'react-dropzone';

const DropzoneComponent = ({ handleAcceptedFiles, handleRejectedFiles, isFirst = false }) => {
  const buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  const handleDrop = (accepted, rejected) => {
    const rejectedArray = [];

    if(rejected.length > 0) {
      rejected.forEach((elem) => {
        if(elem.errors?.length > 0 && elem.errors[0].code === 'file-too-large') {
          rejectedArray.push(`O ficheiro ${elem.file.name} não foi anexado porque tem um tamanho superior a 8Mb!`);
        }
        else if(elem.errors?.length > 0 && elem.errors[0].code === 'file-invalid-type') {
          rejectedArray.push(`O ficheiro ${elem.file.name} não foi anexado porque tem um formato inválido!`);
        }
        else {
          rejectedArray.push(`O ficheiro ${elem.file.name} não foi anexado porque é inválido!`);
        }
      });

      handleRejectedFiles(rejectedArray);
    }

    const acceptedArray = [];

    if(accepted.length > 0) {
      accepted.forEach((elem) => {
        const uploaded = buildImageObject(elem);
        acceptedArray.push(uploaded);
      });

      handleAcceptedFiles(acceptedArray);
    }
  };

  return (
    <Dropzone
      multiple={true}
      onDrop={handleDrop}
      accept='image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      maxSize={8000000}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <>
            <DropzoneField {...getRootProps()} isFirst={isFirst}>
              <input {...getInputProps()} />
              <ReactSVG src={`${process.env.REACT_APP_BO_URL}folder_plus.svg`} className='icon' />
              <span>Clique ou arraste um ou mais ficheiros</span>
            </DropzoneField>
            <DropzoneNote>Tamanho máximo de cada ficheiro: 8MB</DropzoneNote>
            <DropzoneNote>Formatos permitidos: .jpg/.jpeg, .png, .pdf, .doc, .docx</DropzoneNote>
          </>
        );
      }}
    </Dropzone>
  )
};

export default DropzoneComponent;
