/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, notification } from 'antd';
import { BaseForm } from '../../../../styles/BasicStyles';
import { ActionsWrapper, ActionsCol } from '../styles';
import { CardContainer } from '../../styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { CivilStates, getCivilState } from '../../../../infra/utils/CivilStates';
import { Genders } from '../../../../infra/utils/Genders';
import { ProfessionalStates } from '../../../../infra/utils/ProfessionalStates';
import { UpdateProcessClientData } from  '../../../../infra/requests/ProcessRequests';
import { Countries } from '../../../../infra/utils/Countries';
import React, { useEffect, useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextInput from '../../../../components/generic/inputs/TextInput';
import DateInput from '../../../../components/generic/inputs/DateInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';

const validations = FormValidator.make({
  first_name: 'required',
  last_name: 'required',
  email: 'email'
});

const PersonalDataForm = ({ processInfo, user, handleSubmit, setEdit, getInfo, clients, pristine }) => {
  const [loading, setLoading] = useState(false);
  const [civilStateDate, setCivilStateDate] = useState(false);
  const [labelCivilState, setLabelCivilState] = useState(null);

  useEffect(() => {
    checkCivilState(processInfo.civil_state);
  }, []);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const full_name = `${values.first_name} ${values.last_name}`;
      const { success } = await UpdateProcessClientData(processInfo._id, { ...values, full_name });

      if(success) {
        notification.success({
          message: 'Dados alterados com sucesso!'
        });

        setEdit(false);
        await getInfo();
      }

      setLoading(false);
    }
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const checkCivilState = (value) => {
    const civilState = getCivilState(parseInt(value));
    const labelCivilState = civilState ? `${civilState.name} desde` : '';

    setCivilStateDate(civilState?.hasDate || false);
    setLabelCivilState(labelCivilState);
  };

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <CardContainer>
        <ActionsWrapper alignTop='true'>
          <ActionsCol>
          {
            !processInfo?.agent &&
            <div style={{ paddingBottom: '15px', width: '100%' }}>
              <Field
                component={SelectInput}
                name={'client'}
                label={'Cliente'}
                placeholder={'Escolha um cliente'}
                type='select'
                data={clients}
                dataKey={'_id'}
                dataLabel={'name'}
                allowClear={true}
              />
            </div> 
          }
          </ActionsCol>
          <ActionsCol>
            <BaseButton
              htmlType='button'
              type='secondary'
              text='Cancelar'
              style={{ float: 'right', marginRight: '8px' }}
              disabled={loading}
              onClick={() => setEdit(false)}
            />
            <BaseButton
              htmlType='submit'
              type='primary'
              text='Guardar'
              style={{ float: 'right' }}
              loading={loading}
              disabled={loading || pristine}
              onClick={handleSubmit(onSubmit)}
            />
          </ActionsCol>
        </ActionsWrapper>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'first_name'}
              type='text'
              label={'Nome *'}
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'last_name'}
              type='text'
              label={'Apelido *'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={DateInput}
              name='birthday'
              label='Data de Nascimento'
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={DateInput}
              name='dateComeback'
              label='Data prevista de Regresso a Portugal'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={DateInput}
              name='dateRetirement'
              label='Data prevista da Reforma'
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'avs_number'}
              type='text'
              label={'Nº AVS/AHV'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'contact'}
              type='text'
              label={'Contacto'}
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'email'}
              type='email'
              label={'Email'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={SelectInput}
              name={'professional_state'}
              label={'Situação Profissional'}
              placeholder={'Escolha o estado'}
              type='select'
              data={ProfessionalStates}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={SelectInput}
              name={'gender'}
              label={'Género'}
              placeholder={'Escolha o género'}
              type='select'
              data={Genders}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={SelectInput}
              name={'civil_state'}
              label={'Estado Civil'}
              placeholder={'Escolha o estado civil'}
              type='select'
              data={CivilStates}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
              execAfterChange={checkCivilState}
            />
          </Col>
          {
            civilStateDate && 
            <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
              <Field
                component={DateInput}
                name='civil_state_date'
                label={labelCivilState}
              />
            </Col>
          }
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'address'}
              type='text'
              label={'Morada'}
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'postal_code'}
              type='text'
              label={'Código Postal'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={TextInput}
              name={'city'}
              type='text'
              label={'Cidade'}
            />
          </Col>
          <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
            <Field
              component={SelectInput}
              name={'country'}
              label={'País'}
              placeholder={'Escolha um país'}
              type='select'
              data={Countries}
              dataKey={'code'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col>
        </Row>
      </CardContainer>
    </BaseForm>
  );
}

const DataForm = reduxForm({
  form: 'edit_process_form',
  validate: validations
})(PersonalDataForm);

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DataForm);
