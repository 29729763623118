import { device } from '../../styles/Responsive';
import styled, { css } from 'styled-components';

export const Row = styled.div` 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
`;

export const Col = styled.div` 
  width: 25%;
  padding-left: 12px;
  padding-right: 12px;

  & .checkboxWrapper {
    margin-top: 10px;
  }

  @media ${device.laptop} {
    width: 50%;
  }

  @media ${device.tabletS} {
    width: 100%;
  }

  ${({ strechField }) => strechField &&
    css`
      &:first-child {
        width: 50%;
      }

      @media ${device.laptop} {
        &:first-child {
          width: 100%;
        }
      }
    `}
`