import { connect } from 'react-redux';
import { Form, Modal, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { EditFilename } from '../../../../infra/requests/ProcessRequests';
import { FlattenToFormData } from '../../../../infra/services/formdata/TransformToFormData';
import React, { useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  filename: 'required'
});

const EditDocumentName = ({ openModal, closeModal, getInfo, processID, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      
      const payload = FlattenToFormData({ filename: values.filename, process: processID });
      const result = await EditFilename(values._id, payload);

      if(result?.success) {
        notification.success({
          message: 'Documento alterado com sucesso!'
        });

        dispatch(initialize('edit_document_name_form'));
        
        await getInfo();
        closeModal();
      }
      
      setLoading(false);      
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(initialize('edit_document_name_form'));
    closeModal();
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Editar Nome do Documento</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name='filename'
            component={TextInput}
            type='text'
            label='Nome do ficheiro *'
            placeholder='Nome do ficheiro'
            notesText='Introduza o nome do ficheiro sem a extensão.'
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditDocumentNameForm = reduxForm({
  form: 'edit_document_name_form',
  validate: validations
})(EditDocumentName);

export default connect()(EditDocumentNameForm);
