import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetDocumentDetail } from '../../infra/requests/ContactsRequests';
import { FormContainer, SpinLoading, Separator } from '../../styles/BasicStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { Card, CardWrapper, CardTitle } from '../communications/styles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import moment from 'moment';
import HouseDetail from './components/HouseDetail';
import InsuranceDetail from './components/InsuranceDetail';
import WorkDetail from './components/WorkDetail';

class ManageDocumentRequestPage extends Component {
  state = { 
    loading: true,
    initialValues: null
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3 || !params.id) {
      return history.push('/');
    }

    CloseMenu();

    const result = await GetDocumentDetail(params.id);

    this.setState({ 
      loading: false,
      initialValues: result.data
    });
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/document-requests');
  };

  renderOrigin = () => {
    const { initialValues } = this.state;

    let result = 'Formulário de Contacto';
    if(!!initialValues?.other_service) {
      if(initialValues?.other_service.tag === 'INSURANCE') result = 'Seguros';
      else if(initialValues?.other_service.tag === 'CREDIT') result = 'Créditos';
      else if(initialValues?.other_service.tag === '2_PILAR') result = '2º Pilar para Empresas';
    }
    else if(!!initialValues?.estate) result = `Imobiliário (${initialValues?.estate.title?.pt})`;

    return result;
  }

  render() {
    const { loading, initialValues } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Pedidos de Documentos'
          breadcrumbs={['Visualizar']}
          buttons={[
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <CardWrapper style={{ marginTop: '30px' }}>
            <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
              <Col xl={12} sm={12} style={{paddingRight: '0px'}}>
                <CardTitle>Data</CardTitle>
                <Card>
                  {`${moment(initialValues.createdAt).format('DD-MM-YYYY, HH:mm')}h`}
                </Card>
              </Col>
              <Col xl={12} sm={12} style={{paddingRight: '0px'}}>
                <CardTitle>Documento</CardTitle>
                <Card>
                  {initialValues?.document?.title?.pt}
                </Card>
              </Col>
            </Row>  
            <Separator />
            {
              !!initialValues?.house_contract_termination ?
              <HouseDetail info={initialValues.house_contract_termination} />
              :
              !!initialValues?.insurance_contract_termination ?
              <InsuranceDetail info={initialValues.insurance_contract_termination} />
              :
              !!initialValues?.work_contract_termination ?
              <WorkDetail info={initialValues.work_contract_termination} />
              :
              null
            }
            <Separator />
            <Row gutter={24} style={{ paddingBottom: '20px' }}>
              <Col xs={24}>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Ficheiro</span>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
              <Col xs={24}>
                <Card>
                  <a href={`${process.env.REACT_APP_IMAGES_URL}${initialValues?.file?._id}`} target='_blank' rel="noopener noreferrer" style={{ fontSize: '15px' }}>Ver ficheiro</a>
                </Card>
              </Col>
            </Row>
          </CardWrapper>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageDocumentRequestPage));