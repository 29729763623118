import { device } from '../../../../styles/Responsive';
import styled from 'styled-components';

export const PdfWrapper = styled.div` 
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
`;  

export const PdfItemWrapper = styled.div`
  width: 33.333%;
  padding-bottom: 20px;
  padding-right: 20px;

  &:nth-child(3n + 3) {
    padding-right: 0px;
  }

  @media ${device.laptopL} {
    width: 50%;
    padding-right: 20px !important;

    &:nth-child(2n + 2) {
      padding-right: 0px !important;
    }
  }

  @media ${device.tablet} {
    width: 100%;
    padding-right: 0px !important;
  }
`;

export const PdfItem = styled.div`
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
`;

export const PdfTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
`;

export const PdfButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & button {
    margin-top: 10px;
  }

  & button:first-child {
    margin-right: 8px;
  }
`;

export const PdfStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ error }) => error ? '#C92A2A' : '#087F5B' };
  margin-top: 10px;
`;

export const FilenameColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & .alertIcon {   
    padding-right: 10px;

    & div {
      height: 18px;
    }

    & svg path {
      stroke: #CA6901;
    }
  }
`;
