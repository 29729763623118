import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetConfig,
  UpdateConfigType,
} from '../../infra/requests/ConfigsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator,
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { TransformToFormData } from '../../infra/services/formdata/TransformToFormData';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import NumberInput from '../../components/generic/inputs/NumberInput';

const configID = '5f3ce2b8b5f79d55501be35c';

const validations = FormValidator.make({
  email_notifications: 'required',
  address: 'required',
  cod_postal: 'required',
  city: 'required',
  latitude: 'required',
  longitude: 'required',
  phone1: 'required',
  phone2: 'required',
  email: 'required|email',
  company: 'required',
  bank: 'required',
  iban: 'required',
  swift: 'required',
  company_euro: 'required',
  bank_euro: 'required',
  iban_euro: 'required',
  swift_euro: 'required',
});

class ManageConfigsPage extends Component {
  state = {
    loading: true,
  };

  componentDidMount = async () => {
    try {
      const { dispatch, CloseMenu, user, history } = this.props;
      
      if(user.type === 2 || user.type === 3) {
        return history.push('/');
      }

      CloseMenu();

      const configurations = await GetConfig(configID);
      dispatch(
        initialize('manage_config_form', {
          ...configurations.data,
        })
      );

      this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { history } = this.props;

      const formDataValues = TransformToFormData(values);
      await UpdateConfigType(configID, formDataValues);
      return history.push('/configs');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/configs');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Informações Gerais'
          breadcrumbs={['Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='email_notifications'
              type='text'
              label={'Email para Notificações *'}
              placeholder={'Introduza o(s) emails(s) para receber as notificações'}
            />
            <Separator />
            <Field
              component={TextInput}
              name='whatsapp_number'
              type='text'
              label={'Contacto WhatsApp'}
              placeholder={'Introduza o número do WhatsApp'}
              first
            />
            <Separator />
            <Field
              component={NumberInput}
              name={'commission_lemania'}
              label={'Comissão Lemania (%)'}
              placeholder={'0.00%'}
              min={0}
              max={100}
              first
            />
            <Field
              component={NumberInput}
              name={'tax'}
              label={'IVA (%)'}
              placeholder={'0.00%'}
              min={0}
            />
            <Separator />
            <Field
              component={TextInput}
              name='nif'
              type='text'
              label={'NIF'}
              placeholder={'Introduza o nº de contribuinte'}
              first
            />
            <Field
              component={TextInput}
              name='address'
              type='text'
              label={'Morada *'}
              placeholder={'Introduza a morada da empresa'}
              first
            />
            <Field
              component={TextInput}
              name='cod_postal'
              type='text'
              label={'Código Postal *'}
              placeholder={'Introduza o código postal da empresa'}
            />
            <Field
              component={TextInput}
              name='city'
              type='text'
              label={'Cidade *'}
              placeholder={'Introduza a cidade da empresa'}
            />
            <Field
              component={TextInput}
              name='latitude'
              type='text'
              label={'Latitude *'}
              placeholder={'Introduza a latitude empresa'}
            />
            <Field
              component={TextInput}
              name='longitude'
              type='text'
              label={'Longitude *'}
              placeholder={'Introduza a longitude da empresa'}
            />
            <Separator />
            <Field
              component={TextInput}
              name='phone1'
              type='text'
              label={'1º Contacto *'}
              placeholder={'Introduza o 1º contacto da empresa'}
            />
            <Field
              component={TextInput}
              name='phone2'
              type='text'
              label={'2º Contacto *'}
              placeholder={'Introduza o 2º contacto da empresa'}
            />
            <Field
              component={TextInput}
              name='email'
              type='text'
              label={'Email *'}
              placeholder={'Introduza o email geral da empresa'}
            />
            <Field
              component={TextInput}
              name='facebook_page'
              type='text'
              label={'Página de Facebook'}
              placeholder={'Introduza o link da página do Facebook'}
            />
            <Field
              component={TextInput}
              name='instagram_page'
              type='text'
              label={'Página de Instagram'}
              placeholder={'Introduza o link da página do Instagram'}
            />
            <Separator />
            <Field
              component={TextInput}
              name='company'
              type='text'
              label={'Empresa (CHF) *'}
              placeholder={'Introduza a Empresa para pagamentos em CHF'}
            />
            <Field
              component={TextInput}
              name='bank'
              type='text'
              label={'Banco (CHF) *'}
              placeholder={'Introduza o Banco para pagamentos em CHF'}
            />
            <Field
              component={TextInput}
              name='iban'
              type='text'
              label={'IBAN (CHF) *'}
              placeholder={'Introduza o IBAN para pagamentos em CHF'}
            />
            <Field
              component={TextInput}
              name='swift'
              type='text'
              label={'SWIFT (CHF) *'}
              placeholder={'Introduza o SWIFT para pagamentos em CHF'}
            />
            <Separator />
            <Field
              component={TextInput}
              name='company_euro'
              type='text'
              label={'Empresa (EURO) *'}
              placeholder={'Introduza a Empresa para pagamentos em Euros'}
            />
            <Field
              component={TextInput}
              name='bank_euro'
              type='text'
              label={'Banco (EURO) *'}
              placeholder={'Introduza o Banco para pagamentos em Euros'}
            />
            <Field
              component={TextInput}
              name='iban_euro'
              type='text'
              label={'IBAN (EURO) *'}
              placeholder={'Introduza o IBAN para pagamentos em Euros'}
            />
            <Field
              component={TextInput}
              name='swift_euro'
              type='text'
              label={'SWIFT (EURO) *'}
              placeholder={'Introduza o SWIFT para pagamentos em Euros'}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageConfigsPage = reduxForm({
  form: 'manage_config_form',
  validate: validations,
})(ManageConfigsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(ManageConfigsPage)
);