/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { CreateNote } from '../../../../infra/requests/ProcessRequests';
import React, { useEffect, useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextAreaInput from '../../../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  description: 'required'
});

const NewNote = ({ openModal, closeModal, processID, getInfo, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(initialize('new_note_form'));
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await CreateNote({ process: processID, user: user._id, ...values });

      if(result?.success) {
        notification.success({
          message: 'Nota inserida com sucesso!'
        });

        dispatch(initialize('new_note_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Inserir Nota Interna</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={TextAreaInput}
            name={'description'}
            placeholder={'Insira uma nota'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewNoteForm = reduxForm({
  form: 'new_note_form',
  validate: validations
})(NewNote);

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(NewNoteForm);
