import { Row } from 'antd';
import { ModalWrapper, CardTitle, Card } from './styles';
import React from 'react';

const ViewEmailContentModal = ({ open, closeModal, data }) => {
  return (
    <ModalWrapper
      visible={open}
      maskClosable
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <Row gutter={24} style={{ margin: 0, marginTop: '20px' }}>
        <CardTitle>Assunto</CardTitle>
        <Card>
          {data.subject}
        </Card>
      </Row>
      <Row gutter={24} style={{ margin: 0, marginTop: '10px' }}>
        <CardTitle>Conteúdo</CardTitle>
        <Card>
          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.content }} />
        </Card>
      </Row>
    </ModalWrapper>
  );
};

export default ViewEmailContentModal;