import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { StateLabel } from '../process/styles';
import {
  GetCodeList,
  DeleteCode,
  TogglePromoCode
} from '../../infra/requests/PromoCodesRequests';
import { GetServicesList } from '../../infra/requests/ServicesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import PromoCodesFilters from './PromoCodesFilters';
import { getServiceMode } from '../../infra/utils/ServiceModes';
import { getPaymentMethod } from '../../infra/utils/PaymentMethods';
import CurrencyComponent from '../../components/generic/currency/CurrencyComponent';
import { connect } from 'react-redux';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class PromoCodesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: (value) => value,
        width: '15%',
      },
      {
        title: 'Código',
        dataIndex: 'code',
        render: (value) => value,
        width: '11%',
      },
      {
        title: 'Desconto',
        key: 'discount',
        render: (data) => this.renderDiscount(data),
        width: '10%',
      },
      {
        title: 'Serviço',
        dataIndex: 'service',
        render: (value) => this.renderService(value),
        width: '16%',
      },
      {
        title: 'Modalidade Serviço',
        dataIndex: 'service_mode',
        render: (value) => this.renderServiceMode(value),
        width: '16y%',
      },
      {
        title: 'Método Pagamento',
        dataIndex: 'payment_method',
        render: (value) => this.renderPaymentMethod(value),
        width: '15%',
      },
      {
        title: 'Estado',
        dataIndex: 'active',
        render: (value) => this.renderState(value),
        width: '9%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    services: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/promo-codes/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/promo-codes/${data._id}`),
            label: 'Editar' 
          },
          {
            popConfirmtitle: data.active ? 'Quer desativar este Código?' : ' Quer ativar este Código?',
            onClick: () => this.toggleActive(data),
            label: data.active ? 'Desativar' : 'Ativar'
          },
          {
            popConfirmtitle: 'Tem a certeza que quer remover este Código?',
            onClick: () => this.removePromoCode(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  };

  removePromoCode = async (data) => {
    try {
      await DeleteCode(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetCodeList(currentPage, pageSize, filters);
      const services = await GetServicesList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        services: services?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await TogglePromoCode(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Ativo
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Inativo
      </StateLabel>
    );
  };

  renderDiscount = (data) => {
    if(data.discount_type === 1) {
      return (
        <React.Fragment>
          <CurrencyComponent
            value={data.discount_chf}
            suffix='CHF'
          />
          /
          <CurrencyComponent
            value={data.discount_euro}
            suffix='€'
          />
        </React.Fragment>
      );
    }
    else if(data.discount_type === 2) {
      return (
        <React.Fragment>
          <CurrencyComponent
            value={data.discount_perc}
            suffix='%'
          />
        </React.Fragment>
      );
    }
    return '-';
  }

  renderService = (value) => {
    if(value) return value?.title?.pt;
    return '-';
  };

  renderServiceMode = (value) => {
    if(value) return getServiceMode(value);
    return '-';
  };

  renderPaymentMethod = (value) => {
    if(value) return getPaymentMethod(value);
    return '-';
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, services } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Códigos Promocionais</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              customIcon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/promo-codes/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <PromoCodesFilters 
            queryChange={this.handleFilterChange}
            services={services}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Códigos Promocionais inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(PromoCodesPage);