import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
`;

export const OverflowWrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  border: none;
  border-spacing: 0px;
  width: 100%;
  border-collapse: separate;
`;

export const THead = styled.thead`

`;

export const THeadRow = styled.tr`
  background-color: #DEE2E6;
`;

export const THeadCol = styled.td`
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #495057;
  padding: 12px 10px;
  width: 150px;

  &:first-child {
    width: 300px;
    min-width: 180px;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #DEE2E6;
  }
`;

export const TBody = styled.tbody`

`;

export const TBodyRow = styled.tr`
  &:nth-child(odd) {
    background-color: #F0F0F0;

    & td:first-child {
      background-color: #F0F0F0;
    }
  }

  &:nth-child(even) {
    background-color: #E8E8E8;

    & td:first-child {
      background-color: #E8E8E8;
    }
  }
`;

export const TBodyCol = styled.td`
  padding: 10px 10px;
  width: 150px;
  min-width: 150px;
  font-size: 13px;
  line-height: 18px;

  &:first-child {
    width: 300px;
    min-width: 180px;
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const TFooter = styled.tfoot`

`;

export const TFooterRow = styled.tr`
  background-color: #DEE2E6;
`;

export const TFooterCol = styled.td`
  padding: 10px 10px;
  width: 150px;
  min-width: 150px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;

  &:first-child {
    width: 300px;
    min-width: 180px;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #DEE2E6;
  }
`;

export const NoRecords = styled.div`
  width: 100%;
  color: #868E96;
  padding: 12px 24px;
  text-align: center;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 15px 10px;
  text-align: left;

  & div {
    line-height: 1.5;
  }

  & div:first-child {
    margin-bottom: 5px;
  }
`;
