import { connect } from 'react-redux';
import { Form, Modal, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { UpdateProcessFile } from '../../../../infra/requests/ProcessRequests';
import { FileCategories } from '../../../../infra/utils/FileCategories';
import React, { useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';

const validations = FormValidator.make({
  category: 'required'
});

const EditDocumentCategory = ({ openModal, closeModal, getInfo, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      
      const result = await UpdateProcessFile(values._id, values);

      if(result?.success) {
        notification.success({
          message: 'Documento alterado com sucesso!'
        });

        dispatch(initialize('edit_document_category_form'));
        
        await getInfo();
        closeModal();
      }
      
      setLoading(false);      
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(initialize('edit_document_category_form'));
    closeModal();
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Editar Categoria do Documento</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            label={'Categoria *'}
            placeholder={'Selecione uma categoria'}
            name={'category'}
            type='select'
            data={FileCategories.filter(elem => elem.insert)}
            dataKey={'_id'}
            dataLabel={'name'}
            allowClear={true}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditDocumentCategoryForm = reduxForm({
  form: 'edit_document_category_form',
  validate: validations
})(EditDocumentCategory);

export default connect()(EditDocumentCategoryForm);
