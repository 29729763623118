import { connect } from 'react-redux';
import { Form, Modal, notification } from 'antd';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { Field, reduxForm, initialize } from 'redux-form';
import { SendProcessPdf } from '../../../../infra/requests/ProcessRequests';
import React, { useState } from 'react';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import TextInput from '../../../../components/generic/inputs/TextInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  email: 'required|email',
  address: 'required'
});

const PdfEmailAddress = ({ openModal, closeModal, processInfo, pdfType, addressList, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);
  
  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const filters = { ...values, type: pdfType };
      const { success } = await SendProcessPdf(processInfo._id, JSON.stringify(filters));  

      if(success) {
        notification.success({
          message: "Email enviado!",
          description: "O email com o PDF anexado foi enviado com sucesso."
        });
      }
      else {
        notification.error({
          message: "Ocorreu um erro!",
          description: "Por favor tente mais tarde."
        });
      }

      dispatch(initialize('pdf_email_address_form'));
      setLoading(false);
      closeModal();
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClose = () =>{
    dispatch(initialize('pdf_email_address_form'));
    closeModal();
  }

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Enviar Email</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name='email'
            component={TextInput}
            type='email'
            label='Email do destinatário'
            placeholder='Email'
          />
          <Field
            component={SelectInput}
            name={'address'}
            label='Selecione uma morada'
            placeholder={'Escolha uma morada'}
            type='select'
            data={addressList}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Enviar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const PdfEmailAddressForm = reduxForm({
  form: 'pdf_email_address_form',
  validate: validations
})(PdfEmailAddress);

export default connect()(PdfEmailAddressForm);