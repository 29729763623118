import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';

const options = [
  {
    _id: 1,
    name: 'Formulário de Contacto'
  },
  {
    _id: 2,
    name: 'Seguros'
  },
  {
    _id: 3,
    name: 'Créditos'
  },
  {
    _id: 4,
    name: '2º Pilar para Empresas'
  },
  {
    _id: 5,
    name: 'Imobiliário'
  }
];

class ContactRequestsFilters extends Component {
  state = { 
    search: '',
    date: '',
    origin: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, date, origin } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por primeiro nome, último nome, email ou contacto'
            />
          </Col>
          <Col xs={24} md={8}>
            <SelectInput
              label="Origem"
              allowClear
              placeholder="Escolher origem"
              data={options}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: origin,
                onChange: value => this.onInputChange('origin', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={8}>
            <DateInput
              label="Data"
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default ContactRequestsFilters;