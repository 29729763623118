import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetPost,
  UpdatePost,
  CreatePost,
  GetCategoriesList,
} from '../../infra/requests/BlogRequests';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  InputNote,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import LanguagesInput from '../../components/generic/inputs/LanguagesInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import DateInput from '../../components/generic/inputs/DateInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  identifier: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  description: 'required|languages',
  image: 'required',
  image_detail: 'required',
  date: 'required',
  categories: 'required'
});

class ManageBlogPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    initialValues: null,
    categories: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const categories = await GetCategoriesList();

    if(params.id) {
      const result = await GetPost(params.id);
      dispatch(initialize('manage_blog_form', { 
        ...result.data,
        categories: result?.data?.categories.map(elem => elem._id) || []
      }));

      this.setState({ 
        loading: false,
        initialValues: result.data,
        categories: categories?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        categories: categories?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;

      if(values.image && !values.image.blob) delete values.image;
      if(values.image_detail && !values.image_detail.blob) delete values.image_detail;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdatePost(params.id, payload) : await CreatePost(payload);

      if(success) return history.push('/blog/list');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/blog/list');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading, categories } = this.state;

    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Blog'
          breadcrumbs={['Posts', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <SectionTitle>Imagem</SectionTitle>
                <Col md={12} xs={24}>
                  <Field
                    component={ImageInput}
                    name='image'
                    label='Imagem de capa *'
                    ratio={0.8}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={ImageInput}
                    name='image_detail'
                    label='Imagem do detalhe *'
                    ratio={0.5}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Informação Principal</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título do post'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título do post'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={DateInput}
                    name='date'
                    label={'Data *'}
                    placeholder={'Data do post'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    mode="multiple"
                    allowClear
                    component={SelectInput}
                    name='categories'
                    label={'Categorias *'}
                    placeholder={'Selecionar categorias'}
                    type='select'
                    data={categories}
                    dataLabel='title'
                    translatable
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Textos</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'description'}
                    type='draft'
                    label={'Descrição'}
                    placeholder={'Descrição'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Metatags</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name={`identifier`}
                    type='text'
                    label={'URL *'}
                    placeholder={'URL do Serviço'}
                  />
                  <InputNote>
                    <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
                    acentos, letras minúsculas e apenas com letras, números e
                    hífens.
                  </InputNote>
                  <InputNote>
                    <span>EXEMPLO:</span> Título do Serviço: "Novo Serviço 2020"
                    / URL: "novo-servico-2020"
                  </InputNote>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`meta_title.${activeLanguage?.code}`}
                    label={'Meta Title'}
                    type={'text'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_description.${activeLanguage?.code}`}
                    label={'Meta Description'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_keywords.${activeLanguage?.code}`}
                    label={'Meta Keywords'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`meta_title.${language.code}`}
                            label={'Meta Title'}
                            type={'text'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageBlogPage = reduxForm({
  form: 'manage_blog_form',
  validate: validations,
})(ManageBlogPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageBlogPage));