import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import {
  GetAddress,
  UpdateAddress,
  CreateAddress
} from '../../infra/requests/AddressRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import { GetAdminList } from '../../infra/requests/UsersRequests';
import { Note, Title } from './AddressStyles';

const validations = FormValidator.make({
  name: 'required',
  address: 'required',
  cod_postal: 'required',
  city: 'required',
  country: 'required'
});

class ManageAddressPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    admins: [],
    showAdmins: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user, 
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 

    CloseMenu();

    const admins = await GetAdminList();

    if(params.id) {
      const result = await GetAddress(params.id);
  
      dispatch(
        initialize('manage_address_form', {
          ...result?.data, 
          user: result?.data?.user?._id
        })
      );

      this.setState({ 
        loading: false,
        admins: admins?.data || [],
        showAdmins: !result?.data?.main_address
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        admins: admins?.data || [],
        showAdmins: true
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      if(values.main_address || !values.user) values.user = null;
      if(values.user) values.main_address = false;

      const { success } = params.id ? await UpdateAddress(params.id, values) : await CreateAddress(values);

      if(success) return history.push('/address');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/address');
  };

  checkMainAddress = (e) => {
    if(e?.target?.checked) this.setState({ showAdmins: false });
    else this.setState({ showAdmins: true });
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, admins, showAdmins } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Moradas'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={`name`}
                    type='text'
                    label={'Nome *'}
                    placeholder={'Nome'}
                  />
                </Col>
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={TextAreaInput}
                    name={`address`}
                    type='text'
                    label={'Morada *'}
                    placeholder={'Morada'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={`cod_postal`}
                    type='text'
                    label={'Cód. Postal *'}
                    placeholder={'Cód. Postal'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={`city`}
                    type='text'
                    label={'Cidade *'}
                    placeholder={'Cidade'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={`country`}
                    type='text'
                    label={'País *'}
                    placeholder={'País'}
                  />
                </Col>
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={'email'}
                    type='email'
                    label={'Email'}
                    placeholder={'Email'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={TextInput}
                    name={'contact'}
                    type='text'
                    label={'Contacto'}
                    placeholder={'Contacto'}
                  />
                </Col>
              </Row>
              <Separator/>
              <Title>Associar esta morada a um Administrador ou definir como morada principal das Reformas</Title>
              <Note><span style={{ color: 'rgba(255, 0, 0, 0.8)' }}>Nota: </span> Pode associar um administrador a esta morada <span>OU</span> definir como morada principal das Reformas na Suíça.</Note>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={CheckboxInput}
                    name={'main_address'}
                    label={'Morada principal das Reformas?'}
                    execAfterChange={this.checkMainAddress}
                  />
                </Col>
                {
                  showAdmins &&
                  <Col md={12} xs={24}>
                    <Field
                      component={SelectInput}
                      name={'user'}
                      label={'Administrador'}
                      placeholder={'Escolha o utilizador'}
                      type='select'
                      data={admins}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      allowClear
                    />
                  </Col>
                }
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageAddressPage = reduxForm({
  form: 'manage_address_form',
  validate: validations,
})(ManageAddressPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ManageAddressPage);