import { PageContainer, PageTitle, HeaderContainer, HeaderTitle } from '../../styles/BasicStyles';
import { GetProcessList, ToggleProcessArchive } from '../../infra/requests/ProcessRequests';
import { GetClientsList } from '../../infra/requests/ClientsRequests';
import { GetServicesList } from '../../infra/requests/ServicesRequests';
import { GetAdminList, GetAgentsList, GetPartnersList } from '../../infra/requests/UsersRequests';
import { renderProcessPaymentState, renderProcessState } from '../../infra/utils/ProcessStates';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ArchiveFilters from './ArchiveFilters';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import Cookies from 'js-cookie';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class ArchivePage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '{"archived":true}',
    loading: true,
    ready: false,
    columns: [],
    rows: [],
    total: 0,
    services: [],
    users: [],
    clients: [],
    agents: [],
    partners: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/archive/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    const { user } = this.props;
    const { pageSize, filters } = this.state;
    
    //When changing pages, maintain the current pageSize
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage,
      pageSize: pageSize || 30
    });
    Cookies.set('archiveFilters', cookie, { expires: timeout });

    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    const { user } = this.props;
    const { filters } = this.state;

    //Changing the number of rows per page, always reset the page to 1
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage: 1,
      pageSize
    });
    Cookies.set('archiveFilters', cookie, { expires: timeout });

    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  archiveProcess = async (data) => {
    try {
      await ToggleProcessArchive(data._id, { archived: false });
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { user } = this.props;
      let { filters, currentPage, pageSize } = this.state;

      const cookieJson = Cookies.get('archiveFilters');
      if(cookieJson) {
        const cookie = JSON.parse(cookieJson);

        if(cookie?.user === user._id) {
          filters = cookie.filters ? JSON.stringify(cookie.filters) : '';
          currentPage = cookie.currentPage || 1;
          pageSize = cookie.pageSize || 30;
        }
      }

      const result = await GetProcessList(currentPage, pageSize, filters);
      const services = await GetServicesList();
      const users = user?.type === 1 ? await GetAdminList() : [];
      const agents = user?.type === 1 ? await GetAgentsList() : [];
      const partners = user?.type === 1 ? await GetPartnersList() : [];
      const clients = user?.type === 1 ? await GetClientsList() : [];

      const responsibles = users?.data || [];
      responsibles.unshift({
        _id: 'no_user',
        name: 'Sem responsável'
      });

      let columns = [
        {
          title: 'Ref. Processo',
          dataIndex: 'ref',
          render: (value) => value,
          width: '14%',
        },
        {
          title: 'Data de Compra',
          dataIndex: 'purchase_date',
          render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
          width: '14%',
        },
        {
          title: 'Nome',
          dataIndex: 'full_name',
          render: (value) => value,
          width: '20%',
        },
        {
          title: 'Estado do Processo',
          dataIndex: 'state',
          render: (value) => renderProcessState(value),
          width: '18%',
        },
        {
          title: 'Estado de Pagamento',
          key: 'process_state_payment',
          render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
          width: '18%'
        },
        {
          title: 'Valor pendente',
          dataIndex: 'pending_value',
          width: '10%'
        },
        {
          title: 'Ações',
          render: (data) => this.renderActions(data)
        }
      ];

      if(user?.type === 1 || user?.type === 3) {
        columns = [
          {
            title: 'Ref. Processo',
            dataIndex: 'ref',
            render: (value) => value,
            width: '8%',
          },
          {
            title: 'Data de Compra',
            dataIndex: 'purchase_date',
            render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
            width: '10%',
          },
          {
            title: 'Nome',
            dataIndex: 'full_name',
            render: (value) => value,
            width: '18%',
          },
          {
            title: 'Serviço',
            dataIndex: 'service',
            render: (value) => value?.title?.pt,
            width: '17%',
          },
          {
            title: 'Estado do Processo',
            dataIndex: 'state',
            render: (value) => renderProcessState(value),
            width: '17%'
          },
          {
            title: 'Estado de Pagamento',
            key: 'process_state_payment',
            render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
            width: '17%'
          },
          {
            title: 'Valor pendente',
            dataIndex: 'pending_value',
            width: '8%'
          },
          {
            title: 'Ações',
            render: (data) => this.renderActions(data)
          }
        ];
      }

      this.setState({
        rows: result?.data?.items || [],
        total: result?.data?.total || 0,
        services: services?.data || [],
        users: responsibles,
        clients: clients?.data || [],
        agents: agents?.data || [],
        partners: partners?.data || [],
        columns,
        currentPage,
        pageSize
      });

      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history, user } = this.props;

    return (
      <>
        {
          user?.type !== 3 &&
          <ButtonActions 
            icon={'dots'} 
            iconType={'fill'} 
            options={[
              {
                onClick: () => history.push(`/archive/${data._id}`),
                label: 'Ver detalhe' 
              },
              {
                popConfirmtitle: 'Tem a certeza que quer restaurar o processo?',
                onClick: () => this.archiveProcess(data),
                label: 'Restaurar' 
              }
            ]} 
          />
        }
      </>
    );
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      services,
      users,
      clients,
      agents,
      partners
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Lista de Processos Arquivados</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <ArchiveFilters
            queryChange={this.handleFilterChange}
            services={services}
            users={users}
            clients={clients}
            agents={agents}
            partners={partners}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Processos arquivados!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ArchivePage);