/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Tooltip } from 'antd';
import { FormContainer } from '../../../styles/BasicStyles';
import { FixedWrapper, LastUpdate } from './styles';
import { CardWrapper, Card, CardContainer, CardItem, CardTitle, CardValue, MissingLabel } from '../styles';
import { ArchivedWrapper, ArchivedContent, ServiceContainer } from './styles';
import { renderProcessState, renderProcessPaymentState } from '../../../infra/utils/ProcessStates';
import { getLanguage } from '../../../infra/utils/Languages';
import { ReactSVG } from 'react-svg';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getServiceMode } from '../../../infra/utils/ServiceModes';
import React, { useState } from 'react';
import EditProcessStateModal from '../modals/EditProcessState';
import EditProcessResponsibleModal from '../modals/EditProcessResponsible';
import EditProcessLanguageModal from '../modals/EditProcessLanguage';
import moment from 'moment-timezone';

const ProcessHeader = ({ processInfo, dispatch, getInfo, responsibles, currentResponsible, lastLog, history, user }) => {
  const [processStateModal, setProcessStateModal] = useState(false);
  const [processResponsibleModal, setProcessResponsibleModal] = useState(false);
  const [processLanguageModal, setProcessLanguageModal] = useState(false);

  const openEditProcessStateModal = () => {
    dispatch(initialize('edit_process_state_form', { state: processInfo.state }));
    setProcessStateModal(true);
  }

  const openEditProcessResponsibleModal = () => {
    dispatch(initialize('edit_process_responsible_form', { responsible: processInfo.responsible }));
    setProcessResponsibleModal(true);
  }

  const openEditProcessLanguageModal = () => {
    dispatch(initialize('edit_process_language_form', { language: processInfo.language }));
    setProcessLanguageModal(true);
  }

  const showLogsPage = async () => {
    history.push(`/process/${processInfo._id}?showLogs`);

    await getInfo();
  }

  const getServiceTypeIcon = () => {
    if(processInfo.service_mode === 1) return `${process.env.REACT_APP_BO_URL}online.svg`;
    if(processInfo.service_mode === 2) return `${process.env.REACT_APP_BO_URL}postal.svg`;
    if(processInfo.service_mode === 3) return `${process.env.REACT_APP_BO_URL}presential.svg`;
    return null;
  }

  const renderProcessHeader = () => {
    return (
      <>
        {
          processInfo.archived &&
          <ArchivedWrapper>
            <ArchivedContent>
              Este processo encontra-se arquivado! Apenas pode consultar a informação.
            </ArchivedContent>
          </ArchivedWrapper>
        }
        <FormContainer style={{ paddingBottom: '5px' }}>
          <CardWrapper gutter={24}>
            <Card xs={24} style={{ paddingTop: '15px', paddingBottom: '15px', marginBottom: '0px' }}>
              <CardContainer style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                <Row className='infoRow' gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
                  <Col xl={12} xs={24}>
                    <Col lg={12} xs={24}>
                      {
                        user?.type === 1 && processInfo.agent &&
                        <CardItem style={{ paddingBottom: '8px' }}>
                          <CardTitle agentLabel className='cardTitle'>Agente:</CardTitle>
                          <CardValue agentLabel className='cardValue'>{processInfo.agent.name}</CardValue>
                        </CardItem>
                      }
                      {
                        user?.type === 1 && processInfo.partner &&
                        <CardItem style={{ paddingBottom: '8px' }}>
                          <CardTitle agentLabel className='cardTitle'>Parceiro:</CardTitle>
                          <CardValue agentLabel className='cardValue'>{processInfo.partner.name}</CardValue>
                        </CardItem>
                      }
                      <CardItem style={{ paddingBottom: '8px' }}>
                        <CardTitle className='cardTitle'>Nome:</CardTitle>
                        <CardValue className='cardValue'>{processInfo.full_name}</CardValue>
                      </CardItem>
                      {
                        !processInfo.agent &&
                        <CardItem style={{ paddingBottom: '8px' }}>
                          <CardTitle className='cardTitle'>Serviço:</CardTitle>
                          <CardValue className='cardValue'>
                            <ServiceContainer>
                              <span>{processInfo.service_name} <span className='purchaseDate'>({moment(processInfo.purchase_date).format('DD/MM/YYYY')})</span></span>
                              <Tooltip title={getServiceMode(processInfo.service_mode)}>
                                <ReactSVG src={getServiceTypeIcon()} />
                              </Tooltip>
                            </ServiceContainer>
                          </CardValue>
                        </CardItem>
                      }
                    </Col>
                    <Col lg={12} xs={24}>
                      {
                        !processInfo.agent && user?.type !== 3 &&
                        <CardItem style={{ paddingBottom: '8px' }}>
                          <CardTitle className='cardTitle'>Responsável:</CardTitle>
                          <CardItem center='true'>
                            <CardValue className='cardValue' noExpandWidth='true'>
                              {
                                currentResponsible ? 
                                currentResponsible.name : 
                                <MissingLabel className='missingLabel'>
                                  <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                                  <span>Sem Responsável</span>
                                </MissingLabel>
                              }
                            </CardValue>
                            {
                              !processInfo.archived && user?.type !== 3 &&
                              <ReactSVG src={`${process.env.REACT_APP_BO_URL}pencil.svg`} className='icon' onClick={() => openEditProcessResponsibleModal()}/>
                            }
                          </CardItem>
                        </CardItem>
                      }
                      <CardItem style={{ paddingBottom: '8px' }}>
                        <CardTitle className='cardTitle'>Idioma:</CardTitle>
                        <CardItem center='true'>
                          <CardValue className='cardValue' noExpandWidth='true'>{getLanguage(processInfo.language)?.name}</CardValue>
                          {
                            !processInfo.archived && user?.type !== 3 &&
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}pencil.svg`} className='icon' onClick={() => openEditProcessLanguageModal()} />
                          }
                        </CardItem>
                      </CardItem>
                    </Col>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Col lg={12} xs={24}>
                      <CardItem style={{ paddingBottom: '8px' }}>
                        <CardTitle className='cardTitle'>Estado do Processo:</CardTitle>
                      </CardItem>
                      <CardItem style={{ paddingBottom: '8px' }} center='true'>
                        {renderProcessState(processInfo.state)}
                        {
                          !processInfo.archived && user?.type !== 3 &&
                          <ReactSVG src={`${process.env.REACT_APP_BO_URL}pencil.svg`} className='icon' onClick={() => openEditProcessStateModal()} />
                        }
                      </CardItem>
                    </Col>
                    <Col lg={12} xs={24}>
                      <CardItem style={{ paddingBottom: '8px' }}>
                        <CardTitle className='cardTitle'>Estado do Pagamento:</CardTitle>
                      </CardItem>
                      <CardItem style={{ paddingBottom: '8px' }} center='true'>
                        {
                          processInfo.process_state_payment ?
                          renderProcessPaymentState(processInfo.process_state_payment, processInfo.free)
                          :
                          <MissingLabel>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                            <span>Sem estado de pagamento</span>
                          </MissingLabel>
                        }
                      </CardItem>
                    </Col>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px' }}>
                  <Col xs={24} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                    <LastUpdate onClick={() => showLogsPage()}>
                      <span>Ultima Alteração:</span> 
                      <span dangerouslySetInnerHTML={{ __html: 
                        lastLog?.description ?
                        ` ${moment(lastLog.createdAt).format('DD/MM/YYYY HH:mm')}h, ${lastLog.user?._id ? lastLog.user.name : lastLog.callback ? 'Stripe MB Callback' : lastLog.cronjob ? 'CronJob' : 'Cliente'}, ${lastLog.description}`
                        :
                        ' -'
                      }}/>
                    </LastUpdate>
                  </Col>
                </Row>
              </CardContainer>
            </Card>
          </CardWrapper>
        </FormContainer>
      </>
    );
  }

  return (
    <>
      {renderProcessHeader()}
      <FixedWrapper id='processHeaderFixed'>
        {renderProcessHeader()}
      </FixedWrapper>
      {
        !processInfo.archived && user?.type !== 3 &&
        <>
          <EditProcessStateModal
            openModal={processStateModal}
            closeModal={() => setProcessStateModal(false)}
            processID={processInfo._id}
            getInfo={getInfo}
          />
          {
            !processInfo.agent &&
            <EditProcessResponsibleModal
              openModal={processResponsibleModal}
              closeModal={() => setProcessResponsibleModal(false)}
              processID={processInfo._id}
              responsibles={responsibles}
              getInfo={getInfo}
            />
          }
          <EditProcessLanguageModal
            openModal={processLanguageModal}
            closeModal={() => setProcessLanguageModal(false)}
            processID={processInfo._id}
            getInfo={getInfo}
          />
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(ProcessHeader));
