/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../process/styles';
import { CreateLemania } from '../../infra/requests/ProcessRequests';
import React, { useEffect, useState } from 'react';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../components/generic/buttons/BaseButton';
import FundsList from '../process/modals/NewLemania/FundsList';
import SelectInput from '../../components/generic/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  process: 'required'
});

const NewLemania = ({ openModal, closeModal, processList, getInfo, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(initialize('new_lemania_general_form'));
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      // If there's not a description or a fund selected, don't insert a empty record
      if(values.description || values.funds?.length > 0) {
        const result = await CreateLemania(values);

        if(result?.success) {
          notification.success({
            message: 'Lemania inserida com sucesso!'
          });
  
          dispatch(initialize('new_lemania_general_form'));
  
          closeModal();
  
          await getInfo();
        }
      }
      else {
        notification.error({
          message: 'Deverá preencher a descrição ou adicionar algum fundo!'
        });
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
      width={850}
    >
      <>
        <ModalTitle>Conta Lemania</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'process'}
            label={'Processo *'}
            placeholder={'Selecionar processo'}
            data={processList}
            dataKey={'_id'}
            dataLabel={'name'}
            allowClear={true}
          />
          <Field
            component={TextAreaInput}
            name={'description'}
            label={'Descrição'}
            placeholder={'Insira uma descrição'}
          />
          <FieldArray 
            component={FundsList} 
            name='funds' 
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Adicionar Conta'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewLemaniaForm = reduxForm({
  form: 'new_lemania_general_form',
  validate: validations
})(NewLemania);

export default connect()(NewLemaniaForm);
