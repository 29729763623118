import { DropzoneField, DropzoneNote, File, Filename } from './styles';
import { ReactSVG } from 'react-svg';
import { notification } from 'antd';
import React from 'react';
import Dropzone from 'react-dropzone';

const DropzoneComponent = ({ 
  input, 
  meta, 
  maxSize = 8000000,
  maxSizeText = '8MB',
  allowedFormats = 'image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  allowedFormatsText = '.jpg/.jpeg, .png, .pdf, .doc, .docx'
}) => {
  const buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  const openFile = (file) => {
    const link = file.preview ? file.preview : `${process.env.REACT_APP_API}/files/${file._id}`;
    window.open(link, '_blank');
  };

  const handleDrop = (accepted, rejected) => {
    if(rejected.length > 0) {
      if(rejected[0].errors?.length > 0 && rejected[0].errors[0].code === 'file-too-large') {
        return notification.error({
          message: 'Ficheiro Inválido',
          description: 'O ficheiro não foi anexado porque tem um tamanho superior a 8Mb!',
        });
      }
      else if(rejected[0].errors?.length > 0 && rejected[0].errors[0].code === 'file-invalid-type') {
        return notification.error({
          message: 'Ficheiro Inválido',
          description: 'O ficheiro não foi anexado porque tem um formato inválido!',
        });
      }
      else {
        return notification.error({
          message: 'Ficheiro Inválido',
          description: 'O ficheiro não foi anexado porque é inválido!',
        });
      }
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);
  };

  const renderDropZone = () => {
    const { invalid, submitFailed } = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept={allowedFormats}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <>
              <DropzoneField error={showError} {...getRootProps()}>
                <input {...getInputProps()} />
                <ReactSVG src={`${process.env.REACT_APP_BO_URL}folder_plus.svg`} className='icon' />
                <span>Clique ou arraste um ficheiro</span>
              </DropzoneField>
              <DropzoneNote>Tamanho máximo do ficheiro: {maxSizeText}</DropzoneNote>
              <DropzoneNote>Formatos permitidos: {allowedFormatsText}</DropzoneNote>
            </>
          );
        }}
      </Dropzone>
    );
  }

  if(input.value) {
    return (
      <>
        {renderDropZone()}
        <File style={{ marginTop: '20px' }} onClick={() => openFile(input.value)}>
          <Filename>
            {input.value.blob?.name || input.value.filename}
          </Filename>
          <ReactSVG 
            src={`${process.env.REACT_APP_BO_URL}close_circle.svg`} 
            className='icon' 
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); input.onChange(null); }} 
          />
        </File>
      </>
    );
  }

  return (
    <>
      {renderDropZone()}
    </>
  )
};

export default DropzoneComponent;
