export const UserTypes = [
  { _id: 1, name: 'Administrador' },
  { _id: 2, name: 'Agente' },
  { _id: 3, name: 'Parceiro' },
  { _id: 4, name: 'Agente Imobiliário' }
];

export const getUserType = (value) => {
  if(Number.isInteger(value) && value > 0 && UserTypes[value - 1]) return UserTypes[value - 1].name;
  return null;
};