import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../styles/BasicStyles';
import { GetTasks, DeleteTask, UpdateTaskState } from '../../infra/requests/TasksRequests';
import { GetAllUsersList, GetUserAdminsList } from '../../infra/requests/UsersRequests';
import { GetUserProcessFullList} from '../../infra/requests/ProcessRequests';
import { getTask, TasksStates } from '../../infra/utils/TasksStates';
import { StateLabel } from '../process/styles';
import { StateWrapper } from '../process/edit/Tasks/styles';
import { notification } from 'antd';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import BaseButton from '../../components/generic/buttons/BaseButton';
import TasksFilters from './TasksFilters';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class TasksPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '{"validated":false}',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '12%',
      },
      {
        title: 'Processo',
        dataIndex: 'process',
        render: (value) => value?.ref,
        width: '10%'
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        render: (value) => <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>,
        width: '20%',
      },
      {
        title: 'Responsável',
        dataIndex: 'responsible',
        render: (value) => value.name,
        width: '13%',
      },
      {
        title: 'Destinatário',
        dataIndex: 'recipient',
        render: (value) => value.name,
        width: '13%',
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: (value) => value ? `${moment(value).format('DD-MM-YYYY')}` : '',
        width: '10%',
      },
      {
        title: 'Nº Anexos',
        key: 'files',
        render: (data) => data.files?.length || 0,
        width: '7%',
      },
      {
        title: 'Estado',
        key: 'state',
        render: (data) => this.renderState(data),
        width: '10%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    users: [],
    process: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/tasks/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history, user } = this.props;

    return (
      <>
        {
          // Partners can only see the information
          !data.process.archived && user?.type !== 3 &&
          <ButtonActions 
            icon={'dots'} 
            iconType={'fill'} 
            options={[
              {
                onClick: () => history.push(`/process/${data.process?._id}`),
                label: 'Ver Processo' 
              },
              {
                onClick: () => history.push(`/tasks/${data._id}`),
                label: 'Editar' 
              },
              {
                popConfirmtitle: 'Tem a certeza que quer remover esta tarefa?',
                onClick: () => this.removeTask(data),
                label: 'Remover' 
              }
            ]} 
          />
        }
      </>
    );
  };

  removeTask = async (data) => {
    try {
      await DeleteTask(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters } = this.state;
      const { user } = this.props;

      // Validated tasks don't appear by default. We have to filter by that state to show them
      const result = await GetTasks(currentPage, pageSize, filters);
      const users = (user?.type === 2 || user?.type === 3) ? await GetUserAdminsList() : await GetAllUsersList();
      const process = await GetUserProcessFullList();

      const processList = [];
      if(process?.data?.length > 0) {
        process.data.forEach(elem => {
          processList.push({
            _id: elem._id,
            name: `${elem.ref} - ${elem.full_name}`
          })
        });
      }

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        users: users?.data || [],
        process: processList,
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (data) => {
    const { user } = this.props;

    const currentState = getTask(data.state);

    if(!currentState) return null;

    const options = [];
    if(TasksStates.length > 0) {
      TasksStates.forEach(elem => {
        options.push({
          popConfirmtitle: `Tem a certeza que quer alterar o estado desta tarefa para: ${elem.name}?`,
          onClick: () => this.changeState(data._id, elem._id),
          label: elem.name
        });
      });
    }

    return (
      <StateWrapper>
        <StateLabel
          bgColor={currentState.bgColor}
          fontColor={currentState.fontColor}
        >
          {currentState.name}
        </StateLabel>
        {
          // Partners can only see the information
          !data.process.archived && user?.type !== 3 &&
          <ButtonActions 
            icon={'chevronDown'} 
            iconType={'stroke'} 
            options={options} 
          />
        }
      </StateWrapper>
    );
  };

  changeState = async (task, state) => {
    try {
      this.setState({ loading: true });

      await UpdateTaskState(task, { state });
      await this.updateDataTable();

      notification.success({
        message: 'Estado atualizado com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, users, process } = this.state;
    const { history, user } = this.props;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Tarefas</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            {
              user?.type !== 3 &&
              <BaseButton
                customIcon={'plus'}
                text={'Adicionar tarefa'}
                onClick={() => history.push('/tasks/add')}
              />
            }
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <TasksFilters 
            queryChange={this.handleFilterChange} 
            users={users}
            processList={process}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Tarefas inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(TasksPage);
