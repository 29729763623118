import { PageContainer, PageTitle, HeaderContainer, HeaderTitle } from '../../styles/BasicStyles';
import { GetCommissions, DeleteCommission, UpdateCommissionState } from '../../infra/requests/CommissionsRequests';
import { GetAdminsPartnersList } from '../../infra/requests/UsersRequests';
import { GetUserProcessFullList} from '../../infra/requests/ProcessRequests';
import { getCommissionState, CommissionsStates } from '../../infra/utils/CommissionsStates';
import { StateLabel } from '../process/styles';
import { StateWrapper } from '../process/edit/Tasks/styles';
import { notification } from 'antd';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import CommissionsFilters from './CommissionsFilters';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import CurrencyComponent from '../../components/generic/currency/CurrencyComponent';

class CommissionsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '12%',
      },
      {
        title: 'Processo',
        dataIndex: 'process',
        render: (value) => value?.ref,
        width: '10%'
      },
      {
        title: 'Serviço/Pagamento/Lemania',
        key: 'type',
        render: (data) => !!data.lemania ? `Lemania: ${data.lemania}` : !!data.payment?.description ? `Pagamento: ${data.payment?.description}` : data.process?.service_name,
        width: '15%'
      },
      {
        title: 'Total',
        key: 'total',
        render: (data) => 
          <>
            <div>
              <CurrencyComponent
                value={data.total}
                suffix={data.original_currency === 1 ? 'CHF' : data.original_currency === 2 ? '€' : ''}
              />
            </div>
            {
              !!data.lemania && 
              <div style={{ marginTop: 4 }}>
                <CurrencyComponent
                  value={data.total_reformas}
                  suffix={data.original_currency === 1 ? 'CHF (Reformas)' : data.original_currency === 2 ? '€ (Reformas)' : ''}
                />
              </div>
            }
          </>,
        width: '10%'
      },
      {
        title: 'Utilizador',
        dataIndex: 'user',
        render: (value) => value.name,
        width: '12%'
      },
      {
        title: '% / Valor Fixo',
        key: 'value_type',
        render: (data) => 
          data.value_type === 1 ? 
          <CurrencyComponent
            value={data.value}
            suffix={data.commission_currency === 1 ? 'CHF' : data.commission_currency === 2 ? '€' : ''}
          />
          : 
          `${data.value}%`,
        width: '10%'
      },
      {
        title: 'Valor Comissão',
        key: 'commission_value',
        render: (data) => 
          <CurrencyComponent
            value={data.commission_value}
            suffix={data.commission_currency === 1 ? 'CHF' : data.commission_currency === 2 ? '€' : ''}
          />,
        width: '10%'
      },
      {
        title: 'Estado',
        key: 'state',
        render: (data) => this.renderState(data),
        width: '12%'
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    users: [],
    process: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if((user.type === 1 && !user.super_admin) || user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/commissions/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history, user } = this.props;

    if(!user.super_admin) return null;

    return (
      <>
        {
          !data.process.archived &&
          <ButtonActions 
            icon={'dots'} 
            iconType={'fill'} 
            options={[
              {
                onClick: () => history.push(`/commissions/${data._id}`),
                label: 'Editar' 
              },
              {
                popConfirmtitle: 'Tem a certeza que quer remover esta comissão?',
                onClick: () => this.removeCommission(data),
                label: 'Remover' 
              }
            ]} 
          />
        }
      </>
    );
  };

  removeCommission = async (data) => {
    try {
      await DeleteCommission(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetCommissions(currentPage, pageSize, filters);
      const users = await GetAdminsPartnersList();
      const process = await GetUserProcessFullList();

      const processList = [];
      if(process?.data?.length > 0) {
        process.data.forEach(elem => {
          processList.push({
            _id: elem._id,
            name: `${elem.ref} - ${elem.full_name}`
          })
        });
      }

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        users: users?.data || [],
        process: processList,
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (data) => {
    const { user } = this.props;
    
    const currentState = getCommissionState(data.state);

    if(!currentState) return null;

    const options = [];
    if(CommissionsStates.length > 0) {
      CommissionsStates.forEach(elem => {
        options.push({
          popConfirmtitle: `Tem a certeza que quer alterar o estado desta comissão para: ${elem.name}?`,
          onClick: () => this.changeState(data._id, elem._id),
          label: elem.name
        });
      });
    }

    return (
      <StateWrapper>
        <StateLabel
          bgColor={currentState.bgColor}
          fontColor={currentState.fontColor}
        >
          {currentState.name}
        </StateLabel>
        {
          user.super_admin && !data.process.archived &&
          <ButtonActions 
            icon={'chevronDown'} 
            iconType={'stroke'} 
            options={options} 
          />
        }
      </StateWrapper>
    );
  };

  changeState = async (task, state) => {
    try {
      this.setState({ loading: true });

      await UpdateCommissionState(task, { state });
      await this.updateDataTable();

      notification.success({
        message: 'Estado atualizado com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, users, process } = this.state;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Lista de Comissões</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <CommissionsFilters 
            queryChange={this.handleFilterChange} 
            users={users}
            processList={process}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Comissões inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(CommissionsPage);
