import { GetDocumentTexts } from '../../../infra/requests/DocumentsRequests';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';

class TextsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history, document } = this.props;
    history.push(`/digital-documents/list/${document?.identifier}/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { document } = this.props;

      const result = await GetDocumentTexts(document?._id);

      this.setState({
        rows: result.data || [],
        total: result.data?.length || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;

    return (
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Textos inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.handleOnPressRow}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(TextsPage));