import { device } from '../../../../styles/Responsive';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  & > div:first-child {
    padding-right: 5px;
  }

  & > div:last-child {
    padding-left: 5px;
  }

  @media ${device.tablet} {
    & > div:first-child {
      padding-right: 0px;
      width: 100%;
    }

    & > div:last-child {
      padding-left: 0px;
      padding-top: 10px;
      width: 100%;
    }
  }
`;

export const DateResent = styled.div`
  & span {
    font-weight: bold; 
  }
`;
