import client from '../config/AxiosConfig';

export const GetFaqs = async (currentPage, limit, filters) => await client.get(`/faqs/${currentPage}/${limit}?filter=${filters}`);

export const GetFaq = async (id) => await client.get(`/faqs/${id}`);

export const CreateFaq = async (data) => await client.post(`/faqs`, data);

export const UpdateFaq = async (id, data) => await client.put(`/faqs/${id}`, data);

export const DeleteFaq = async (id) => await client.delete(`/faqs/${id}`);

export const GetFaqsList = async () => await client.get(`/faqs`);

export const UpdateFaqsOrder = async (data) => await client.post(`/faqs/order`, data);

export const GetFaqsIntro = async (id) => await client.get(`/faqs/intro/${id}`);

export const UpdateFaqsIntro = async (id, data) => await client.put(`/faqs/intro/${id}`, data);