import { ReactSVG } from 'react-svg';
import { StyledButton, CustomIconWrapper } from "./ButtonsStyles";
import React from "react";
import PropTypes from "prop-types";

export const BaseButton = ({
  text,
  type,
  size,
  onClick,
  disabled = false,
  loading,
  shape,
  htmlType,
  align,
  icon,
  style,
  block,
  href,
  customIcon
}) => (
  <StyledButton
    style={style}
    type={type}
    block={block}
    size={size}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    shape={shape}
    htmlType={htmlType}
    align={align}
    icon={icon}
    href={href}
    hastext={!!text ? 'true' : 'false'}
  >
    {
      !loading && customIcon ?
      <CustomIconWrapper size={size} hasText={!!text ? 'true' : 'false'}>
        <ReactSVG src={`${process.env.REACT_APP_BO_URL}${customIcon}.svg`} />
        {text}
      </CustomIconWrapper>
      : 
      <>
        {text}
      </>
    }
  </StyledButton>
);

BaseButton.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "grey",
    "black",
    "clear"
  ]).isRequired,
  htmlType: PropTypes.oneOf(["reset", "submit", "button"]).isRequired,
  size: PropTypes.oneOf(["small", "default"]).isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  shape: PropTypes.oneOf(["circle", "round", undefined]),
  align: PropTypes.oneOf(["left", "right", "initial"]),
  icon: PropTypes.string,
  ghost: PropTypes.bool,
  block: PropTypes.bool,
  href: PropTypes.string,
  customIcon: PropTypes.string
};

BaseButton.defaultProps = {
  type: "primary",
  htmlType: "button",
  size: "default",
  ghost: false,
  block: false
};

export default BaseButton;
