import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyComponent = ({ value, suffix }) => {
  return (
    <NumberFormat
      value={value || undefined}
      defaultValue={'000.00'}
      displayType={'text'}
      suffix={suffix}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

CurrencyComponent.propTypes = {
  value: PropTypes.number.isRequired
};

export default CurrencyComponent;
