import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName,
  GraphTitle,
  CustomTooltipContent,
  CustomTooltipLabel
} from './GraphStyles';

const CustomTooltip = ({ active, payload }) => {
  if(active) {
    return (
      <CustomTooltipContent>
        <CustomTooltipLabel>{`${payload[0].payload?._id[0]?.title?.pt} : ${payload[0].value}`}</CustomTooltipLabel>
      </CustomTooltipContent>
    );
  }
  return null;
}

const ServiceGraph = ({ title, data, addedLabels }) => {
  return (
    <GraphContainer>
      <GraphContent>
        <ResponsiveContainer width={280} height={280}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data.filter(elem => elem.value > 0)}
              outerRadius={100}>
              {data
                .filter(elem => elem.value > 0)
                .map((entry, index) => (
                  <Cell key={index} fill={entry.bgColor} />
                ))}
            </Pie>
            <Tooltip content={<CustomTooltip/>} />
          </PieChart>
        </ResponsiveContainer>
      </GraphContent>
      <LabelsContainer>
        { title && <GraphTitle>{title}</GraphTitle> }
        {
          addedLabels?.length > 0 && addedLabels.map((label, index) =>
            <Label key={index}>
              <LabelColor color={label.bgColor} />
              <LabelName>
                {label.name} - {label.value}
              </LabelName>
            </Label>
          )
        }
        {
          data?.length > 0 && data.map((label, index) =>
            <Label key={index}>
              <LabelColor color={label.bgColor} />
              <LabelName>
                {label._id[0]?.title?.pt} - {label.value}
              </LabelName>
            </Label>
          )
        }
      </LabelsContainer>
    </GraphContainer>
  );
};

export default ServiceGraph;