/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { CreatePayment } from '../../../../infra/requests/ProcessRequests';
import { PaymentMethods } from '../../../../infra/utils/PaymentMethods';
import { PaymentStates } from '../../../../infra/utils/ProcessStates';
import { AmountWrapper } from './styles';
import { Currencies, getCurrency } from '../../../../infra/utils/Currencies';
import React, { useEffect, useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextInput from '../../../../components/generic/inputs/TextInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';
import CurrencyInput from '../../../../components/generic/inputs/CurrencyInput';
import CheckboxInput from '../../../../components/generic/inputs/CheckboxInput';
import moment from 'moment';

const validations = FormValidator.make({
  date: 'required',
  description: 'required',
  currency: 'required',
  amount: 'required',
  payment_method: 'required',
  payment_state: 'required'
});

const NewPayment = ({ openModal, closeModal, processID, getInfo, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState('€');

  useEffect(() => {
    dispatch(initialize('new_payment_form', { date: moment.utc(), currency: 2, tax_included: true }));

    setCurrentCurrency('€');
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await CreatePayment({ process: processID, user: user._id, ...values });

      if(result?.success) {
        notification.success({
          message: 'Pagamento inserido com sucesso!'
        });

        dispatch(initialize('new_payment_form'));

        closeModal();

        // Redirect to Payments page after reloading the info
        await getInfo(true);
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Novo Pagamento</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={DateInput}
            name={'date'}
            label={'Data *'}
            placeholder={'Selecione uma data'}
          />
          <Field
            component={TextInput}
            name={'description'}
            type={'text'}
            label={'Descrição *'}
            placeholder={'Insira uma descrição'}
          />
          <AmountWrapper>
            <Field
              component={SelectInput}
              name={'currency'}
              label={'Moeda *'}
              placeholder={'Escolha a moeda'}
              type='select'
              data={Currencies}
              dataKey={'_id'}
              dataLabel={'name'}
              execAfterChange={(value) => setCurrentCurrency(getCurrency(parseInt(value)))}
            />
            <Field
              component={CurrencyInput}
              name={'amount'}
              label={'Montante *'}
              suffix={currentCurrency}
            />
          </AmountWrapper>
          <div style={{ margin: '6px 0' }}>
            <Field
              component={CheckboxInput}
              name={`tax_included`}
              label={'IVA incluído?'}
              importantLabel={true}
              inlineCheckbox={true}
            />
          </div>
          <Field
            component={SelectInput}
            name={'payment_method'}
            label={'Método de Pagamento *'}
            placeholder={'Escolha o método'}
            type='select'
            data={PaymentMethods.filter(elem => elem.allowInPayments)}
            dataKey={'_id'}
            dataLabel={'name'}
            execAfterChange={(value) => setSendEmail(parseInt(value) === 2 ? true: false)}
          />
          {/* If the selected payment method is "Transferência Bancária", show a checkbox to allow sending an email */}
          <Field
            component={SelectInput}
            name={'payment_state'}
            label={'Estado de Pagamento *'}
            placeholder={'Escolha o estado'}
            type='select'
            data={PaymentStates.filter(elem => elem.allowInPayments)}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          {
            sendEmail &&
            <div style={{ marginTop: '10px' }}>
              <Field
                component={CheckboxInput}
                name={'send_bank_transfer_email'}
                importantLabel={true}
                inlineCheckbox={true}
                label={'Enviar email com dados de pagamento?'}
              />
            </div>
          }
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewPaymentForm = reduxForm({
  form: 'new_payment_form',
  validate: validations
})(NewPayment);

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(NewPaymentForm);
