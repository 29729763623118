/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../process/styles';
import { CreateLog } from '../../infra/requests/ProcessRequests';
import { LogSections } from '../../infra/utils/LogSections';
import React, { useEffect, useState } from 'react';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../components/generic/buttons/BaseButton';
import SelectInput from '../../components/generic/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  process: 'required',
  section: 'required',
  description: 'required'
});

const NewLog = ({ openModal, closeModal, processList, getInfo, handleSubmit, dispatch, user }) => {
  const [loading, setLoading] = useState(false);

  let sections = LogSections;
  if(user?.type === 2) {
    sections = LogSections.filter((elem) => elem.allowAgentInsert);
  }

  useEffect(() => {
    dispatch(initialize('new_log_general_form'));
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await CreateLog({ user: user._id, automatic: false, ...values });

      if(result?.success) {
        notification.success({
          message: 'Log inserido com sucesso!'
        });

        dispatch(initialize('new_log_general_form'));

        closeModal();

        await getInfo();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Novo Log</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'process'}
            label={'Processo *'}
            placeholder={'Selecionar processo'}
            data={processList}
            dataKey={'_id'}
            dataLabel={'name'}
            allowClear={true}
          />
          <Field
            component={SelectInput}
            name={'section'}
            label={'Secção *'}
            placeholder={'Escolha a secção'}
            type='select'
            data={sections}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          <Field
            component={TextAreaInput}
            name={'description'}
            label={'Descrição *'}
            placeholder={'Insira uma mensagem'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const NewLogForm = reduxForm({
  form: 'new_log_general_form',
  validate: validations
})(NewLog);

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(NewLogForm);
