
import { connect } from 'react-redux';
import { PageContainer, SpinLoading } from '../../styles/BasicStyles';
import { DateFilterSection, BackButton, Year, NextButton, GraphsContainer, SectionTitle, SectionContainer, SectionContent } from './DashboardStyles';
import { Icon } from 'antd';
import { GetProcessByYear, GetPaymentsByYear, GetServiceByMonth, GetServiceTypeByMonth } from '../../infra/requests/ProcessRequests';
import { ProcessStates, ProcessPaymentStates } from '../../infra/utils/ProcessStates';
import { ServiceModes } from '../../infra/utils/ServiceModes';
import React, { Component } from 'react';
import ServiceGraph from '../../components/generic/graphs/ServiceGraph';
import BaseGraph from '../../components/generic/graphs/BaseGraph';
import moment from 'moment-timezone';
import 'moment/locale/pt';
moment.locale('pt');

class DashboardPage extends Component {
  state = {
    ready: false,
    process: [],
    totalProcess: 0,
    selectedProcessYear: moment().format('YYYY'),
    serviceType: ServiceModes,
    totalServiceType: 0,
    selectedServiceTypeDate: moment().format('YYYY-MM'),
    service: [],
    totalService: 0,
    selectedServiceDate: moment().format('YYYY-MM'),
    payments: [],
    totalPayment: 0,
    selectedPaymentYear: moment().format('YYYY')
  };

  componentDidMount() {
    const { user, history } = this.props;

    if(user?.type === 4) return history.push('/estate/list');

    this.getProcessInfo();
    this.getPaymentsInfo();

    if(user?.type === 1 || user?.type === 3) {
      this.getServiceInfo();
      this.getServiceTypeInfo();
    }
    
    this.setState({
      ready: true
    });
  }

  getProcessInfo = async () => {
    const { user } = this.props;
    const { selectedProcessYear } = this.state;

    let states = ProcessStates;
    if(user?.type === 2) {
      states = ProcessStates.filter(elem => elem.showAgent);
    }

    const result = await GetProcessByYear(selectedProcessYear);

    states.find(elem => elem._id === 1).value = result?.data?.state1 || 0;
    states.find(elem => elem._id === 2).value = result?.data?.state2 || 0;
    states.find(elem => elem._id === 3).value = result?.data?.state3 || 0;
    states.find(elem => elem._id === 4).value = result?.data?.state4 || 0;
    states.find(elem => elem._id === 5).value = result?.data?.state5 || 0;
    states.find(elem => elem._id === 6).value = result?.data?.state1 || 0;
    states.find(elem => elem._id === 7).value = result?.data?.state7 || 0;
    states.find(elem => elem._id === 8).value = result?.data?.state8 || 0;
    states.find(elem => elem._id === 9).value = result?.data?.state9 || 0;
    states.find(elem => elem._id === 10).value = result?.data?.state10 || 0;
    states.find(elem => elem._id === 11).value = result?.data?.state11 || 0;
    states.find(elem => elem._id === 12).value = result?.data?.state12 || 0;
    states.find(elem => elem._id === 13).value = result?.data?.state13 || 0;
    states.find(elem => elem._id === 14).value = result?.data?.state14 || 0;
    states.find(elem => elem._id === 15).value = result?.data?.state15 || 0;

    const totalProcess = result?.data?.total || 0;

    this.setState({
      process: states,
      totalProcess
    });
  }

  getPaymentsInfo = async () => {
    const { selectedPaymentYear } = this.state;

    const result = await GetPaymentsByYear(selectedPaymentYear);
    
    ProcessPaymentStates[0].value = result?.data?.state1 || 0;
    ProcessPaymentStates[1].value = result?.data?.state2 || 0;
    ProcessPaymentStates[2].value = result?.data?.state3 || 0;
    ProcessPaymentStates[3].value = result?.data?.state4 || 0;
    ProcessPaymentStates[4].value = result?.data?.state5 || 0;

    const totalPayment = result?.data?.total || 0;

    this.setState({
      payments: ProcessPaymentStates,
      totalPayment
    });
  }

  getServiceInfo = async () => {
    const { selectedServiceDate } = this.state;

    const result = await GetServiceByMonth(moment(selectedServiceDate).format('YYYY'), moment(selectedServiceDate).format('MM'));
    const totalService = result?.data?.total || 0;

    this.setState({
      service: result?.data?.services || [],
      totalService
    });
  }

  getServiceTypeInfo = async () => {
    const { serviceType, selectedServiceTypeDate } = this.state;

    const result = await GetServiceTypeByMonth(moment(selectedServiceTypeDate).format('YYYY'), moment(selectedServiceTypeDate).format('MM'));
    const totalServiceType = result?.data?.total || 0;
    serviceType[0].value = result?.data?.service_mode1 || 0;
    serviceType[1].value = result?.data?.service_mode2 || 0;
    serviceType[2].value = result?.data?.service_mode3 || 0;

    this.setState({
      serviceType,
      totalServiceType
    });
  }

  changeProcessYear = type => {
    const { selectedProcessYear } = this.state;

    if(type === 'minus') {
      const lastYear = moment(selectedProcessYear, 'YYYY').subtract(1, 'year').format('YYYY');
      this.setState({ selectedProcessYear: lastYear }, this.getProcessInfo);
    }
    else if(type === 'plus') {
      const nextYear = moment(selectedProcessYear, 'YYYY').add(1, 'year').format('YYYY');
      this.setState({ selectedProcessYear: nextYear }, this.getProcessInfo);
    }
  };

  changePaymentYear = type => {
    const { selectedPaymentYear } = this.state;

    if(type === 'minus') {
      const lastYear = moment(selectedPaymentYear, 'YYYY').subtract(1, 'year').format('YYYY');
      this.setState({ selectedPaymentYear: lastYear }, this.getPaymentsInfo);
    }
    else if(type === 'plus') {
      const nextYear = moment(selectedPaymentYear, 'YYYY').add(1, 'year').format('YYYY');
      this.setState({ selectedPaymentYear: nextYear }, this.getPaymentsInfo);
    }
  };

  changeServiceDate = type => {
    const { selectedServiceDate } = this.state;

    if(type === 'minus') {
      const lastMonth = moment(selectedServiceDate, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM');
      this.setState({ selectedServiceDate: lastMonth }, this.getServiceInfo);
    }
    else if(type === 'plus') {
      const nextMonth = moment(selectedServiceDate, 'YYYY-MM').add(1, 'month').format('YYYY-MM');
      this.setState({ selectedServiceDate: nextMonth }, this.getServiceInfo);
    }
  };

  changeServiceTypeDate = type => {
    const { selectedServiceTypeDate } = this.state;

    if(type === 'minus') {
      const lastMonth = moment(selectedServiceTypeDate, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM');
      this.setState({ selectedServiceTypeDate: lastMonth }, this.getServiceTypeInfo);
    }
    else if(type === 'plus') {
      const nextMonth = moment(selectedServiceTypeDate, 'YYYY-MM').add(1, 'month').format('YYYY-MM');
      this.setState({ selectedServiceTypeDate: nextMonth }, this.getServiceTypeInfo);
    }
  };

  render() {
    const { user } = this.props;
    const { 
      ready, 
      selectedProcessYear, 
      totalProcess, 
      process, 
      serviceType, 
      selectedServiceTypeDate, 
      totalServiceType, 
      service, 
      selectedServiceDate, 
      totalService,
      payments,
      totalPayment,
      selectedPaymentYear
    } = this.state;

    if(!ready) return <SpinLoading />;

    return (
      <PageContainer>
        <SectionContainer>
          <SectionContent>
            <SectionTitle>Estado do Processo (Anual)</SectionTitle>
            <DateFilterSection>
              <BackButton onClick={() => this.changeProcessYear('minus')}>
                <Icon type="arrow-left" />
              </BackButton>
              <Year>{selectedProcessYear}</Year>
              <NextButton onClick={() => this.changeProcessYear('plus')}>
                <Icon type="arrow-right" />
              </NextButton>
            </DateFilterSection>
            <GraphsContainer>
              <BaseGraph
                data={process}
                addedLabels={[
                  {
                    name: 'Total',
                    value: totalProcess,
                    bgColor: '#DECBB3'
                  }
                ]}
              />
            </GraphsContainer>
          </SectionContent>
          <SectionContent>
            <SectionTitle>Estado de Pagamento (Anual)</SectionTitle>
            <DateFilterSection>
              <BackButton onClick={() => this.changePaymentYear('minus')}>
                <Icon type="arrow-left" />
              </BackButton>
              <Year>{selectedPaymentYear}</Year>
              <NextButton onClick={() => this.changePaymentYear('plus')}>
                <Icon type="arrow-right" />
              </NextButton>
            </DateFilterSection>
            <GraphsContainer>
              <BaseGraph
                data={payments}
                addedLabels={[
                  {
                    name: 'Total',
                    value: totalPayment,
                    bgColor: '#DECBB3'
                  }
                ]}
              />
            </GraphsContainer>
          </SectionContent>
        </SectionContainer>
        {
          (user?.type === 1 || user?.type === 3) &&
          <SectionContainer>
            <SectionContent>
              <SectionTitle>Serviço (Mensal)</SectionTitle>
              <DateFilterSection>
                <BackButton onClick={() => this.changeServiceDate('minus')}>
                  <Icon type="arrow-left" />
                </BackButton>
                <Year>{moment(selectedServiceDate).format('MMMM, YYYY')}</Year>
                <NextButton onClick={() => this.changeServiceDate('plus')}>
                  <Icon type="arrow-right" />
                </NextButton>
              </DateFilterSection>
              <GraphsContainer>
                <ServiceGraph
                  data={service}
                  addedLabels={[
                    {
                      name: 'Total',
                      value: totalService,
                      bgColor: '#DECBB3'
                    }
                  ]}
                />
              </GraphsContainer>
            </SectionContent>
            <SectionContent>
              <SectionTitle>Tipo de Serviço (Mensal)</SectionTitle>
              <DateFilterSection>
                <BackButton onClick={() => this.changeServiceTypeDate('minus')}>
                  <Icon type="arrow-left" />
                </BackButton>
                <Year>{moment(selectedServiceTypeDate).format('MMMM, YYYY')}</Year>
                <NextButton onClick={() => this.changeServiceTypeDate('plus')}>
                  <Icon type="arrow-right" />
                </NextButton>
              </DateFilterSection>
              <GraphsContainer>
                <BaseGraph
                  data={serviceType}
                  addedLabels={[
                    {
                      name: 'Total',
                      value: totalServiceType,
                      bgColor: '#DECBB3'
                    }
                  ]}
                />
              </GraphsContainer>
            </SectionContent>
          </SectionContainer>
        }
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DashboardPage);