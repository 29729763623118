import { BalanceItem, BalanceItemTitle, BalanceItemValue, BalanceItemTotal, BalanceItemAmount } from './styles';
import React from 'react';
import CurrencyComponent from '../../../components/generic/currency/CurrencyComponent';

export const renderBalanceItem = (title, index, commission, color = '') => {
  return (
    <BalanceItem key={index}>
      <BalanceItemTitle mainTitle={index === 0} color={index > 0 && color}>{title}:</BalanceItemTitle>
      <BalanceItemValue>
        <BalanceItemTotal mainTitle={index === 0} color={index > 0 && color}>
          {commission.total}
        </BalanceItemTotal>
        <BalanceItemAmount mainTitle={index === 0} color={index > 0 && color}>
          ({
            commission.amount_chf > 0 && commission.amount_eur > 0 ?
            <>
              <CurrencyComponent
                value={commission.amount_chf}
                suffix={'CHF'}
              />
              <span style={{ padding: '0 5px' }}>+</span>
              <CurrencyComponent
                value={commission.amount_eur}
                suffix={'€'}
              />
            </>
            :
            commission.amount_chf > 0 ?
            <CurrencyComponent
              value={commission.amount_chf}
              suffix={'CHF'}
            />
            :
            commission.amount_eur > 0 ?
            <CurrencyComponent
              value={commission.amount_eur}
              suffix={'€'}
            />
            :
            '0CHF'
          })
        </BalanceItemAmount>
      </BalanceItemValue>
    </BalanceItem>
  )
};