
import { Row, Col } from '../process/list/styles';
import { TableFilterSection } from '../../styles/BasicStyles';
import { ProcessStates, PaymentStates } from "../../infra/utils/ProcessStates";
import { ServiceModes } from "../../infra/utils/ServiceModes";
import { PaymentMethods } from "../../infra/utils/PaymentMethods";
import { connect } from 'react-redux';
import { ClearFiltersWrapper } from '../process/list/styles';
import React, { Component } from 'react';
import BaseButton from '../../components/generic/buttons/BaseButton';
import Cookies from 'js-cookie';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';
import MonthInput from '../../components/generic/inputs/MonthInput';

const commissions = [
  {
    _id: 1,
    name: 'Sem comissão'
  },
  {
    _id: 2,
    name: 'Serviço'
  },
  {
    _id: 4,
    name: 'Pagamentos'
  },
  {
    _id: 3,
    name: 'Lemania'
  }
];

class ArchiveFilters extends Component {
  state = { 
    search: '',
    purchase_date: '',
    state: '',
    process_state_payment: '',
    service: '',
    service_mode: '',
    payment_method: '',
    client: '',
    responsible: '',
    agent: '',
    partner: '',
    dateComeback: '',
    dateRetirement: '',
    birthday: '',
    commission: '',
    archived: true
  };

  componentDidMount = () => {
    const { user } = this.props;

    const cookieJson = Cookies.get('archiveFilters');
    if(cookieJson) {
      const cookie = JSON.parse(cookieJson);

      if(cookie?.user === user._id) {
        this.setState({
          ...cookie.filters
        });
      }
    }
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange, user } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if(this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });

    //When selecting a new filter, reset the currentPage and pageSize values
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: query,
      currentPage: 1,
      pageSize: 30
    });
    Cookies.set('archiveFilters', cookie, { expires: timeout })

    queryChange(JSON.stringify(query));
  };

  clearFilters = () => {
    const { queryChange } = this.props;
    Cookies.remove('archiveFilters');

    this.setState({
      search: '',
      purchase_date: '',
      state: '',
      process_state_payment: '',
      service: '',
      service_mode: '',
      payment_method: '',
      client: '',
      responsible: '',
      dateComeback: '',
      dateRetirement: '',
      agent: '',
      partner: '',
      birthday: '',
      commission: ''
    });

    queryChange(JSON.stringify({archived:true}));
  };

  render() {
    const { services, users, clients, user, agents, partners } = this.props;
    const { search, purchase_date, state, process_state_payment, service, service_mode, payment_method, client, responsible, agent, partner, dateComeback, dateRetirement, birthday, commission } = this.state;

    let states = ProcessStates;
    if(user?.type === 2) {
      states = ProcessStates.filter(elem => elem.showAgent);
    }

    return (
      <>
        <TableFilterSection>
          <Row>
            <Col>
              <TextInput
                input={{
                  value: search,
                  onChange: (event) =>
                    this.onInputChange('search', event.target.value, 1000),
                }}
                meta={{ valid: true }}
                type='text'
                label='Pesquisar'
                placeholder='Procurar por ref, cliente, nº AVS/AHV, contacto, email, cidade ou código postal'
              />
            </Col>
            <Col>
              <DateInput
                label="Data de Compra"
                placeholder="Escolher data de compra"
                input={{
                  value: purchase_date,
                  onChange: value => this.onInputChange('purchase_date', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label="Estado do Processo"
                allowClear
                placeholder="Escolher estado"
                data={states}
                dataKey="_id"
                dataLabel="name"
                input={{
                  value: state,
                  onChange: value => this.onInputChange('state', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label="Estado do Pagamento"
                allowClear
                placeholder="Escolher estado"
                data={PaymentStates}
                dataKey="_id"
                dataLabel="name"
                input={{
                  value: process_state_payment,
                  onChange: value => this.onInputChange('process_state_payment', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            {
              (user?.type === 1 || user?.type === 3) &&
              <>
                <Col>
                  <SelectInput
                    label="Serviço"
                    allowClear
                    placeholder="Escolher serviço"
                    data={services}
                    dataKey="_id"
                    dataLabel="title"
                    translatable={true}
                    input={{
                      value: service,
                      onChange: value => this.onInputChange('service', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
                <Col>
                  <SelectInput
                    label="Modalidade do Serviço"
                    allowClear
                    placeholder="Escolher modalidade"
                    data={ServiceModes}
                    dataKey="_id"
                    dataLabel="name"
                    input={{
                      value: service_mode,
                      onChange: value => this.onInputChange('service_mode', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
                <Col>
                  <SelectInput
                    label="Método de Pagamento"
                    allowClear
                    placeholder="Escolher método"
                    data={PaymentMethods}
                    dataKey="_id"
                    dataLabel="name"
                    input={{
                      value: payment_method,
                      onChange: value => this.onInputChange('payment_method', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
              </>
            }
            {
              user?.type === 1 &&
              <>
                <Col>
                  <SelectInput
                    label="Cliente"
                    allowClear
                    placeholder="Escolher cliente"
                    data={clients}
                    dataKey="_id"
                    dataLabel="name"
                    input={{
                      value: client,
                      onChange: value => this.onInputChange('client', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
                <Col>
                  <SelectInput
                    label="Responsável"
                    allowClear
                    placeholder="Escolher responsável"
                    data={users}
                    dataKey="_id"
                    dataLabel="name"
                    translatable={false}
                    input={{
                      value: responsible,
                      onChange: value => this.onInputChange('responsible', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
                <Col>
                  <SelectInput
                    label="Agente"
                    allowClear
                    placeholder="Escolher agente"
                    data={agents}
                    dataKey="_id"
                    dataLabel="name"
                    translatable={false}
                    input={{
                      value: agent,
                      onChange: value => this.onInputChange('agent', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
                <Col>
                  <SelectInput
                    label="Parceiro"
                    allowClear
                    placeholder="Escolher parceiro"
                    data={partners}
                    dataKey="_id"
                    dataLabel="name"
                    translatable={false}
                    input={{
                      value: partner,
                      onChange: value => this.onInputChange('partner', value)
                    }}
                    meta={{valid: true}}
                  />
                </Col>
              </>
            }
            <Col>
              <MonthInput
                label='Mês de Regresso a Portugal'
                placeholder='Escolher mês'
                input={{
                  value: dateComeback,
                  onChange: value => this.onInputChange('dateComeback', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <MonthInput
                label='Mês de Reforma'
                placeholder='Escolher mês'
                input={{
                  value: dateRetirement,
                  onChange: value => this.onInputChange('dateRetirement', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <DateInput
                label="Data de Nascimento"
                placeholder="Escolher data de nascimento"
                input={{
                  value: birthday,
                  onChange: value => this.onInputChange('birthday', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <SelectInput
                label='Comissão'
                allowClear
                placeholder='Escolher opção'
                data={commissions}
                dataKey='_id'
                dataLabel='name'
                translatable={false}
                input={{
                  value: commission,
                  onChange: value => this.onInputChange('commission', value)
                }}
                meta={{valid: true}}
              />
            </Col>
            <Col>
              <ClearFiltersWrapper>
                <BaseButton
                  type={'clear'}
                  icon={'close'}
                  text={'Limpar filtros'}
                  onClick={() => this.clearFilters()}
                />
              </ClearFiltersWrapper>
            </Col>
          </Row>
        </TableFilterSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ArchiveFilters);
