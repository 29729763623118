import { renderToStaticMarkup } from 'react-dom/server';
import { initialize } from 'react-localize-redux';
import { GetInfo } from '../../infra/requests/BaseRequests';
import { SaveInfo } from '../Info/info.actions';
import { SaveUser } from '../User/user.actions';

const getName = (code) => {
  if (code === 'pt') return 'Português';
  if (code === 'fr') return 'Francês';
  if (code === 'de') return 'Alemão';
  return 'Lingua não definida';
};

const defineLangNames = (list = []) => {
  return list.map((lang) => ({ code: lang, name: getName(lang) }));
};

export default function start() {
  return async (dispatch) => {
    try {
      const info = await GetInfo();

      const languages = info?.data?.languages?.length > 0 ? info?.data?.languages : ['pt', 'fr', 'de'];

      dispatch(SaveUser(info.data?.user));
      dispatch(SaveInfo(info?.data));
      dispatch(
        initialize({
          languages: defineLangNames(languages),
          translation: [],
          options: {
            renderToStaticMarkup,
            defaultLanguage: info?.data?.default_language || 'pt',
          },
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
}
