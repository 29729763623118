import { connect } from 'react-redux';
import { Form, Modal } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import React from 'react';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  address: 'required'
});

const PdfGenerateAddress = ({ openModal, closeModal, processID, pdfType, addressList, user, handleSubmit, dispatch }) => {
  const onSubmit = values => {
    const filters = { ...values, type: pdfType };
    window.open(`${process.env.REACT_APP_API}/automatic_pdf/process/${user?._id}/${processID}?filter=${JSON.stringify(filters)}`, '_blank');    

    dispatch(initialize('pdf_generate_address_form'));
    closeModal();
  };

  const onClose = () =>{
    dispatch(initialize('pdf_generate_address_form'));
    closeModal();
  }

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => onClose()}
      footer={null}
    >
      <>
        <ModalTitle>Gerar PDF</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            name={'address'}
            label='Selecione uma morada'
            placeholder={'Escolha uma morada'}
            type='select'
            data={addressList}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Gerar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const PdfGenerateAddressForm = reduxForm({
  form: 'pdf_generate_address_form',
  validate: validations
})(PdfGenerateAddress);

export default connect()(PdfGenerateAddressForm);
