import { GetClientProcessList, ToggleProcessArchive } from '../../infra/requests/ProcessRequests';
import { withRouter } from 'react-router-dom';
import { renderProcessState, renderProcessPaymentState } from '../../infra/utils/ProcessStates';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import ButtonActions from '../../components/generic/buttons/ButtonActions';

class ProcessList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Ref. Processo',
        dataIndex: 'ref',
        render: (value) => value,
        width: '8%',
      },
      {
        title: 'Data de Compra',
        dataIndex: 'purchase_date',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '10%',
      },
      {
        title: 'Nome',
        dataIndex: 'full_name',
        render: (value) => value,
        width: '18%',
      },
      {
        title: 'Serviço',
        dataIndex: 'service',
        render: (value) => value?.title?.pt,
        width: '17%',
      },
      {
        title: 'Estado do Processo',
        dataIndex: 'state',
        render: (value) => renderProcessState(value),
        width: '16%'
      },
      {
        title: 'Estado de Pagamento',
        key: 'process_state_payment',
        render: (data) => renderProcessPaymentState(data.process_state_payment, data.free),
        width: '16%'
      },
      {
        title: 'Valor pendente',
        dataIndex: 'pending_value',
        width: '10%'
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/process/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/process/${data._id}`),
            label: 'Ver detalhe' 
          },
          {
            popConfirmtitle: data.archived ? 'Tem a certeza que quer restaurar o processo?' : 'Tem a certeza que quer arquivar o processo?',
            onClick: () => this.archiveProcess(data),
            label: data.archived ? 'Restaurar' : 'Arquivar'
          }
        ]} 
      />
    );
  };

  archiveProcess = async (data) => {
    try {
      await ToggleProcessArchive(data._id, { archived: !data.archived });
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { clientID } = this.props;
      const { currentPage, pageSize } = this.state;

      const result = await GetClientProcessList(clientID, currentPage, pageSize);

      this.setState({
        rows: result?.data?.items || [],
        total: result?.data?.total || 0,
      });

      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return (
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Processos inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        onPressRow={this.handleOnPressRow}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }
}

export default withRouter(ProcessList);