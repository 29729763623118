import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { GetConfigs } from '../../infra/requests/ConfigsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import {
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
} from '../../styles/BasicStyles';
import { HeaderSection, SectionTitle, InfoSectionText } from './ConfigsStyles';
import InfoSection from './components/InfoSection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';

class ConfigsPage extends Component {
  state = {
    configs: {},
  };

  componentDidMount = async () => {
    const { CloseMenu, user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    await this.getConfigurations();
  };

  getConfigurations = async () => {
    await this.setState({ loading: true });

    try {
      const result = await GetConfigs();
      this.setState({
        configs: result.data[0],
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { history } = this.props;
    const { configs } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Configurações</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Row gutter={24}>
            <Col md={6} xs={0}></Col>
            <Col md={12} xs={24} offset={0}>
              <HeaderSection>
                <SectionTitle>Informação Geral</SectionTitle>
                <BaseButton
                  icon={'edit'}
                  text={'Edit'}
                  onClick={() => history.push(`configs/edit`)}
                />
              </HeaderSection>
              <InfoSection title={'Email para Notificações'} text={configs?.email_notifications} />
              <InfoSection title={'Contacto WhatsApp'} text={configs?.whatsapp_number} />
              <InfoSection title={'Percentagem Comissão Lemania'} text={`${configs?.commission_lemania || 0}%`} />
              <InfoSection title={'Percentagem IVA'} text={`${configs?.tax || 0}%`} />
              <InfoSection title={'NIF'} text={configs?.nif} />
              <InfoSection title={'Morada'} text={configs?.address} />
              <InfoSection title={'Código Postal'} text={configs?.cod_postal} />
              <InfoSection title={'Cidade'} text={configs?.city} />
              <InfoSection title={'Latitude'} text={configs?.latitude} />
              <InfoSection title={'Longitude'} text={configs?.longitude} />
              <InfoSection title={'1º Contacto'} text={configs?.phone1} />
              <InfoSection title={'2º Contacto'} text={configs?.phone2} />
              <InfoSection title={'Email'} text={configs?.email} />
              <InfoSection title={'Página de Facebook'} text={configs?.facebook_page} />
              <InfoSection title={'Página de Instagram'} text={configs?.instagram_page} />
              <InfoSection title={'Dados Bancários (CHF)'}>
                <InfoSectionText>Empresa: {configs.company}</InfoSectionText>
                <InfoSectionText>Banco: {configs.bank}</InfoSectionText>
                <InfoSectionText>IBAN: {configs.iban}</InfoSectionText>
                <InfoSectionText>BIC (SWIFT): {configs.swift}</InfoSectionText>
              </InfoSection>
              <InfoSection title={'Dados Bancários (EURO)'}>
                <InfoSectionText>Empresa: {configs.company_euro}</InfoSectionText>
                <InfoSectionText>Banco: {configs.bank_euro}</InfoSectionText>
                <InfoSectionText>IBAN: {configs.iban_euro}</InfoSectionText>
                <InfoSectionText>BIC (SWIFT): {configs.swift_euro}</InfoSectionText>
              </InfoSection>
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(ConfigsPage);