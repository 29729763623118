import axios from "axios";
import { notification } from "antd";
import { auth_token_key } from "./LocalStorageKeys";

const REACT_APP_API = process.env.REACT_APP_API;

const mergeCommonHeaders = (rest) => {
  const authToken = localStorage.getItem(auth_token_key);

  return {
    ...rest,
    Authorization: `Bearer ${authToken}`,
  };
};

const client = axios.create({
  baseURL: REACT_APP_API,
  responseType: "json",
});

client.interceptors.request.use((request) => {
  const headers = mergeCommonHeaders(request.headers.common);
  request.headers.common = headers;
  return request;
});

client.setupInterceptors = (store) => {
  client.interceptors.response.use(
    (success) => {
      return success.data;
    },
    (error) => {
      let description = "An error has ocurred";
      if (error.response && error.response.data) {
        description = error.response.data.message || "An error has ocurred";
      }

      if (error && error.response) {
        const url = error.response?.config.url;

        if (url !== "/info") {
          notification.error({
            message: "Request Error",
            description,
            duration: 5,
          });
        }

        if (error.response.status === 401 && url !== "/info") {
          localStorage.clear();
          // eslint-disable-next-line no-restricted-globals
          location.replace("/login");
        }
      }

      return error.response
        ? { ...error.response.data, success: false }
        : { success: false };
    }
  );
};

export default client;
