export const PrimaryColour = '#ED1D24';
export const SecondaryColour = '#FFD400';
export const ThirdColour = '#8DC63F';
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColour = '#ED1D24';
export const InputBorderColour = '#CED4DA';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#ED1D24';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#F8F9FA';
export const SecondaryBackgroundColor = '#8c8c8c';
export const ThirdBackgroundColor = '#cccccc';
export const HeaderBackgroundColor = '#e8e8e8';
export const TableHeaderTextColor = '#495057';
export const TableHeaderBackgroundColor = '#DEE2E6';
export const InputHoverColor = '#FFC9C9';