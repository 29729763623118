import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetService,
  UpdateService,
  CreateService,
} from '../../infra/requests/ServicesRequests';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator,
  InputNote,
  SectionTitle,
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import CurrencyInput from '../../components/generic/inputs/CurrencyInput';
import LanguagesInput from '../../components/generic/inputs/LanguagesInput';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { Categories } from '../../infra/utils/Categories';
import Dropzone from '../process/components/FileUpload/SingleDropzone';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  identifier: 'noSpaces|noSpecialCharacter',
  short_title: 'required|languages',
  title: 'required|languages',
  description_main: 'required|languages',
  image: 'required',
  category: 'required'
});

class ManageServicesPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    initialValues: null,
    showPrice: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetService(params.id);
      dispatch(initialize('manage_services_form', { ...result.data }));

      this.setState({ 
        loading: false,
        initialValues: result?.data,
        showPrice: !result?.data?.free
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;
      const { initialValues } = this.state;

      if(values.image && !values.image.blob) delete values.image;

      values.is_in_country = false;
      values.was_in_country = false;

      if(values.category) {
        if(parseInt(values.category) === 3) {
          values.is_in_country = true;
          values.was_in_country = true;
        } 
        else if(parseInt(values.category) === 1) {
          values.is_in_country = true;
        } 
        else if(parseInt(values.category) === 2) {
          values.was_in_country = true;
        }
      }

      // If we delete any of the conditions files and don't choose another, send a flag to api to delete the file
      if(initialValues?.conditions_pt?._id && !values.conditions_pt) values.delete_conditions_pt = true;
      if(initialValues?.conditions_fr?._id && !values.conditions_fr) values.delete_conditions_fr = true;
      if(initialValues?.conditions_de?._id && !values.conditions_de) values.delete_conditions_de = true;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateService(params.id, payload) : await CreateService(payload);

      if(success) return history.push('/services/list');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/services/list');
  };

  checkPrice = (e) => this.setState({ showPrice: !e.target.checked});

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading, showPrice } = this.state;

    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Serviços'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <SectionTitle>Imagem</SectionTitle>
                <Col md={12} xs={24}>
                  <Field
                    component={ImageInput}
                    name='image'
                    label='Imagem de capa *'
                    ratio={0.6}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Condições do Serviço</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field 
                    name={`conditions_${activeLanguage?.code}`}
                    component={Dropzone}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field 
                            name={`conditions_${language.code}`}
                            component={Dropzone}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Informação Principal</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título do Serviço'}
                  />
                  <Field
                    component={TextInput}
                    name={`short_title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título Abreviado*'}
                    placeholder={'Título abreviado do Serviço'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título do Serviço'}
                          />
                          <Field
                            component={TextInput}
                            name={`short_title.${language.code}`}
                            type='text'
                            label={'Título Abreviado *'}
                            placeholder={'Título abreviado do Serviço'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Separator />
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col xs={24}>
                  <Field
                    component={SelectInput}
                    name={'category'}
                    label={'Categoria *'}
                    placeholder={'Escolha a categoria'}
                    type='select'
                    data={Categories}
                    dataKey={'_id'}
                    dataLabel={'name'}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col lg={12} xs={24}>
                  <Field
                    component={CheckboxInput}
                    name={`free`}
                    label={'Serviço Gratuito?'}
                    importantLabel={true}
                    inlineCheckbox
                    execAfterChange={this.checkPrice}
                  />
                </Col>
              </Row>
              {
                showPrice &&
                <>
                  <Row gutter={24}>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_online_euro'}
                        defaultValue='0.00'
                        suffix='€'
                        label='Preço Base e Preço Online (€)'
                        placeholder='Insira o preço para o processo exclusivamente online'
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_postal_euro'}
                        defaultValue='0.00'
                        suffix='€'
                        label='Preço Via Postal (€)'
                        placeholder='Insira o preço para o processo via postal'
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_presential_euro'}
                        defaultValue='0.00'
                        suffix='€'
                        label='Preço Presencial (€)'
                        placeholder='Insira o preço para o processo presencial'
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_online_chf'}
                        defaultValue='0.00'
                        suffix='CHF'
                        label='Preço Base e Preço Online (CHF)'
                        placeholder='Insira o preço para o processo exclusivamente online'
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_postal_chf'}
                        defaultValue='0.00'
                        suffix='CHF'
                        label='Preço Via Postal (CHF)'
                        placeholder='Insira o preço para o processo via postal'
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'price_presential_chf'}
                        defaultValue='0.00'
                        suffix='CHF'
                        label='Preço Presencial (CHF)'
                        placeholder='Insira o preço para o processo presencial'
                      />
                    </Col>
                  </Row>
                </>
              }
              <Row gutter={24}>
                <SectionTitle>Textos</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'description_main'}
                    type='draft'
                    label={'Descrição Principal'}
                    placeholder={'Descrição Principal'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'description_included'}
                    type='draft'
                    label={'O que inclui'}
                    placeholder={'O que inclui'}
                  />
                </Col>
                {/* <Col xs={12}>
                  <Field
                    component={LanguagesInput}
                    name={'description_when'}
                    type='draft'
                    label={'Quando Usar'}
                    placeholder={'Quando Usar'}
                  />
                </Col> */}
              </Row>
              {/* <Row gutter={24}>
                <Col xs={12}>
                  <Field
                    component={LanguagesInput}
                    name={'description_how'}
                    type='draft'
                    label={'Como Usar'}
                    placeholder={'Como Usar'}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    component={LanguagesInput}
                    name={'description_included'}
                    type='draft'
                    label={'O que inclui'}
                    placeholder={'O que inclui'}
                  />
                </Col>
              </Row> */}
              <Row gutter={24}>
                <SectionTitle>Metatags</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name={`identifier`}
                    type='text'
                    label={'URL *'}
                    placeholder={'URL do Serviço'}
                  />
                  <InputNote>
                    <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
                    acentos, letras minúsculas e apenas com letras, números e
                    hífens.
                  </InputNote>
                  <InputNote>
                    <span>EXEMPLO:</span> Título do Serviço: "Novo Serviço 2020"
                    / URL: "novo-servico-2020"
                  </InputNote>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`meta_title.${activeLanguage?.code}`}
                    label={'Meta Title'}
                    type={'text'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_description.${activeLanguage?.code}`}
                    label={'Meta Description'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_keywords.${activeLanguage?.code}`}
                    label={'Meta Keywords'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`meta_title.${language.code}`}
                            label={'Meta Title'}
                            type={'text'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageServicesPage = reduxForm({
  form: 'manage_services_form',
  validate: validations,
})(ManageServicesPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageServicesPage));