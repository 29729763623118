import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { ServiceModes } from "../../infra/utils/ServiceModes";
import { PaymentMethods } from "../../infra/utils/PaymentMethods";

const promoCodesStates = [
  {
    _id: 1,
    name: "Ativo",
    value: true
  },
  {
    _id: 2,
    name: "Inativo",
    value: false
  }
];

class PromoCodesFilters extends Component {
  state = { 
    search: '',
    active: '',
    service: '',
    service_mode: '',
    payment_method: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, active, service, service_mode, payment_method } = this.state;
    const { services } = this.props;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={5}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome ou código'
            />
          </Col>
          <Col xs={24} md={5}>
            <SelectInput
              label="Serviço"
              allowClear
              placeholder="Escolher serviço"
              data={services}
              dataKey="_id"
              dataLabel="title"
              translatable={true}
              input={{
                value: service,
                onChange: value => this.onInputChange('service', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={5}>
            <SelectInput
              label="Modalidade do Serviço"
              allowClear
              placeholder="Escolher modalidade"
              data={ServiceModes}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: service_mode,
                onChange: value => this.onInputChange('service_mode', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={5}>
            <SelectInput
              label="Método de Pagamento"
              allowClear
              placeholder="Escolher método"
              data={PaymentMethods}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: payment_method,
                onChange: value => this.onInputChange('payment_method', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={4}>
            <SelectInput
              label="Estado"
              allowClear
              placeholder="Escolher estado"
              data={promoCodesStates}
              dataKey="value"
              dataLabel="name"
              input={{
                value: active,
                onChange: value => this.onInputChange('active', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default PromoCodesFilters;