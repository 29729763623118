import client from '../config/AxiosConfig';

export const GetRecommendations = async (currentPage, limit, filters) => await client.get(`/clients_recommend/${currentPage}/${limit}?filter=${filters}`);

export const GetRecommendation = async (id) => await client.get(`/clients_recommend/${id}`);

export const CreateRecommendation = async (data) => await client.post(`/clients_recommend`, data);

export const UpdateRecommendation = async (id, data) => await client.put(`/clients_recommend/${id}`, data);

export const DeleteRecommendation = async (id) => await client.delete(`/clients_recommend/${id}`);
