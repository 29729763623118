/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../../styles/BasicStyles';
import { FileCategories } from '../../../../infra/utils/FileCategories';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';

const Filters = ({ queryChange }) => {
  const [filters, setFilters] = useState({
    search: '',
    date: '',
    category: ''
  });

  let timeout = null;

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if(filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });

    queryChange(JSON.stringify(query));
  };

  return (
    <TableFilterSection>
      <Row gutter={24}>
        <Col xs={24} md={6}>
          <TextInput
            input={{
              value: filters.search,
              onChange: (event) => onInputChange('search', event.target.value, 1000),
            }}
            meta={{ valid: true }}
            type='text'
            label='Pesquisar'
            placeholder='Procurar por nome'
          />
        </Col>
        <Col xs={24} md={6}>
          <DateInput
            label='Data'
            placeholder='Escolher data'
            input={{
              value: filters.date,
              onChange: value => onInputChange('date', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        <Col xs={24} md={6}>
        <SelectInput
              label="Categoria"
              allowClear
              placeholder="Escolher categoria"
              data={FileCategories}
              dataKey="_id"
              dataLabel="name"
              input={{
                value: filters.category,
                onChange: value => onInputChange('category', value)
              }}
              meta={{valid: true}}
            />
        </Col>
      </Row>
    </TableFilterSection>
  );
}

export default Filters;
