/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle, FileDownload } from '../../styles';
import { FilenameColumn } from './styles';
import { GetProcessReportsList, DeleteProcessReport } from '../../../../infra/requests/ProcessRequests';
import { Spin, notification, Tooltip } from 'antd';
import { ReactSVG } from 'react-svg';
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import ReportsFilters from './ReportsFilters';
import GetFile from '../../../../infra/utils/GetFile';
import NewReportModal from '../../modals/NewReport';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';

const Reports = ({ processInfo, reloadTab }) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newModal, setNewModal] = useState(false);
  const [downloadLoading, _setDownloadLoading] = useState([]);

  const downloadLoadingRef = useRef(downloadLoading);
  const setDownloadLoading = (data) => {
    downloadLoadingRef.current = data;
    _setDownloadLoading(data);
  };

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, reloadTab]);

  useEffect(() => {
    const init = async () => await getColumns();

    init();
  }, [downloadLoading]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const { success, data } = await GetProcessReportsList(processInfo._id, filters);
      const downloadList = [];

      if(success && data?.length > 0) {
        data.forEach(elem => {
          downloadList.push({
            _id: elem.file?._id,
            loading: false
          });    
        });
      }

      getColumns();
      setDownloadLoading(downloadList);
      setRows(data || []);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getColumns = () => {
    let finalColumns = [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '18%'
      },
      {
        title: 'Número',
        dataIndex: 'number',
        width: '10%'
      },
      {
        title: 'Ficheiros incluídos',
        dataIndex: 'files_included',
        width: '35%',
        render: (files) => {
          let documentsText = '';
          if(files.length > 0) {
            files.forEach((elem, index) => {
              if(elem.documents?.length > 0) {
                documentsText = `${documentsText}<span style="font-weight: 700">${elem.categoryName}</span>: `;

                elem.documents.forEach(doc => {
                  documentsText = `${documentsText}${doc}, `;
                });

                documentsText = documentsText.slice(0, -2);
              }

              if(index !== files.length - 1) documentsText = `${documentsText}<br>`;
            });
          }

          return <div dangerouslySetInnerHTML={{ __html: documentsText }}/>;
        }
      },
      {
        title: 'Tamanho',
        key: 'size',
        width: '10%',
        render: (data) => {
          const size = data.file?.length / (1024 * 1024);

          return (
            <FilenameColumn>
              {
                size > 10 &&
                <Tooltip placement='bottom' title='Este ficheiro pode ser demasiado grande para enviar como anexo em emails.'>
                  <ReactSVG 
                    src={`${process.env.REACT_APP_BO_URL}warning.svg`} 
                    className='alertIcon' 
                  />
                </Tooltip>
              }
              {size.toFixed(2)}MB
            </FilenameColumn>
          );
        }
      },
      {
        title: 'Ficheiro',
        key: 'file',
        width: '20%',
        render: (data) => {
          const isLoading = downloadLoading.find(elem => elem._id === data.file?._id)?.loading;

          return (
            <>
              {
                isLoading ?
                <Spin />
                :
                <FileDownload onClick={() => { callbackDownload(data.file?._id); GetFile(data.file?._id, callbackDownload) }}>
                  <span>{data.file?.filename}</span>
                  <ReactSVG src={`${process.env.REACT_APP_BO_URL}download.svg`} />
                </FileDownload>
              }
            </>
          );
        }
      }
    ];

    if(!processInfo.archived) {
      finalColumns.push(
        {
          title: 'Ações',
          key: 'actions',
          render: (data) => 
            <ButtonActions 
              icon={'dots'} 
              iconType={'fill'} 
              options={[
                {
                  popConfirmtitle: 'Tem a certeza que quer remover o ficheiro?',
                  onClick: () => removeFile(data),
                  label: 'Remover' 
                }
              ]} 
            />
        }
      );
    }

    setColumns(finalColumns);
  };

  const callbackDownload = (id) => {
    const aux = [...downloadLoadingRef?.current];

    const index = aux.findIndex(elem => elem._id === id);
    if(index >= 0) aux[index].loading = !aux[index].loading; 

    setDownloadLoading(aux);
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  const removeFile = async (data) => {
    try {
      setLoading(true);

      await DeleteProcessReport(data._id);
      await getInfo();

      notification.success({
        message: 'Relatório removido com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>Relatórios</TabTitle>
        <TabButtonsWrapper>
          {
            !processInfo.archived &&
            <BaseButton
              type={'primary'}
              text={'Gerar relatório'}
              onClick={() => setNewModal(true)}
            />
          }
        </TabButtonsWrapper>
      </TabTitleWrapper>
      <ReportsFilters
        queryChange={handleFilterChange}
      />
      <Table
        columns={columns}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Relatórios inseridos!'}
        rowKey={'_id'}
        hasPagination={false}
      />
      {
        !processInfo.archived &&
        <NewReportModal
          openModal={newModal}
          closeModal={() => setNewModal(false)}
          getInfo={getInfo}
          processID={processInfo._id}
        />
      }
    </>
  );
}

export default Reports;
