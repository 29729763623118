import styled from 'styled-components';

export const StateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & button {
    line-height: 0;
    padding: 0;
    padding-left: 10px; 
    height: 7px;
    
    & div {
      height: 7px;
    }

    & svg {
      width: 12px;
      height: 7px;
    }
  }
`;
