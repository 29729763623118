import { Icon, Modal, notification } from 'antd';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { SpinLoading } from '../../../../styles/BasicStyles';
import { CreateReport, GetProcessDocuments } from '../../../../infra/requests/ProcessRequests';
import { getCategory } from '../../../../infra/utils/FileCategories';
import { Tree } from 'antd';
import { TreeWrapper, NoDocuments, Note } from './styles';
import React, { useEffect, useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import moment from 'moment';
const { TreeNode } = Tree;

const NewReport = ({ openModal, closeModal, getInfo, processID }) => {
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    const init = async () => {
      setLoadingInfo(true);

      try {
        const { success, data } = await GetProcessDocuments(processID);
        
        if(success && data?.length > 0) {
          const result = [], auxChecked = [];

          data.forEach(elem => {
            const categoryIndex = result.findIndex(item => item.category === elem.category);

            // Only images and pdf's can be selected
            const document = {
              _id: elem._id,
              name: elem.file?.filename,
              date: elem.createdAt,
              disabled: !elem.file?.contentType?.includes('image/') && !elem.file?.contentType?.includes('application/pdf') ? true : false
            };

            if(categoryIndex >= 0) {
              result[categoryIndex].documents.push(document);
            }
            else {
              result.push({
                category: elem.category,
                documents: [document]
              });

              // Only certain categories must have a selected file by default
              const hasSelected = [4, 5, 7, 9, 10];
              if(hasSelected.includes(elem.category)) auxChecked.push(document._id);
            }
          });

          result.sort((a, b) => a.category - b.category);

          setCheckedKeys(auxChecked);
          setDocuments(result);
        }
        
        setLoadingInfo(false);
      } 
      catch (error) {
        console.error(error);
        setLoadingInfo(false);
      }
    };

    if(openModal) init();
  }, [openModal, processID]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      
      const result = await CreateReport({ process: processID, documents: checkedKeys });

      if(result?.success) {
        notification.success({
          message: 'Relatório gerado com sucesso!'
        });
        
        await getInfo();
        closeModal();
      }
      
      setLoading(false);
    } 
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onSelect = (selected, info) => {
    // When removing the selection, the array passed is empty, so we need to get the ID from another place
    let key = '';
    if(selected?.length > 0) key = selected[0];
    else key = info?.node?.props?.eventKey;

    if(key) {
      // First add/remove the selected key
      const currentKeys = [...checkedKeys];
      const index = currentKeys.findIndex(elem => elem === key);

      if(index >= 0) currentKeys.splice(index, 1);
      else currentKeys.push(key);

      // Then, we need to do this cycle to maintain the current order of the categories, so we add the files based on their category order
      const aux = [];

      documents.forEach(elem => {
        if(elem.documents.length > 0) {
          elem.documents.forEach(doc => {
            if(currentKeys.includes(doc._id)) aux.push(doc._id);
          });
        }
      });

      setCheckedKeys(aux);
    }
  };

  const onCheck = (checked) => {
    const aux = [];

    // We need to do this cycle to maintain the current order of the categories, so we add the files based on their category order
    documents.forEach(elem => {
      if(elem.documents.length > 0) {
        elem.documents.forEach(doc => {
          if(checked.includes(doc._id)) aux.push(doc._id);
        });
      }
    });

    setCheckedKeys(aux);
  };

  const onDrop = (info) => {
    const { dropPosition } = info;
    const dragKey = info?.dragNode?.props?.eventKey;

    const aux = [...documents];

    // Get the dragged item index
    const currentIndex = aux.findIndex(elem => `category_${elem.category}` === dragKey);
    const dragElem = aux[currentIndex];

    // Remove the item from the old index
    aux.splice(currentIndex, 1);

    // Add it in the new index
    // The index depends on the drag direction, if we're dragging to the top, we need to add 1 position, otherwise use the default value
    aux.splice(dropPosition < currentIndex ? dropPosition + 1 : dropPosition, 0, dragElem);

    // After changing the category order, we need to redo the checked keys to apply the new order
    const newChecked = [];

    aux.forEach(elem => {
      if(elem.documents.length > 0) {
        elem.documents.forEach(doc => {
          if(checkedKeys.includes(doc._id)) newChecked.push(doc._id);
        });
      }
    });

    setCheckedKeys(newChecked);
    setDocuments(aux);
  };

  // Don't allow drag/drop files, only categories are allowed
  const onDragStart = (info) => {
    const { node } = info;
    if(node?.props?.isLeaf) info.event.preventDefault();
  };

  const renderDocumentTitle = (document) => {
    let disabled = '';
    if(document.disabled) disabled = '(Formato não suportado)';

    return (
      <>
        {moment(document.date).format('DD-MM-YYYY, HH:mm')}h - <i>{document.name}</i> <span>{disabled}</span>
      </>
    );
  };

  return (
    <Modal
      visible={openModal}
      maskClosable
      onCancel={() => closeModal()}
      footer={null}
      width={600}
    >
      <ModalTitle>Novo Relatório</ModalTitle>
      {
        loadingInfo ?
        <SpinLoading />
        :
        !documents || documents?.length === 0 ?
        <NoDocuments>Não existem documentos inseridos!</NoDocuments>
        :
        <>
          <TreeWrapper>
            <Tree
              checkable
              defaultExpandAll={true}
              onSelect={onSelect}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              draggable={true}
              onDrop={onDrop}
              onDragStart={onDragStart}
              showIcon
            >
              {
                documents.length > 0 &&
                documents.map(elem => 
                  <TreeNode 
                    checkable={false} 
                    selectable={false} 
                    title={getCategory(elem.category)?.name} 
                    key={`category_${elem.category}`}
                    icon={<Icon type="drag" />}
                  >
                    {
                      elem.documents.map(document =>
                        <TreeNode 
                          disabled={document.disabled || loading} 
                          title={renderDocumentTitle(document)} 
                          key={document._id} 
                          isLeaf={true}
                        />
                      )
                    }
                  </TreeNode>
                )
              }
            </Tree>
          </TreeWrapper>
          <Note><span>Nota: </span>Pode arrastar as categorias para definir a ordem pela qual aparecem no relatório.</Note>
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              disabled={checkedKeys?.length === 0}
              htmlType="button"
              onClick={onSubmit}
              text="Gerar"
            />
          </ModalButtonWrapper>
        </>
      }
    </Modal>
  );
};

export default NewReport;
