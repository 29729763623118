import { device } from '../../../../styles/Responsive';
import styled, { css } from 'styled-components';

/* LIST */

export const TableWrapper = styled.div` 
  ${({ hasTotal }) => hasTotal &&
    css`
      & .ant-table {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    `}
`;

export const TotalRow = styled.div` 
  background-color: #F8F9FA;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;

export const EmptyColumn = styled.div` 
  width: ${({ width }) => width};
  padding: 0px 8px;
`;

export const TextColumn = styled.div` 
  width: ${({ width }) => width};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #343A40;
  text-align: right;
  padding: 0px 8px;
`;

export const TotalColumn = styled.div` 
  width: ${({ width }) => width};
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #343A40;
  padding: 0px 8px;
`;

/* PROCESS DETAIL */

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InfoColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-right: 10px;
  }
  &:last-child {
    padding-left: 10px;
  }

  @media ${device.laptop} {
    width: 100%;

    &:first-child {
      padding-right: 0px;
    }
    &:last-child {
      padding-left: 0px;
    }
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;

  @media ${device.tabletS} {
    flex-wrap: wrap;
  }
`;

export const FieldTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #343A40;
  padding-right: 10px;
  width: ${({ width }) => width || '50%'};
  flex-shrink: 0;

  @media ${device.laptop} {
    width: 50%;
  }

  @media ${device.tabletS} {
    width: 100%;
    padding-right: 0px;
  }
`;

export const FieldValue = styled.div`
  font-weight: ${({ big }) => big ? '700' : '400'};
  font-size: ${({ big }) => big ? '18px' : '16px'};
  line-height: ${({ big }) => big ? '28px' : '25px'};
  color: #495057;
`;

export const ButtonsRow = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;

  & button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
