import LoginPage from "../../pages/auth/LoginPage";
import RecoverAccountPage from "../../pages/auth/RecoverAccountPage";
import RecoverPasswordPage from "../../pages/auth/RecoverPasswordPage";
import RegisterPage from "../../pages/auth/RegisterPage";

export default [
  {
    path: "/login",
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: "/recover-account",
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: "/recover-password",
    component: RecoverPasswordPage,
    isAuthenticated: false
  },
  {
    path: "/register",
    component: RegisterPage,
    isAuthenticated: false
  },
];
