import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetMetatag,
  UpdateMetatag,
  CreateMetatag,
} from '../../infra/requests/BaseRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

class ManageMetatags extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();
    
    if (params.id !== 'add') {
      const result = await GetMetatag(params.id);
      dispatch(initialize('manage_metatags_form', { ...result.data }));
      this.setState({
        ready: true,
        isNew: false,
        info: result || {},
      });
    } else {
      this.setState({
        ready: true,
        isNew: true,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      const { isNew } = this.state;

      isNew
        ? await CreateMetatag(values)
        : await UpdateMetatag(params.id, values);
      return history.push('/metatags');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/metatags');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, ready, info } = this.state;
    if (!ready) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Metatags'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col md={12} xs={24}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage.code}`}
                  label={'Title'}
                  type={'text'}
                />
                {
                  !isNew && info?.data?.tag !== 'SUCCESS' && info?.data?.tag !== 'PRIVATE_AREA' && info?.data?.tag !== 'SIGNATURE' && 
                  <React.Fragment>
                    <Field
                      component={TextAreaInput}
                      name={`description.${activeLanguage.code}`}
                      label={'Description'}
                    />
                    <Field
                      component={TextAreaInput}
                      name={`keywords.${activeLanguage.code}`}
                      label={'Keywords'}
                    />
                  </React.Fragment>
                }
              </Col>
              <Col md={12} xs={24}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          label={'Title'}
                          type={'text'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageMetatags = reduxForm({
  form: 'manage_metatags_form'
})(ManageMetatags);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageMetatags));