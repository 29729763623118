import { Field } from 'redux-form';
import { BaseForm, Separator, InputNote } from '../../../styles/BasicStyles';
import { UserTypes } from '../../../infra/utils/UserTypes';
import { commissionOptions } from '../../../infra/utils/CommissionsStates';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';
import RadioButtons from '../../../components/generic/inputs/RadioButtons';
import CurrencyInput from '../../../components/generic/inputs/CurrencyInput';
import NumberInput from '../../../components/generic/inputs/NumberInput';
import ImageInput from '../../../components/generic/inputs/ImageInput';
import TextInput from '../../../components/generic/inputs/TextInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';

const UserForm = ({ handleSubmit, onSubmit, userInfo, user }) => {
  const [commissionServiceType, setCommissionServiceType] = useState(userInfo?.commission_service_type);
  const [commissionPaymentsType, setCommissionPaymentsType] = useState(userInfo?.commission_payments_type);
  const [commissionLemaniaType, setCommissionLemaniaType] = useState(userInfo?.commission_lemania_type);
  const [type, setType] = useState(userInfo?.type);

  return (
    <BaseForm onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={SelectInput}
        name={'type'}
        label={'Tipo'}
        placeholder={'Escolha o tipo'}
        type='select'
        data={UserTypes}
        dataKey={'_id'}
        dataLabel={'name'}
        execAfterChange={(value) => setType(parseInt(value))}
      />
      <Field
        component={TextInput}
        name='name'
        type='text'
        label='Nome *'
        placeholder='Primeiro e último nome'
      />
      <Field
        component={TextInput}
        name='email'
        type='email'
        label='Email *'
        placeholder='Email do utilizador'
      />
      <Field
        component={TextInput}
        name='contact'
        type='text'
        label='Contacto'
        placeholder='Contacto do utilizador'
      />
      {
        user.super_admin && (type === 1 || type === 3) &&
        <>
          <Separator/>
          <Field
            component={RadioButtons}
            name={'commission_service_type'}
            options={commissionOptions}
            label={'Comissão dos Serviços *'}
            execAfterChange={(value) => setCommissionServiceType(value)}
            first
          />
          {
            commissionServiceType === 1 ?
            <Row gutter={24}>
              <Col md={12} xs={24}>
                <Field
                  component={CurrencyInput}
                  name={'commission_service_chf'}
                  label={'Valor (CHF)'}
                  suffix={'CHF'}
                />
              </Col>
              <Col md={12} xs={24}>
                <Field
                  component={CurrencyInput}
                  name={'commission_service_eur'}
                  label={'Valor (Euro)'}
                  suffix={'€'}
                />
              </Col>
            </Row>
            :
            commissionServiceType === 2 ?
            <Field
              component={NumberInput}
              name={'commission_service_perc'}
              label={'Valor (%)'}
              placeholder={'0.00%'}
              min={0}
              max={100}
            />
            :
            null
          }
          <Separator/>
          <Field
            component={RadioButtons}
            name={'commission_payments_type'}
            options={commissionOptions}
            label={'Comissão de Pagamentos *'}
            execAfterChange={(value) => setCommissionPaymentsType(value)}
            first
          />
          {
            commissionPaymentsType === 1 ?
            <Row gutter={24}>
              <Col md={12} xs={24}>
                <Field
                  component={CurrencyInput}
                  name={'commission_payments_chf'}
                  label={'Valor (CHF)'}
                  suffix={'CHF'}
                />
              </Col>
              <Col md={12} xs={24}>
                <Field
                  component={CurrencyInput}
                  name={'commission_payments_eur'}
                  label={'Valor (Euro)'}
                  suffix={'€'}
                />
              </Col>
            </Row>
            :
            commissionPaymentsType === 2 ?
            <Field
              component={NumberInput}
              name={'commission_payments_perc'}
              label={'Valor (%)'}
              placeholder={'0.00%'}
              min={0}
              max={100}
            />
            :
            null
          }
          <Separator/>
          <Field
            component={RadioButtons}
            name={'commission_lemania_type'}
            options={commissionOptions}
            label={'Comissão da Lemania *'}
            execAfterChange={(value) => setCommissionLemaniaType(value)}
            first
          />
          {
            commissionLemaniaType === 1 ?
            <Field
              component={CurrencyInput}
              name={'commission_lemania'}
              label={'Valor (CHF)'}
              suffix={'CHF'}
            />
            :
            commissionLemaniaType === 2 ?
            <Field
              component={NumberInput}
              name={'commission_lemania'}
              label={'Valor (%)'}
              placeholder={'0.00%'}
              min={0}
              max={100}
            />
            :
            null
          }
        </>
      }
      {
        type === 2 &&
        <>
          <Separator/>
          <Field
            component={ImageInput}
            name='image'
            label='Logo do Agente'
            noCrop={true}
          />
          <InputNote style={{ fontSize: '15px', marginBottom: '20px' }}><span>NOTA:</span> Selecione uma imagem com uma largura máxima de 150px! Caso contrário poderá desformatar os emails.</InputNote>
          <Field
            component={TextAreaInput}
            name={`address`}
            type='text'
            label={'Morada'}
            placeholder={'Morada'}
          />
          <Field
            component={TextInput}
            name={`cod_postal`}
            type='text'
            label={'Cód. Postal'}
            placeholder={'Cód. Postal'}
          />
          <Field
            component={TextInput}
            name={`city`}
            type='text'
            label={'Cidade'}
            placeholder={'Cidade'}
          />
          <Field
            component={TextInput}
            name={`country`}
            type='text'
            label={'País'}
            placeholder={'País'}
          />
        </>
      }
    </BaseForm>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(UserForm);