import { Field } from 'redux-form';
import {
  DefaultLanguageTab,
  Separator
} from '../../../styles/BasicStyles';
import { withLocalize } from 'react-localize-redux';
import { GetTranslationDescription } from '../../../infra/services/translations/AvailableTranslations';
import { Popconfirm, Tooltip, Icon, Tabs, Row, Col, notification } from 'antd';
import React, { Fragment, useState } from 'react';
import TextInput from '../../../components/generic/inputs/TextInput';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import NewParamModal from './NewParamModal';
const TabPane = Tabs.TabPane;

const ParamsList = ({ input, languages, activeLanguage }) => {
  const [open, setOpen] = useState(false);

  const params = input?.value || [];

  const addParam = (key) => {
    const found = params.find(t => t.key === key);
    if(found) {
      return notification.error({
        message: 'Já existe um parâmetro com a chave inserida!'
      });
    }

    const aux = [...params];
    aux.push({ key, value: undefined });
    input.onChange(aux);
  };

  const deleteParam = (index) => {
    const aux = [...params];
    aux.splice(index, 1);
    input.onChange(aux);
  };

  return (
    <>
      <Separator />
      <Row gutter={24}>
        <Col xs={24}>
          <BaseButton
            type={'black'}
            text={'Criar Parâmetro'}
            onClick={() => setOpen(true)}
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
      {
        params.length > 0 ?
        <Row gutter={24}>
          <Col md={12} xs={24} offset={0}>
            <DefaultLanguageTab>
              {GetTranslationDescription(activeLanguage?.code)}
            </DefaultLanguageTab>
            {
              params.map((elem, index) => 
                <Row gutter={24} key={`${index}_${activeLanguage?.code}`}>
                  <Col xs={20}>
                    <Field
                      component={TextInput}
                      name={`params[${index}].translation[${activeLanguage?.code}]`}
                      type='text'
                      label={`Conteúdo {{params.${elem.key}}}`}
                      placeholder={'Conteúdo'}
                    />
                  </Col>
                  <Col xs={4} style={{ marginTop: '45px' }}>
                    <Popconfirm
                      placement='topRight'
                      title={'Tem a certeza que pretende remove este parâmetro?'}
                      onConfirm={() => deleteParam(index)}
                    >
                      <Tooltip placement='bottom' title='Remover'>
                        <Icon type="delete" />
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                </Row>
              )
            }
          </Col>
          <Col md={12} xs={24} offset={0}>
            <Tabs>
              {
                languages
                .filter((x) => !x.active)
                .map((language, langIndex) =>
                  <TabPane
                    tab={GetTranslationDescription(language.code)}
                    key={language.code}
                  >
                    <Fragment key={langIndex}>
                      {
                        params.map((elem, index) => 
                          <Row gutter={24} key={`${index}_${language.code}`}>
                            <Col xs={20}>
                              <Field
                                component={TextInput}
                                name={`params[${index}].translation[${language.code}]`}
                                type='text'
                                label={`Conteúdo {{params.${elem.key}}}`}
                                placeholder={'Conteúdo'}
                              />
                            </Col>
                            <Col xs={4} style={{ marginTop: '45px' }}>
                              <Popconfirm
                                placement='topRight'
                                title={'Tem a certeza que pretende remove este parâmetro?'}
                                onConfirm={() => deleteParam(index)}
                              >
                                <Tooltip placement='bottom' title='Remover'>
                                  <Icon type="delete" />
                                </Tooltip>
                              </Popconfirm>
                            </Col>
                          </Row>
                        )
                      }
                    </Fragment>
                  </TabPane>
                )
              }
            </Tabs>
          </Col>
        </Row>
        :
        <Row gutter={24}>
          <Col xs={24}>
            <div style={{ marginTop: '20px', textAlign: 'center', fontSize: '15px' }}>Ainda não inseriu parâmetros!</div>
          </Col>
        </Row>
      }
      {
        open && 
        <NewParamModal
          open={open}
          closeModal={() => setOpen(false)}
          addParam={addParam}
        />
      }
    </>
  );
};

export default withLocalize(ParamsList);
