import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetEstate,
  UpdateEstate,
  CreateEstate,
  GetTypesDropdown,
  GetFeaturesList,
  GetCountriesList,
  GetCitiesByCountry,
  ValidateRealEstate
} from '../../infra/requests/EstateRequests';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  InputNote,
  SectionTitle,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import CurrencyInput from '../../components/generic/inputs/CurrencyInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import LanguagesInput from '../../components/generic/inputs/LanguagesInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import DateInput from '../../components/generic/inputs/DateInput';
import ImageGallery from './ImageGallery';
import RejectEstateModal from './RejectEstateModal';
import { StateLabel } from '../process/styles';
import moment from 'moment';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  identifier: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  description: 'required|languages',
  summary: 'required|languages',
  image: 'required',
  date: 'required',
  price_euro: 'required',
  price_chf: 'required',
  type: 'required',
  country: 'required',
  city: 'required'
});

class ManageEstatePage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    initialValues: null,
    types: [],
    features: [],
    countries: [],
    cities: [],
    rejectModal: false,
    loadingValidate: false,
    gallery: []
  };

  componentDidMount = async () => {
    const {
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    await this.getInfo();
  };

  getInfo = async () => {
    const {
      match: { params },
      dispatch,
      history
    } = this.props;

    const types = await GetTypesDropdown();
    const features = await GetFeaturesList();
    const countries = await GetCountriesList();

    if(params.id) {
      const result = await GetEstate(params.id);

      if(!result?.data?._id) return history.push('/estate/list');

      const cities = result?.data?.country?._id ? await GetCitiesByCountry(result?.data?.country?._id) : null;

      dispatch(initialize('manage_estate_form', { 
        ...result.data,
        type: result?.data?.type?._id,
        country: result?.data?.country?._id,
        city: result?.data?.city?._id,
        features: result?.data?.features.map(elem => elem._id) || []
      }));

      this.setState({ 
        loading: false,
        initialValues: result?.data,
        types: types?.data || [],
        features: features?.data || [],
        countries: countries?.data || [],
        cities: cities?.data || [],
        gallery: result?.data?.gallery || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        types: types?.data || [],
        features: features?.data || [],
        countries: countries?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, user, history } = this.props;
      const { gallery } = this.state;

      if(values.image && !values.image.blob) delete values.image;

      const payload = FlattenToFormData({ ...values, gallery, agent: user?.type === 4 ? user._id : null });
      const { success } = params.id ? await UpdateEstate(params.id, payload) : await CreateEstate(payload);

      if(success) {
        if(user?.type === 4) {
          if(params.id) {
            notification.success({
              message: 'Sucesso',
              description: 'O Imóvel foi alterado com sucesso! As alterações necessitam de ser validadas pela administração.',
              duration: 10
            });
          }
          else {
            notification.success({
              message: 'Sucesso',
              description: 'O Imóvel foi inserido com sucesso! Irá agora ser validado pela administração e será notificado por email caso seja aprovado ou rejeitado.',
              duration: 10
            });
          }
        }

        return history.push('/estate/list');
      }
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/estate/list');
  };

  loadCities = async (value) => {
    try {
      const cities = await GetCitiesByCountry(value);

      this.setState({
        cities: cities?.data || []
      });
    }
    catch (e) {
      console.error(e);
    }
  };

  saveNewImage = async image => {
    const { gallery } = this.state;

    const aux = [...gallery];
    aux.push(image);
    this.setState({ gallery: aux });
  };

  deleteImage = async index => {
    const { gallery } = this.state;

    const aux = [...gallery];
    aux.splice(index, 1);
    this.setState({ gallery: aux });
  };

  validateEstate = async () => {
    try {
      this.setState({ loadingValidate: true });
      const { initialValues } = this.state;

      const { success } = await ValidateRealEstate(initialValues?._id);

      if(success) {
        notification.success({
          message: 'Sucesso',
          description: 'O Imóvel foi validado e ativado com sucesso!'
        });

        await this.getInfo();
      }
      else {
        notification.error({
          message: 'Erro',
          description: 'Ocorreu um erro ao validar o Imóvel! Por favor tente mais tarde ou entre em contacto com a administração'
        });
      }

      this.setState({ loadingValidate: false });
    }
    catch (e) {
      console.error(e);
      this.setState({ loadingValidate: false });
    }
  };

  renderButtons = () => {
    const { handleSubmit, user } = this.props;
    const { initialValues, loadingValidate } = this.state;

    const buttons = [
      {
        type: 'primary',
        icon: 'save',
        text: 'Gravar',
        onClick: handleSubmit((values) => this.onSubmit(values)),
        disabled: loadingValidate
      },
      {
        type: 'secondary',
        icon: 'close',
        text: 'Cancelar',
        onClick: this.handleOnCancel,
        disabled: loadingValidate
      }
    ];

    if(user?.type === 1 && !!initialValues?.agent) {
      if(initialValues?.validated === 'PENDING') {
        buttons.push({
          type: 'grey',
          icon: 'close',
          text: 'Rejeitar',
          onClick: () => this.setState({ rejectModal: true }),
          disabled: loadingValidate
        });
      }

      if(initialValues?.validated === 'PENDING' || initialValues?.validated === 'REJECTED') {
        buttons.push({
          type: 'black',
          icon: 'check',
          text: 'Validar',
          popConfirmtitle: 'Tem a certeza que pretender validar este Imóvel?',
          onClick: this.validateEstate,
          disabled: loadingValidate
        });
      }
    }

    return buttons;
  };

  renderValidatedState = () => {
    const { initialValues } = this.state;

    if(initialValues?.validated === 'VALIDATED') {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Validado
        </StateLabel>
      );
    }
    if(initialValues?.validated === 'PENDING') {
      return (
        <StateLabel
          bgColor='#FFA000'
          fontColor='#ffffff'
        >
          À espera de validação
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Rejeitado
      </StateLabel>
    );
  };

  render() {
    const { handleSubmit, activeLanguage, languages, user } = this.props;
    const { isNew, loading, types, features, countries, cities, initialValues, rejectModal, gallery } = this.state;

    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Imobiliário'
          breadcrumbs={[title]}
          buttons={this.renderButtons()}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              {
                user?.type === 1 && !!initialValues?.agent &&
                <Row gutter={24}>
                  <SectionTitle>Agente Imobiliário</SectionTitle>
                  <div style={{ marginTop: '20px', fontSize: '16px' }}>Agente: <span style={{ fontWeight: 'bold' }}>{initialValues.agent.name}</span></div>
                  <div style={{ marginTop: '10px', fontSize: '16px' }}>Estado: {this.renderValidatedState()}</div>
                  {
                    initialValues.validated === 'REJECTED' ?
                    <>
                      <div style={{ marginTop: '10px', fontSize: '16px' }}>Rejeitado por: <span style={{ fontWeight: 'bold' }}>{initialValues.validated_info?.admin?.name}</span></div>
                      <div style={{ marginTop: '10px', fontSize: '16px' }}>Data: <span style={{ fontWeight: 'bold' }}>{initialValues.validated_info?.date ? moment.utc(initialValues.validated_info?.date).format('DD/MM/YYYY HH:mm') : '-'}</span></div>
                      <div style={{ marginTop: '10px', fontSize: '16px' }}>Motivo: <span style={{ fontWeight: 'bold' }}>{initialValues.validated_info?.message}</span></div>
                    </>
                    :
                    initialValues.validated === 'VALIDATED' ?
                    <>
                      <div style={{ marginTop: '10px', fontSize: '16px' }}>Validado por: <span style={{ fontWeight: 'bold' }}>{initialValues.validated_info?.admin?.name}</span></div>
                      <div style={{ marginTop: '10px', fontSize: '16px' }}>Data: <span style={{ fontWeight: 'bold' }}>{initialValues.validated_info?.date ? moment.utc(initialValues.validated_info?.date).format('DD/MM/YYYY HH:mm') : '-'}</span></div>
                    </>
                    :
                    null
                  }
                </Row>
              }
              <Row gutter={24}>
                <SectionTitle>Imagem</SectionTitle>
                <Col md={12} xs={24}>
                  <Field
                    component={ImageInput}
                    name='image'
                    label='Imagem de capa *'
                    ratio={0.78}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Galeria</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <ImageGallery 
                    gallery={gallery}
                    handleAdd={this.saveNewImage}
                    handleDelete={this.deleteImage}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Informação Principal</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                </Col>
                <Col md={12} xs={24} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Separator />
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={DateInput}
                    name='date'
                    label={'Data *'}
                    placeholder={'Data'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={SelectInput}
                    name='type'
                    label={'Tipologia *'}
                    placeholder={'Selecionar tipologia'}
                    type='select'
                    data={types}
                    dataLabel='title'
                    translatable
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={SelectInput}
                    name='country'
                    label={'País *'}
                    placeholder={'Selecionar país'}
                    type='select'
                    data={countries}
                    dataLabel='name'
                    translatable
                    execAfterChange={(value) => this.loadCities(value)}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={SelectInput}
                    name='city'
                    label={'Cidade *'}
                    placeholder={'Selecionar cidade'}
                    type='select'
                    data={cities}
                    dataLabel='name'
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={CurrencyInput}
                    name={'price_euro'}
                    defaultValue='0.00'
                    suffix='€'
                    label={'Preço (€) *'}
                    placeholder={'Insira o preço'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={CurrencyInput}
                    name={'price_chf'}
                    defaultValue='0.00'
                    suffix='CHF'
                    label={'Preço (CHF) *'}
                    placeholder={'Insira o preço'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    mode="multiple"
                    allowClear
                    component={SelectInput}
                    name='features'
                    label={'Características'}
                    placeholder={'Selecionar características'}
                    type='select'
                    data={features}
                    dataLabel='title'
                    translatable
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Textos</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'summary'}
                    type='draft'
                    label={'Resumo'}
                    placeholder={'Resumo'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'description'}
                    type='draft'
                    label={'Descrição'}
                    placeholder={'Descrição'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Field
                    component={LanguagesInput}
                    name={'description_details'}
                    type='draft'
                    label={'Detalhes do Imóvel'}
                    placeholder={'Descrição'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <SectionTitle>Metatags</SectionTitle>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name={`identifier`}
                    type='text'
                    label={'URL *'}
                    placeholder={'URL do Serviço'}
                  />
                  <InputNote>
                    <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
                    acentos, letras minúsculas e apenas com letras, números e
                    hífens.
                  </InputNote>
                  <InputNote>
                    <span>EXEMPLO:</span> Título do Serviço: "Novo Serviço 2020"
                    / URL: "novo-servico-2020"
                  </InputNote>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`meta_title.${activeLanguage?.code}`}
                    label={'Meta Title'}
                    type={'text'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_description.${activeLanguage?.code}`}
                    label={'Meta Description'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_keywords.${activeLanguage?.code}`}
                    label={'Meta Keywords'}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`meta_title.${language.code}`}
                            label={'Meta Title'}
                            type={'text'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
        <RejectEstateModal
          openModal={rejectModal}
          closeModal={() => this.setState({ rejectModal: false })}
          getInfo={this.getInfo}
          initialValues={initialValues}
        />
      </React.Fragment>
    );
  }
}

ManageEstatePage = reduxForm({
  form: 'manage_estate_form',
  validate: validations,
})(ManageEstatePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageEstatePage));