import client from '../config/AxiosConfig';

export const GetClientsList = async (payload) => await client.post('/communications/users', payload);

export const GetCommunication = async (id) => await client.get(`/communications/${id}`);

export const GetCommunicationEmails = async (id, currentPage, limit, filters) => await client.get(`/communications/emails/${id}/${currentPage}/${limit}?filter=${filters}`);

export const GetCommunications = async (currentPage, limit, filters) => await client.get(`/communications/${currentPage}/${limit}?filter=${filters}`);

export const SaveCommunication = async (payload) => await client.post(`/communications/`, payload);

export const SendCommunicationEmails = async (id) => await client.get(`/communications/send/${id}`);
