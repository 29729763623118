/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../../styles/BasicStyles';
import { GetAdminList, GetAgentsList } from '../../../infra/requests/UsersRequests';
import { GetServicesList } from '../../../infra/requests/ServicesRequests';
import { connect } from 'react-redux';
import { GetCommunications } from '../../../infra/requests/CommunicationsRequests';
import { getProcessPaymentState, getProcessState } from '../../../infra/utils/ProcessStates';
import { FiltersText } from '../styles';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import ButtonActions from '../../../components/generic/buttons/ButtonActions';
import Table from '../../../components/generic/table/Table';
import Filters from './Filters';

const ComunicationsPage = ({ history, user }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    // NOTE: This area was made for both agents and admins, but only admins have access for now. 
    // If the agents should have access in the future just remove this verification
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    } 

    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize]);

  const getInfo = async () => {
    setLoading(true);

    try {
      let columns = [
        {
          title: 'Data',
          dataIndex: 'createdAt',
          render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
          width: '15%',
        },
        {
          title: 'Assunto',
          dataIndex: 'subject',
          width: '30%',
        },
        {
          title: 'Nº Destinatários',
          key: 'emails_selected',
          width: '10%',
          render: (data) => data.emails?.length || 0
        },
        {
          title: 'Filtros',
          key: 'type',
          render: (data) => renderFilters(data),
          width: '35%',
        },
        {
          title: 'Ações',
          render: (data) => 
            <ButtonActions 
              icon={'dots'} 
              iconType={'fill'} 
              options={[
                {
                  onClick: () => history.push(`/communications/${data._id}`),
                  label: 'Detalhes' 
                }
              ]} 
            />
        }
      ];

      if(user?.type === 1) {
        columns = [
          {
            title: 'Data',
            dataIndex: 'createdAt',
            render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
            width: '15%',
          },
          {
            title: 'Admin',
            key: 'user',
            width: '15%',
            render: (data) => data?.user?.name
          },
          {
            title: 'Assunto',
            dataIndex: 'subject',
            width: '20%',
          },
          {
            title: 'Nº Destinatários',
            key: 'emails_selected',
            width: '10%',
            render: (data) => data.emails?.length || 0
          },
          {
            title: 'Filtros',
            key: 'type',
            render: (data) => renderFilters(data),
            width: '30%',
          },
          {
            title: 'Ações',
            render: (data) => 
              <ButtonActions 
                icon={'dots'} 
                iconType={'fill'} 
                options={[
                  {
                    onClick: () => history.push(`/communications/${data._id}`),
                    label: 'Detalhes' 
                  }
                ]} 
              />
          }
        ];
      }
      
      const result = await GetCommunications(currentPage, pageSize, filters);

      // NOTE: This area was made for both agents and admins, but only admins have access for now. 
      // If the agents should have access in the future just uncomment this line and remove the other below
      // const users = user?.type === 2 ? await GetUserAdminsList() : await GetAllUsersList();
      const users = await GetAdminList();

      const servicesList = user?.type === 1 ? await GetServicesList() : [];
      const agentsList = user?.type === 1 ? await GetAgentsList() : [];

      let responsiblesList = [];
      if(users?.data?.length > 0) {
        responsiblesList = [...users?.data];

        responsiblesList.unshift({
          _id: 'all',
          name: 'Todos os responsáveis'
        })
      }

      if(agentsList?.data?.length > 0) {
        agentsList.data.unshift({
          _id: 'all',
          name: 'Todos os agentes'
        })
      }

      setColumns(columns);
      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setUsers(users?.data || []);
      setAgents(agentsList?.data || []);
      setResponsibles(responsiblesList || []);
      setServices(servicesList?.data || []);
      setCurrentPage(currentPage);
      setPageSize(pageSize);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const renderFilters = (data) => {
    return (
      <>
        {data.all_users && <FiltersText>Utilizadores: <span>Todos</span></FiltersText>}
        {data.newsletter && <FiltersText>Inscrito na Newsletter: <span>Sim</span></FiltersText>}
        {data.all_agents && <FiltersText>Agente: <span>Todos</span></FiltersText>}
        {data.agent && <FiltersText>Agente: <span>{data.agent?.name}</span></FiltersText>}
        {data.all_responsibles && <FiltersText>Responsável: <span>Todos</span></FiltersText>}
        {data.responsible && <FiltersText>Responsável: <span>{data.responsible?.name}</span></FiltersText>}
        {data.service && <FiltersText>Serviço: <span>{data.service?.title?.pt}</span></FiltersText>}
        {data.process_state && <FiltersText>Estado do Processo: <span>{getProcessState(data.process_state)?.name}</span></FiltersText>}
        {data.payment_state && <FiltersText>Estado do Pagamento: <span>{getProcessPaymentState(data.payment_state)?.name}</span></FiltersText>}
      </>
    );
  };

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>
          <PageTitle>Comunicações</PageTitle>
        </HeaderTitle>
        <HeaderButtonsContainer buttons={1}>
          <BaseButton
            iconType={'stroke'}
            customIcon={'plus'}
            text={'Nova Comunicação'}
            onClick={() => history.push('/communications/add')}
          />
        </HeaderButtonsContainer>
      </HeaderContainer>
      <PageContainer>
        <Filters 
          queryChange={handleFilterChange} 
          users={users}
          agents={agents}
          responsibles={responsibles}
          services={services}
        />
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Comunicações inseridas!'}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          onPressRow={(record) => history.push(`/communications/${record._id}`)}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PageContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ComunicationsPage);
