import { HeaderLine, HeaderColumn, Row, RowColumn, NoFunds } from './styles';
import React from 'react';

const LemaniaDetail = ({ lemania, filtersString }) => {
  let funds = lemania.funds;

  // If we have selected filters, show only the funds that have those values
  if(funds?.length > 0 && filtersString) {
    const filters = JSON.parse(filtersString);

    if(filters.search) {
      const regex = new RegExp(filters.search, 'i');

      funds = funds.filter(elem => regex.test(elem.fund) || regex.test(elem.notes));
    }

    if(filters.lemania == 1) funds = funds.filter(elem => elem.lemania);
    else if(filters.lemania == 2) funds = funds.filter(elem => !elem.lemania);

    if(filters.commission == 1) funds = funds.filter(elem => elem.commission);
    else if(filters.commission == 2) funds = funds.filter(elem => !elem.commission);
  }

  return (
    <>
      <HeaderLine type={2}>
        <HeaderColumn width="20%">Tem fundos onde?</HeaderColumn>
        <HeaderColumn width="36%">Notas</HeaderColumn>
        <HeaderColumn width="14%">Valor</HeaderColumn>
        <HeaderColumn width="15%">Já passou para Lemania</HeaderColumn>
        <HeaderColumn width="15%">Comissão</HeaderColumn>
        <HeaderColumn width="10%"></HeaderColumn>
      </HeaderLine>
      {
        funds?.length > 0 ?
        funds.map((elem, index) =>
          <Row key={index}>
            <RowColumn width="20%">{elem.fund}</RowColumn>
            <RowColumn width="36%">{elem.notes}</RowColumn>
            <RowColumn width="14%">{elem.amount}CHF</RowColumn>
            <RowColumn width="15%">{elem.lemania ? 'Sim' : 'Não'}</RowColumn>
            <RowColumn width="15%">{elem.commission ? 'Sim' : 'Não'}</RowColumn>
            <RowColumn width="10%"></RowColumn>
          </Row>
        )
        :
        <NoFunds>
          Não existem Fundos inseridos!
        </NoFunds>
      }
    </>
  );
}

export default LemaniaDetail;
