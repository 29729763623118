import client from '../config/AxiosConfig';

export const GetAddressList = async (currentPage, limit, filters) => await client.get(`/address/${currentPage}/${limit}?filter=${filters}`);

export const GetAddress = async (id) => await client.get(`/address/${id}`);

export const CreateAddress = async (data) => await client.post(`/address`, data);

export const UpdateAddress = async (id, data) => await client.put(`/address/${id}`, data);

export const DeleteAddress = async (id) => await client.delete(`/address/${id}`);

export const GetAddressFullList = async () => await client.get(`/address`);

export const ToggleAddressFavorite = async (identifier, data) => await client.put(`/address/${identifier}/toggle-favorite`, data);