import { Card, CardWrapper, CardTitle, FiltersText, SeeMore, UsersContainer, UserEmail } from '../styles';
import { Row, Col } from 'antd';
import { trim } from '../../../infra/utils/Trim';
import { FilesSelected, File, FileName, FileSize } from './styles';
import { getProcessState, getProcessPaymentState } from '../../../infra/utils/ProcessStates';
import React, { useState } from 'react'
import moment from 'moment-timezone';
import GetFile from '../../../infra/utils/GetFile';

const userslimit = 10;

const Details = ({ communication }) => {
  const [seeMore, setSeeMore] = useState(false);
  const users = trim(communication.emails, userslimit);

  return (
    <CardWrapper>
      <Row gutter={24} style={{ marginRight: '0px', paddingBottom: '20px' }}>
        <Col xl={12} sm={12} style={{paddingRight: '0px'}}>
          <CardTitle>Admin</CardTitle>
          <Card>
            {communication.user?.name}
          </Card>
        </Col>
        <Col xl={12} sm={12} style={{paddingRight: '0px'}}>
          <CardTitle>Data</CardTitle>
          <Card>
            {`${moment(communication.updatedAt).format('DD-MM-YYYY, HH:mm')}h`}
          </Card>
        </Col>
      </Row>  
      <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
        <CardTitle>Filtros</CardTitle>
        <Card>
          {communication.all_users && <FiltersText>Utilizadores: <span>Todos</span></FiltersText>}
          {communication.newsletter && <FiltersText>Inscrito na Newsletter: <span>Sim</span></FiltersText>}
          {communication.all_agents && <FiltersText>Agente: <span>Todos</span></FiltersText>}
          {communication.agent && <FiltersText>Agente: <span>{communication.agent?.name}</span></FiltersText>}
          {communication.all_responsibles && <FiltersText>Responsável: <span>Todos</span></FiltersText>}
          {communication.responsible && <FiltersText>Responsável: <span>{communication.responsible?.name}</span></FiltersText>}
          {communication.service && <FiltersText>Serviço: <span>{communication.service?.title?.pt}</span></FiltersText>}
          {communication.process_state && <FiltersText>Estado do Processo: <span>{getProcessState(communication.process_state)?.name}</span></FiltersText>}
          {communication.payment_state && <FiltersText>Estado do Pagamento: <span>{getProcessPaymentState(communication.payment_state)?.name}</span></FiltersText>}
        </Card>
      </Row>
      <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
        <CardTitle>Destinatários <span>({communication.emails.length} emails)</span></CardTitle>
        <Card>
          <UsersContainer>
            {!seeMore ? (
              <>
                {users?.new.map((user, index) => (
                  <UserEmail key={index}>
                    {user.email}
                    {users?.new.length - 1 === index ? '' : ','}
                    {users?.new.length - 1 === index ? '' : '\u00a0'}
                    {users?.new.length - 1 === index && users?.complete?.length > userslimit ? '...' : ''}
                  </UserEmail>
                ))}
                {users?.complete?.length > userslimit &&
                  <SeeMore onClick={() => setSeeMore(!seeMore)}>+{users?.showMore}</SeeMore>
                }
              </>
            ) : (
              users?.complete.map((user, index) => (
                <UserEmail key={index}>
                  {user.email}
                  {users?.complete.length - 1 === index ? '' : ','}
                  {"\u00a0"}
                </UserEmail>
              ))
            )
            }
          </UsersContainer>
        </Card>
      </Row>
      <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
        <CardTitle>Assunto</CardTitle>
        <Card>
          {communication.subject}
        </Card>
      </Row>
      <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
        <CardTitle>Descrição</CardTitle>
        <Card>
          <div dangerouslySetInnerHTML={{ __html: communication.content }} />
        </Card>
      </Row>
      <Row gutter={24} style={{ marginLeft: '0px', marginRight: '0px', paddingBottom: '20px' }}>
        <CardTitle>Ficheiros anexados</CardTitle>
        <Card>
          {
            communication.files?.length > 0 ?
            <FilesSelected>
              {
                communication.files.map((file, index) => 
                  <File key={index} onClick={() => GetFile(file._id)}>
                    <FileName>{file.filename}</FileName>
                    <FileSize>({(file.length / (1024 * 1024)).toFixed(2)}MB)</FileSize>
                  </File>
                )
              }
            </FilesSelected>
            :
            'Não existem ficheiros anexados'
          }
        </Card>
      </Row>
    </CardWrapper>
  )
}

export default Details;
